<template>

	
<div style="background-color:#ffffffd9">

<section v-html="termsContent"></section>
</div>

</template>


<script>

import axios from "axios";

import { getConfigDataFromLocalStorage } from '@/localStorageData/localStorageUtil.js';

export default {
	data() {
		return {

			termsContent: "",
			getTermsUrl: "",
			termsType: "",
			getLangname:""
		}
	},

	methods: {

		getConfig() {
			// let getConfigData = this.$store.state.data

			// let portalLocal = localStorage.getItem("portalData")
			// let getConfigData = JSON.parse(portalLocal)
			const getConfigData = getConfigDataFromLocalStorage();
			if (getConfigData) {
				let locale = this.getLangname;
				let getsiteContentData = getConfigData.payload.portal_configuration.site_content_data[locale]
				this.getTermsUrl = getsiteContentData.termsofuse.url
				this.termsType = getsiteContentData.termsofuse.type
				// console.log(getConfigData, 'head...about..1..')
				// console.log(this.getTermsUrl, 'head...about..2..')

				this.getConfigFunc();
			}
		},

		getConfigFunc() {
			axios.get(this.getTermsUrl, {
				headers: {
					"Content-Type": this.termsType,
				},
			})
				.then((response) => {

					this.termsContent = response.data
					// console.log(response.data, "getTermsUrlrgetTermsUrlrgetTermsUrlrab.....")

				})

				.catch((error) => {
					console.log(error, 'erroroor.....')
				})
		},
		updateContent() {
			this.getLangname = this.$i18n.locale;
			console.log(this.$i18n.locale, 'getcontententUrl...1')
			this.getConfig();
		}
	},
	watch:{
		"$i18n.locale": {
			handler: function () {
				this.updateContent();
				this.getConfig();
				this.getConfigFunc();
			},
			deep: true,
		},
	},

	created() {
		this.updateContent();
		// this.getConfig();
		this.getConfigFunc();
	}
}

</script>