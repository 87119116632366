<template>
  <div class="p-3" style="background-color:#ffffffd9">
        <section v-html="aboutContent"></section>
  </div>
   
</template>

<script>

import axios from "axios";

import { getConfigDataFromLocalStorage } from '@/localStorageData/localStorageUtil.js';

export default {
    data() {
        return {

            aboutContent:"",
            getAboutUrl: "",
            aboutType: "",
            getLangname: "",
        }
    },

    methods: {

        getConfig() {
            // let getConfigData = this.$store.state.data

            // let portalLocal = localStorage.getItem("portalData")
            // let getConfigData = JSON.parse(portalLocal)

             let locale = this.getLangname
            const getConfigData = getConfigDataFromLocalStorage();
            if (getConfigData) {
                let getsiteContentData = getConfigData.payload.portal_configuration.site_content_data
                // this.getAboutUrl = getConfigData.payload.portal_configuration.content_data.aboutus.url;
                // this.aboutType = getConfigData.payload.portal_configuration.content_data.aboutus.type

                Object.keys(getsiteContentData).forEach(v => {
                    if (v == locale) {
                this.getAboutUrl = getsiteContentData[v].aboutus.url
                this.aboutType = getsiteContentData[v].aboutus.type
                console.log(getConfigData, 'head...about..1..')
                // console.log(this.getAboutUrl, 'head...about..2..')
                    }
                })

                this.getConfigFunc();
            }
        },

        getConfigFunc() {
            axios.get(this.getAboutUrl, {
                headers: {
                    "Content-Type": this.aboutType,
                },
            })
                .then((response) => {

                    this.aboutContent = response.data
                    // console.log(response.data, "getAboutUrlrgetAboutUrlrgetAboutUrlrab.....")

                })

                .catch((error) => {
                    console.log(error, 'erroroor.....')
                })
        },
     updateContent() {
            let getlanglocale = this.$i18n.locale;

            this.$router.push({
                path: '/aboutus',
                query: { lang: getlanglocale }
            }).then(() => {
                this.getLangname = this.$route.query.lang;
                console.log(this.getLangname, 'getcontententUrl...7');
                this.getConfig();
            }).catch(() => {
                this.getLangname = this.$i18n.locale;
                console.log(this.getLangname, 'getcontententUrl...8');
                this.getConfig();
            });

            console.log(this.getLangname, 'getcontententUrl...1');
            this.getConfig();
        }

    },

    watch: {

        "$i18n.locale": {
            handler: function () {
                this.updateContent();
            },
            deep: true,
        },

        aboutContent: {
            handler() {
                // console.log('Aboutcontentupdated:', newVal);
            },
            deep: true,
        },
    },

    mounted() {
        this.updateContent();
    },

    created() {
        this.updateContent();
    }
}

</script>