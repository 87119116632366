<template>
    <v-container class="d-flex align-center justify-center">
        <div class="p-5 Error-section">

            <div>
                <div class="mb-5">
                    <v-img class="m-auto" :src="portalLogo" width="250px" height="105px"></v-img>
                </div>

                <div class="mb-5">
                    <p class="error-content-1">{{ $t("pageError.contentText1")}}</p>
                    <p class="error-content-2">{{ $t("pageError.contentText2") }}
                    </p>
                </div>

                <div class="d-flex justify-center">
                    <v-btn @click="added()" color="#162849" style="text-transform: none;">{{ $t("pageError.changeBtn")}}</v-btn>
                </div>
            </div>
        </div>
    </v-container>

</template>
<script>

import { getConfigDataFromLocalStorage } from '@/localStorageData/localStorageUtil.js';

export default {
    data() {
        return {
            portalLogo: "",
        }
    },
    methods: {
        added() {
            this.$router.push('/')
            document.getElementById("navbar").style.display = "block";
            document.getElementById("food").style.display = "block";
        },

        getConfig() {
            // Parse the decrypted data as JSON
            const getConfigData = getConfigDataFromLocalStorage();


            console.log(getConfigData, 'head...1')

            if (getConfigData) {

                this.portalLogo = getConfigData.payload.portal_configuration.logo_path
            }
        }
    },

    created(){
        this.getConfig();
    }
}



</script>

<style scoped>
.Error-section {
    background-color: #ffffffd9;
    border-radius: 20px;
}

.error-content-1 {
    font-size: 32px;
    font-family: 'Roboto';
    text-align: center;
    padding: 0px 20px;
}

.error-content-2 {
    font-size: 26px;
    font-family: 'Roboto';
    text-align: center;
    padding: 0px 20px;

}
>>>.v-container{
    height:100vh;
}
@media screen and (max-width:480px) {
    >>>.v-container{
        height:100%;
    }
}
</style>