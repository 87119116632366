<template>
  <main>
    <section class="slider-area">
      <div class="slider-active">
        <!-- This div will handle the background image -->
        <div class="slider-bg" :style="backgroundStyle"></div>
        
        <!-- This div contains the content, which stays fixed while the background changes -->
        <div class="slider-content-wrapper">
          <div class="container">
            <div class="row">
              <div class="col-xl-12 col-lg-12">
                <div class="slider-content text-center">
                  <h2 class="title" data-animation="fadeInUp" data-delay=".2s">
                    {{ $t("homePageContent.welcomeMsg.text1") }}
                  </h2>
                  <p data-animation="fadeInUp" data-delay=".4s">
                    {{ $t("homePageContent.welcomeMsg.text2") }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
export default {
  data() {
    return {
      currentSlide: 0,
      items: [
        { src: 'https://via.placeholder.com/800x400?text=Slide+1' },
        { src: 'https://via.placeholder.com/800x400?text=Slide+2' },
        { src: 'https://via.placeholder.com/800x400?text=Slide+3' }
      ],
      interval: null
    };
  },
  computed: {
    backgroundStyle() {
      return {
        backgroundImage: `url(${this.items[this.currentSlide].src})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        transition: 'background-image 0.8s ease-in-out'
      };
    }
  },
  mounted() {
    if (this.items.length > 1) {
      this.startAutoplay();
    }
  },
  methods: {
    startAutoplay() {
      this.interval = setInterval(() => {
        this.currentSlide = (this.currentSlide + 1) % this.items.length;
      }, 5000); // Change background every 5 seconds
    },
    stopAutoplay() {
      clearInterval(this.interval);
    }
  },
  beforeUnmount() {
    this.stopAutoplay();
  }
};
</script>

<style scoped>
.slider-area {
  position: relative;
  height: 400px; /* Set the desired height */
  overflow: hidden;
}

.slider-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1; /* Ensure it's behind the content */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.slider-content-wrapper {
  position: relative;
  z-index: 2; /* Ensure content is above the background */
}

.slider-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  text-align: center;
}
</style>
