<template>
  <div v-if="searchresult" class="mb-4">
    <div class="bg-image pt-2">
      <div class="container">
        <div style="overflow: unset; background-color: #e4dedc">
          <div class="py-2 px-3 rounded-3 row">
            <div class="col-md-4 col-12 pt-2 pe-0">
              <v-radio-group v-model="radios" inline>
                <v-radio
                  :label="this.$t('formsContents.options.roundTrip')"
                  value="one"
                  @click="act(1)"
                ></v-radio>
                <v-radio
                  :label="this.$t('formsContents.options.oneWay')"
                  value="two"
                  @click="act(2)"
                ></v-radio>
                <v-radio
                  :label="this.$t('formsContents.options.multiCity')"
                  value="three"
                  @click="act(3)"
                ></v-radio>
              </v-radio-group>
            </div>
            <div
              class="col-md-6 col-lg-2 col-sm-6 col-5 pt-md-3 pt-0 my-lg-0 my-3 align-center passengers-data"
            >
              <!-- <span style= "color:black;">{{oneway.class}}</span> -->
              <div class="input-styles rounded-border modify-data">
                <v-select
                  v-model="classType"
                  ref="mySelect"
                  class="select-2"
                  :items="classTypeitems"
                  variant="underlined"
                  item-title="text"
                  style="color: black"
                ></v-select>
              </div>
            </div>
            <div
              class="col-lg-3 col-sm-6 col-7 pt-md-3 pt-0 my-lg-0 my-3 passengers-data"
            >
              <div class="d-flex input-styles">
                <!-- <span class="d-flex me-2" style="font-size: 18px; color: gray">
                        <v-icon> mdi-account-outline </v-icon>
                      </span> -->

                <v-select
                  v-model="selectedCountry"
                  :items="countries"
                  ref="countrySelect"
                  variant="underlined"
                  class="select-1"
                >
                  <!-- <template #item="{ item }"> -->
                  <template #item="{}">
                    <div class="dropdown_section" style="cursor: pointer">
                      <div
                        class="row d-flex align-center my-2 mx-1"
                        style="height: 37px"
                        @click.stop
                      >
                        <div class="col-3" style="width: 38%">
                          <span
                            style="
                              font-size: 14px;
                              font-weight: 500;
                              color: #4e4d4d;
                            "
                            >{{ $t("formsContents.options.adult") }}</span
                          >
                        </div>
                        <div class="col-3 px-0" style="width: 9%">
                          <v-icon
                            style="width: auto; color: grey; font-size: 18px"
                          >
                            mdi-human-male-female
                          </v-icon>
                        </div>
                        <div
                          class="col-6 adult"
                          style="width: 50%; height: auto"
                        >
                          <div class="row" style="padding: 0px">
                            <div class="col-4" @click="deg1()">
                              <div class="adult-plus">-</div>
                            </div>
                            <div
                              class="col-4"
                              style="background-color: white"
                              align="center"
                            >
                              <div>{{ aduls }}</div>
                            </div>
                            <div class="col-4" @click="add1()">
                              <div class="adult-plus">+</div>
                            </div>
                          </div>
                        </div>
                        <!-- <div>{{ item.name }}</div> -->
                      </div>

                      <div
                        class="row d-flex align-center my-2 mx-1"
                        @click.stop
                      >
                        <div
                          class="col-4 d-flex flex-column pe-0"
                          style="width: 38%"
                        >
                          <span
                            style="
                              font-size: 14px;
                              font-weight: 500;
                              color: #4e4d4d;
                            "
                            >{{ $t("formsContents.options.children") }}</span
                          >
                          <span style="font-size: 11px; color: #4e4d4d"
                            >2-11(yrs)</span
                          >
                        </div>
                        <div class="col-2 px-0" style="width: 9%">
                          <v-icon
                            style="width: auto; color: grey; font-size: 18px"
                          >
                            mdi-human-child
                          </v-icon>
                        </div>
                        <div class="col-6 adult" style="width: 50%">
                          <div class="row" style="padding: 0px">
                            <div class="col-4" @click="deg2()">
                              <div class="adult-plus">-</div>
                            </div>
                            <div
                              class="col-4"
                              style="background-color: white"
                              align="center"
                            >
                              <div>{{ aduls1 }}</div>
                            </div>
                            <div class="col-4" @click="add2()">
                              <div class="adult-plus">+</div>
                            </div>
                          </div>
                        </div>
                        <!-- <div>{{ item.name }}</div> -->
                      </div>

                      <div
                        class="row d-flex align-center my-2 mx-1"
                        @click.stop
                      >
                        <div
                          class="col-5 d-flex flex-column pe-0"
                          style="width: 38%"
                        >
                          <span
                            style="
                              font-size: 14px;
                              font-weight: 500;
                              color: #4e4d4d;
                            "
                            >{{ $t("formsContents.options.infant") }}</span
                          >
                          <span style="font-size: 11px; color: #4e4d4d"
                            >2(yrs)</span
                          >
                        </div>

                        <div class="col-1 ps-0" style="width: 9%">
                          <v-icon
                            style="width: auto; color: grey; font-size: 18px"
                          >
                            mdi-baby-buggy
                          </v-icon>
                        </div>
                        <div class="col-6 adult" style="width: 50%">
                          <div class="row" style="padding: 0px">
                            <div class="col-4" @click="deg3()">
                              <div class="adult-plus">-</div>
                            </div>
                            <div
                              class="col-4"
                              style="background-color: white"
                              align="center"
                            >
                              <div>{{ aduls2 }}</div>
                            </div>
                            <div class="col-4" @click="add3()">
                              <div class="adult-plus">+</div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <!-- <div class="row d-flex align-center my-2 mx-1" @click.stop>
                                    <div class="col-4">
                                      <span style="
                                    font-size: 14px;
                                    font-weight: 500;
                                    color: grey;
                                  ">Cabin</span>
                                    </div>

                                    <div class="col-2" align="center">
                                      <v-icon style="
                                    width: auto;
                                    color: grey;
                                    font-size: 18px;
                                  ">
                                        mdi-seat-passenger
                                      </v-icon>
                                    </div>
                                    <div class="col-6">
                                      <div class="input-styles-1 select-option select-2">
                                        <v-select v-model="classType" ref="mySelect" @click="selectFunction()" :items="[
                                          'Economy',
                                          'Business',
                                          'First Class',
                                        ]" variant="underlined" style="color: gray"></v-select>
                                      </div>
                                    </div>
                                  </div> -->

                      <div
                        align="end"
                        class="mt-4 pe-3 d-flex justify-end"
                        @click="(travels = false), localdone(), closeDropdown()"
                      >
                        <v-btn
                          rounded="3"
                          color="#162849"
                          width="100px"
                          height="30px"
                          style="color: white"
                        >
                          {{ $t("formsContents.options.done") }}
                        </v-btn>
                      </div>
                    </div>
                  </template>

                  <template #selection="{}">
                    <div class="pax-fonts d-flex align-center">
                      <span
                        class="d-flex me-2"
                        style="font-size: 18px; color: gray"
                      >
                        <v-icon> mdi-account-outline </v-icon>
                      </span>
                      <span
                        >{{ resultDataPass }}
                        {{ $t("formsContents.options.passengers") }}</span
                      >
                      <!-- <span>{{ aduls }} Adt</span>
                                  <span v-if="aduls1 > 0">, {{ aduls1 }} Ch</span>
                                  <span v-if="aduls2 > 0">, {{ aduls2 }} Inf</span> -->

                      <!-- <span>, {{ oneway.class }}</span> -->
                    </div>
                  </template>
                </v-select>
              </div>
            </div>

            <div v-if="rount">
              <div class="row mt-3 gx-0">
                <div class="col-lg-10 col-12">
                  <div class="row">
                    <div class="col-lg-3 col-sm-6 mb-3">
                      <div class="labelTop">
                        <p class="m-0 ps-2 fw-bold" style="color: black">
                          {{ $t("formsContents.labels.fromlabel") }}
                        </p>
                      </div>
                      <div
                        class="d-flex input-styles input-collapse align-center"
                      >
                        <span class="d-flex align-center">
                          <v-icon>mdi-airplane-takeoff</v-icon>
                        </span>
                        <span class="w-100">
                          <div class="vas-autocomplete pe-2">
                            <div class="d-flex align-center justify-content-between pe-1">
                              <div>
                                <input
                                  type="text"
                                  v-model="oneway.from"
                                  placeholder="From"
                                  @keyup="typeCity(oneway.from, 1)"
                                  class="inter-input text-truncate"
                                  @click="
                                    (oneway.from = ''),
                                      (formres = true),
                                      (formres1 = false);
                                    city1 = [];
                                  "
                                  @change="onChangeFrom()"
                                  ref="fromAutoComplete"
                                />
                              </div>
                              <div v-if="selectedFrom">
                                <v-icon
                                  @click="fromInputData()"
                                  size="16"
                                  color="grey"
                                  >mdi-close</v-icon
                                >
                              </div>
                            </div>
                            <div v-if="typedrop">
                              <div
                                class="autocompete-menual-dropdown"
                                v-if="this.city.length > 0"
                              >
                                <div v-for="(data, index) in city" :key="index">
                                  <div
                                    @click="datachange(data)"
                                    :style="
                                      index == city.length - 1
                                        ? ''
                                        : 'border-bottom:1px solid lightgray'
                                    "
                                    class="d-flex va-back"
                                  >
                                    <div
                                      style="display: flex; align-items: center"
                                    >
                                      <v-icon
                                        style="transform: rotate(45deg)"
                                        color="gray"
                                        size="20"
                                      >
                                        {{
                                          data.location === "airport"
                                            ? "mdi-airplane"
                                            : "mdi-radiobox-marked"
                                        }}</v-icon
                                      >
                                    </div>

                                    <div class="m-1 va-content">
                                      {{ data.iata }}, {{ data.city }},{{
                                        data.name
                                      }}
                                    </div>
                                  </div>
                                  <!-- -------group--- -->

                                  <div v-if="data.group_airports.length > 0">
                                    <div
                                      v-for="group in data.group_airports"
                                      :key="group"
                                    >
                                      <div
                                        v-if="
                                          data.group_airport_city != group.iata
                                        "
                                      >
                                        <div
                                          class="d-flex va-back"
                                          @click="datachange(group)"
                                        >
                                          <div
                                            style="
                                              display: flex;
                                              align-items: center;
                                            "
                                            class="ms-3"
                                          >
                                            <v-icon
                                              style="transform: rotate(45deg)"
                                              color="gray"
                                              size="20"
                                              >mdi-airplane</v-icon
                                            >
                                          </div>

                                          <div class="m-1 va-content">
                                            {{ group.iata }},
                                            {{ group.city }},{{ group.name }}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </span>
                        <div v-if="fromloader">
                          <div
                            class="spinner-border spinner-border-sm"
                            style="
                              display: flex;
                              align-items: center;
                              color: rgb(47, 47, 47);
                            "
                            role="status"
                          ></div>
                        </div>
                      </div>
                      <div class="text-start">
                        <span class="p-error" v-if="fromErrorMessage">{{
                          $t("formsContents.errorMsg.departReq")
                        }}</span>
                        <br v-if="fromErrorMessage && maximumletter" />
                        <span class="p-error" v-if="maximumletter">{{
                          $t("formsContents.errorMsg.enterMinimum")
                        }}</span>
                      </div>
                    </div>

                    <div class="col-lg-3 col-sm-6 mb-3">
                      <div class="labelTop">
                        <p class="m-0 ps-2 fw-bold" style="color: black">
                          {{ $t("formsContents.labels.tolabel") }}
                        </p>
                      </div>
                      <div class="d-flex input-styles align-center">
                        <span class="d-flex align-center">
                          <v-icon>mdi-airplane-landing</v-icon>
                        </span>

                        <span class="w-100">
                          <div class="vas-autocomplete pe-2">
                            <div class="d-flex align-center justify-content-between pe-1">
                              <div>
                                <input
                                  type="text"
                                  placeholder="To"
                                  v-model="oneway.to"
                                  @keyup="typeCity(oneway.to, 2)"
                                  class="inter-input text-truncate"
                                  @click="
                                    (oneway.to = ''),
                                      (formres1 = true),
                                      (formres = false),
                                      (city = [])
                                  "
                                  @change="onChangeTo()"
                                  ref="toAutoComplete"
                                />
                              </div>
                              <div v-if="selectedTo">
                                <v-icon
                                  @click="toInputData()"
                                  size="16"
                                  color="grey"
                                  >mdi-close</v-icon
                                >
                              </div>
                            </div>
                            <div v-if="typedrop1">
                              <div
                                class="autocompete-menual-dropdown"
                                v-if="this.city1.length > 0"
                              >
                                <div
                                  v-for="(data, index) in city1"
                                  :key="index"
                                >
                                  <div
                                    @click="datachangeTo(data)"
                                    :style="
                                      index == city1.length - 1
                                        ? ''
                                        : 'border-bottom:1px solid lightgray'
                                    "
                                    class="d-flex va-back"
                                  >
                                    <div
                                      style="display: flex; align-items: center"
                                    >
                                      <v-icon
                                        style="transform: rotate(45deg)"
                                        color="gray"
                                        size="20"
                                      >
                                        {{
                                          data.location === "airport"
                                            ? "mdi-airplane"
                                            : "mdi-radiobox-marked"
                                        }}
                                      </v-icon>
                                    </div>

                                    <div class="m-1 va-content">
                                      {{ data.iata }},{{ data.city }},{{
                                        data.name
                                      }}
                                    </div>
                                  </div>

                                  <div
                                    class="ms-3"
                                    v-if="data.group_airports.length > 0"
                                  >
                                    <div
                                      v-for="group in data.group_airports"
                                      :key="group"
                                    >
                                      <div
                                        v-if="
                                          data.group_airport_city != group.iata
                                        "
                                      >
                                        <div
                                          class="d-flex va-back"
                                          @click="datachangeTo(group)"
                                        >
                                          <div
                                            style="
                                              display: flex;
                                              align-items: center;
                                            "
                                          >
                                            <v-icon
                                              style="transform: rotate(45deg)"
                                              color="gray"
                                              size="20"
                                              >mdi-airplane</v-icon
                                            >
                                          </div>

                                          <div class="m-1 va-content">
                                            {{ group.iata }},{{ group.city }},{{
                                              group.name
                                            }}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </span>
                        <div v-if="toloader">
                          <div
                            class="spinner-border spinner-border-sm"
                            style="
                              display: flex;
                              align-items: center;
                              color: rgb(47, 47, 47);
                            "
                            role="status"
                          ></div>
                        </div>
                      </div>
                      <div class="text-start">
                        <span class="p-error" v-if="toErrorMessage">{{
                          $t("formsContents.errorMsg.arrivalReq")
                        }}</span>
                        <br v-if="toErrorMessage && maximumletter1" />
                        <span class="p-error" v-if="maximumletter1">{{
                          $t("formsContents.errorMsg.enterMinimum")
                        }}</span>
                      </div>
                    </div>

                    <div class="col-lg-3 col-sm-6 mb-3">
                      <div class="labelTop">
                        <p class="m-0 ps-2 fw-bold" style="color: black">
                          {{ $t("formsContents.labels.departDate") }}
                        </p>
                      </div>
                      <div class="d-flex input-styles">
                        <span class="d-flex align-center">
                          <v-icon>mdi-calendar</v-icon>
                        </span>

                        <span class="p-float-label">
                          <VueDatePicker
                            ref="dpRef1"
                            v-model="oneway.dedate"
                            placeholder="Depart Date"
                            :min-date="new Date()"
                            :max-date="
                              new Date(
                                new Date().getFullYear() + 1,
                                new Date().getMonth(),
                                new Date().getDate()
                              )
                            "
                            :format="dateFormat"
                            :hide-navigation="['time']"
                            auto-apply
                            :multi-calendars="multiCalender"
                            @update:modelValue="fromDateChanged"
                            :on-click-outside="ClickOutDepat"
                            :month-change-on-scroll="false"
                            class="px-0"
                          />
                        </span>
                      </div>
                      <div class="text-start">
                        <span class="p-error" v-if="fromDateError">{{
                          $t("formsContents.errorMsg.depdateReq")
                        }}</span>
                      </div>
                    </div>

                    <div class="col-lg-3 col-sm-6 mb-3">
                      <div class="labelTop">
                        <p class="m-0 ps-2 fw-bold" style="color: black">
                          {{ $t("formsContents.labels.returnDate") }}
                        </p>
                      </div>
                      <div class="d-flex input-styles">
                        <span class="d-flex align-center">
                          <v-icon>mdi-calendar</v-icon>
                        </span>
                        <span class="p-float-label">
                          <VueDatePicker
                            ref="dpRef2"
                            v-model="oneway.redate"
                            :format="dateFormat"
                            :min-date="oneway.dedate"
                            placeholder="Return Date"
                            :max-date="
                              new Date(
                                new Date().getFullYear() + 1,
                                new Date().getMonth(),
                                new Date().getDate()
                              )
                            "
                            :hide-navigation="['time']"
                            auto-apply
                            @update:modelValue="toDateChanged"
                            :multi-calendars="multiCalender"
                            :on-click-outside="ClickOutReturn"
                          />
                        </span>
                      </div>
                      <div class="text-start">
                        {{ console.log(toDateError, "yesconditon......3") }}
                        <span class="p-error" v-if="toDateError">{{
                          $t("formsContents.errorMsg.retdateReq")
                        }}</span>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  class="col-lg-2 mt-4 d-flex align-center justify-center justify-content-lg-start magnify-btn1"
                >
                  <div @click="sended()">
                    <v-btn
                      color="#26225e"
                      height="40px"
                      width="80px"
                      class="ms-lg-3 ms-0"
                      rounded="1"
                    >
                      <v-icon color="white">mdi-magnify</v-icon>
                    </v-btn>
                  </div>
                </div>
              </div>
            </div>

            <div v-if="Oneway">
              <div class="row mt-3 gx-0">
                <div class="col-lg-10 col-12">
                  <div class="row">
                    <div class="col-lg-3 col-sm-6 mb-3">
                      <div class="labelTop">
                        <p class="m-0 ps-2 fw-bold" style="color: black">
                          {{ $t("formsContents.labels.fromlabel") }}
                        </p>
                      </div>
                      <div class="d-flex input-styles align-center">
                        <span class="d-flex align-center">
                          <v-icon>mdi-airplane-takeoff</v-icon>
                        </span>
                        <span class="w-100">
                          <div class="vas-autocomplete pe-2">
                            <div class="d-flex align-center justify-content-between pe-1">
                              <div>
                                <input
                                  type="text"
                                  placeholder="From"
                                  v-model="oneway.from"
                                  @keyup="typeCity(oneway.from, 1)"
                                  class="inter-input text-truncate"
                                  @click="
                                    (oneway.from = ''),
                                      (formres = true),
                                      (formres1 = false);
                                    city1 = [];
                                  "
                                  @change="onChangeFrom()"
                                />
                              </div>
                              <div v-if="selectedFrom">
                                <v-icon
                                  @click="fromInputData()"
                                  size="16"
                                  color="grey"
                                  >mdi-close</v-icon
                                >
                              </div>
                            </div>
                            <div v-if="typedrop">
                              <div
                                class="autocompete-menual-dropdown"
                                v-if="this.city.length > 0"
                              >
                                <div v-for="(data, index) in city" :key="index">
                                  <div
                                    @click="datachange(data)"
                                    :style="
                                      index == city.length - 1
                                        ? ''
                                        : 'border-bottom:1px solid lightgray'
                                    "
                                    class="d-flex va-back"
                                  >
                                    <div
                                      style="display: flex; align-items: center"
                                    >
                                      <v-icon
                                        style="transform: rotate(45deg)"
                                        color="gray"
                                        size="20"
                                      >
                                        {{
                                          data.location === "airport"
                                            ? "mdi-airplane"
                                            : "mdi-radiobox-marked"
                                        }}
                                      </v-icon>
                                    </div>

                                    <div class="m-1 va-content">
                                      {{ data.iata }},{{ data.city }},{{
                                        data.name
                                      }}
                                    </div>
                                  </div>
                                  <!-- -------group--- -->

                                  <div v-if="data.group_airports.length > 0">
                                    <div
                                      v-for="group in data.group_airports"
                                      :key="group"
                                    >
                                      <div
                                        v-if="
                                          data.group_airport_city != group.iata
                                        "
                                      >
                                        <div
                                          class="d-flex va-back"
                                          @click="datachange(group)"
                                        >
                                          <div
                                            style="
                                              display: flex;
                                              align-items: center;
                                            "
                                            class="ms-3"
                                          >
                                            <v-icon
                                              style="transform: rotate(45deg)"
                                              color="gray"
                                              size="20"
                                              >mdi-airplane</v-icon
                                            >
                                          </div>

                                          <div class="m-1 va-content">
                                            {{ group.iata }},{{ group.city }},{{
                                              group.name
                                            }}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </span>
                        <div v-if="fromloader">
                          <div
                            class="spinner-border spinner-border-sm"
                            style="
                              display: flex;
                              align-items: center;
                              color: rgb(47, 47, 47);
                            "
                            role="status"
                          ></div>
                        </div>
                      </div>
                      <div class="text-start">
                        <span class="p-error" v-if="fromErrorMessage">{{
                          $t("formsContents.errorMsg.departReq")
                        }}</span>
                        <br v-if="fromErrorMessage && maximumletter" />
                        <span class="p-error" v-if="maximumletter">{{
                          $t("formsContents.errorMsg.enterMinimum")
                        }}</span>
                      </div>
                    </div>

                    <div class="col-lg-3 col-sm-6 mb-3">
                      <div class="labelTop">
                        <p class="m-0 ps-2 fw-bold" style="color: black">
                          {{ $t("formsContents.labels.tolabel") }}
                        </p>
                      </div>
                      <div class="d-flex input-styles align-center">
                        <span class="d-flex align-center">
                          <v-icon>mdi-airplane-landing</v-icon>
                        </span>

                        <span class="w-100">
                          <div class="vas-autocomplete pe-2">
                            <div class="d-flex align-center justify-content-between pe-1">
                              <div>
                                <input
                                  type="text"
                                  placeholder="To"
                                  v-model="oneway.to"
                                  @keyup="typeCity(oneway.to, 2)"
                                  class="inter-input text-truncate"
                                  @click="
                                    (oneway.to = ''),
                                      (formres1 = true),
                                      (formres = false),
                                      (city = [])
                                  "
                                  @change="onChangeTo()"
                                />
                              </div>
                              <div v-if="selectedTo">
                                <v-icon
                                  @click="toInputData()"
                                  size="16"
                                  color="grey"
                                  >mdi-close</v-icon
                                >
                              </div>
                            </div>
                            <div v-if="typedrop1">
                              <div
                                class="autocompete-menual-dropdown"
                                v-if="this.city1.length > 0"
                              >
                                <div
                                  v-for="(data, index) in city1"
                                  :key="index"
                                >
                                  <div
                                    @click="datachangeTo(data)"
                                    :style="
                                      index == city1.length - 1
                                        ? ''
                                        : 'border-bottom:1px solid lightgray'
                                    "
                                    class="d-flex va-back"
                                  >
                                    <div
                                      style="display: flex; align-items: center"
                                    >
                                      <v-icon
                                        style="transform: rotate(45deg)"
                                        color="gray"
                                        size="20"
                                      >
                                        {{
                                          data.location === "airport"
                                            ? "mdi-airplane"
                                            : "mdi-radiobox-marked"
                                        }}
                                      </v-icon>
                                    </div>

                                    <div class="m-1 va-content">
                                      {{ data.iata }},{{ data.city }},{{
                                        data.name
                                      }}
                                    </div>
                                  </div>

                                  <div
                                    class="ms-3"
                                    v-if="data.group_airports.length > 0"
                                  >
                                    <div
                                      v-for="group in data.group_airports"
                                      :key="group"
                                    >
                                      <div
                                        v-if="
                                          data.group_airport_city != group.iata
                                        "
                                      >
                                        <div
                                          class="d-flex va-back"
                                          @click="datachangeTo(group)"
                                        >
                                          <div
                                            style="
                                              display: flex;
                                              align-items: center;
                                            "
                                          >
                                            <v-icon
                                              style="transform: rotate(45deg)"
                                              color="gray"
                                              size="20"
                                              >mdi-airplane</v-icon
                                            >
                                          </div>

                                          <div class="m-1 va-content">
                                            {{ group.iata }},{{ group.city }},{{
                                              group.name
                                            }}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </span>
                        <div v-if="toloader">
                          <div
                            class="spinner-border spinner-border-sm"
                            style="
                              display: flex;
                              align-items: center;
                              color: rgb(47, 47, 47);
                            "
                            role="status"
                          ></div>
                        </div>
                      </div>
                      <div class="text-start">
                        <span class="p-error" v-if="toErrorMessage">{{
                          $t("formsContents.errorMsg.arrivalReq")
                        }}</span>
                        <br v-if="toErrorMessage && maximumletter1" />
                        <span class="p-error" v-if="maximumletter1">{{
                          $t("formsContents.errorMsg.enterMinimum")
                        }}</span>
                      </div>
                    </div>

                    <div class="col-lg-3 col-sm-6 mb-3">
                      <div class="labelTop">
                        <p class="m-0 ps-2 fw-bold" style="color: black">
                          {{ $t("formsContents.labels.departDate") }}
                        </p>
                      </div>
                      <div class="d-flex input-styles">
                        <span class="d-flex align-center">
                          <v-icon>mdi-calendar</v-icon>
                        </span>

                        <span class="p-float-label">
                          <VueDatePicker
                            ref="dpRef1"
                            placeholder="Depart Date"
                            v-model="oneway.dedate"
                            :min-date="new Date()"
                            :max-date="
                              new Date(
                                new Date().getFullYear() + 1,
                                new Date().getMonth(),
                                new Date().getDate()
                              )
                            "
                            :format="dateFormat"
                            :hide-navigation="['time']"
                            auto-apply
                            :multi-calendars="multiCalender"
                            @update:modelValue="fromDateChanged"
                            :on-click-outside="ClickOutDepat"
                            class="px-0"
                          />
                        </span>
                      </div>
                      <div class="text-start">
                        <span class="p-error" v-if="fromDateError">{{
                          $t("formsContents.errorMsg.depdateReq")
                        }}</span>
                      </div>
                    </div>

                    <div
                      class="col-lg-3 col-sm-6 mb-3"
                      :disabled="true"
                      style="pointer-events: none; opacity: 0.5"
                    >
                      <div class="labelTop">
                        <p class="m-0 ps-2 fw-bold" style="color: black">
                          {{ $t("formsContents.labels.returnDate") }}
                        </p>
                      </div>
                      <div
                        class="d-flex input-styles"
                        @click="triggerFunction()"
                      >
                        <span class="d-flex align-center">
                          <v-icon>mdi-calendar</v-icon>
                        </span>
                        <span class="p-float-label">
                          <!-- <span
                              style="
                                font-size: 14px;
                                color: #242424;
                                font-weight: 600;
                              "
                              class="datepicker-label2 pb-1"
                            >
                              Return Date
                            </span> -->
                          <VueDatePicker
                            disabled
                            placeholder="Return Date"
                            :format="dateFormat"
                            :min-date="oneway.dedate"
                            :max-date="
                              new Date(
                                new Date().getFullYear() + 1,
                                new Date().getMonth(),
                                new Date().getDate()
                              )
                            "
                            :hide-navigation="['time']"
                            auto-apply
                            @update:modelValue="toDateChanged"
                            :multi-calendars="multiCalender"
                            :on-click-outside="ClickOutReturn"
                            :month-change-on-scroll="false"
                          />

                          <!-- <VueDatePicker ref="dpRef2" v-model="oneway.redate" :format="dateFormat" :min-date="oneway.dedate"
                        :max-date="new Date(
                          new Date().getFullYear() + 1,
                          new Date().getMonth(),
                          new Date().getDate()
                        )
                          " :hide-navigation="['time']" auto-apply @update:modelValue="toDateChanged"
                        :on-click-outside="ClickOutReturn" /> -->
                        </span>
                      </div>
                      <div class="text-start">
                        <span class="p-error" v-if="toDateError">{{
                          $t("formsContents.errorMsg.retdateReq")
                        }}</span>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  class="col-lg-1 mt-2 d-flex align-center justify-center justify-content-lg-start magnify-btn"
                >
                  <div @click="sendedone()">
                    <v-btn
                      color="#26225e"
                      height="40px"
                      width="80"
                      class="ms-lg-3 ms-0"
                      rounded="1"
                    >
                      <v-icon color="white">mdi-magnify</v-icon>
                    </v-btn>
                  </div>
                </div>
              </div>
            </div>

            <div v-if="multicity">
              <div class="row mt-3 gx-0">
                <div class="col-lg-8 col-12">
                  <div
                    v-for="(datas, index) of multi_city.itinery"
                    :key="index"
                  >
                    <div class="row itin_Borders">
                      <div class="col-lg-4 col-sm-6 mb-3">
                        <div class="labelTop">
                          <p class="m-0 ps-2 fw-bold" style="color: black">
                            {{ $t("formsContents.labels.fromlabel") }}
                          </p>
                        </div>
                        <div class="d-flex input-styles align-center">
                          <span class="d-flex align-center">
                            <v-icon>mdi-airplane-takeoff</v-icon>
                          </span>
                          <span class="w-100">
                            <div class="vas-autocomplete">
                              <div class="d-flex align-center justify-content-between pe-1">
                                <div>
                                  <input
                                    type="text"
                                    placeholder="From"
                                    style="cursor: pointer"
                                    v-model="datas.from"
                                    @click="multi_city.itinery[index].from = ''"
                                    @keyup="typeMultiCity(datas.from, index, 1)"
                                    class="inter-input text-truncate"
                                    ref="multiFromAutocomplete"
                                  />
                                </div>
                                <div
                                  v-if="datas.selectedFrom"
                                  class="col-2 ps-0"
                                >
                                  <v-icon
                                    @click="multiFrom(index)"
                                    size="16"
                                    color="grey"
                                    >mdi-close</v-icon
                                  >
                                </div>
                              </div>
                              <!-- {{console.log(data,'data.autocomfrom')}} -->
                              <div
                                v-if="
                                  multi_city.itinery[index].autocomfrom
                                    ? true
                                    : false
                                "
                              >
                                <div
                                  class="autocompete-menual-dropdown"
                                  v-if="
                                    this.multidrop.length > 0 ? true : false
                                  "
                                >
                                  <div
                                    v-for="(data1, index1) in multidrop"
                                    :key="index1"
                                  >
                                    <!-- {{console.log(data1,"data1data1")}} -->
                                    <div
                                      @click="datachangeMuti(data1, index)"
                                      :style="
                                        index1 == multidrop.length - 1
                                          ? ''
                                          : 'border-bottom:1px solid lightgray'
                                      "
                                      class="d-flex p-2 va-back"
                                    >
                                      <div
                                        style="
                                          display: flex;
                                          align-items: center;
                                        "
                                      >
                                        <v-icon
                                          style="transform: rotate(45deg)"
                                          color="gray"
                                          size="20"
                                          >mdi-airplane</v-icon
                                        >
                                      </div>

                                      <div class="ms-1 va-content">
                                        {{ data1.iata }},{{ data1.city }},{{
                                          data1.name
                                        }}
                                      </div>
                                    </div>

                                    <div v-if="data1.group_airports.length > 0">
                                      <div
                                        v-for="(
                                          group, index1
                                        ) in data1.group_airports"
                                        :key="index1"
                                      >
                                        <div
                                          v-if="
                                            data1.group_airport_city !=
                                            group.iata
                                          "
                                        >
                                          <div
                                            class="d-flex va-back"
                                            @click="
                                              datachangeMuti(group, index1)
                                            "
                                          >
                                            <div
                                              style="
                                                display: flex;
                                                align-items: center;
                                              "
                                              class="ms-3"
                                            >
                                              <v-icon
                                                style="transform: rotate(45deg)"
                                                color="gray"
                                                size="20"
                                                >mdi-airplane</v-icon
                                              >
                                            </div>

                                            <div class="m-1 va-content">
                                              {{ group.iata }},{{
                                                group.city
                                              }},{{ group.name }}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </span>
                          <div v-if="datas.autocomfrom">
                            <div
                              class="spinner-border spinner-border-sm"
                              style="
                                display: flex;
                                align-items: center;
                                color: rgb(47, 47, 47);
                              "
                              role="status"
                            ></div>
                          </div>
                        </div>
                        <div class="text-start">
                          <span class="p-error" v-if="datas.multiFromError">{{
                            $t("formsContents.errorMsg.departReq")
                          }}</span>
                          <!-- <br v-if="fromErrorMessage && maximumletter" />
                          <span class="p-error" v-if="maximumletter"
                            >Enter minimum 3 letters</span
                          > -->
                        </div>
                      </div>

                      <div class="col-lg-4 col-sm-6 mb-3">
                        <div class="labelTop">
                          <p class="m-0 ps-2 fw-bold" style="color: black">
                            {{ $t("formsContents.labels.tolabel") }}
                          </p>
                        </div>
                        <div class="d-flex input-styles align-center">
                          <span class="d-flex align-center">
                            <v-icon>mdi-airplane-landing</v-icon>
                          </span>

                          <span class="w-100">
                            <div class="vas-autocomplete">
                              <div class="d-flex align-center justify-content-between pe-1">
                                <div>
                                  <input
                                    style="cursor: pointer"
                                    type="text"
                                    placeholder="To"
                                    v-model="datas.to"
                                    @click="multi_city.itinery[index].to = ''"
                                    @keyup="typeMultiCity(datas.to, index, 2)"
                                    class="inter-input text-truncate"
                                    ref="multiToAutocomplete"
                                  />
                                </div>
                                <div v-if="datas.selectedTo" class="col-2 ps-0">
                                  <v-icon
                                    @click="multiTo(index)"
                                    size="16"
                                    color="grey"
                                    >mdi-close</v-icon
                                  >
                                </div>
                              </div>

                              <div v-if="datas.autocomTo">
                                <div
                                  class="autocompete-menual-dropdown"
                                  v-if="multidrop1.length > 0 ? true : false"
                                >
                                  <div
                                    v-for="(data, index1) in multidrop1"
                                    :key="index1"
                                  >
                                    <div
                                      @click="datachangeMutiTo(data, index)"
                                      :style="
                                        index1 == multidrop1.length - 1
                                          ? ''
                                          : 'border-bottom:1px solid lightgray'
                                      "
                                      class="d-flex p-2 va-back"
                                    >
                                      <div
                                        style="
                                          display: flex;
                                          align-items: center;
                                        "
                                      >
                                        <v-icon
                                          style="transform: rotate(45deg)"
                                          color="gray"
                                          size="20"
                                        >
                                          {{
                                            data.location === "airport"
                                              ? "mdi-airplane"
                                              : "mdi-radiobox-marked"
                                          }}
                                        </v-icon>
                                      </div>

                                      <div class="ms-1 va-content">
                                        {{ data.iata }},{{ data.city }},{{
                                          data.name
                                        }}
                                      </div>
                                    </div>

                                    <div v-if="data.group_airports.length > 0">
                                      <div
                                        v-for="(
                                          group, index1
                                        ) in data.group_airports"
                                        :key="index1"
                                      >
                                        <div
                                          v-if="
                                            data.group_airport_city !=
                                            group.iata
                                          "
                                        >
                                          <div
                                            class="d-flex va-back"
                                            @click="
                                              datachangeMutiTo(group, index1)
                                            "
                                          >
                                            <div
                                              style="
                                                display: flex;
                                                align-items: center;
                                              "
                                              class="ms-3"
                                            >
                                              <v-icon
                                                style="transform: rotate(45deg)"
                                                color="gray"
                                                size="20"
                                                >mdi-airplane</v-icon
                                              >
                                            </div>

                                            <div class="m-1 va-content">
                                              {{ group.iata }},{{
                                                group.city
                                              }},{{ group.name }}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </span>
                          <div v-if="datas.autocomTo">
                            <div
                              class="spinner-border spinner-border-sm"
                              style="
                                display: flex;
                                align-items: center;
                                color: rgb(47, 47, 47);
                              "
                              role="status"
                            ></div>
                          </div>
                        </div>
                        <div class="text-start">
                          <span class="p-error" v-if="datas.multiToError">{{
                            $t("formsContents.errorMsg.arrivalReq")
                          }}</span>
                          <!-- <br v-if="toErrorMessage && maximumletter1" />
                          <span class="p-error" v-if="maximumletter1"
                            >Enter minimum 3 letters</span
                          > -->
                        </div>
                      </div>

                      <div class="col-lg-4 col-sm-6 mb-3">
                        <div class="labelTop">
                          <p class="m-0 ps-2 fw-bold" style="color: black">
                            {{ $t("formsContents.labels.departDate") }}
                          </p>
                        </div>
                        <div class="d-flex input-styles">
                          <span class="d-flex align-center">
                            <v-icon>mdi-calendar</v-icon>
                          </span>

                          <span class="">
                            <VueDatePicker
                              ref="dpRef4"
                              v-model="datas.departDate"
                              placeholder="Depart Date"
                              :start-date="
                                getDatepicker(multi_city.itinery, index)
                              "
                              :min-date="
                                getDatepicker(multi_city.itinery, index)
                              "
                              :max-date="
                                new Date(
                                  new Date().getFullYear() + 1,
                                  new Date().getMonth(),
                                  new Date().getDate()
                                )
                              "
                              :format="dateFormat"
                              :hide-navigation="['time']"
                              auto-apply
                              :multi-calendars="multiCalender"
                              @update:modelValue="
                                (newValue) => multiDateChanged(newValue, index)
                              "
                              :on-click-outside="multiClickOutside()"
                              :month-change-on-scroll="false"
                              class="px-0"
                            />
                          </span>
                        </div>
                        <div class="text-start">
                          <span class="p-error" v-if="datas.multiDateError">{{
                            $t("formsContents.errorMsg.depdateReq")
                          }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 col-12 d-grid">
                  <div
                    class="row justify-content-between"
                    style="margin-bottom: 16px"
                  >
                    <div
                      class="col-md-8 d-flex align-end justify-content-sm-start justify-content-end mb-1"
                    >
                      <span
                        class="d-flex align-center ms-1 mb-1"
                        style="cursor: pointer"
                        @click="deleteSegment(index)"
                        v-if="multi_city.itinery.length > 2"
                      >
                        <v-icon color="red" size="20">mdi-close-octagon</v-icon>
                      </span>
                      <span
                        class="d-flex align-center add_style ms-2 order-sm-0 order-1"
                        style="width: 130px"
                        @click="multiSend(index)"
                        v-if="addmore"
                      >
                        <v-icon color="white" size="20">mdi-plus-circle</v-icon>
                        <span
                          class="ms-1"
                          style="font-size: 12; font-weight: 600"
                          >{{ $t("formsContents.options.addMore") }}</span
                        >
                      </span>
                      <span
                        class="d-flex align-center clear_style ms-2 order-sm-1 order-0"
                        style="width: 125px"
                        @click="clearall1()"
                        v-if="multi_city.itinery.length > 3"
                      >
                        <v-icon color="white" size="20"
                          >mdi-close-box-multiple</v-icon
                        >
                        <span
                          class="ms-1"
                          style="
                            font-size: 12;
                            font-weight: 600;
                            cursor: pointer;
                          "
                          >{{ $t("formsContents.options.clearAll") }}</span
                        >
                      </span>

                      <!-- <span class="d-flex align-center add_style" @click="send(index)" v-if="addmore">
                        <v-icon color="green" size="20" style="width: auto;">mdi-plus-circle</v-icon>
                        <span class="ms-1" style="font-size:14; font-weight: 600;">Add Moreee</span>
                      </span> -->
                    </div>
                    <div
                      class="col-lg-4 mt-lg-0 mt-4 d-flex pe-2 align-end justify-center justify-content-lg-end magnify-btn"
                    >
                      <div @click="sendedMulti()">
                        <v-btn
                          color="#26225e"
                          height="40px"
                          width="110px"
                          class="ms-lg-3 ms-0"
                          rounded="1"
                        >
                          <v-icon color="white">mdi-magnify</v-icon>
                        </v-btn>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              v-if="rount"
              class="col-lg-1 col-sm-5 mt-3 mt-lg-0 d-flex align-center justify-center justify-content-lg-start magnify-btn2"
            >
              <div @click="sended()">
                <v-btn color="#26225e" height="40px" width="120" rounded="1">
                  <v-icon color="white">mdi-magnify</v-icon>
                </v-btn>
              </div>
            </div>
            <div
              v-if="Oneway"
              class="col-lg-1 col-sm-5 mt-3 mt-lg-0 d-flex align-center justify-center justify-content-lg-start magnify-btn2"
            >
              <div @click="sendedone()">
                <v-btn color="#26225e" height="40px" width="120" rounded="1">
                  <v-icon color="white">mdi-magnify</v-icon>
                </v-btn>
              </div>
            </div>

            <!-- <div class="my-2 text-center">
                  <span class="not-collapsed" data-bs-toggle="collapse" href="#multiCollapseExample2" aria-expanded="true"
                    aria-controls="multiCollapseExample2"
                    style="padding-right: 5px;cursor: pointer;font-size: 12px;letter-spacing: 1px;">
                    <span style="color:#0D6EFD; text-decoration:underline;">Advanced Options</span>
                    <span><v-icon style="color:#0D6EFD;">mdi-menu-down</v-icon></span>
                  </span>
                </div> -->
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row mt-4">
        <div class="col-lg-3 mb-2 mb-lg-0">
          <v-card>
            <div style="background-color: rgb(37 33 94); padding: 3px">
              <div class="row p-2 align-center" style="color: white">
                <div class="col-6">
                  <div>
                    <!-- <v-icon size="20px"> mdi-filter-variant </v-icon> -->
                    <i class="fa fa-sliders" style="font-size: 20px"></i>
                    <span style="font-size: 16px" class="ms-2">
                      {{ $t("searchPageContent.filterContent.filterBy") }}</span
                    >
                  </div>
                </div>
                <div class="col-4" align="center">
                  <!-- <div>Reset All</div> -->
                </div>
                <div class="col-2 filter-toggle" align="center">
                  <v-icon
                    size="30"
                    class="not-collapsed"
                    data-bs-toggle="collapse"
                    href="#collapse-Example"
                    aria-expanded="true"
                    aria-controls="collapse-Example"
                    v-model="isIcon"
                    @click="isIcon = !isIcon"
                    style="overflow-anchor: none"
                    >{{
                      isIcon
                        ? "mdi-chevron-up-circle-outline"
                        : "mdi-chevron-down-circle-outline"
                    }}</v-icon
                  >
                </div>
              </div>
            </div>

            <div
              class="filter_icons mb-3"
              style="padding: 10px"
              :class="{ collapse: !isIcon, show: isIcon }"
              aria-expanded="false"
              :id="{ 'collapse-Example': isIcon }"
            >
              <div>
                <!-- <br /> -->

                <div class="row gy-0" v-if="showNonStop">
                  <div class="col-8">
                    <v-checkbox
                      :label="this.$t('searchPageContent.itinContent.nonStop')"
                      v-model="NonStopFil"
                      @change="applyFilters"
                      :disabled="!hasNonStop"
                      color="primary"
                    ></v-checkbox>
                  </div>
                </div>

                <div class="row" v-if="showOneStop">
                  <div class="col-8">
                    <v-checkbox
                      :label="`1 ${this.$t(
                        'searchPageContent.itinContent.stop'
                      )}`"
                      v-model="OneStopFil"
                      @change="applyFilters"
                      :disabled="!hasOneStop"
                      color="primary"
                    ></v-checkbox>
                  </div>
                </div>

                <div class="row" v-if="showOneplusStop">
                  <div class="col-8">
                    <v-checkbox
                      :label="`1 + ${this.$t(
                        'searchPageContent.itinContent.stops'
                      )}`"
                      v-model="OnePlusFil"
                      @change="applyFilters"
                      :disabled="!hasOnePlusStop"
                      color="primary"
                    ></v-checkbox>
                  </div>
                </div>

                <!-- <hr />
                <div class="stop mb-3">Baggage</div>
                <div class="row mt-1">
                  <div class="col-8">
                    <v-checkbox label="Baggage Inclusive" color="primary"></v-checkbox>
                  </div>
                  <div class="col-4" align="center">
                  <div>(100)</div>
                </div>
                </div> -->

                <hr />
                <div class="stop">
                  {{ $t("searchPageContent.filterContent.fareType") }}
                </div>
                <div class="row mt-1">
                  <div class="col-8">
                    <v-checkbox
                      :label="this.$t('searchPageContent.itinContent.refund')"
                      color="primary"
                      v-model="RefundableData"
                      @change="applyFilters"
                      :disabled="!hasRefundable"
                    ></v-checkbox>
                  </div>
                </div>

                <div class="row">
                  <div class="col-12">
                    <v-checkbox
                      :label="
                        this.$t('searchPageContent.itinContent.nonRefund')
                      "
                      color="primary"
                      v-model="nonRefundableData"
                      @change="applyFilters"
                      :disabled="!hasNonRefundable"
                    ></v-checkbox>
                  </div>
                </div>

                <hr />
                <div class="stop">
                  {{ $t("searchPageContent.filterContent.priceRange") }}
                  <span style="font-size: 13px">
                    ({{
                      $t("searchPageContent.filterContent.avgperPax")
                    }})</span
                  >
                </div>
                <div class="row me-1">
                  <v-range-slider
                    v-model="changevalue"
                    @change="applyFilters"
                    :min="minimum"
                    :max="maximum"
                    :step="steps"
                    thumb-label
                    class="price_slider"
                    color="#0c4ca3"
                  ></v-range-slider>
                </div>
                <div class="row">
                  <div class="col-6" align="center">
                    <p class="f-size-13">
                      {{ currencyCode }} {{ changevalue[0] }}
                    </p>
                  </div>
                  <div class="col-6" align="center">
                    <p class="f-size-13">
                      {{ currencyCode }} {{ changevalue[1] }}
                    </p>
                  </div>
                </div>
                <hr style="margin-bottom: 12px !important" />

                <div class="my-2" v-if="this.onedatafil || this.rounddatafil">
                  <h5>
                    {{ $t("searchPageContent.filterContent.onwardjourney") }}
                  </h5>

                  <div class="my-2">
                    <div style="font-size: 16px; font-weight: 500">
                      {{ $t("searchPageContent.filterContent.depFrom") }}
                      {{ FilterTimeDepname }}
                    </div>
                  </div>

                  <div class="d-flex my-2 justify-content-around">
                    <div @click="TimeFilter4()">
                      <v-card
                        :color="timeFilterData4 ? '#bae9e45e' : 'white'"
                        style="cursor: pointer"
                      >
                        <div class="text-center p-1">
                          <v-icon
                            size="22"
                            class="m-1"
                            :color="timeFilterData4 ? '#ff6b0a' : 'grey'"
                            >mdi-weather-sunset-up</v-icon
                          >
                          <p
                            class="m-0"
                            style="font-size: 10px; font-weight: 500"
                          >
                            {{ $t("searchPageContent.filterContent.before") }}
                            6am
                          </p>
                        </div>
                      </v-card>
                    </div>
                    <div @click="TimeFilter1()">
                      <v-card
                        :color="timeFilterData1 ? '#bae9e45e' : 'white'"
                        style="cursor: pointer"
                      >
                        <div class="text-center p-1">
                          <v-icon
                            size="22"
                            class="m-1"
                            :color="timeFilterData1 ? '#ff6b0a' : 'grey'"
                            >mdi-weather-sunny</v-icon
                          >
                          <p
                            class="m-0"
                            style="font-size: 10px; font-weight: 500"
                          >
                            6am-12pm
                          </p>
                        </div>
                      </v-card>
                    </div>
                    <div @click="TimeFilter2()">
                      <v-card
                        :color="timeFilterData2 ? '#bae9e45e' : 'white'"
                        style="cursor: pointer"
                      >
                        <div class="text-center p-1">
                          <v-icon
                            size="22"
                            class="m-1"
                            :color="timeFilterData2 ? '#ff6b0a' : 'grey'"
                            >mdi-weather-sunset-down</v-icon
                          >
                          <p
                            class="m-0"
                            style="font-size: 10px; font-weight: 500"
                          >
                            12pm-6pm
                          </p>
                        </div>
                      </v-card>
                    </div>
                    <div @click="TimeFilter3()">
                      <!-- :class="{ disabled: !hasEveningFlights }" -->
                      <v-card
                        :color="timeFilterData3 ? '#bae9e45e' : 'white'"
                        style="cursor: pointer"
                      >
                        <div class="text-center p-1">
                          <v-icon
                            size="22"
                            class="m-1"
                            :color="timeFilterData3 ? '#ff6b0a' : 'grey'"
                            >mdi-weather-night-partly-cloudy</v-icon
                          >
                          <p
                            class="m-0"
                            style="font-size: 10px; font-weight: 500"
                          >
                            {{ $t("searchPageContent.filterContent.after") }}
                            6pm
                          </p>
                        </div>
                      </v-card>
                    </div>
                  </div>

                  <div class="my-2">
                    <div style="font-size: 16px; font-weight: 500">
                      {{ $t("searchPageContent.filterContent.arrivalAt") }}
                      {{ FilterTimearrname }}
                    </div>
                  </div>

                  <div class="d-flex my-2 justify-content-around">
                    <div @click="TimeFilterArr4()">
                      <v-card
                        :color="timeFilterArrData4 ? '#bae9e45e' : 'white'"
                        style="cursor: pointer"
                      >
                        <div class="text-center p-1">
                          <v-icon
                            size="22"
                            class="m-1"
                            :color="timeFilterArrData4 ? '#ff6b0a' : 'grey'"
                            >mdi-weather-sunset-up</v-icon
                          >
                          <p
                            class="m-0"
                            style="font-size: 10px; font-weight: 500"
                          >
                            {{ $t("searchPageContent.filterContent.before") }}
                            6am
                          </p>
                        </div>
                      </v-card>
                    </div>
                    <div @click="TimeFilterArr1()">
                      <v-card
                        :color="timeFilterArrData1 ? '#bae9e45e' : 'white'"
                        style="cursor: pointer"
                      >
                        <div class="text-center p-1">
                          <v-icon
                            size="22"
                            class="m-1"
                            :color="timeFilterArrData1 ? '#ff6b0a' : 'grey'"
                            >mdi-weather-sunny</v-icon
                          >
                          <p
                            class="m-0"
                            style="font-size: 10px; font-weight: 500"
                          >
                            6am-12pm
                          </p>
                        </div>
                      </v-card>
                    </div>
                    <div @click="TimeFilterArr2()">
                      <!-- :class="{ disabled: !hasAfternoonFlights1 }" -->
                      <v-card
                        :color="timeFilterArrData2 ? '#bae9e45e' : 'white'"
                        style="cursor: pointer"
                      >
                        <div class="text-center p-1">
                          <v-icon
                            size="22"
                            class="m-1"
                            :color="
                              this.timeFilterArrData2 ? '#ff6b0a' : 'grey'
                            "
                            >mdi-weather-sunset-down</v-icon
                          >
                          <p
                            class="m-0"
                            style="font-size: 10px; font-weight: 500"
                          >
                            12pm-6pm
                          </p>
                        </div>
                      </v-card>
                    </div>
                    <div @click="TimeFilterArr3()">
                      <!-- :class="{ disabled: !hasEveningFlights1 }" -->
                      <v-card
                        :color="timeFilterArrData3 ? '#bae9e45e' : 'white'"
                        style="cursor: pointer"
                      >
                        <div class="text-center p-1">
                          <v-icon
                            size="22"
                            class="m-1"
                            :color="timeFilterArrData3 ? '#ff6b0a' : 'grey'"
                            >mdi-weather-night-partly-cloudy</v-icon
                          >
                          <p
                            class="m-0"
                            style="font-size: 10px; font-weight: 500"
                          >
                            {{ $t("searchPageContent.filterContent.after") }}
                            6pm
                          </p>
                        </div>
                      </v-card>
                    </div>
                  </div>
                  <hr />
                </div>

                <div class="my-2" v-if="this.rounddatafil">
                  <h5>
                    {{ $t("searchPageContent.filterContent.returnjourney") }}
                  </h5>

                  <div class="my-2">
                    <div style="font-size: 16px; font-weight: 500">
                      {{ $t("searchPageContent.filterContent.depFrom") }}
                      {{ FilterTimearrname }}
                    </div>
                  </div>

                  <div class="d-flex my-2 justify-content-around">
                    <div @click="TimeretFilter4()">
                      <v-card
                        :color="timearrFilterData4 ? '#bae9e45e' : 'white'"
                        style="cursor: pointer"
                      >
                        <div class="text-center p-1">
                          <v-icon
                            size="22"
                            class="m-1"
                            :color="timearrFilterData4 ? '#ff6b0a' : 'grey'"
                            >mdi-weather-sunset-up</v-icon
                          >
                          <p
                            class="m-0"
                            style="font-size: 10px; font-weight: 500"
                          >
                            {{ $t("searchPageContent.filterContent.before") }}
                            6am
                          </p>
                        </div>
                      </v-card>
                    </div>
                    <div @click="TimeretFilter1()">
                      <v-card
                        :color="timearrFilterData1 ? '#bae9e45e' : 'white'"
                        style="cursor: pointer"
                      >
                        <div class="text-center p-1">
                          <v-icon
                            size="22"
                            class="m-1"
                            :color="timearrFilterData1 ? '#ff6b0a' : 'grey'"
                            >mdi-weather-sunny</v-icon
                          >
                          <p
                            class="m-0"
                            style="font-size: 10px; font-weight: 500"
                          >
                            6am-12pm
                          </p>
                        </div>
                      </v-card>
                    </div>
                    <div @click="TimeretFilter2()">
                      <v-card
                        :color="timearrFilterData2 ? '#bae9e45e' : 'white'"
                        style="cursor: pointer"
                      >
                        <div class="text-center p-1">
                          <v-icon
                            size="22"
                            class="m-1"
                            :color="timearrFilterData2 ? '#ff6b0a' : 'grey'"
                            >mdi-weather-sunset-down</v-icon
                          >
                          <p
                            class="m-0"
                            style="font-size: 10px; font-weight: 500"
                          >
                            12pm-6pm
                          </p>
                        </div>
                      </v-card>
                    </div>
                    <div @click="TimeretFilter3()">
                      <v-card
                        :color="timearrFilterData3 ? '#bae9e45e' : 'white'"
                        style="cursor: pointer"
                      >
                        <div class="text-center p-1">
                          <v-icon
                            size="22"
                            class="m-1"
                            :color="timearrFilterData3 ? '#ff6b0a' : 'grey'"
                            >mdi-weather-night-partly-cloudy</v-icon
                          >
                          <p
                            class="m-0"
                            style="font-size: 10px; font-weight: 500"
                          >
                            {{ $t("searchPageContent.filterContent.after") }}
                            6pm
                          </p>
                        </div>
                      </v-card>
                    </div>
                  </div>

                  <div class="my-2">
                    <div style="font-size: 16px; font-weight: 500">
                      {{ $t("searchPageContent.filterContent.arrivalAt") }}
                      {{ FilterTimeDepname }}
                    </div>
                  </div>

                  <div class="d-flex my-2 justify-content-around">
                    <div @click="TimeretFilterarr4()">
                      <v-card
                        :color="timearrFilterArrData4 ? '#bae9e45e' : 'white'"
                        style="cursor: pointer"
                      >
                        <div class="text-center p-1">
                          <v-icon
                            size="22"
                            class="m-1"
                            :color="timearrFilterArrData4 ? '#ff6b0a' : 'grey'"
                            >mdi-weather-sunset-up</v-icon
                          >
                          <p
                            class="m-0"
                            style="font-size: 10px; font-weight: 500"
                          >
                            {{ $t("searchPageContent.filterContent.before") }}
                            6am
                          </p>
                        </div>
                      </v-card>
                    </div>
                    <div @click="TimeretFilterarr1()">
                      <v-card
                        :color="timearrFilterArrData1 ? '#bae9e45e' : 'white'"
                        style="cursor: pointer"
                      >
                        <div class="text-center p-1">
                          <v-icon
                            size="22"
                            class="m-1"
                            :color="timearrFilterArrData1 ? '#ff6b0a' : 'grey'"
                            >mdi-weather-sunny</v-icon
                          >
                          <p
                            class="m-0"
                            style="font-size: 10px; font-weight: 500"
                          >
                            6am-12pm
                          </p>
                        </div>
                      </v-card>
                    </div>
                    <div @click="TimeretFilterarr2()">
                      <v-card
                        :color="timearrFilterArrData2 ? '#bae9e45e' : 'white'"
                        style="cursor: pointer"
                      >
                        <div class="text-center p-1">
                          <v-icon
                            size="22"
                            class="m-1"
                            :color="timearrFilterArrData2 ? '#ff6b0a' : 'grey'"
                            >mdi-weather-sunset-down</v-icon
                          >
                          <p
                            class="m-0"
                            style="font-size: 10px; font-weight: 500"
                          >
                            12pm-6pm
                          </p>
                        </div>
                      </v-card>
                    </div>
                    <div @click="TimeretFilterarr3()">
                      <v-card
                        :color="timearrFilterArrData3 ? '#bae9e45e' : 'white'"
                        style="cursor: pointer"
                      >
                        <div class="text-center p-1">
                          <v-icon
                            size="22"
                            class="m-1"
                            :color="timearrFilterArrData3 ? '#ff6b0a' : 'grey'"
                            >mdi-weather-night-partly-cloudy</v-icon
                          >
                          <p
                            class="m-0"
                            style="font-size: 10px; font-weight: 500"
                          >
                            {{ $t("searchPageContent.filterContent.after") }}
                            6pm
                          </p>
                        </div>
                      </v-card>
                    </div>
                  </div>
                  <hr />
                </div>

                <div class="d-none my-2" v-if="multifil">
                  <div>
                    <!-- <h5>Flight1 Journey</h5> -->

                    <div class="my-2">
                      <div style="font-size: 16px; font-weight: 500">
                        <!-- Departure From {{ codeData[0].from }} -->
                      </div>
                    </div>

                    <div class="d-flex my-2 justify-content-around">
                      <!-- <div @click="hasMorningFlights ? TimeFilter1() : null" -->
                      <div @click="TimeMulti4()">
                        <v-card
                          :color="timeMulti4 ? '#bae9e45e' : 'white'"
                          style="cursor: pointer"
                        >
                          <div class="text-center p-1">
                            <v-icon
                              size="22"
                              class="m-1"
                              :color="timeMulti4 ? '#ff6b0a' : 'grey'"
                              >mdi-weather-sunset-up</v-icon
                            >
                            <p
                              class="m-0"
                              style="font-size: 10px; font-weight: 500"
                            >
                              {{ $t("searchPageContent.filterContent.before") }}
                              6am
                            </p>
                          </div>
                        </v-card>
                      </div>
                      <div @click="TimeMulti1()">
                        <v-card
                          :color="timeMulti1 ? '#bae9e45e' : 'white'"
                          style="cursor: pointer"
                        >
                          <div class="text-center p-1">
                            <v-icon
                              size="22"
                              class="m-1"
                              :color="timeMulti1 ? '#ff6b0a' : 'grey'"
                              >mdi-weather-sunny</v-icon
                            >
                            <p
                              class="m-0"
                              style="font-size: 10px; font-weight: 500"
                            >
                              6am-12pm
                            </p>
                          </div>
                        </v-card>
                      </div>
                      <div @click="TimeMulti2()">
                        <!-- :class="{ disabled: !hasAfternoonFlights }" -->
                        <v-card
                          :color="timeMulti2 ? '#bae9e45e' : 'white'"
                          style="cursor: pointer"
                        >
                          <div class="text-center p-1">
                            <v-icon
                              size="22"
                              class="m-1"
                              :color="timeMulti2 ? '#ff6b0a' : 'grey'"
                              >mdi-weather-sunset-down</v-icon
                            >
                            <p
                              class="m-0"
                              style="font-size: 10px; font-weight: 500"
                            >
                              12pm-6pm
                            </p>
                          </div>
                        </v-card>
                      </div>
                      <div @click="TimeMulti3()">
                        <!-- :class="{ disabled: !hasEveningFlights }" -->
                        <v-card
                          :color="timeMulti3 ? '#bae9e45e' : 'white'"
                          style="cursor: pointer"
                        >
                          <div class="text-center p-1">
                            <v-icon
                              size="22"
                              class="m-1"
                              :color="timeMulti3 ? '#ff6b0a' : 'grey'"
                              >mdi-weather-night-partly-cloudy</v-icon
                            >
                            <p
                              class="m-0"
                              style="font-size: 10px; font-weight: 500"
                            >
                              {{ $t("searchPageContent.filterContent.after") }}
                              6pm
                            </p>
                          </div>
                        </v-card>
                      </div>
                    </div>

                    <div class="my-2">
                      <div style="font-size: 16px; font-weight: 500">
                        <!-- Arrival at {{ codeData[3].to }} -->
                      </div>
                    </div>

                    <div class="d-flex my-2 justify-content-around">
                      <!-- <div @click="hasMorningFlights ? TimeFilter1() : null" -->
                      <div @click="TimeMultiArr4()">
                        <v-card
                          :color="timeMultiArr4 ? '#bae9e45e' : 'white'"
                          style="cursor: pointer"
                        >
                          <div class="text-center p-1">
                            <v-icon
                              size="22"
                              class="m-1"
                              :color="timeMultiArr4 ? '#ff6b0a' : 'grey'"
                              >mdi-weather-sunset-up</v-icon
                            >
                            <p
                              class="m-0"
                              style="font-size: 10px; font-weight: 500"
                            >
                              6am-12pm
                            </p>
                          </div>
                        </v-card>
                      </div>
                      <div @click="TimeMultiArr1()">
                        <v-card
                          :color="timeMultiArr1 ? '#bae9e45e' : 'white'"
                          style="cursor: pointer"
                        >
                          <div class="text-center p-1">
                            <v-icon
                              size="22"
                              class="m-1"
                              :color="timeMultiArr1 ? '#ff6b0a' : 'grey'"
                              >mdi-weather-sunny</v-icon
                            >
                            <p
                              class="m-0"
                              style="font-size: 10px; font-weight: 500"
                            >
                              6am-12pm
                            </p>
                          </div>
                        </v-card>
                      </div>
                      <div @click="TimeMultiArr2()">
                        <!-- :class="{ disabled: !hasAfternoonFlights }" -->
                        <v-card
                          :color="timeMultiArr2 ? '#bae9e45e' : 'white'"
                          style="cursor: pointer"
                        >
                          <div class="text-center p-1">
                            <v-icon
                              size="22"
                              class="m-1"
                              :color="timeMultiArr2 ? '#ff6b0a' : 'grey'"
                              >mdi-weather-sunset-down</v-icon
                            >
                            <p
                              class="m-0"
                              style="font-size: 10px; font-weight: 500"
                            >
                              12pm-6pm
                            </p>
                          </div>
                        </v-card>
                      </div>
                      <div @click="TimeMultiArr3()">
                        <!-- :class="{ disabled: !hasEveningFlights }" -->
                        <v-card
                          :color="timeMultiArr3 ? '#bae9e45e' : 'white'"
                          style="cursor: pointer"
                        >
                          <div class="text-center p-1">
                            <v-icon
                              size="22"
                              class="m-1"
                              :color="timeMultiArr3 ? '#ff6b0a' : 'grey'"
                              >mdi-weather-night-partly-cloudy</v-icon
                            >
                            <p
                              class="m-0"
                              style="font-size: 10px; font-weight: 500"
                            >
                              {{ $t("searchPageContent.filterContent.after") }}
                              6pm
                            </p>
                          </div>
                        </v-card>
                      </div>
                    </div>
                  </div>
                  <hr
                    style="
                      margin-bottom: 12px !important;
                      margin-top: 12px !important;
                    "
                  />
                </div>
                <div v-if="multifil">
                  <h6>
                    {{ $t("searchPageContent.filterContent.flightDuration") }}
                  </h6>
                  <span class="d-inline-flex"
                    ><v-img
                      :src="require('../assets/img/images/airplane.png')"
                      width="20px"
                      class="me-2"
                    ></v-img
                    >{{ $t("searchPageContent.filterContent.outBound") }}</span
                  >
                  <div class="outbound">
                    <v-select
                      :items="outBoundTimeMulti"
                      v-model="defaultOutBoundMulti"
                      @change="applyFilters"
                    ></v-select>
                  </div>
                </div>
                <div v-if="this.onedatafil || this.rounddatafil" class="d-none">
                  <div>
                    <h6>
                      {{ $t("searchPageContent.filterContent.airports") }}
                    </h6>
                    <span class="d-inline-flex"
                      ><v-img
                        :src="require('../assets/img/images/airplane.png')"
                        width="20px"
                        class="me-2"
                      >
                      </v-img
                      >{{
                        $t("searchPageContent.filterContent.departure")
                      }}</span
                    >
                    <!-- {{console.log(fromAirportName,'Airpporttanenm')}} -->
                    <div
                      v-for="(flight, index) in fromAirportName"
                      :key="index"
                    >
                      <v-checkbox
                        :value="flight"
                        :label="flight"
                        v-model="selectedFromAirports"
                        @change="applyFilters"
                      ></v-checkbox>
                    </div>
                  </div>
                  <hr
                    v-if="isReturn"
                    style="
                      margin-bottom: 12px !important;
                      margin-top: 12px !important;
                    "
                  />
                  <div v-if="isReturn">
                    <span class="d-inline-flex"
                      ><v-img
                        :src="require('../assets/img/images/airplane.png')"
                        width="20px"
                        class="me-2"
                        style="transform: rotate(180deg)"
                      ></v-img
                      >{{ $t("searchPageContent.filterContent.arrival") }}</span
                    >
                    <div v-for="(val, index) of toAirportName" :key="index">
                      <v-checkbox
                        :label="val"
                        :value="val"
                        v-model="selectedToAirports"
                        @change="applyFilters"
                      ></v-checkbox>
                    </div>
                  </div>
                </div>

                <div v-if="multifil" class="d-none">
                  <div v-for="(flight, index) in multiAirport" :key="index">
                    <p
                      class="mb-0 mt-2"
                      style="font-size: 16px; font-weight: 500"
                    >
                      Flight{{ index + 1 }}
                    </p>
                    <div>
                      <v-checkbox
                        :value="flight.from"
                        :label="flight.from"
                        v-model="multiFromAirports"
                        @change="applyFilters"
                      ></v-checkbox>
                    </div>
                    <div>
                      <v-checkbox
                        :value="flight.to"
                        :label="flight.to"
                        v-model="multiToAirports"
                        @change="applyFilters"
                      ></v-checkbox>
                    </div>
                  </div>
                </div>

                <hr
                  class="d-none"
                  style="
                    margin-bottom: 12px !important;
                    margin-top: 12px !important;
                  "
                />

                <div v-if="this.onedatafil || this.rounddatafil">
                  <div>
                    <h6>
                      {{ $t("searchPageContent.filterContent.flightDuration") }}
                    </h6>
                    <span class="d-inline-flex"
                      ><v-img
                        :src="require('../assets/img/images/airplane.png')"
                        width="20px"
                        class="me-2"
                      ></v-img
                      >{{
                        $t("searchPageContent.filterContent.outBound")
                      }}</span
                    >
                    <div class="outbound">
                      <v-select
                        :items="outBoundTime"
                        v-model="defaultOutBoundTime"
                        @change="applyFilters"
                      ></v-select>
                    </div>
                  </div>
                  <hr
                    v-if="isReturn"
                    style="
                      margin-bottom: 12px !important;
                      margin-top: 12px !important;
                    "
                  />
                  <div v-if="isReturn">
                    <span class="d-inline-flex"
                      ><v-img
                        :src="require('../assets/img/images/airplane.png')"
                        width="20px"
                        class="me-2"
                        style="transform: rotate(180deg)"
                      ></v-img
                      >{{ $t("searchPageContent.filterContent.inBound") }}</span
                    >
                    <div class="inbound">
                      <v-select
                        :items="inBoundTime"
                        v-model="defaultInBoundTime"
                        @change="applyFilters"
                      ></v-select>
                    </div>
                  </div>
                  <hr
                    style="
                      margin-bottom: 12px !important;
                      margin-top: 12px !important;
                    "
                  />
                </div>

                <div v-if="this.onedatafil || this.rounddatafil">
                  <div
                    class="stop"
                    v-if="this.multicityData.length > 0 ? false : true"
                  >
                    {{ $t("searchPageContent.filterContent.airlines") }}
                  </div>
                  <div
                    class="row mb-4"
                    style="font-size: 14px; cursor: pointer"
                    v-if="this.multicityData.length > 0 ? false : true"
                  >
                    <div class="col-md-6 ps-3" @click="airlineSelect()">
                      {{ $t("searchPageContent.filterContent.selectAll") }}
                    </div>
                    <div class="col-md-6 text-end pe-3" @click="airlineClear()">
                      {{ $t("searchPageContent.filterContent.clearAll") }}
                    </div>
                  </div>

                  <div
                    class="row"
                    v-for="(data, index) of airlineList.slice(
                      0,
                      maxItemsToShow
                    )"
                    :key="index"
                  >
                    <div class="col-12">
                      <v-checkbox
                        class="text-truncate"
                        :label="data.airline"
                        color="primary"
                        v-model="data.active"
                        @click="selectAirline(data, index)"
                        @change="applyFilters"
                      >
                      </v-checkbox>
                    </div>
                  </div>

                  <div v-if="airlineList.length > 5">
                    <p
                      class="m-0"
                      style="cursor: pointer; color: #3366cc; font-size: 14px"
                      @click="showMore"
                      v-if="!readMore"
                    >
                      ( +{{ airlineList.length - maxItemsToShow }}
                      {{ $t("searchPageContent.filterContent.more") }} )
                    </p>

                    <p
                      class="m-0"
                      style="cursor: pointer; color: #3366cc; font-size: 14px"
                      @click="showMore"
                      v-if="readMore"
                    >
                      ( {{ $t("searchPageContent.filterContent.showless") }} )
                    </p>
                  </div>
                </div>

                <div v-if="multifil">
                  <div
                    class="stop"
                    v-if="this.multicityData.length > 0 ? true : false"
                  >
                    {{ $t("searchPageContent.filterContent.airlines") }}
                  </div>
                  <div
                    class="row mb-4"
                    style="font-size: 14px; cursor: pointer"
                    v-if="this.multicityData.length > 0 ? true : false"
                  >
                    <div class="col-md-6 ps-3" @click="airlineSelect()">
                      {{ $t("searchPageContent.filterContent.selectAll") }}
                    </div>
                    <div class="col-md-6 text-end pe-3" @click="airlineClear()">
                      {{ $t("searchPageContent.filterContent.clearAll") }}
                    </div>
                  </div>

                  <div
                    class="row"
                    v-for="(data, index) of airlineList.slice(
                      0,
                      maxItemsToShow
                    )"
                    :key="index"
                  >
                    <div class="col-12">
                      <v-checkbox
                        class="text-truncate"
                        :label="data.airline"
                        color="primary"
                        v-model="data.active"
                        @click="selectAirline(data, index)"
                        @change="applyFilters"
                      >
                      </v-checkbox>
                    </div>
                  </div>

                  <div v-if="airlineList.length > 5">
                    <p
                      class="m-0"
                      style="cursor: pointer; color: #3366cc; font-size: 14px"
                      @click="showMore"
                      v-if="!readMore"
                    >
                      ( +{{ airlineList.length - maxItemsToShow }}
                      {{ $t("searchPageContent.filterContent.more") }} )
                    </p>

                    <p
                      class="m-0"
                      style="cursor: pointer; color: #3366cc; font-size: 14px"
                      @click="showMore"
                      v-if="readMore"
                    >
                      ( {{ $t("searchPageContent.filterContent.showless") }} )
                    </p>
                  </div>
                </div>

                <br />
              </div>
            </div>
          </v-card>
        </div>
        <div class="col-lg-9 mb-3">
          <div class="col-73">
            <div
              class="booking-list-item"
              v-for="(res, index) of rountresult"
              :key="index"
            >
              <div class="booking-list-item-inner">
                <div class="booking-list-top row">
                  <div class="col-lg-9 rountdata">
                    <div
                      class="row d-flex flex-row justify-content-evenly align-items-center pb-2"
                      style="border-bottom: 1px dashed #d2d2d2"
                    >
                      <div class="col-md-3 col-12 flight-airway pe-0">
                        <div class="flight-logo d-flex justify-content-start">
                          <img
                            :src="res.Depature[0].MarketingCarrier.logo"
                            alt=""
                            style="margin-right: 7px; width: 30px"
                          />
                          <h5 class="title">
                            {{ res.Depature[0].MarketingCarrier.Name }}
                          </h5>
                        </div>
                      </div>
                      <div
                        class="col-md-9 col-12 flight-info d-block mb-0 text-center"
                      >
                        <div class="row">
                          <div
                            class="col-md-3 col-2 d-flex flex-column justify-center p-0 itineryDatas"
                          >
                            <span class="fw-bold">{{getAirportCode(res.Depature)}}</span>
                            <span class="d-md-block d-none">{{ getCountryName1(tripData.from) }}</span>
                          </div>
                          <div class="col-md-6 col-8 p-0">
                            <div class="row g-0 justify-center">
                              <div
                                class="col-md-4 col-3 d-flex flex-column justify-center"
                              >
                                <p
                                  class="res-time text-center m-0 date-section-hide"
                                >
                                  {{ getDate(res.Depature) }}
                                </p>
                                <p
                                  class="res-time text-center m-0 date-section-show"
                                >
                                  {{ getDateDeparture(res.Depature) }}
                                </p>
                                <p
                                  class="text-center m-0"
                                  style="font-weight: 500; font-size: 18px"
                                >
                                  {{ getTime(res.Depature) }}
                                </p>
                              </div>

                              <div
                                class="col-md-4 col-6 d-flex flex-column justify-center"
                                v-if="res.Depaturejourney.Stops == 0"
                              >
                                <p class="res-stop m-0 text-center">Direct</p>
                                <div
                                  class="d-flex align-center justify-center my-1"
                                >
                                  <hr
                                    style="width: 87%; border: 1px solid black"
                                  />
                                </div>

                                <div class="d-flex justify-center time-section">
                                  <v-icon color="grey"
                                    >mdi-clock-outline</v-icon
                                  >
                                  <span>{{ res.Depaturejourney.Time }}</span>
                                </div>
                              </div>

                              <div
                                class="col-md-4 col-6 d-flex flex-column justify-center"
                                v-if="res.Depaturejourney.Stops == 1"
                              >
                                <p class="res-stop m-0 text-center">
                                  {{ res.Depaturejourney.Stops }} Stop
                                </p>

                                <div
                                  class="d-flex align-center justify-center my-1"
                                  style="position: relative"
                                >
                                  <hr
                                    style="width: 40%; border: 1px solid black"
                                  />

                                  <v-icon
                                    size="8"
                                    @mouseover="showDetails1(res, index)"
                                    @mouseout="hideDetails1(res)"
                                    >mdi-circle</v-icon
                                  >

                                  <div
                                    v-if="res.Depature.length >= 2"
                                    class="hoverData1"
                                    id="tooltip"
                                    :class="{ visible: res.hoveredName1 }"
                                  >
                                    <div style="text-align: center"></div>
                                    <div style="color: white !important">
                                      <span style="font-size: 12px">{{
                                        getarrivaldata(res.Depature)
                                      }}</span>
                                      <span> | </span>
                                      <span style="font-size: 12px">{{
                                        getarrtime(res.Depature)
                                      }}</span>
                                    </div>
                                  </div>

                                  <hr
                                    style="width: 40%; border: 1px solid black"
                                  />
                                </div>

                                <div class="d-flex justify-center time-section">
                                  <v-icon color="grey"
                                    >mdi-clock-outline
                                  </v-icon>
                                  <span>{{ res.Depaturejourney.Time }}</span>
                                </div>
                              </div>

                              <div
                                class="col-md-4 col-6 d-flex flex-column justify-center"
                                v-if="res.Depaturejourney.Stops >= 2"
                              >
                                <p class="res-stop m-0 text-center">
                                  {{ res.Depaturejourney.Stops }} Stops
                                </p>

                                <div
                                  class="d-flex align-center justify-center my-1"
                                  style="position: relative"
                                >
                                  <hr
                                    style="width: 30%; border: 1px solid black"
                                  />

                                  <v-icon
                                    size="8"
                                    @mouseover="showDetails1(res, index)"
                                    @mouseout="hideDetails1(res)"
                                    >mdi-circle</v-icon
                                  >

                                  <div
                                    v-if="res.Depature.length >= 2"
                                    class="hoverDataStop1"
                                    id="tooltip"
                                    :class="{ visible: res.hoveredName1 }"
                                  >
                                    <div style="text-align: center"></div>
                                    <div
                                      class="d-inline"
                                      style="color: white !important"
                                    >
                                      <span style="font-size: 12px">{{
                                        getarrivaldata(res.Depature)
                                      }}</span>
                                      <span> | </span>
                                      <span style="font-size: 12px">{{
                                        getarrtime(res.Depature)
                                      }}</span>
                                    </div>
                                  </div>

                                  <hr
                                    style="width: 15%; border: 1px solid black"
                                  />

                                  <v-icon
                                    size="8"
                                    @mouseover="showDetails2(res, index)"
                                    @mouseout="hideDetails2(res)"
                                    >mdi-circle</v-icon
                                  >
                                  <div
                                    v-if="res.Depature.length >= 3"
                                    class="hoverDataStop2"
                                    id="tooltip"
                                    :class="{ visible: res.hoveredName2 }"
                                  >
                                    <div style="text-align: center"></div>
                                    <div
                                      class=""
                                      style="color: white !important"
                                    >
                                      <span style="font-size: 12px">{{
                                        get1arrtime(res.Depature, index)
                                      }}</span>
                                      <span> | </span>
                                      <span style="font-size: 12px">{{
                                        get2arrtime(res.Depature)
                                      }}</span>
                                    </div>
                                  </div>

                                  <hr
                                    style="width: 30%; border: 1px solid black"
                                  />
                                </div>

                                <div class="d-flex justify-center time-section">
                                  <v-icon color="grey"
                                    >mdi-clock-outline
                                  </v-icon>
                                  <span>{{ res.Depaturejourney.Time }}</span>
                                </div>
                              </div>

                              <div
                                class="col-md-4 col-3 d-flex flex-column justify-center"
                              >
                                <p
                                  class="res-time text-center m-0 date-section-hide"
                                >
                                  {{ getArrivaldate(res.Depature) }}
                                </p>
                                <p
                                  class="res-time text-center m-0 date-section-show"
                                >
                                  {{ getArrivaldateReturn(res.Depature) }}
                                </p>
                                <p
                                  class="text-center m-0"
                                  style="font-weight: 500; font-size: 18px"
                                >
                                  {{ getArrivalTime(res.Depature) }}
                                </p>
                              </div>
                            </div>
                          </div>
                          <div
                            class="col-md-3 col-2 d-flex flex-column justify-center p-0 itineryDatas"
                          >
                            <span class="fw-bold">{{getArrivalCode(res.Depature)}}</span>
                            <span class="d-md-block d-none">{{ getCountryName2(tripData.to) }}</span>
                          </div>
                          <!-- <div class="time col-4"><span>{{res.Depaturejourney.Time}}</span>{{res.Depaturejourney.Stops}} 
                            {{res.Depaturejourney.Stops > 1 ? $t("searchPageContent.itinContent.stops") : $t("searchPageContent.itinContent.stop")}}</div> -->
                        </div>
                      </div>
                    </div>
                    <div
                      class="row d-flex flex-row justify-content-evenly align-items-center pt-2 pb-2"
                    >
                      <div class="col-md-3 col-12 flight-airway pe-0">
                        <div class="flight-logo d-flex justify-content-start">
                          <img
                            :src="res.Return[0].MarketingCarrier.logo"
                            alt=""
                            style="margin-right: 7px; width: 30px"
                          />
                          <h5 class="title">
                            {{ res.Return[0].MarketingCarrier.Name }}
                          </h5>
                        </div>
                      </div>
                      <div
                        class="col-md-9 col-12 flight-info d-block mb-0 text-center"
                      >
                        <div class="row">
                          <div
                            class="col-md-3 col-2 p-0 d-flex flex-column justify-center itineryDatas"
                          >
                            <span class="fw-bold">{{getreturnAirportCode(res.Return)}}</span>
                            <span class="d-md-block d-none">{{ getCountryName2(tripData.to) }}</span>
                          </div>
                          <div
                            class="col-md-6 col-8 p-0"
                            style="font-family: Barlow, sans-serif"
                          >
                            <div class="row g-0 mt-1 justify-center">
                              <div
                                class="col-md-4 col-3 d-flex flex-column justify-center"
                              >
                                <p
                                  class="res-time text-center m-0 date-section-hide"
                                >
                                  {{ getreturnDate(res.Return) }}
                                </p>
                                <p
                                  class="res-time text-center m-0 date-section-show"
                                >
                                  {{ getreturnDateDeparture(res.Return) }}
                                </p>
                                <p
                                  class="text-center m-0"
                                  style="font-weight: 500; font-size: 18px"
                                >
                                  {{ getreturnTime(res.Return) }}
                                </p>
                              </div>

                              <div
                                class="col-md-4 col-6 d-flex flex-column justify-center"
                                v-if="res.Returnjourney.Stops == 0"
                              >
                                <p class="res-stop m-0 text-center">Direct</p>
                                <div
                                  class="d-flex align-center justify-center my-1"
                                >
                                  <hr
                                    style="width: 87%; border: 1px solid black"
                                  />
                                </div>

                                <div class="d-flex justify-center time-section">
                                  <v-icon color="grey"
                                    >mdi-clock-outline</v-icon
                                  >
                                  <span>{{ res.Returnjourney.Time }}</span>
                                </div>
                              </div>

                              <div
                                class="col-md-4 col-6 d-flex flex-column justify-center"
                                v-if="res.Returnjourney.Stops == 1"
                              >
                                <p class="res-stop m-0 text-center">
                                  {{ res.Returnjourney.Stops }} Stop
                                </p>

                                <div
                                  class="d-flex align-center justify-center my-1"
                                  style="position: relative"
                                >
                                  <hr
                                    style="width: 40%; border: 1px solid black"
                                  />

                                  <v-icon
                                    size="8"
                                    @mouseover="showDetails3(res, index)"
                                    @mouseout="hideDetails3(res)"
                                    >mdi-circle</v-icon
                                  >

                                  <div
                                    v-if="res.Return.length >= 2"
                                    class="hoverData1"
                                    id="tooltip"
                                    :class="{ visible: res.hoveredName3 }"
                                  >
                                    <div style="text-align: center"></div>
                                    <div style="color: white">
                                      <span style="font-size: 12px">{{
                                        getreturndata(res.Return)
                                      }}</span>
                                      <span> | </span>
                                      <span style="font-size: 12px">{{
                                        getreturntime(res.Return)
                                      }}</span>
                                    </div>
                                  </div>

                                  <hr
                                    style="width: 40%; border: 1px solid black"
                                  />
                                </div>

                                <div class="d-flex justify-center time-section">
                                  <v-icon color="grey"
                                    >mdi-clock-outline</v-icon
                                  >
                                  <span>{{ res.Returnjourney.Time }}</span>
                                </div>
                              </div>

                              <div
                                class="col-md-4 col-6 d-flex flex-column justify-center"
                                v-if="res.Returnjourney.Stops >= 2"
                              >
                                <p class="res-stop m-0 text-center">
                                  {{ res.Returnjourney.Stops }} Stops
                                </p>

                                <div
                                  class="d-flex align-center justify-center my-1"
                                  style="position: relative"
                                >
                                  <hr
                                    style="width: 30%; border: 1px solid black"
                                  />

                                  <v-icon
                                    size="8"
                                    @mouseover="showDetails3(res, index)"
                                    @mouseout="hideDetails3(res)"
                                    >mdi-circle</v-icon
                                  >

                                  <div
                                    v-if="res.Return.length >= 2"
                                    class="hoverDataStop1"
                                    id="tooltip"
                                    :class="{ visible: res.hoveredName3 }"
                                  >
                                    <div style="text-align: center"></div>
                                    <div style="color: white">
                                      <span style="font-size: 12px">{{
                                        getreturndata(res.Return)
                                      }}</span>
                                      <span> | </span>
                                      <span style="font-size: 12px">{{
                                        getreturntime(res.Return)
                                      }}</span>
                                    </div>
                                  </div>

                                  <hr
                                    style="width: 15%; border: 1px solid black"
                                  />

                                  <v-icon
                                    size="8"
                                    @mouseover="showDetails4(res, index)"
                                    @mouseout="hideDetails4(res)"
                                    >mdi-circle</v-icon
                                  >
                                  <div
                                    v-if="res.Return.length >= 3"
                                    class="hoverDataStop2"
                                    id="tooltip"
                                    :class="{ visible: res.hoveredName4 }"
                                  >
                                    <div style="text-align: center"></div>
                                    <div style="color: white">
                                      <span style="font-size: 12px">{{
                                        get1returntime(res.Return, index)
                                      }}</span>
                                      <span> | </span>
                                      <span style="font-size: 12px">{{
                                        get2returntime(res.Return)
                                      }}</span>
                                    </div>
                                  </div>

                                  <hr
                                    style="width: 30%; border: 1px solid black"
                                  />
                                </div>

                                <div class="d-flex justify-center time-section">
                                  <v-icon color="grey"
                                    >mdi-clock-outline
                                  </v-icon>
                                  <span>{{ res.Returnjourney.Time }}</span>
                                </div>
                              </div>

                              <div
                                class="col-md-4 col-3 d-flex flex-column justify-center"
                              >
                                <p
                                  class="res-time text-center m-0 date-section-hide"
                                >
                                  {{ getreturnArrivaldate(res.Return) }}
                                </p>
                                <p
                                  class="res-time text-center m-0 date-section-show"
                                >
                                  {{ getreturnArrivaldateReturn(res.Return) }}
                                </p>
                                <p
                                  class="text-center m-0"
                                  style="font-weight: 500; font-size: 18px"
                                >
                                  {{ getreturnArrivalTime(res.Return) }}
                                </p>
                              </div>
                            </div>
                          </div>
                          <div
                            class="col-md-3 col-2 p-0 d-flex flex-column justify-center itineryDatas"
                          >
                            <span class="fw-bold">{{getreturnArrivalcode(res.Return)}}</span>
                            <span class="d-md-block d-none">{{ getCountryName1(tripData.from) }}</span>
                          </div>
                        </div>
                        <!-- <div>{{ getreturnAirportCode(res.Return) }}, <span>{{ getreturnDate(res.Return) }}</span></div>
                          <div class="time"><span>{{res.Returnjourney.Time}}</span>{{res.Returnjourney.Stops}} 
                          {{res.Returnjourney.Stops > 1 ? $t("searchPageContent.itinContent.stops") : $t("searchPageContent.itinContent.stop")}}</div>
                          <div>{{ getreturnArrivalcode(res.Return) }},<span>{{ getreturnArrivaldate(res.Return) }}</span></div> -->
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-3 flight-price text-center pt-lg-0 pt-3">
                    <h4 class="title">
                      <span style="font-size: 15px">{{ res.currency }}</span>
                      {{ res.find1 }}.{{ res.find2 }}
                    </h4>
                    <p class="pricePer">Per Person</p>
                    <a
                      href="/flightInfo"
                      class="btn"
                      @click.prevent="selected(res, index)"
                      >{{ $t("searchPageContent.itinContent.select") }}
                      <v-icon color="white">mdi-airplane-takeoff</v-icon></a
                    >
                  </div>
                </div>
                <div class="booking-list-bottom">
                  <ul
                    style="border-top: 1px dashed #d2d2d2"
                    class="d-flex flex-lg-row flex-column"
                  >
                    <li class="order-md-2 order-1"></li>
                    <li
                      class="detail order-md-1 order-2"
                      @click="flightDetail(index)"
                    >
                      <i
                        :class="
                          res.Flightdepature
                            ? 'fa-solid fa-angle-up'
                            : 'fa-solid fa-angle-down'
                        "
                      ></i>
                      {{ $t("searchPageContent.itinContent.flightDetails") }}
                    </li>
                  </ul>
                </div>
              </div>
              <div v-if="flightDetails == index">
                <div class="flight-detail-wrap">
                  <div>
                    <div
                      class="mb-2 p-1 px-2"
                      style="
                        background-color: #ebebee;
                        border-radius: 8px;
                        width: fit-content;
                      "
                    >
                      <p
                        class="m-0"
                        style="
                          color: #571336;
                          font-weight: 500;
                          font-size: 14px;
                          line-height: 1.3;
                        "
                      >
                        {{ getAirportCode(res.Depature) }} -
                        {{ getArrivalCode(res.Depature) }}
                      </p>
                    </div>
                    <div
                      v-for="(data, index) of res.Depature"
                      :key="index"
                      class="w-100 position-relative"
                    >
                      <!-- Flight Segment Information -->
                      <div
                        class="d-flex flex-lg-row flex-column w-100 justify-content-evenly pb-2"
                      >
                        <div class="flight-date d-md-block d-none">
                          <ul>
                            <li>
                              {{ getdated(data.Departure.Date) }} -
                              {{ data.Departure.Time }}
                            </li>
                            <li class="d-flex flex-row align-center">
                              <i class="fa-solid fa-plane me-1"></i
                              >{{ data.FlightDetail.FlightDuration.Value }}
                              <!-- <span class="overnight-info" v-if="isOvernightFlight(res.Depature[index])">
                              <v-icon size="15" color="red">mdi-eye</v-icon>
                              <span class="tooltip-text">Overnight flight</span>
                            </span> -->
                            </li>
                            <li>
                              {{ getdated1(data.Arrival.Date) }} -
                              {{ data.Arrival.Time }}
                            </li>
                          </ul>
                        </div>

                        <div class="flight-detail-right">
                          <h4
                            class="title d-flex flex-row align-center"
                            style="padding-left: 0px; left: -7px"
                          >
                            <v-icon color="#571336" size="20"
                              >mdi-airplane-takeoff</v-icon
                            >
                            <span>
                              {{ data.Departure.AirportCode }} -
                              {{ data.Departure.AirportName }}
                              </span>
                          </h4>
                          <div
                            class="flight-detail-info d-flex align-items-center"
                          >
                            <img
                              :src="data.MarketingCarrier.logo"
                              alt=""
                              width="34px"
                            />
                            <ul>
                              <li>
                                {{ data.MarketingCarrier.AirlineID }}
                                {{ data.MarketingCarrier.FlightNumber }} -
                                {{ data.MarketingCarrier.Name }}
                              </li>
                              <li>
                                {{ translateClass(oneway.class) }}
                                {{ translateRefund(res.Refund) }} Baggage
                              </li>
                            </ul>
                          </div>
                          <h4 class="title title-two">
                            {{ data.Arrival.AirportCode }} -
                            {{ data.Arrival.AirportName }}
                          </h4>
                        </div>
                      </div>

                      <!-- Layover Time & Dashed Line together -->
                      <div
                        v-if="index < res.Depature.length - 1"
                        class="layover-section w-100 text-center my-2"
                      >
                        <div
                          class="dashed-line"
                          style="border-bottom: 1px dashed gray; width: 100%"
                        ></div>
                        <span
                          class="layover-time"
                          style="
                            font-size: 11px;
                            color: #6a2e4d;
                            position: absolute;
                            left: 50%;
                            transform: translateX(-50%);
                            top: -10px;
                          "
                        >
                          Layover: {{ res.layoverTimes1[index] }}

                          <span
                            v-if="
                              res.layoverTimes1[index].includes('h') &&
                              parseInt(
                                res.layoverTimes1[index].split('h')[0]
                              ) >= 8
                            "
                            class="overnight-info"
                          >
                            <!-- <v-icon color="red" size="17">mdi-information-outline</v-icon> -->
                            <img
                              src="../assets/img/icon/sandclock1.png"
                              style="width: 13px; height: 15px"
                            />
                            <span class="tooltip-text">Long Layover</span>
                          </span>
                          <!-- <span v-if="isOvernightFlight(res.Depature[index + 1])" class="overnight-info">
                        <v-icon color="red" size="17">mdi-information-outline</v-icon>
                        <span class="tooltip-text">Overnight flight</span>
                      </span> -->
                        </span>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div
                      class="mb-2 p-1 px-2"
                      style="
                        background-color: #ebebee;
                        border-radius: 8px;
                        width: fit-content;
                      "
                    >
                      <p
                        class="m-0"
                        style="
                          color: #571336;
                          font-weight: 500;
                          font-size: 14px;
                          line-height: 1.3;
                        "
                      >
                        {{ getreturnAirportCode(res.Return) }} -
                        {{ getreturnArrivalcode(res.Return) }}
                      </p>
                    </div>
                    <div
                      v-for="(data, index) of res.Return"
                      :key="index"
                      class="w-100 position-relative"
                    >
                      <!-- Flight Segment Information -->
                      <div
                        class="d-flex flex-lg-row flex-column w-100 justify-content-evenly pb-2"
                      >
                        <div class="flight-date d-md-block d-none">
                          <ul>
                            <li>
                              {{ getdated(data.Departure.Date) }} -
                              {{ data.Departure.Time }}
                            </li>
                            <li class="d-flex flex-row align-center">
                              <i class="fa-solid fa-plane me-1"></i
                              >{{ data.FlightDetail.FlightDuration.Value }}
                              <!-- <span class="overnight-info" v-if="isOvernightFlight1(res.Return[index])">
                              <v-icon size="15" color="red">mdi-eye</v-icon>
                              <span class="tooltip-text">Overnight flight</span>
                            </span> -->
                            </li>
                            <li>
                              {{ getdated1(data.Arrival.Date) }} -
                              {{ data.Arrival.Time }}
                            </li>
                          </ul>
                        </div>

                        <div class="flight-detail-right">
                          <h4
                            class="title d-flex flex-row align-center"
                            style="padding-left: 0px; left: -7px"
                          >
                            <v-icon color="#571336" size="20"
                              >mdi-airplane-takeoff</v-icon
                            >
                            <span>
                            {{ data.Departure.AirportCode }} -
                            {{ data.Departure.AirportName }}</span>
                          </h4>
                          <div
                            class="flight-detail-info d-flex align-items-center"
                          >
                            <img
                              :src="data.MarketingCarrier.logo"
                              alt=""
                              width="34px"
                            />
                            <ul>
                              <li>
                                {{ data.MarketingCarrier.AirlineID }}
                                {{ data.MarketingCarrier.FlightNumber }} -
                                {{ data.MarketingCarrier.Name }}
                              </li>
                              <li>
                                {{ translateClass(oneway.class) }}
                                {{ translateRefund(res.Refund) }} Baggage
                              </li>
                            </ul>
                          </div>
                          <h4 class="title title-two">
                            {{ data.Arrival.AirportCode }} -
                            {{ data.Arrival.AirportName }}
                          </h4>
                        </div>
                      </div>

                      <!-- Layover Time & Dashed Line together -->
                      <div
                        v-if="index < res.Return.length - 1"
                        class="layover-section w-100 text-center my-2"
                      >
                        <div
                          class="dashed-line"
                          style="border-bottom: 1px dashed gray; width: 100%"
                        ></div>
                        <span
                          class="layover-time"
                          style="
                            font-size: 11px;
                            color: #6a2e4d;
                            position: absolute;
                            left: 50%;
                            transform: translateX(-50%);
                            top: -10px;
                          "
                        >
                          Layover: {{ res.layoverTimes2[index] }}
                          <span
                            v-if="
                              res.layoverTimes2[index].includes('h') &&
                              parseInt(
                                res.layoverTimes2[index].split('h')[0]
                              ) >= 8
                            "
                            class="overnight-info"
                          >
                            <!-- <v-icon color="red" size="17">mdi-information-outline</v-icon> -->
                            <img
                              src="../assets/img/icon/sandclock1.png"
                              style="width: 13px; height: 15px"
                            />
                            <span class="tooltip-text">Long Layover</span>
                          </span>
                          <!-- <span v-if="isOvernightFlight(res.Return[index + 1])" class="overnight-info">
                        <v-icon color="red" size="17">mdi-information-outline</v-icon>
                        <span class="tooltip-text">Overnight flight</span>
                      </span> -->
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="booking-list-item"
              v-for="(res, index) of onewayresulted"
              :key="index"
            >
              <div class="booking-list-item-inner">
                <div class="booking-list-top row">
                  <div class="col-lg-9">
                    <div
                      class="row d-flex flex-row justify-content-evenly align-items-center pb-2"
                    >
                      <div class="col-md-3 col-12 flight-airway pe-0">
                        <div class="flight-logo d-flex justify-content-start">
                          <img
                            :src="res.Depature[0].MarketingCarrier.logo"
                            alt=""
                            style="margin-right: 7px; width: 30px"
                          />
                          <h5 class="title">
                            {{ res.Depature[0].MarketingCarrier.Name }}
                          </h5>
                        </div>
                      </div>
                      <div
                        class="col-md-9 col-12 flight-info d-block mb-0 text-center"
                      >
                        <div class="row">
                          <div
                            class="col-md-3 col-2 d-flex flex-column justify-center p-0 itineryDatas"
                          >
                            <span class="fw-bold">{{getAirportCode(res.Depature)}}</span>
                            <span class="d-md-block d-none">{{ getCountryName1(tripData.from) }}</span>
                          </div>
                          <div class="col-md-6 col-8 p-0">
                            <div class="row g-0 justify-center">
                              <div
                                class="col-md-4 col-3 d-flex flex-column justify-center"
                              >
                                <p
                                  class="res-time text-center m-0 date-section-hide"
                                >
                                  {{ getDate(res.Depature) }}
                                </p>
                                <p
                                  class="res-time text-center m-0 date-section-show"
                                >
                                  {{ getDateDeparture(res.Depature) }}
                                </p>
                                <p
                                  class="text-center m-0"
                                  style="font-weight: 500; font-size: 18px"
                                >
                                  {{ getTime(res.Depature) }}
                                </p>
                              </div>

                              <div
                                class="col-md-4 col-6 d-flex flex-column justify-center"
                                v-if="res.Depaturejourney.Stops == 0"
                              >
                                <p class="res-stop m-0 text-center">Direct</p>
                                <div
                                  class="d-flex align-center justify-center my-1"
                                >
                                  <hr
                                    style="width: 87%; border: 1px solid black"
                                  />
                                </div>

                                <div class="d-flex justify-center time-section">
                                  <v-icon color="grey"
                                    >mdi-clock-outline</v-icon
                                  >
                                  <span>{{ res.Depaturejourney.Time }}</span>
                                </div>
                              </div>

                              <div
                                class="col-md-4 col-6 d-flex flex-column justify-center"
                                v-if="res.Depaturejourney.Stops == 1"
                              >
                                <p class="res-stop m-0 text-center">
                                  {{ res.Depaturejourney.Stops }} Stop
                                </p>

                                <div
                                  class="d-flex align-center justify-center my-1"
                                  style="position: relative"
                                >
                                  <hr
                                    style="width: 40%; border: 1px solid black"
                                  />

                                  <v-icon
                                    size="8"
                                    @mouseover="showDetails1(res, index)"
                                    @mouseout="hideDetails1(res)"
                                    >mdi-circle</v-icon
                                  >

                                  <div
                                    v-if="res.Depature.length >= 2"
                                    class="hoverData1"
                                    id="tooltip"
                                    :class="{ visible: res.hoveredName1 }"
                                  >
                                    <div style="text-align: center"></div>
                                    <div style="color: white !important">
                                      <span style="font-size: 12px">{{
                                        getarrivaldata(res.Depature)
                                      }}</span>
                                      <span> | </span>
                                      <span style="font-size: 12px">{{
                                        getarrtime(res.Depature)
                                      }}</span>
                                    </div>
                                  </div>

                                  <hr
                                    style="width: 40%; border: 1px solid black"
                                  />
                                </div>

                                <div class="d-flex justify-center time-section">
                                  <v-icon color="grey"
                                    >mdi-clock-outline
                                  </v-icon>
                                  <span>{{ res.Depaturejourney.Time }}</span>
                                </div>
                              </div>

                              <div
                                class="col-md-4 col-6 d-flex flex-column justify-center"
                                v-if="res.Depaturejourney.Stops >= 2"
                              >
                                <p class="res-stop m-0 text-center">
                                  {{ res.Depaturejourney.Stops }} Stops
                                </p>

                                <div
                                  class="d-flex align-center justify-center my-1"
                                  style="position: relative"
                                >
                                  <hr
                                    style="width: 30%; border: 1px solid black"
                                  />

                                  <v-icon
                                    size="8"
                                    @mouseover="showDetails1(res, index)"
                                    @mouseout="hideDetails1(res)"
                                    >mdi-circle</v-icon
                                  >

                                  <div
                                    v-if="res.Depature.length >= 2"
                                    class="hoverDataStop1"
                                    id="tooltip"
                                    :class="{ visible: res.hoveredName1 }"
                                  >
                                    <div style="text-align: center"></div>
                                    <div
                                      class="d-inline"
                                      style="color: white !important"
                                    >
                                      <span style="font-size: 12px">{{
                                        getarrivaldata(res.Depature)
                                      }}</span>
                                      <span> | </span>
                                      <span style="font-size: 12px">{{
                                        getarrtime(res.Depature)
                                      }}</span>
                                    </div>
                                  </div>

                                  <hr
                                    style="width: 15%; border: 1px solid black"
                                  />

                                  <v-icon
                                    size="8"
                                    @mouseover="showDetails2(res, index)"
                                    @mouseout="hideDetails2(res)"
                                    >mdi-circle</v-icon
                                  >
                                  <div
                                    v-if="res.Depature.length >= 3"
                                    class="hoverDataStop2"
                                    id="tooltip"
                                    :class="{ visible: res.hoveredName2 }"
                                  >
                                    <div style="text-align: center"></div>
                                    <div
                                      class=""
                                      style="color: white !important"
                                    >
                                      <span style="font-size: 12px">{{
                                        get1arrtime(res.Depature, index)
                                      }}</span>
                                      <span> | </span>
                                      <span style="font-size: 12px">{{
                                        get2arrtime(res.Depature)
                                      }}</span>
                                    </div>
                                  </div>

                                  <hr
                                    style="width: 30%; border: 1px solid black"
                                  />
                                </div>

                                <div class="d-flex justify-center time-section">
                                  <v-icon color="grey"
                                    >mdi-clock-outline
                                  </v-icon>
                                  <span>{{ res.Depaturejourney.Time }}</span>
                                </div>
                              </div>

                              <div
                                class="col-md-4 col-3 d-flex flex-column justify-center"
                              >
                                <p
                                  class="res-time text-center m-0 date-section-hide"
                                >
                                  {{ getArrivaldate(res.Depature) }}
                                </p>
                                <p
                                  class="res-time text-center m-0 date-section-show"
                                >
                                  {{ getArrivaldateReturn(res.Depature) }}
                                </p>
                                <p
                                  class="text-center m-0"
                                  style="font-weight: 500; font-size: 18px"
                                >
                                  {{ getArrivalTime(res.Depature) }}
                                </p>
                              </div>
                            </div>
                          </div>
                          <div
                            class="col-md-3 col-2 d-flex flex-column justify-center p-0 itineryDatas"
                          >
                            <span class="fw-bold">{{getArrivalCode(res.Depature)}}</span>
                            <span class="d-md-block d-none">{{ getCountryName2(tripData.to) }}</span>
                          </div>
                          <!-- <div class="time col-4"><span>{{res.Depaturejourney.Time}}</span>{{res.Depaturejourney.Stops}} 
                            {{res.Depaturejourney.Stops > 1 ? $t("searchPageContent.itinContent.stops") : $t("searchPageContent.itinContent.stop")}}</div> -->
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="col-lg-3 flight-price text-center pt-lg-0 pt-3 onewayData"
                  >
                    <h4 class="title mb-0">
                      <span style="font-size: 15px">{{ res.currency }}</span>
                      {{ res.find1 }}.{{ res.find2 }}
                    </h4>
                    <p class="pricePer mb-0">Per Person</p>
                    <a
                      href="/flightInfo"
                      class="btn"
                      @click.prevent="onewayselect(res, index)"
                      >{{ $t("searchPageContent.itinContent.select") }}
                      <v-icon color="white">mdi-airplane-takeoff</v-icon></a
                    >
                  </div>
                </div>
                <div class="booking-list-bottom">
                  <ul
                    style="border-top: 1px dashed #d2d2d2"
                    class="d-flex flex-lg-row flex-column"
                  >
                    <!-- <li class="order-md-2 order-1">{{ $t("searchPageContent.itinContent.pricePerson") }}</li> -->
                    <li
                      class="detail order-md-1 order-2"
                      @click="flightDetailOneway(index)"
                    >
                      <i
                        :class="
                          res.Flightdepature
                            ? 'fa-solid fa-angle-up'
                            : 'fa-solid fa-angle-down'
                        "
                      ></i>
                      {{ $t("searchPageContent.itinContent.flightDetails") }}
                    </li>
                  </ul>
                </div>
              </div>
              <div v-if="flightDetails == index" class="flight-detail-wrap">
                <div
                  class="mb-2 p-1 px-2"
                  style="
                    background-color: #ebebee;
                    border-radius: 8px;
                    width: fit-content;
                  "
                >
                  <p
                    class="m-0"
                    style="
                      color: #571336;
                      font-weight: 500;
                      font-size: 14px;
                      line-height: 1.3;
                    "
                  >
                    {{ getAirportCode(res.Depature) }} -
                    {{ getArrivalCode(res.Depature) }}
                  </p>
                </div>
                <div
                  v-for="(data, index) of res.Depature"
                  :key="index"
                  class="w-100 position-relative"
                >
                  <!-- Flight Segment Information -->
                  <div
                    class="d-flex flex-lg-row flex-column w-100 justify-content-evenly pb-2"
                  >
                    <div class="flight-date d-md-block d-none">
                      <ul>
                        <li>
                          {{ getdated(data.Departure.Date) }} -
                          {{ data.Departure.Time }}
                        </li>
                        <li>
                          <i class="fa-solid fa-plane me-1"></i
                          >{{ data.FlightDetail.FlightDuration.Value }}
                        </li>
                        <li>
                          {{ getdated1(data.Arrival.Date) }} -
                          {{ data.Arrival.Time }}
                        </li>
                      </ul>
                    </div>

                    <div class="flight-detail-right">
                      <h4 class="title d-flex flex-row align-center" style="padding-left: 0px; left: -7px">
                        <v-icon color="#571336" size="20"
                          >mdi-airplane-takeoff</v-icon
                        >
                        <span>
                        {{ data.Departure.AirportCode }} -
                        {{ data.Departure.AirportName }}</span>
                      </h4>
                      <div class="flight-detail-info d-flex align-items-center">
                        <img
                          :src="data.MarketingCarrier.logo"
                          alt=""
                          width="34px"
                        />
                        <ul>
                          <li>
                            {{ data.MarketingCarrier.AirlineID }}
                            {{ data.MarketingCarrier.FlightNumber }} -
                            {{ data.MarketingCarrier.Name }}
                          </li>
                          <li>
                            {{ translateClass(oneway.class) }}
                            {{ translateRefund(res.Refund) }} Baggage
                          </li>
                        </ul>
                      </div>
                      <h4 class="title title-two">
                        {{ data.Arrival.AirportCode }} -
                        {{ data.Arrival.AirportName }}
                      </h4>
                    </div>
                  </div>

                  <div
                    v-if="index < res.Depature.length - 1"
                    class="layover-section w-100 text-center my-2"
                  >
                    <div
                      class="dashed-line"
                      style="border-bottom: 1px dashed gray; width: 100%"
                    ></div>
                    <span
                      class="layover-time"
                      style="
                        font-size: 11px;
                        color: #6a2e4d;
                        position: absolute;
                        left: 50%;
                        transform: translateX(-50%);
                        top: -10px;
                      "
                    >
                      Layover: {{ res.layoverTimes[index] }}
                      <span
                        v-if="
                          res.layoverTimes[index].includes('h') &&
                          parseInt(res.layoverTimes[index].split('h')[0]) >= 8
                        "
                        class="overnight-info"
                      >
                        <!-- <v-icon color="red" size="17">mdi-information-outline</v-icon> -->
                        <img
                          src="../assets/img/icon/sandclock1.png"
                          style="width: 13px; height: 15px"
                        />
                        <span class="tooltip-text">Long Layover</span>
                      </span>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="booking-list-item"
              v-for="(res, index) of multicityData"
              :key="index"
            >
              <div class="booking-list-item-inner">
                <div class="booking-list-top row">
                  <div class="col-lg-9 multicityData">
                    <div
                      class="row d-flex flex-row justify-content-evenly align-items-center pt-2 pb-2"
                    >
                      <div class="col-md-3 col-12 flight-airway pe-0">
                        <div class="flight-logo d-flex justify-content-start">
                          <img
                            :src="res.Flight1[0].MarketingCarrier.logo"
                            alt=""
                            style="margin-right: 7px; width: 30px"
                          />
                          <h5 class="title">
                            {{ res.Flight1[0].MarketingCarrier.Name }}
                          </h5>
                        </div>
                      </div>
                      <div
                        class="col-md-9 col-12 flight-info d-block mb-0 text-center"
                      >
                        <div class="row">
                          <div
                            class="col-md-3 col-2 d-flex flex-column justify-center p-0 itineryDatas"
                          >
                            <span class="fw-bold">
                              {{ getAirportCode(res.Flight1) }}</span>
                            <span class="d-md-block d-none">{{ res.muticityData[0].from }}</span>
                          </div>
                          <div class="col-md-6 col-8 p-0">
                            <div class="row g-0 mt-1 justify-center">
                              <div class="col-md-4 col-3">
                                <p
                                  class="res-time text-center m-0 date-section-hide"
                                >
                                  {{ getDate(res.Flight1) }}
                                </p>
                                <p
                                  class="res-time text-center m-0 date-section-show"
                                >
                                  {{ getDateDeparture(res.Flight1) }}
                                </p>
                                <p
                                  class="text-center m-0"
                                  style="font-weight: 500; font-size: 18px"
                                >
                                  {{ getTime(res.Flight1) }}
                                </p>
                              </div>

                              <div
                                class="col-md-4 col-6 d-flex flex-column justify-center"
                                v-if="res.Flight1JourneyTime.Stops == 0"
                              >
                                <p class="res-stop m-0 text-center">Direct</p>

                                <div
                                  class="d-flex align-center justify-center my-1"
                                >
                                  <hr
                                    style="width: 87%; border: 1px solid black"
                                  />
                                </div>

                                <div class="d-flex justify-center time-section">
                                  <v-icon color="grey"
                                    >mdi-clock-outline
                                  </v-icon>
                                  <span
                                    >{{ res.Flight1JourneyTime.Time }}
                                  </span>
                                </div>
                              </div>

                              <div
                                class="col-md-4 col-6 d-flex flex-column justify-center"
                                v-if="res.Flight1JourneyTime.Stops == 1"
                              >
                                <p class="res-stop m-0 text-center">
                                  {{ res.Flight1JourneyTime.Stops }} Stop
                                </p>

                                <div
                                  class="d-flex align-center justify-center my-1"
                                  style="position: relative"
                                >
                                  <hr
                                    style="width: 40%; border: 1px solid black"
                                  />

                                  <v-icon
                                    size="8"
                                    @mouseover="showDetails1(res, index)"
                                    @mouseout="hideDetails1(res)"
                                    >mdi-circle</v-icon
                                  >

                                  <div
                                    v-if="res.Flight1.length >= 2"
                                    class="hoverData1"
                                    id="tooltip"
                                    :class="{ visible: res.hoveredName1 }"
                                  >
                                    <div style="text-align: center"></div>
                                    <div style="color: white">
                                      <span style="font-size: 12px">{{
                                        getarrivaldata(res.Flight1)
                                      }}</span>
                                      <span> | </span>
                                      <span style="font-size: 12px">{{
                                        getarrtime(res.Flight1)
                                      }}</span>
                                    </div>
                                  </div>

                                  <hr
                                    style="width: 40%; border: 1px solid black"
                                  />
                                </div>

                                <div class="d-flex justify-center time-section">
                                  <v-icon color="grey"
                                    >mdi-clock-outline
                                  </v-icon>
                                  <span>{{ res.Flight1JourneyTime.Time }}</span>
                                </div>
                              </div>

                              <div
                                class="col-md-4 col-6 d-flex flex-column justify-center"
                                v-if="res.Flight1JourneyTime.Stops >= 2"
                              >
                                <p class="res-stop m-0 text-center">
                                  {{ res.Flight1JourneyTime.Stops }} Stops
                                </p>

                                <div
                                  class="d-flex align-center justify-center my-1"
                                  style="position: relative"
                                >
                                  <hr
                                    style="width: 30%; border: 1px solid black"
                                  />

                                  <v-icon
                                    size="8"
                                    @mouseover="showDetails1(res, index)"
                                    @mouseout="hideDetails1(res)"
                                    >mdi-circle</v-icon
                                  >

                                  <div
                                    v-if="res.Flight1.length >= 2"
                                    class="hoverDataStop1"
                                    id="tooltip"
                                    :class="{ visible: res.hoveredName1 }"
                                  >
                                    <div style="text-align: center"></div>
                                    <div style="color: white">
                                      <span style="font-size: 12px">{{
                                        getarrivaldata(res.Flight1)
                                      }}</span>
                                      <span> | </span>
                                      <span style="font-size: 12px">{{
                                        getarrtime(res.Flight1)
                                      }}</span>
                                    </div>
                                  </div>

                                  <hr
                                    style="width: 15%; border: 1px solid black"
                                  />

                                  <v-icon
                                    size="8"
                                    @mouseover="showDetails2(res, index)"
                                    @mouseout="hideDetails2(res)"
                                    >mdi-circle</v-icon
                                  >
                                  <div
                                    v-if="res.Flight1.length >= 3"
                                    class="hoverDataStop2"
                                    id="tooltip"
                                    :class="{ visible: res.hoveredName2 }"
                                  >
                                    <div style="text-align: center"></div>
                                    <div style="color: white">
                                      <span style="font-size: 12px">{{
                                        get1arrtime(res.Flight1, index)
                                      }}</span>
                                      <span> | </span>
                                      <span style="font-size: 12px">{{
                                        get2arrtime(res.Flight1)
                                      }}</span>
                                    </div>
                                  </div>

                                  <hr
                                    style="width: 30%; border: 1px solid black"
                                  />
                                </div>

                                <div class="d-flex justify-center time-section">
                                  <v-icon color="grey"
                                    >mdi-clock-outline
                                  </v-icon>
                                  <span>{{ res.Flight1JourneyTime.Time }}</span>
                                </div>
                              </div>

                              <div class="col-md-4 col-3">
                                <p
                                  class="res-time text-center m-0 date-section-hide"
                                >
                                  {{ getArrivaldate(res.Flight1) }}
                                </p>
                                <p
                                  class="res-time text-center m-0 date-section-show"
                                >
                                  {{ getArrivaldateReturn(res.Flight1) }}
                                </p>
                                <p
                                  class="text-center m-0"
                                  style="font-weight: 500; font-size: 18px"
                                >
                                  {{ getArrivalTime(res.Flight1) }}
                                </p>
                              </div>
                            </div>
                          </div>
                          <div
                            class="col-md-3 col-2 d-flex flex-column justify-center p-0 itineryDatas"
                          >
                            <span class="fw-bold">{{getArrivalCode(res.Flight1)}}</span>
                            <span class="d-md-block d-none">{{ res.muticityData[0].to }}</span>
                          </div>
                          <!-- <div class="time col-4"><span>{{res.Depaturejourney.Time}}</span>{{res.Depaturejourney.Stops}} 
                            {{res.Depaturejourney.Stops > 1 ? $t("searchPageContent.itinContent.stops") : $t("searchPageContent.itinContent.stop")}}</div> -->
                        </div>
                      </div>
                    </div>
                    <div
                      class="row d-flex flex-row justify-content-evenly align-items-center pt-2 pb-2"
                      style="border-top: 1px dashed #d2d2d2"
                    >
                      <div class="col-md-3 col-12 flight-airway pe-0">
                        <div class="flight-logo d-flex justify-content-start">
                          <img
                            :src="res.Flight2[0].MarketingCarrier.logo"
                            alt=""
                            style="margin-right: 7px; width: 30px"
                          />
                          <h5 class="title">
                            {{ res.Flight2[0].MarketingCarrier.Name }}
                          </h5>
                        </div>
                      </div>
                      <div
                        class="col-md-9 col-12 flight-info d-block mb-0 text-center"
                      >
                        <div class="row">
                          <div
                            class="col-md-3 col-2 d-flex flex-column justify-center p-0 itineryDatas"
                          >
                            <span class="fw-bold">
                              {{ getAirportCode(res.Flight2) }}</span>
                            <span class="d-md-block d-none">{{ res.muticityData[1].from }}</span>
                          </div>
                          <div class="col-md-6 col-8 p-0">
                            <div class="row g-0 mt-1 justify-center">
                              <div class="col-md-4 col-3">
                                <p
                                  class="res-time text-center m-0 date-section-hide"
                                >
                                  {{ getDate(res.Flight2) }}
                                </p>
                                <p
                                  class="res-time text-center m-0 date-section-show"
                                >
                                  {{ getDateDeparture(res.Flight2) }}
                                </p>
                                <p
                                  class="text-center m-0"
                                  style="font-weight: 500; font-size: 18px"
                                >
                                  {{ getTime(res.Flight2) }}
                                </p>
                              </div>

                              <div
                                class="col-md-4 col-6 d-flex flex-column justify-center"
                                v-if="res.Flight2JourneyTime.Stops == 0"
                              >
                                <p class="res-stop m-0 text-center">Direct</p>

                                <div
                                  class="d-flex align-center justify-center my-1"
                                >
                                  <hr
                                    style="width: 87%; border: 1px solid black"
                                  />
                                </div>

                                <div class="d-flex justify-center time-section">
                                  <v-icon color="grey"
                                    >mdi-clock-outline
                                  </v-icon>
                                  <span
                                    >{{ res.Flight2JourneyTime.Time }}
                                  </span>
                                </div>
                              </div>

                              <div
                                class="col-md-4 col-6 d-flex flex-column justify-center"
                                v-if="res.Flight2JourneyTime.Stops == 1"
                              >
                                <p class="res-stop m-0 text-center">
                                  {{ res.Flight2JourneyTime.Stops }} Stop
                                </p>

                                <div
                                  class="d-flex align-center justify-center my-1"
                                  style="position: relative"
                                >
                                  <hr
                                    style="width: 40%; border: 1px solid black"
                                  />

                                  <v-icon
                                    size="8"
                                    @mouseover="showDetails2(res, index)"
                                    @mouseout="hideDetails2(res)"
                                    >mdi-circle</v-icon
                                  >

                                  <div
                                    v-if="res.Flight2.length >= 2"
                                    class="hoverData1"
                                    id="tooltip"
                                    :class="{ visible: res.hoveredName2 }"
                                  >
                                    <div style="text-align: center"></div>
                                    <div style="color: white">
                                      <span style="font-size: 12px">{{
                                        getarrivaldata(res.Flight2)
                                      }}</span>
                                      <span> | </span>
                                      <span style="font-size: 12px">{{
                                        getarrtime(res.Flight2)
                                      }}</span>
                                    </div>
                                  </div>

                                  <hr
                                    style="width: 40%; border: 1px solid black"
                                  />
                                </div>

                                <div class="d-flex justify-center time-section">
                                  <v-icon color="grey"
                                    >mdi-clock-outline
                                  </v-icon>
                                  <span>{{ res.Flight2JourneyTime.Time }}</span>
                                </div>
                              </div>

                              <div
                                class="col-md-4 col-6 d-flex flex-column justify-center"
                                v-if="res.Flight2JourneyTime.Stops >= 2"
                              >
                                <p class="res-stop m-0 text-center">
                                  {{ res.Flight2JourneyTime.Stops }} Stops
                                </p>

                                <div
                                  class="d-flex align-center justify-center my-1"
                                  style="position: relative"
                                >
                                  <hr
                                    style="width: 30%; border: 1px solid black"
                                  />

                                  <v-icon
                                    size="8"
                                    @mouseover="showDetails2(res, index)"
                                    @mouseout="hideDetails2(res)"
                                    >mdi-circle</v-icon
                                  >

                                  <div
                                    v-if="res.Flight2.length >= 2"
                                    class="hoverDataStop1"
                                    id="tooltip"
                                    :class="{ visible: res.hoveredName2 }"
                                  >
                                    <div style="text-align: center"></div>
                                    <div style="color: white">
                                      <span style="font-size: 12px">{{
                                        getarrivaldata(res.Flight2)
                                      }}</span>
                                      <span> | </span>
                                      <span style="font-size: 12px">{{
                                        getarrtime(res.Flight2)
                                      }}</span>
                                    </div>
                                  </div>

                                  <hr
                                    style="width: 15%; border: 1px solid black"
                                  />

                                  <v-icon
                                    size="8"
                                    @mouseover="showDetails3(res, index)"
                                    @mouseout="hideDetails3(res)"
                                    >mdi-circle</v-icon
                                  >
                                  <div
                                    v-if="res.Flight2.length >= 3"
                                    class="hoverDataStop2"
                                    id="tooltip"
                                    :class="{ visible: res.hoveredName3 }"
                                  >
                                    <div style="text-align: center"></div>
                                    <div style="color: white">
                                      <span style="font-size: 12px">{{
                                        get1arrtime(res.Flight2, index)
                                      }}</span>
                                      <span> | </span>
                                      <span style="font-size: 12px">{{
                                        get2arrtime(res.Flight2)
                                      }}</span>
                                    </div>
                                  </div>

                                  <hr
                                    style="width: 30%; border: 1px solid black"
                                  />
                                </div>

                                <div class="d-flex justify-center time-section">
                                  <v-icon color="grey"
                                    >mdi-clock-outline
                                  </v-icon>
                                  <span>{{ res.Flight2JourneyTime.Time }}</span>
                                </div>
                              </div>

                              <div class="col-md-4 col-3">
                                <p
                                  class="res-time text-center m-0 date-section-hide"
                                >
                                  {{ getArrivaldate(res.Flight2) }}
                                </p>
                                <p
                                  class="res-time text-center m-0 date-section-show"
                                >
                                  {{ getArrivaldateReturn(res.Flight2) }}
                                </p>
                                <p
                                  class="text-center m-0"
                                  style="font-weight: 500; font-size: 18px"
                                >
                                  {{ getArrivalTime(res.Flight2) }}
                                </p>
                              </div>
                            </div>
                          </div>
                          <div
                            class="col-md-3 col-2 d-flex flex-column justify-center p-0 itineryDatas"
                          >
                            <span class="fw-bold">{{getArrivalCode(res.Flight2)}}</span>
                            <span class="d-md-block d-none">{{ res.muticityData[1].to }}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div v-if="res.Flight3">
                      <div
                        class="row d-flex flex-row justify-content-evenly align-items-center pt-2 pb-2"
                        style="border-top: 1px dashed #d2d2d2"
                      >
                        <div class="col-md-3 col-12 flight-airway pe-0">
                          <div class="flight-logo d-flex justify-content-start">
                            <img
                              :src="res.Flight3[0].MarketingCarrier.logo"
                              alt=""
                              style="margin-right: 7px; width: 30px"
                            />
                            <h5 class="title">
                              {{ res.Flight3[0].MarketingCarrier.Name }}
                            </h5>
                          </div>
                        </div>
                        <div
                          class="col-md-9 col-12 flight-info d-block mb-0 text-center"
                        >
                          <div class="row">
                            <div
                              class="col-md-3 col-2 d-flex flex-column justify-center p-0 itineryDatas"
                            >
                              <span class="fw-bold">
                                {{ getAirportCode(res.Flight3) }}</span>
                              <span class="d-md-block d-none">{{ res.muticityData[2].from }}</span>
                            </div>
                            <div class="col-md-6 col-8 p-0">
                              <div class="row g-0 mt-1 justify-center">
                                <div class="col-md-4 col-3">
                                  <p
                                    class="res-time text-center m-0 date-section-hide"
                                  >
                                    {{ getDate(res.Flight3) }}
                                  </p>
                                  <p
                                    class="res-time text-center m-0 date-section-show"
                                  >
                                    {{ getDateDeparture(res.Flight3) }}
                                  </p>
                                  <p
                                    class="text-center m-0"
                                    style="font-weight: 500; font-size: 18px"
                                  >
                                    {{ getTime(res.Flight3) }}
                                  </p>
                                </div>

                                <div
                                  class="col-md-4 col-6 d-flex flex-column justify-center"
                                  v-if="res.Flight3JourneyTime.Stops == 0"
                                >
                                  <p class="res-stop m-0 text-center">Direct</p>

                                  <div
                                    class="d-flex align-center justify-center my-1"
                                  >
                                    <hr
                                      style="
                                        width: 87%;
                                        border: 1px solid black;
                                      "
                                    />
                                  </div>

                                  <div
                                    class="d-flex justify-center time-section"
                                  >
                                    <v-icon color="grey"
                                      >mdi-clock-outline
                                    </v-icon>
                                    <span
                                      >{{ res.Flight3JourneyTime.Time }}
                                    </span>
                                  </div>
                                </div>

                                <div
                                  class="col-md-4 col-6 d-flex flex-column justify-center"
                                  v-if="res.Flight3JourneyTime.Stops == 1"
                                >
                                  <p class="res-stop m-0 text-center">
                                    {{ res.Flight3JourneyTime.Stops }} Stop
                                  </p>

                                  <div
                                    class="d-flex align-center justify-center my-1"
                                    style="position: relative"
                                  >
                                    <hr
                                      style="
                                        width: 40%;
                                        border: 1px solid black;
                                      "
                                    />

                                    <v-icon
                                      size="8"
                                      @mouseover="showDetails1(res, index)"
                                      @mouseout="hideDetails1(res)"
                                      >mdi-circle</v-icon
                                    >

                                    <div
                                      v-if="res.Flight3.length >= 2"
                                      class="hoverData1"
                                      id="tooltip"
                                      :class="{ visible: res.hoveredName1 }"
                                    >
                                      <div style="text-align: center"></div>
                                      <div style="color: white">
                                        <span style="font-size: 12px">{{
                                          getarrivaldata(res.Flight3)
                                        }}</span>
                                        <span> | </span>
                                        <span style="font-size: 12px">{{
                                          getarrtime(res.Flight3)
                                        }}</span>
                                      </div>
                                    </div>

                                    <hr
                                      style="
                                        width: 40%;
                                        border: 1px solid black;
                                      "
                                    />
                                  </div>

                                  <div
                                    class="d-flex justify-center time-section"
                                  >
                                    <v-icon color="grey"
                                      >mdi-clock-outline
                                    </v-icon>
                                    <span>{{
                                      res.Flight3JourneyTime.Time
                                    }}</span>
                                  </div>
                                </div>

                                <div
                                  class="col-md-4 col-6 d-flex flex-column justify-center"
                                  v-if="res.Flight3JourneyTime.Stops >= 2"
                                >
                                  <p class="res-stop m-0 text-center">
                                    {{ res.Flight3JourneyTime.Stops }} Stops
                                  </p>

                                  <div
                                    class="d-flex align-center justify-center my-1"
                                    style="position: relative"
                                  >
                                    <hr
                                      style="
                                        width: 30%;
                                        border: 1px solid black;
                                      "
                                    />

                                    <v-icon
                                      size="8"
                                      @mouseover="showDetails1(res, index)"
                                      @mouseout="hideDetails1(res)"
                                      >mdi-circle</v-icon
                                    >

                                    <div
                                      v-if="res.Flight2.length >= 2"
                                      class="hoverDataStop1"
                                      id="tooltip"
                                      :class="{ visible: res.hoveredName1 }"
                                    >
                                      <div style="text-align: center"></div>
                                      <div style="color: white">
                                        <span style="font-size: 12px">{{
                                          getarrivaldata(res.Flight3)
                                        }}</span>
                                        <span> | </span>
                                        <span style="font-size: 12px">{{
                                          getarrtime(res.Flight3)
                                        }}</span>
                                      </div>
                                    </div>

                                    <hr
                                      style="
                                        width: 15%;
                                        border: 1px solid black;
                                      "
                                    />

                                    <v-icon
                                      size="8"
                                      @mouseover="showDetails2(res, index)"
                                      @mouseout="hideDetails2(res)"
                                      >mdi-circle</v-icon
                                    >
                                    <div
                                      v-if="res.Flight3.length >= 3"
                                      class="hoverDataStop2"
                                      id="tooltip"
                                      :class="{ visible: res.hoveredName2 }"
                                    >
                                      <div style="text-align: center"></div>
                                      <div style="color: white">
                                        <span style="font-size: 12px">{{
                                          get1arrtime(res.Flight3, index)
                                        }}</span>
                                        <span> | </span>
                                        <span style="font-size: 12px">{{
                                          get2arrtime(res.Flight3)
                                        }}</span>
                                      </div>
                                    </div>

                                    <hr
                                      style="
                                        width: 30%;
                                        border: 1px solid black;
                                      "
                                    />
                                  </div>

                                  <div
                                    class="d-flex justify-center time-section"
                                  >
                                    <v-icon color="grey"
                                      >mdi-clock-outline
                                    </v-icon>
                                    <span>{{
                                      res.Flight3JourneyTime.Time
                                    }}</span>
                                  </div>
                                </div>

                                <div class="col-md-4 col-3">
                                  <p
                                    class="res-time text-center m-0 date-section-hide"
                                  >
                                    {{ getArrivaldate(res.Flight3) }}
                                  </p>
                                  <p
                                    class="res-time text-center m-0 date-section-show"
                                  >
                                    {{ getArrivaldateReturn(res.Flight3) }}
                                  </p>
                                  <p
                                    class="text-center m-0"
                                    style="font-weight: 500; font-size: 18px"
                                  >
                                    {{ getArrivalTime(res.Flight3) }}
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div
                              class="col-md-3 col-2 d-flex flex-column justify-center p-0 itineryDatas"
                            >
                              <span class="fw-bold">{{getArrivalCode(res.Flight3)}}</span>
                              <span class="d-md-block d-none">{{ res.muticityData[2].to }}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div v-if="res.Flight4">
                      <div
                        class="row d-flex flex-row justify-content-evenly align-items-center pt-2 pb-2"
                        style="border-top: 1px dashed #d2d2d2"
                      >
                        <div class="col-md-3 col-12 flight-airway pe-0">
                          <div class="flight-logo d-flex justify-content-start">
                            <img
                              :src="res.Flight4[0].MarketingCarrier.logo"
                              alt=""
                              style="margin-right: 7px; width: 30px"
                            />
                            <h5 class="title">
                              {{ res.Flight4[0].MarketingCarrier.Name }}
                            </h5>
                          </div>
                        </div>
                        <div
                          class="col-md-9 col-12 flight-info d-block mb-0 text-center"
                        >
                          <div class="row">
                            <div
                              class="col-md-3 col-2 d-flex flex-column justify-center p-0 itineryDatas"
                            >
                              <span class="fw-bold">
                                {{ getAirportCode(res.Flight4) }}</span>
                              <span class="d-md-block d-none">{{ res.muticityData[3].from }}</span>
                            </div>
                            <div class="col-md-6 col-8 p-0">
                              <div class="row g-0 mt-1 justify-center">
                                <div class="col-md-4 col-3">
                                  <p
                                    class="res-time text-center m-0 date-section-hide"
                                  >
                                    {{ getDate(res.Flight4) }}
                                  </p>
                                  <p
                                    class="res-time text-center m-0 date-section-show"
                                  >
                                    {{ getDateDeparture(res.Flight4) }}
                                  </p>
                                  <p
                                    class="text-center m-0"
                                    style="font-weight: 500; font-size: 18px"
                                  >
                                    {{ getTime(res.Flight4) }}
                                  </p>
                                </div>

                                <div
                                  class="col-md-4 col-6 d-flex flex-column justify-center"
                                  v-if="res.Flight4JourneyTime.Stops == 0"
                                >
                                  <p class="res-stop m-0 text-center">Direct</p>

                                  <div
                                    class="d-flex align-center justify-center my-1"
                                  >
                                    <hr
                                      style="
                                        width: 87%;
                                        border: 1px solid black;
                                      "
                                    />
                                  </div>

                                  <div
                                    class="d-flex justify-center time-section"
                                  >
                                    <v-icon color="grey"
                                      >mdi-clock-outline
                                    </v-icon>
                                    <span
                                      >{{ res.Flight4JourneyTime.Time }}
                                    </span>
                                  </div>
                                </div>

                                <div
                                  class="col-md-4 col-6 d-flex flex-column justify-center"
                                  v-if="res.Flight4JourneyTime.Stops == 1"
                                >
                                  <p class="res-stop m-0 text-center">
                                    {{ res.Flight4JourneyTime.Stops }} Stop
                                  </p>

                                  <div
                                    class="d-flex align-center justify-center my-1"
                                    style="position: relative"
                                  >
                                    <hr
                                      style="
                                        width: 40%;
                                        border: 1px solid black;
                                      "
                                    />

                                    <v-icon
                                      size="8"
                                      @mouseover="showDetails1(res, index)"
                                      @mouseout="hideDetails1(res)"
                                      >mdi-circle</v-icon
                                    >

                                    <div
                                      v-if="res.Flight4.length >= 2"
                                      class="hoverData1"
                                      id="tooltip"
                                      :class="{ visible: res.hoveredName1 }"
                                    >
                                      <div style="text-align: center"></div>
                                      <div style="color: white">
                                        <span style="font-size: 12px">{{
                                          getarrivaldata(res.Flight4)
                                        }}</span>
                                        <span> | </span>
                                        <span style="font-size: 12px">{{
                                          getarrtime(res.Flight4)
                                        }}</span>
                                      </div>
                                    </div>

                                    <hr
                                      style="
                                        width: 40%;
                                        border: 1px solid black;
                                      "
                                    />
                                  </div>

                                  <div
                                    class="d-flex justify-center time-section"
                                  >
                                    <v-icon color="grey"
                                      >mdi-clock-outline
                                    </v-icon>
                                    <span>{{
                                      res.Flight4JourneyTime.Time
                                    }}</span>
                                  </div>
                                </div>

                                <div
                                  class="col-md-4 col-6 d-flex flex-column justify-center"
                                  v-if="res.Flight4JourneyTime.Stops >= 2"
                                >
                                  <p class="res-stop m-0 text-center">
                                    {{ res.Flight4JourneyTime.Stops }} Stops
                                  </p>

                                  <div
                                    class="d-flex align-center justify-center my-1"
                                    style="position: relative"
                                  >
                                    <hr
                                      style="
                                        width: 30%;
                                        border: 1px solid black;
                                      "
                                    />

                                    <v-icon
                                      size="8"
                                      @mouseover="showDetails1(res, index)"
                                      @mouseout="hideDetails1(res)"
                                      >mdi-circle</v-icon
                                    >

                                    <div
                                      v-if="res.Flight4.length >= 2"
                                      class="hoverDataStop1"
                                      id="tooltip"
                                      :class="{ visible: res.hoveredName1 }"
                                    >
                                      <div style="text-align: center"></div>
                                      <div style="color: white">
                                        <span style="font-size: 12px">{{
                                          getarrivaldata(res.Flight4)
                                        }}</span>
                                        <span> | </span>
                                        <span style="font-size: 12px">{{
                                          getarrtime(res.Flight4)
                                        }}</span>
                                      </div>
                                    </div>

                                    <hr
                                      style="
                                        width: 15%;
                                        border: 1px solid black;
                                      "
                                    />

                                    <v-icon
                                      size="8"
                                      @mouseover="showDetails2(res, index)"
                                      @mouseout="hideDetails2(res)"
                                      >mdi-circle</v-icon
                                    >
                                    <div
                                      v-if="res.Flight4.length >= 3"
                                      class="hoverDataStop2"
                                      id="tooltip"
                                      :class="{ visible: res.hoveredName2 }"
                                    >
                                      <div style="text-align: center"></div>
                                      <div style="color: white">
                                        <span style="font-size: 12px">{{
                                          get1arrtime(res.Flight4, index)
                                        }}</span>
                                        <span> | </span>
                                        <span style="font-size: 12px">{{
                                          get2arrtime(res.Flight4)
                                        }}</span>
                                      </div>
                                    </div>

                                    <hr
                                      style="
                                        width: 30%;
                                        border: 1px solid black;
                                      "
                                    />
                                  </div>

                                  <div
                                    class="d-flex justify-center time-section"
                                  >
                                    <v-icon color="grey"
                                      >mdi-clock-outline
                                    </v-icon>
                                    <span>{{
                                      res.Flight4JourneyTime.Time
                                    }}</span>
                                  </div>
                                </div>

                                <div class="col-md-4 col-3">
                                  <p
                                    class="res-time text-center m-0 date-section-hide"
                                  >
                                    {{ getArrivaldate(res.Flight4) }}
                                  </p>
                                  <p
                                    class="res-time text-center m-0 date-section-show"
                                  >
                                    {{ getArrivaldateReturn(res.Flight4) }}
                                  </p>
                                  <p
                                    class="text-center m-0"
                                    style="font-weight: 500; font-size: 18px"
                                  >
                                    {{ getArrivalTime(res.Flight4) }}
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div
                              class="col-md-3 col-2 d-flex flex-column justify-center p-0 itineryDatas"
                            >
                              <span class="fw-bold">{{getArrivalCode(res.Flight4)}}</span>
                              <span class="d-md-block d-none">{{ res.muticityData[3].to }}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-3 flight-price text-center pt-lg-0 pt-3">
                    <h4 class="title">
                      <span style="font-size: 15px">{{ res.currency }}</span>
                      {{ res.find1 }}.{{ res.find2 }}
                    </h4>
                    <p class="pricePer">Per Person</p>
                    <a
                      href="/flightInfo"
                      class="btn"
                      @click.prevent="selected(res, index)"
                      >{{ $t("searchPageContent.itinContent.select") }}
                      <v-icon color="white">mdi-airplane-takeoff</v-icon></a
                    >
                  </div>
                </div>
                <div class="booking-list-bottom">
                  <ul
                    style="border-top: 1px dashed #d2d2d2"
                    class="d-flex flex-lg-row flex-column"
                  >
                    <!-- <li class="order-md-2 order-1">{{ $t("searchPageContent.itinContent.pricePerson") }}</li> -->
                    <li
                      class="detail order-md-1 order-2"
                      @click="flightDetail(index)"
                    >
                      <i
                        :class="
                          res.Flightdepature
                            ? 'fa-solid fa-angle-up'
                            : 'fa-solid fa-angle-down'
                        "
                      ></i>
                      {{ $t("searchPageContent.itinContent.flightDetails") }}
                    </li>
                  </ul>
                </div>
              </div>
              <div v-if="flightDetails == index">
                <div class="flight-detail-wrap">
                  <div
                    class="mb-2 p-1 px-2"
                    style="
                      background-color: #ebebee;
                      border-radius: 8px;
                      width: fit-content;
                    "
                  >
                    <p
                      class="m-0"
                      style="
                        color: #571336;
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 1.3;
                      "
                    >
                      {{ res.Flight1[0].Departure.AirportCode }} -
                      {{
                        res.Flight1[res.Flight1.length - 1].Arrival.AirportCode
                      }}
                    </p>
                  </div>
                  <div
                    v-for="(data, index) of res.Flight1"
                    :key="index"
                    class="w-100 position-relative"
                  >
                    <div
                      class="d-flex flex-lg-row flex-column w-100 justify-content-evenly pb-2"
                    >
                      <div class="flight-date d-md-block d-none">
                        <ul>
                          <!-- <li>{{ getdated(data.Departure.Date) }}</li> -->
                          <li>
                            {{ getdated(data.Departure.Date) }} -
                            {{ data.Departure.Time }}
                          </li>
                          <li>
                            <i class="fa-solid fa-plane me-1"></i
                            >{{ data.FlightDetail.FlightDuration.Value }}
                          </li>
                          <li>
                            {{ getdated1(data.Arrival.Date) }} -
                            {{ data.Arrival.Time }}
                          </li>
                        </ul>
                      </div>
                      <div class="flight-detail-right">
                        <h4 class="title d-flex flex-row align-center" style="padding-left: 0px; left: -7px">
                          <v-icon color="#571336" size="20"
                            >mdi-airplane-takeoff</v-icon
                          >
                          <span>
                          {{ data.Departure.AirportCode }} -
                          {{ data.Departure.AirportName }}</span>
                        </h4>
                        <div class="flight-detail-info">
                          <img
                            :src="data.MarketingCarrier.logo"
                            alt=""
                            width="34px"
                          />
                          <ul>
                            <li>
                              {{ data.MarketingCarrier.AirlineID }}
                              {{ data.MarketingCarrier.FlightNumber }} -
                              {{ data.MarketingCarrier.Name }}
                            </li>
                            <li>
                              {{ translateClass(oneway.class) }}
                              {{ translateRefund(res.Refund) }} Baggage
                            </li>
                            <!-- <li></li> -->
                          </ul>
                        </div>
                        <h4 class="title title-two">
                          {{ data.Arrival.AirportCode }} -
                          {{ data.Arrival.AirportName }}
                        </h4>
                      </div>
                    </div>
                    <div
                      v-if="index < res.Flight1.length - 1"
                      class="layover-section w-100 text-center my-2"
                    >
                      <div
                        class="dashed-line"
                        style="border-bottom: 1px dashed gray; width: 100%"
                      ></div>
                      <span
                        class="layover-time"
                        style="
                          font-size: 11px;
                          color: #6a2e4d;
                          position: absolute;
                          left: 50%;
                          transform: translateX(-50%);
                          top: -10px;
                        "
                      >
                        Layover: {{ res.layoverTimes1[index] }}
                        <span
                          v-if="
                            res.layoverTimes1[index].includes('h') &&
                            parseInt(res.layoverTimes1[index].split('h')[0]) >=
                              8
                          "
                          class="overnight-info"
                        >
                          <!-- <v-icon color="red" size="17">mdi-information-outline</v-icon> -->
                          <img
                            src="../assets/img/icon/sandclock1.png"
                            style="width: 13px; height: 15px"
                          />
                          <span class="tooltip-text">Long Layover</span>
                        </span>
                      </span>
                    </div>
                  </div>
                </div>
                <div class="flight-detail-wrap">
                  <div
                    class="mb-2 p-1 px-2"
                    style="
                      background-color: #ebebee;
                      border-radius: 8px;
                      width: fit-content;
                    "
                  >
                    <p
                      class="m-0"
                      style="
                        color: #571336;
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 1.3;
                      "
                    >
                      {{ res.Flight2[0].Departure.AirportCode }} -
                      {{
                        res.Flight2[res.Flight2.length - 1].Arrival.AirportCode
                      }}
                    </p>
                  </div>
                  <div
                    v-for="(data, index) of res.Flight2"
                    :key="index"
                    class="w-100 position-relative"
                  >
                    <div
                      class="d-flex flex-lg-row flex-column w-100 justify-content-evenly pb-2"
                    >
                      <div class="flight-date d-md-block d-none">
                        <ul>
                          <!-- <li>{{ getdated2(data.Departure.Date) }}</li> -->
                          <li>
                            {{ getdated2(data.Departure.Date) }} -
                            {{ data.Departure.Time }}
                            <!-- <span></span> -->
                          </li>
                          <li>
                            <i class="fa-solid fa-plane me-1"></i
                            >{{ data.FlightDetail.FlightDuration.Value }}
                          </li>
                          <li>
                            {{ getdated3(data.Arrival.Date) }} -
                            {{ data.Arrival.Time }}
                          </li>
                        </ul>
                      </div>
                      <div class="flight-detail-right">
                        <h4 class="title d-flex flex-row align-center" style="padding-left: 0px; left: -7px">
                          <v-icon color="#571336" size="20"
                            >mdi-airplane-takeoff</v-icon
                          >
                          <span>
                          {{ data.Departure.AirportCode }} -
                          {{ data.Departure.AirportName }}</span>
                        </h4>
                        <div class="flight-detail-info">
                          <img
                            :src="data.MarketingCarrier.logo"
                            alt=""
                            width="34px"
                          />
                          <ul>
                            <li>
                              {{ data.MarketingCarrier.AirlineID }}
                              {{ data.MarketingCarrier.FlightNumber }} -
                              {{ data.MarketingCarrier.Name }}
                            </li>
                            <li>
                              {{ translateClass(oneway.class) }}
                              {{ translateRefund(res.Refund) }}
                            </li>
                            <!-- <li></li> -->
                          </ul>
                        </div>
                        <h4 class="title title-two">
                          {{ data.Arrival.AirportCode }} -
                          {{ data.Arrival.AirportName }}
                        </h4>
                      </div>
                    </div>
                    <div
                      v-if="index < res.Flight2.length - 1"
                      class="layover-section w-100 text-center my-2"
                    >
                      <div
                        class="dashed-line"
                        style="border-bottom: 1px dashed gray; width: 100%"
                      ></div>
                      <span
                        class="layover-time"
                        style="
                          font-size: 11px;
                          color: #6a2e4d;
                          position: absolute;
                          left: 50%;
                          transform: translateX(-50%);
                          top: -10px;
                        "
                      >
                        Layover: {{ res.layoverTimes2[index] }}
                        <span
                          v-if="
                            res.layoverTimes2[index].includes('h') &&
                            parseInt(res.layoverTimes2[index].split('h')[0]) >=
                              8
                          "
                          class="overnight-info"
                        >
                          <!-- <v-icon color="red" size="17">mdi-information-outline</v-icon> -->
                          <img
                            src="../assets/img/icon/sandclock1.png"
                            style="width: 13px; height: 15px"
                          />
                          <span class="tooltip-text">Long Layover</span>
                        </span>
                      </span>
                    </div>
                  </div>
                </div>
                <div v-if="res.Flight3" class="flight-detail-wrap">
                  <div
                    class="mb-2 p-1 px-2"
                    style="
                      background-color: #ebebee;
                      border-radius: 8px;
                      width: fit-content;
                    "
                  >
                    <p
                      class="m-0"
                      style="
                        color: #571336;
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 1.3;
                      "
                    >
                      {{ res.Flight3[0].Departure.AirportCode }} -
                      {{
                        res.Flight3[res.Flight3.length - 1].Arrival.AirportCode
                      }}
                    </p>
                  </div>
                  <div
                    v-for="(data, index) of res.Flight3"
                    :key="index"
                    class="w-100 position-relative"
                  >
                    <div
                      class="d-flex flex-lg-row flex-column w-100 justify-content-evenly pb-2"
                    >
                      <div class="flight-date d-md-block d-none">
                        <ul>
                          <!-- <li>{{ getdated2(data.Departure.Date) }}</li> -->
                          <li>
                            {{ getdated2(data.Departure.Date) }} -
                            {{ data.Departure.Time }}
                            <!-- <span></span> -->
                          </li>
                          <li>
                            <i class="fa-solid fa-plane me-1"></i
                            >{{ data.FlightDetail.FlightDuration.Value }}
                          </li>
                          <li>
                            {{ getdated3(data.Arrival.Date) }} -
                            {{ data.Arrival.Time }}
                          </li>
                        </ul>
                      </div>
                      <div class="flight-detail-right">
                        <h4 class="title d-flex flex-row align-center" style="padding-left: 0px; left: -7px">
                          <v-icon color="#571336" size="20"
                            >mdi-airplane-takeoff</v-icon
                          >
                          <span>
                          {{ data.Departure.AirportCode }} -
                          {{ data.Departure.AirportName }}</span>
                        </h4>
                        <div class="flight-detail-info">
                          <img
                            :src="data.MarketingCarrier.logo"
                            alt=""
                            width="34px"
                          />
                          <ul>
                            <li>
                              {{ data.MarketingCarrier.AirlineID }}
                              {{ data.MarketingCarrier.FlightNumber }} -
                              {{ data.MarketingCarrier.Name }}
                            </li>
                            <li>
                              {{ translateClass(oneway.class) }}
                              {{ translateRefund(res.Refund) }}
                            </li>
                            <!-- <li></li> -->
                          </ul>
                        </div>
                        <h4 class="title title-two">
                          {{ data.Arrival.AirportCode }} -
                          {{ data.Arrival.AirportName }}
                        </h4>
                      </div>
                    </div>
                    <div
                      v-if="index < res.Flight3.length - 1"
                      class="layover-section w-100 text-center my-2"
                    >
                      <div
                        class="dashed-line"
                        style="border-bottom: 1px dashed gray; width: 100%"
                      ></div>
                      <span
                        class="layover-time"
                        style="
                          font-size: 11px;
                          color: #6a2e4d;
                          position: absolute;
                          left: 50%;
                          transform: translateX(-50%);
                          top: -10px;
                        "
                      >
                        Layover: {{ res.layoverTimes3[index] }}
                        <span
                          v-if="
                            res.layoverTimes3[index].includes('h') &&
                            parseInt(res.layoverTimes3[index].split('h')[0]) >=
                              8
                          "
                          class="overnight-info"
                        >
                          <!-- <v-icon color="red" size="17">mdi-information-outline</v-icon> -->
                          <img
                            src="../assets/img/icon/sandclock1.png"
                            style="width: 13px; height: 15px"
                          />
                          <span class="tooltip-text">Long Layover</span>
                        </span>
                      </span>
                    </div>
                  </div>
                </div>
                <div v-if="res.Flight4" class="flight-detail-wrap">
                  <div
                    class="mb-2 p-1 px-2"
                    style="
                      background-color: #ebebee;
                      border-radius: 8px;
                      width: fit-content;
                    "
                  >
                    <p
                      class="m-0"
                      style="
                        color: #571336;
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 1.3;
                      "
                    >
                      {{ res.Flight4[0].Departure.AirportCode }} -
                      {{
                        res.Flight4[res.Flight4.length - 1].Arrival.AirportCode
                      }}
                    </p>
                  </div>
                  <div
                    v-for="(data, index) of res.Flight4"
                    :key="index"
                    class="w-100 position-relative"
                  >
                    <div
                      class="d-flex flex-lg-row flex-column w-100 justify-content-evenly pb-2"
                    >
                      <div class="flight-date d-md-block d-none">
                        <ul>
                          <!-- <li>{{ getdated2(data.Departure.Date) }}</li> -->
                          <li>
                            {{ getdated2(data.Departure.Date) }} -
                            {{ data.Departure.Time }}
                            <!-- <span></span> -->
                          </li>
                          <li>
                            <i class="fa-solid fa-plane me-1"></i
                            >{{ data.FlightDetail.FlightDuration.Value }}
                          </li>
                          <li>
                            {{ getdated3(data.Arrival.Date) }} -
                            {{ data.Arrival.Time }}
                          </li>
                        </ul>
                      </div>
                      <div class="flight-detail-right">
                        <h4 class="title d-flex flex-row align-center" style="padding-left: 0px; left: -7px">
                          <v-icon color="#571336" size="20"
                            >mdi-airplane-takeoff</v-icon
                          >
                          <span>
                          {{ data.Departure.AirportCode }} -
                          {{ data.Departure.AirportName }}</span>
                        </h4>
                        <div class="flight-detail-info">
                          <img
                            :src="data.MarketingCarrier.logo"
                            alt=""
                            width="34px"
                          />
                          <ul>
                            <li>
                              {{ data.MarketingCarrier.AirlineID }}
                              {{ data.MarketingCarrier.FlightNumber }} -
                              {{ data.MarketingCarrier.Name }}
                            </li>
                            <li>
                              {{ translateClass(oneway.class) }}
                              {{ translateRefund(res.Refund) }}
                            </li>
                            <!-- <li>{{ translateRefund(res.Refund) }}</li> -->
                          </ul>
                        </div>
                        <h4 class="title title-two">
                          {{ data.Arrival.AirportCode }} -
                          {{ data.Arrival.AirportName }}
                        </h4>
                      </div>
                    </div>
                    <div
                      v-if="index < res.Flight4.length - 1"
                      class="layover-section w-100 text-center my-2"
                    >
                      <div
                        class="dashed-line"
                        style="border-bottom: 1px dashed gray; width: 100%"
                      ></div>
                      <span
                        class="layover-time"
                        style="
                          font-size: 11px;
                          color: #6a2e4d;
                          position: absolute;
                          left: 50%;
                          transform: translateX(-50%);
                          top: -10px;
                        "
                      >
                        Layover: {{ res.layoverTimes4[index] }}
                        <span
                          v-if="
                            res.layoverTimes4[index].includes('h') &&
                            parseInt(res.layoverTimes4[index].split('h')[0]) >=
                              8
                          "
                          class="overnight-info"
                        >
                          <!-- <v-icon color="red" size="17">mdi-information-outline</v-icon> -->
                          <img
                            src="../assets/img/icon/sandclock1.png"
                            style="width: 13px; height: 15px"
                          />
                          <span class="tooltip-text">Long Layover</span>
                        </span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div v-if="disableData">
            <v-card class="p-3">
              <div class="d-flex flex-row">
                <span
                  ><v-img
                    :src="require('../assets/img/icon/no-flight.png')"
                    width="45px"
                    height="30px"
                  ></v-img
                ></span>
                <p class="m-0">
                  {{ $t("searchPageContent.errorMsg.text1") }}
                </p>
                <br />
                <p>{{ $t("searchPageContent.errorMsg.text2") }}</p>
              </div>
            </v-card>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-if="searchloader">
    <loader></loader>
  </div>
  <v-dialog v-model="pricedialog" persistent>
    <div class="d-flex justify-center">
      <v-card class="p-4">
        <h5>{{ priceText }}</h5>
        <span class="d-flex flex-row align-center"
          >New Fare:
          <h6 class="ms-2 m-0">
            {{ priceChangeValue.currency }}
            {{ priceRes.payload.itin_total_fare }}
          </h6></span
        >

        <span>Do you want to proceed with the booking?</span>

        <div class="d-flex justify-space-between mt-5">
          <v-btn color="#faa41f" @click="priceConfirm(1)"
            >Continue To Book</v-btn
          >
          <v-btn class="ms-2" color="#faa41f" @click="pricedialog = false"
            >Select Another Flight</v-btn
          >
        </div>
      </v-card>
    </div>
  </v-dialog>

  <v-dialog v-model="priceError" persistent>
    <div class="d-flex justify-center">
      <v-card class="p-4">
        <h6 class="text-center">
          We are unable to confirm the price and seat availability at this
          moment.
        </h6>
        <h6 class="text-center">
          Please retry booking the flight after some time.
        </h6>

        <div class="d-flex justify-center mt-5">
          <v-btn color="#faa41f" @click="this.priceError = false"
            >Book another flight</v-btn
          >
        </div>
      </v-card>
    </div>
  </v-dialog>
</template>

<script>
import loader from "@/components/loaderComponent.vue";
// import onewayService from '@/service/onewayService'
import multicityService from "@/service/multicityService";
import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import moment from "moment";
// import AutoComplete from "primevue/autocomplete";
// import userData from "@/airport_icao.json";
import axios from "axios";
const totalPricefilter = require("lodash");
// import pageError from "@/components/pageError.vue";
// import userData from '@/airport_icao.json';

import { getConfigDataFromLocalStorage } from "@/localStorageData/localStorageUtil.js";

export default {
  components: {
    VueDatePicker,
    // AutoComplete,
    loader,
    // pageError,
  },

  data() {
    return {
      autocompleteApi: "",
      pricedialog: false,
      priceRes: [],
      priceError: false,
      openFlight: false,
      flightDetails: null,
      radios: "one",
      showModal: null,
      openmodal: false,
      editing: false,
      cityNameInput: "",
      scrollTop: 0,
      headerStyles: ["headerCom", "headerCom1", "headerCom2", "headerCom3"],
      footerStyles: ["footerCom", "footerCom1", "footerCom2", "footerCom3"],
      strResult: "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ",
      selectedHeader: "headerCom1",
      selectedFooter: "footerCom",
      resultDataPass: 1,
      drawerVisible: false,
      pageError: false,
      dialog: false,
      fareOpen: false,
      multiCalender: true,
      totaladult: 0,
      classSelect: false,
      triggerRadio: false,
      vj: "",
      modifySection: false,
      FlightDetail: false,
      FlightDetail1: false,
      dateFormat: " dd MMM, yyyy",
      detail: {
        from: "",
        to: "",
        dedate: "",
        redate: "",
        class: "Economy",
        child: "",
        adult: "",
        infrant: "",
        city: "",
      },
      items: [],
      airlogodata: [],
      outBoundTime: [],
      inBoundTime: [],
      outBoundTimeMulti: [],
      defaultOutBoundMulti: null,
      defaultOutBoundTime: null,
      defaultInBoundTime: null,
      outBoundMulti: null,
      fromAirportName: [],
      toAirportName: [],
      isReturn: false,
      oneway1: false,
      roundtrip: false,
      multitrip: false,
      multicityTotalData: [],
      searchresult: false,
      searchloader: true,
      isIcon1: true,
      fareIcon: true,
      isIcon: true,
      date1: "",
      date2: "",
      value: "1",
      class: "Business",
      travels: false,
      red: 1,
      red1: 1,
      // paxhide: true,

      flights: [], // Your flight data array
      selectedFlight: null,
      roundModify: true,
      onewayModify: false,
      multiModify: false,

      find1: [],
      find2: [],

      offerData1: [],
      offerData2: [],
      offerData3: [],
      offerData4: [],
      offerData5: [],
      filteredData: [],
      returnData: [],
      combinedPricedetail: [],
      Oneway: false,
      rount: true,
      multicity: false,
      airData: [],
      flight: [],
      flight1: [],
      flightTime: [],
      flightseg: [],
      segkey: [],
      SegmentKey: [],
      arriv: [],
      dep: [],
      splitValues: [],
      splitValues1: [],
      seats: [],
      seg: [],
      uniqueId: [],
      Journey: [],
      Journey1: [],
      flightsegmet: [],
      flightsegmetkey: [],
      datas: [],
      journeyData: [],
      changevalue: [],
      steps: null,
      value1: [0, 100],
      value2: [0, 24],
      datasource: [],
      air: [],
      codeData: [],
      airnam: [],
      airnumber: [],
      airflight: [],
      flightprice: [],
      price: [],
      flightdate: [],
      time: [],
      arrivaltime: [],
      Renuvable: [],
      vasan: [],
      vasan1: [],
      totalprice: [],
      segmentref: [],
      stop: [],
      time1: [],
      multicityData: [],
      multicityData1: [],
      aircode: [],
      depcode: [],
      airname: [],
      depname: [],
      airtime: [],
      deptime: [],
      airid: [],
      airnum: [],
      airstop: [],
      offerpay: [],
      airstime: [],
      airfname: [],
      airdate: [],
      depdate: [],
      airref: [],
      airprice: [],
      sortingPrice: [],
      depnamedate: [],
      localData: [],
      rountresult: [],
      filterData: [],
      onewayresulted: [],
      onewayfilterData: [],
      multiFilterData: [],
      routtime: [],
      airportname: "",
      traveltime: "",
      Adult: [],
      Child: [],
      Infrant: [],
      maximum: null,
      minimum: null,
      countries: [{ name: "Australia", code: "AU" }],
      selectedCountry: "",
      loading: false,
      fromAirports: [],
      airitems: [],

      aduls: 1,
      aduls1: 0,
      aduls2: 0,
      addition: 0,
      classType: "",

      oneway: {
        from: null,
        to: null,
        dedate: null,
        redate: null,
        class: "",
        child: "",
        adult: "",
        infrant: "",
        city: "",
      },

      tripData: {
        from: null,
        to: null,
        dedate: null,
        redate: null,
        class: "",
        child: "",
        adult: "",
        infrant: "",
        city: "",
      },

      data_id: "",
      roundOfferId: [],

      oneway_id: "",
      onewayOfferId: [],

      typedrop: false,
      typedrop1: false,

      responseData: [],
      clicked1: false,
      clicked2: false,
      clicked3: false,
      datePickerIsOpen: false,
      priceChangeValue: [],
      fromErrorMessage: false,
      toErrorMessage: false,
      fromDateError: false,
      toDateError: false,

      disButton: false,
      loadSearch: false,

      hoveredName: false,
      hoverDetail: "Hello....",

      storefilterData: [],

      showNonStop: true,
      showOneStop: true,
      showOneplusStop: true,

      NonStopFil: false,
      OneStopFil: false,
      OnePlusFil: false,
      hasNonStop: false,
      hasOneStop: false,
      hasOnePlusStop: false,
      hasRefundable: false,
      hasNonRefundable: false,
      RefundableData: false,
      nonRefundableData: false,
      selectedFromAirports: false,
      selectedToAirports: false,
      onedatafil: false,
      rounddatafil: false,
      timeFilterData1: false,
      timeFilterData2: false,
      timeFilterData3: false,
      timeFilterData4: false,
      timeFilterArrData1: false,
      timeFilterArrData2: false,
      timeFilterArrData3: false,
      timeFilterArrData4: false,
      timearrFilterData1: false,
      timearrFilterData2: false,
      timearrFilterData3: false,
      timearrFilterData4: false,
      timearrFilterArrData1: false,
      timearrFilterArrData2: false,
      timearrFilterArrData3: false,
      timearrFilterArrData4: false,
      morningRange: [6, 12],
      afternoonRange: [12, 18],
      eveningRange: [18, 24],
      beforeMorningRange: [0, 6],
      hasMorningFlights: false,
      hasAfternoonFlights: false,
      hasEveningFlights: false,
      hasMorningFlights1: false,
      hasAfternoonFlights1: false,
      hasEveningFlights1: false,
      hasMorningReturn: false,
      hasAfternoonReturn: false,
      hasEveningReturn: false,
      hasMorningReturn1: false,
      hasAfternoonReturn1: false,
      hasEveningReturn1: false,
      nonStop: [],
      oneStop: [],
      onePlusStop: [],
      disableData: true,

      filNonstop: [],
      filOneStop: [],
      filOnePlusStop: [],
      concatStops: [],
      totalpassenger: [],

      maximumletter: false,
      maximumletter1: false,

      // layoverTimes: [],
      // layoverTimes1: [],
      // input: [
      //   {
      //     from: null,
      //     to: null,
      //     departDate: null,
      //     multiFromError: false,
      //     multiToError: false,
      //     multiDateError: false,
      //     clicked3:false,
      //   },
      //   {
      //     from: null,
      //     to: null,
      //     departDate: null,
      //     multiFromError: false,
      //     multiToError: false,
      //     multiDateError: false,
      //     clicked3:false,
      //   },
      // ],

      // input: [
      //   {
      //     from: null,
      //     to: null,
      //     departDate: null,
      //     multiFromError: false,
      //     multiToError: false,
      //     multiDateError: false,
      //     maximumletter: false,
      //     maximumletter1: false,
      //     clicked3: false,
      //   },
      //   {
      //     from: null,
      //     to: null,
      //     departDate: null,
      //     multiFromError: false,
      //     multiToError: false,
      //     multiDateError: false,
      //     maximumletter: false,
      //     maximumletter1: false,
      //     clicked3: false,
      //   },
      // ],

      Flight2: [],
      Flight3: [],
      Flight4: [],
      Flight5: [],
      resulted: [],

      multidrop: [],
      multidrop1: [],
      fromloader: false,
      toloader: false,

      preferCurrency: "",
      metaCurrency: "",
      pointofSale: "",
      pricingApi: "",
      onewaydata: {
        request_type: "air_search",
        request: {
          AirShoppingRQ: {
            CoreQuery: {
              OriginDestinations: {
                OriginDestination: [
                  {
                    Departure: {
                      AirportCode: "MAA",
                      Date: "2023-10-19",
                    },
                    Arrival: {
                      AirportCode: "BOM",
                    },
                  },
                ],
              },
            },
            DataLists: {
              PassengerList: {
                Passenger: [
                  {
                    PassengerID: "T1",
                    PTC: "ADT",
                  },
                ],
              },
            },
            Preference: {
              TripType: "",
              CabinType: "",
              Currency: "",
              FareType: ["PUB"],
            },
            MetaData: {
              Currency: "USD",
            },
          },
        },
        country: "",
        token: "Tc7RQaX79Hrx5CFK",
        portal_domain: "",
      },

      Rountdata: {
        request_type: "air_search",
        request: {
          AirShoppingRQ: {
            CoreQuery: {
              OriginDestinations: {
                OriginDestination: [
                  {
                    Departure: {
                      AirportCode: "MAA",
                      Date: "2023-08-25",
                    },
                    Arrival: {
                      AirportCode: "BOM",
                    },
                  },
                  {
                    Departure: {
                      AirportCode: "BOM",
                      Date: "2023-10-27",
                    },
                    Arrival: {
                      AirportCode: "MAA",
                    },
                  },
                ],
              },
            },
            DataLists: {
              PassengerList: {
                Passenger: [
                  {
                    PassengerID: "T1",
                    PTC: "ADT",
                  },
                ],
              },
            },
            Preference: {
              TripType: "",
              CabinType: "",
              Currency: "",
              FareType: ["PUB"],
            },
            MetaData: {
              Currency: "",
            },
          },
        },
        country: "",
        token: "Tc7RQaX79Hrx5CFK",
        portal_domain: "",
      },

      MulticityReq: {
        request_type: "air_search",
        request: {
          AirShoppingRQ: {
            CoreQuery: {
              OriginDestinations: {
                OriginDestination: [],
              },
            },
            DataLists: {
              PassengerList: {
                Passenger: [],
              },
            },
            Preference: {
              TripType: "",
              CabinType: "",
              Currency: "",
              FareType: ["PUB"],
            },
            MetaData: {
              Currency: "",
            },
          },
        },
        country: "",
        token: "Tc7RQaX79Hrx5CFK",
        portal_domain: "",
      },

      itinaryRequest: {
        SearchClass: "",
        OriginCity1: "YVR",
        DestinationCity1: "SEL",
        OriginCity2: "SEL",
        DestinationCity2: "YVR",
        TripType: "RoundTrip",
        PointOfSaleCountry: "CA",
        DisplayedPrice: "5915.24",
        DisplayedPriceCurrency: "CAD",
        UserLanguage: "en",
        UserCurrency: "CAD",
        Adult: "1",
        Child: "0",
        InfantLap: "0",
        Slice1: "1",
        Slice2: "2,3",
        mTime: "2023-09-06T12:49:58.885351Z",
        ugid: "43a49112d6a5-4b03-970af4d126a1deb1",
        ReferralId: "btgfs",
        HitId: "ca-65093a3b3f7c02-50740846-5893",
      },

      itinaryRequestoneway: {
        SearchClass: "",
        OriginCity1: "",
        DestinationCity1: "",
        OriginCity2: "",
        DestinationCity2: "",
        TripType: "Oneway",
        PointOfSaleCountry: "CA",
        DisplayedPrice: "5915.24",
        DisplayedPriceCurrency: "CAD",
        UserLanguage: "en",
        UserCurrency: "CAD",
        Adult: "1",
        Child: "0",
        InfantLap: "0",
        Slice1: "1",
        mTime: "2023-09-06T12:49:58.885351Z",
        ugid: "43a49112d6a5-4b03-970af4d126a1deb1",
        ReferralId: "btgfs",
        HitId: "ca-65093a3b3f7c02-50740846-5893",
      },

      formMultiData: [],

      multi_city: {
        itinery: [
          {
            from: null,
            to: null,
            departDate: null,
            multiFromError: false,
            multiToError: false,
            multiDateError: false,
            maximumletter: false,
            maximumletter1: false,
            clicked3: false,
            multiSearchBtn: true,
            focusMultiEvent1: false,
            focusMultiEvent2: false,
            focusMultiEvent3: false,
            autocomfrom: false,
            autocomTo: false,
            selectedFrom: false,
            selectedTo: false,
          },
          {
            from: null,
            to: null,
            departDate: null,
            multiFromError: false,
            multiToError: false,
            multiDateError: false,
            maximumletter: false,
            maximumletter1: false,
            clicked3: false,
            multiSearchBtn: true,
            focusMultiEvent1: false,
            focusMultiEvent2: false,
            focusMultiEvent3: false,
            autocomfrom: false,
            autocomTo: false,
            selectedFrom: false,
            selectedTo: false,
          },
        ],
        class: "",
        child: "",
        adult: "",
        infrant: "",
        city: "3",
      },

      showMulti_data: {},

      // multi_city: [
      //   {
      //     from: null,
      //     to: null,
      //     departDate: null,
      //     multiFromError: false,
      //     multiToError: false,
      //     multiDateError: false,
      //   },
      //   {
      //     from: null,
      //     to: null,
      //     departDate: null,
      //     multiFromError: false,
      //     multiToError: false,
      //     multiDateError: false,
      //   }
      // ],
      addmore: true,
      clearall: false,
      deleteSeg: true,

      makeSearchUrl: "",
      airLogoApi: [],

      airline_logoData: [],

      airlineIcaoUrl: "",
      airlineIcaoType: "",
      airlineList: [],
      maxItemsToShow: 5,
      selectedFrom: false,
      selectedTo: false,
      longLayover: false,
      portal_domain: "",
      portal_id:"",
      // isFlightDetailOpen:[],
      classTypeitems: [
        {
          text: this.$t("formsContents.options.economy"),
          value: "Economy",
          department: "Economy Department",
        },
        {
          text: this.$t("formsContents.options.business"),
          value: "Business",
          department: "Business Department",
        },
        {
          text: this.$t("formsContents.options.firstClass"),
          value: "First Class",
          department: "First Class Department",
        },
        {
          text: this.$t("formsContents.options.premiumEconomy"),
          value: "Premium Economy",
          department: "Premium Economy Department",
        },
      ],
    };
  },

  methods: {
    calculateLayover(currentFlight, nextFlight) {
      const arrivalTime = new Date(
        `${currentFlight.Arrival.Date}T${currentFlight.Arrival.Time}`
      );
      const nextDepartureTime = new Date(
        `${nextFlight.Departure.Date}T${nextFlight.Departure.Time}`
      );

      const diffInMinutes = (nextDepartureTime - arrivalTime) / (1000 * 60);
      const hours = Math.floor(diffInMinutes / 60);
      const minutes = diffInMinutes % 60;
      if (hours > 8) {
        this.longLayover = true;
        return `${hours}h ${minutes}m`;
      } else {
        this.longLayover = false;
      }

      return `${hours}h ${minutes}m`;
    },
    isOvernightFlight(segment) {
      console.log(segment, "segmentttt");

      const departureDate = new Date(
        `${segment.Departure.Date}T${segment.Departure.Time}`
      );
      const arrivalDate = new Date(
        `${segment.Arrival.Date}T${segment.Arrival.Time}`
      );

      if (arrivalDate > departureDate) {
        if (departureDate.getDate() !== arrivalDate.getDate()) {
          return true;
        }
      }

      const departureHour = departureDate.getHours();
      // const arrivalHour = arrivalDate.getHours();
      console.log(departureHour, "departureHour");

      if (departureHour > 18) {
        return true;
      }

      return false;
    },

    isOvernightFlight1(segment) {
      console.log(segment, "segmentttt");

      const departureDate = new Date(
        `${segment.Departure.Date}T${segment.Departure.Time}`
      );
      const arrivalDate = new Date(
        `${segment.Arrival.Date}T${segment.Arrival.Time}`
      );

      if (arrivalDate > departureDate) {
        if (departureDate.getDate() !== arrivalDate.getDate()) {
          return true;
        }
      }

      const departureHour = departureDate.getHours();
      // const arrivalHour = arrivalDate.getHours();
      console.log(departureHour, "departureHour");

      if (departureHour > 18) {
        return true;
      }

      return false;
    },

    fromInputData() {
      this.oneway.from = "";
      if (!this.oneway.from) {
        this.selectedFrom = false;
        // this.$refs.fromAutoComplete.focus();
      }
      // const regex = /[-()]/;
      // if(regex.test(this.oneway.from)){
      //   this.selectedFrom = true;
      // }
      // if(!this.oneway.from || !this.inputCity){
      //    this.$refs.firstAutoComplete.focus();
      // }
    },
    toInputData() {
      this.oneway.to = "";
      if (!this.oneway.to) {
        this.selectedTo = false;
        // this.$refs.toAutoComplete.focus();
      }
      // if(!this.oneway.to || !this.inputCityto){
      //    this.$refs.secondAutoComplete.focus();
      // }
      // this.$refs.secondAutoComplete.focus();
    },
    multiFrom(index) {
      // const regex = /[-()]/;
      this.multi_city.itinery.map((v, i) => {
        if (i == index) {
          v.from = "";
          v.selectedFrom = false;
          this.$refs.multiFromAutocomplete[index].focus();
        }
        //   else if(regex.test(v.from)){
        //     v.selectedFrom = true
        // }
      });
    },
    multiTo(index) {
      // const regex = /[-()]/;
      this.multi_city.itinery.map((v, i) => {
        if (i == index) {
          v.to = "";
          v.selectedTo = false;
          this.$refs.multiToAutocomplete[index].focus();
        }
        //   else if(regex.test(v.from)){
        //     v.selectedTo = true
        // }
      });
    },

    // openModal(flight) {
    //     this.selectedFlight = flight;
    //     $('#flightDetailsModal').modal('show'); // Show the modal
    // },
    flightDetail(index) {
      if (this.flightDetails === index) {
        this.flightDetails = null;
      } else {
        this.flightDetails = index;
      }
      if (this.rountresult) {
        this.rountresult.find((v, i) =>
          i == index
            ? (v.Flightdepature = !v.Flightdepature)
            : (v.Flightdepature = false)
        );
      }
      if (this.multicityData) {
        this.multicityData.find((v, i) =>
          i == index
            ? (v.Flightdepature = !v.Flightdepature)
            : (v.Flightdepature = false)
        );
      }
    },
    flightDetailOneway(index) {
      if (this.flightDetails === index) {
        this.flightDetails = null;
      } else {
        this.flightDetails = index;
      }
      this.onewayresulted.find((v, i) =>
        i == index
          ? (v.Flightdepature = !v.Flightdepature)
          : (v.Flightdepature = false)
      );
    },

    getRandomChars(length) {
      let result = "";
      for (let i = 0; i < length; i++) {
        const randomIndex = Math.floor(Math.random() * this.strResult.length);
        result += this.strResult[randomIndex];
      }
      // console.log(result, "result");
      return result;
    },
    getRandomInt(min, max) {
      return Math.floor(Math.random() * (max - min + 1)) + min;
    },

    generateBookingRefId() {
      const randomChars1 = this.getRandomChars(8);
      const randomNum = this.getRandomInt(10, 99);
      const randomChars2 = this.getRandomChars(8);

      this.uniqueId = randomChars1 + randomNum + randomChars2;
      // console.log(
      //   this.uniqueId,
      //   "bookingRefIdbookingRefIdbookingRefId"
      // );
    },
    showMore() {
      this.readMore = !this.readMore;

      // let $data = []
      console.log(this.filterAirline, "this.filterAirline ");

      if (this.readMore) {
        this.maxItemsToShow = this.airlineList.length;
      } else {
        this.maxItemsToShow = 5;
      }
    },
    airlineFilter() {
      let storeAirline = new Set();
      let $event = [];

      if (this.rountresult.length > 0) {
        $event = this.filterData;
      }

      if (this.onewayresulted.length > 0) {
        $event = this.onewayfilterData;
      }

      if (this.multicityData.length > 0) {
        $event = this.multiFilterData;
      }

      if (this.rountresult) {
        this.rountresult.forEach((data) => {
          data.Depature.forEach((flight) => {
            if (flight.MarketingCarrier.Name) {
              storeAirline.add(flight.MarketingCarrier.Name);
            }
          });
          data.Return.forEach((flight) => {
            if (flight.MarketingCarrier.Name) {
              storeAirline.add(flight.MarketingCarrier.Name);
            }
          });
        });
      }
      if (this.onewayresulted) {
        this.onewayresulted.forEach((data) => {
          data.Depature.forEach((flight) => {
            if (flight.MarketingCarrier.Name) {
              storeAirline.add(flight.MarketingCarrier.Name);
            }
          });
        });
      }
      if (this.multicityData) {
        this.multicityData.forEach((data) => {
          data.Flight1.forEach((flight) => {
            storeAirline.add(flight.MarketingCarrier.Name);
          });
          data.Flight2.forEach((flight) => {
            storeAirline.add(flight.MarketingCarrier.Name);
          });
          if (data.Flight3) {
            data.Flight3.forEach((flight) => {
              storeAirline.add(flight.MarketingCarrier.Name);
            });
          } else if (data.Flight4) {
            data.Flight4.forEach((flight) => {
              storeAirline.add(flight.MarketingCarrier.Name);
            });
          }
        });
      }

      $event.forEach((v) => {
        if (v.ownername && !storeAirline.has(v.ownername)) {
          storeAirline.add(v.ownername);
        }
      });

      this.airlineList = Array.from(storeAirline)
        .sort()
        .map((s) => ({ airline: s, active: true }));

      // console.log(this.airlineList, "airlineList");

      this.filterAirline = this.airlineList;
    },
    selectAirline(data, index) {
      let $data = [];
      let $result = [];
      if (this.filterData.length > 0) {
        $data = this.filterData;
      }

      if (this.onewayfilterData.length > 0) {
        $data = this.onewayfilterData;
      }
      if (this.multiFilterData.length > 0) {
        $data = this.multiFilterData;
      }
      this.airlineList.map((a, i) => {
        if (i == index) {
          a.active = !a.active;
        }
        if (a.active) {
          $data.forEach((c) => {
            if (c.ownername == a.airline) {
              $result.push(c);
            }
          });
        }

        $result.sort((a, b) => a.perperson - b.perperson);
        if (this.filterData.length > 0) {
          this.rountresult = $result;
        } else if (this.onewayfilterData.length > 0) {
          this.onewayresulted = $result;
        } else if (this.multiFilterData.length > 0) {
          this.multicityData = $result;
        }
      });
      this.applyFilters();

      //  this.Cheapest()
      //   this.Shortest()
      // this.bestlayTime()
      //  this.Recommend()
      // $result.sort((a, b) => a.perperson - b.perperson);
      // this.rountresult = $result;
    },
    airlineClear() {
      let $result = [];
      let $data = [];

      if (this.filterData.length > 0) {
        $data = this.filterData;
      }

      if (this.onewayfilterData.length > 0) {
        $data = this.onewayfilterData;
      }
      if (this.multicityData.length > 0) {
        $data = this.multiFilterData;
      }

      this.airlineList.forEach((v, i) => {
        if (i !== 0) {
          v.active = false;
        } else {
          v.active = true;
          $data.forEach((c) => {
            if (c.ownername == v.airline) {
              $result.push(c);
            }
          });
        }
      });

      $result.sort((a, b) => a.perperson - b.perperson);
      if (this.filterData.length > 0) {
        this.rountresult = $result;
      } else if (this.onewayfilterData.length > 0) {
        this.onewayresulted = $result;
      } else if (this.multiFilterData.length > 0) {
        this.multicityData = $result;
      }
      this.applyFilters();
    },

    airlineSelect() {
      this.airlineList.forEach((v) => {
        v.active = true;
      });
      if (this.rountresult.length > 0) {
        this.rountresult = this.filterData;
      } else if (this.onewayresulted.length > 0) {
        this.onewayresulted = this.onewayfilterData;
      } else if (this.multicityData.length > 0) {
        this.multicityData = this.multiFilterData;
      }
      this.applyFilters();
    },

    showDetails1(res, index) {
      // console.log(res,'resshowdetailssss')
      if (this.rountresult) {
        this.rountresult.map((v, i) => {
          if (i == index) {
            v.hoveredName1 = true;
          } else {
            v.hoveredName1 = false;
          }
        });
      }
      if (this.onewayresulted) {
        this.onewayresulted.map((v, i) => {
          // console.log(i, "oooooooooooppppppppppppppppppp");
          if (i == index) {
            v.hoveredName1 = true;
          } else {
            v.hoveredName1 = false;
          }
        });
      }

      if (this.multicityData) {
        this.multicityData.map((v, i) => {
          if (i == index) {
            v.hoveredName1 = true;
          } else {
            v.hoveredName1 = false;
          }
        });
      }
    },
    hideDetails1(res) {
      res.hoveredName1 = false;

      // console.log(res, "oooooooooooooooo");
    },

    showDetails2(res, index) {
      this.rountresult.map((v, i) => {
        if (i == index) {
          v.hoveredName2 = true;
        } else {
          v.hoveredName2 = false;
        }
      });
      if (this.onewayresulted) {
        this.onewayresulted.map((v, i) => {
          // console.log(i, "oooooooooooppppppppppppppppppp");
          if (i == index) {
            v.hoveredName2 = true;
          } else {
            v.hoveredName2 = false;
          }
        });
      }
    },
    hideDetails2(res) {
      res.hoveredName2 = false;
    },

    showDetails3(res, index) {
      this.rountresult.map((v, i) => {
        // console.log(v, "ppppp");
        if (i == index) {
          v.hoveredName3 = true;
        } else {
          v.hoveredName3 = false;
        }
      });
    },
    hideDetails3(res) {
      res.hoveredName3 = false;

      // console.log(res, "oooooooooooooooo");
    },

    showDetails4(res, index) {
      this.rountresult.map((v, i) => {
        if (i == index) {
          v.hoveredName4 = true;
        } else {
          v.hoveredName4 = false;
        }
      });
    },
    hideDetails4(res) {
      res.hoveredName4 = false;
    },

    act(data) {
      this.red1 = data;
      // this.oneway.city = this.red1;
      console.log(this.red1, "pppppppppppppppppppppppppp");

      if (this.red1 == 2) {
        this.Oneway = true;
        (this.rount = false), (this.multicity = false);
      }
      if (this.red1 == 1) {
        this.Oneway = false;
        this.rount = true;
        this.multicity = false;
      }
      if (this.red1 == 3) {
        this.radios = "three";
        this.Oneway = false;
        this.rount = false;
        this.multicity = true;
        // this.paxhide = false
      }
    },

    actActive() {
      // console.log(this.red1, "TRTRTRTR1");
      if (this.red1 == 2) {
        this.radios = "two";
        this.Oneway = true;
        (this.rount = false), (this.multicity = false);
        this.roundModify = false;
        this.onewayModify = true;
      } else if (this.red1 == 1) {
        this.radios = "one";
        this.Oneway = false;
        this.rount = true;
        this.multicity = false;
        this.roundModify = true;
        this.onewayModify = false;
      } else {
        this.radios = "three";
        this.Oneway = false;
        this.rount = false;
        this.multicity = true;
        // this.paxhide = false
      }
    },
    passengerData() {
      this.resultDataPass = this.aduls + this.aduls1 + this.aduls2;
    },
    deg1() {
      // this.adulted1();
      if (this.aduls == this.aduls2 && this.aduls2 > 1) {
        this.deg3();
      }
      if (this.aduls > 1) {
        this.aduls = this.aduls - 1;

        this.added();
      }
    },
    add1() {
      console.log(this.aduls, "1111");
      if (this.aduls < 9 && this.addition < 9) {
        this.aduls = this.aduls + 1;
        console.log(this.aduls, "2222");
        // this.adulted();
        this.added();
      }
    },

    deg2() {
      if (this.aduls1 > 0) {
        this.aduls1 = this.aduls1 - 1;

        // this.Childed1();
        this.added();
      }
      // console.log( this.Child,'concatenatedArray')
    },
    add2() {
      if (this.aduls1 < 8 && this.addition < 9) {
        this.aduls1 = this.aduls1 + 1;

        // console.log(this.aduls1, "2222");

        // this.Childed();
        this.added();
      }
      // console.log( this.Child,'concatenatedArray')
    },
    deg3() {
      if (this.aduls2 > 0) {
        this.aduls2 = this.aduls2 - 1;

        this.Infranted1();
        this.added();
      }
    },
    add3() {
      if (this.addition < 9 && this.aduls > this.aduls2) {
        this.aduls2 = this.aduls2 + 1;

        // console.log(this.aduls2, "3333");

        this.Infranted();
        this.added();
      }
    },
    Infranted() {
      this.Infrant.push({
        PassengerID: "T",
        PTC: "INF",
      });
      this.passengerData();
    },
    Infranted1() {
      this.Infrant.pop();
    },
    added() {
      this.addition = this.aduls + this.aduls1 + this.aduls2;
      this.concatenatedArray = this.Adult.concat(this.Child).concat(
        this.Infrant
      );
      this.passengerData();
      // console.log(this.concatenatedArray, "concatenatedArray");
    },

    localdone() {
      localStorage.setItem("value1", "this.aduls");
    },

    selectFunction() {
      this.classSelect = !this.classSelect;
      this.$refs.mySelect.focus();
      // console.log(this.classSelect, "ffffaaaallllssseeee");
      if (!this.classSelect) {
        this.$refs.countrySelect.focus();
      }
    },

    closeDropdown() {
      setTimeout(() => {
        this.$refs.mySelect.blur();
        if (this.classType) {
          this.$refs.countrySelect.blur();
          console.log("working:");
        }
      }, 50);
    },

    multiSend() {
      if (this.multi_city.itinery.length < 4) {
        this.multi_city.itinery.push({
          from: null,
          to: null,
          departDate: null,
          multiFromError: false,
          multiToError: false,
          multiDateError: false,
        });

        if (this.multi_city.itinery.length == 4) {
          console.log(
            this.multi_city,
            this.multi_city.itinery.length,
            "lengthlen.....gthlength"
          );

          this.addmore = false;
          this.clearall = true;
        }
        // else{
        //   this.clearall = false;
        // }
      }
      if (this.multi_city.itinery.length > 2) {
        this.deleteSeg = true;
      }

      this.multi_city.itinery.map((v, i) => {
        // console.log(i, v, "indexooo");

        if (this.multi_city.itinery.length - 2 == i) {
          this.multi_city.itinery[i + 1].from = this.multi_city.itinery[i].to;
          if (this.multi_city.itinery[i + 1].from != null) {
            this.multi_city.itinery[i + 1].selectedFrom = true;
          }
        }
      });

      // for (let i = 1; i < this.multi_city.itinery.length; i++) {
      //   this.multi_city.itinery[i].from = this.multi_city.itinery[i - 1].to;
      // }
    },

    deleteSegment() {
      if (this.multi_city.itinery.length == 3) {
        this.multi_city.itinery.splice(-1);
        this.deleteSeg = false;
        this.addmore = true;
      } else if (this.multi_city.itinery.length > 2) {
        console.log(this.multi_city.itinery.length, "lengthlengthl,..111..");
        this.multi_city.itinery.splice(-1);
        this.deleteSeg = true;
        this.addmore = true;
      } else {
        this.deleteSeg = false;
      }
    },

    clearall1() {
      this.multi_city.itinery.splice(2, 5);

      (this.multi_city.itinery = [
        {
          from: null,
          to: null,
          departDate: null,
          multiFromError: false,
          multiToError: false,
          multiDateError: false,
        },
        {
          from: null,
          to: null,
          departDate: null,
          multiFromError: false,
          multiToError: false,
          multiDateError: false,
        },
      ]),
        (this.clearall = false);
      this.addmore = true;
    },

    // getprice(res) {
    //   return (((res.TotalAmount) / this.totaladult).toFixed(2));
    // },

    getdepseat(data) {
      // console.log(data,'ppppppppppppppppppppppp')
      return data[0].Seat;
    },

    getretseat(data) {
      return data[0].Seat;
    },
    // getlogo($event) {
    //   return $event[0].MarketingCarrier.logo;
    // },
    // getlogo1($event) {
    //   return $event[0].MarketingCarrier.logo;
    // },
    getname($event) {
      return $event[0].MarketingCarrier.Name;
    },
    getid($event) {
      return $event[0].MarketingCarrier.AirlineID;
    },
    getNumber($event) {
      return $event[0].MarketingCarrier.FlightNumber;
    },

    // All Date Section
    getDate($event) {
      return moment($event[0].Departure.Date, "YYYY-MM-DD").format("MMM DD");
    },
    getDate1($event) {
      return moment($event[0].Departure.Date, "YYYY-MM-DD").format(
        "dddd, MMM DD"
      );
    },

    getArrivaldate($event) {
      return moment(
        $event[$event.length - 1].Arrival.Date,
        "YYYY-MM-DD"
      ).format("MMM DD");
    },
    getArrivaldate1($event) {
      return moment(
        $event[$event.length - 1].Arrival.Date,
        "YYYY-MM-DD"
      ).format("dddd, MMM DD");
    },

    getreturnDate($event) {
      return moment($event[0].Departure.Date, "YYYY-MM-DD").format("MMM DD");
    },

    getreturnArrivaldate($event) {
      return moment(
        $event[$event.length - 1].Arrival.Date,
        "YYYY-MM-DD"
      ).format("MMM DD");
    },

    // All Date Section End

    // Mobile Date Format Section

    getDateDeparture($event) {
      return moment($event[0].Departure.Date, "YYYY-MM-DD").format("MMM DD");
    },

    getArrivaldateReturn($event) {
      return moment(
        $event[$event.length - 1].Arrival.Date,
        "YYYY-MM-DD"
      ).format("MMM DD");
    },

    getreturnDateDeparture($event) {
      return moment($event[0].Departure.Date, "YYYY-MM-DD").format("MMM DD");
    },

    getreturnArrivaldateReturn($event) {
      return moment(
        $event[$event.length - 1].Arrival.Date,
        "YYYY-MM-DD"
      ).format("MMM DD");
    },

    // Mobile Date Show End

    getdepLogo($event) {
      for (let i = 0; i < this.airline_logoData.length - 1; i++) {
        if ($event.AirlineID == this.airline_logoData[i].id) {
          return this.airline_logoData[i].logo;
        }
      }
    },

    getreturnLogo($event) {
      // console.log($event,'getreturnLogogetreturnLogo')
      for (let i = 0; i < this.airline_logoData.length - 1; i++) {
        if ($event.AirlineID == this.airline_logoData[i].id) {
          // console.log(this.airline_logoData[i].logo,"logoDatalogoDatalogoData");
          return this.airline_logoData[i].logo;
        }
      }
    },

    getAirportCode($event) {
      //console.log($event,'airportCOdeeeee')
      return $event[0].Departure.AirportCode;
    },

    getshowAirCode1($event) {
      const parts = $event.split("(");
      const depCode = parts.pop().replace(")", "");
      return depCode;
    },

    getshowAirCode2($event) {
      const parts = $event.split("(");
      const retCode = parts.pop().replace(")", "");
      return retCode;
    },

    getCountryName1($event) {
      this.FilterTimeDepname = $event.split("(")[0];
      return $event.split("(")[0];
    },
    getCountryName2($event) {
      this.FilterTimearrname = $event.split("(")[0];
      return $event.split("(")[0];
    },

    getAirportNameFrom($event) {
      return $event[0].Departure.AirportName;
    },

    getAirportNameTo($event) {
      return $event[0].Departure.AirportName;
    },

    getarrivaldata($event) {
      return $event[0].Arrival.AirportName;
    },
    getreturndata($event) {
      return $event[0].Arrival.AirportName;
    },
    getarrtime($event) {
      return $event[0].Arrival.Time;
    },
    getreturntime($event) {
      return $event[0].Arrival.Time;
    },
    get1arrtime($event) {
      // console.log($event, '$event')

      return $event[$event.length - 1].Departure.AirportName;
    },
    get1returntime($event) {
      return $event[$event.length - 1].Departure.AirportName;
    },

    get2arrtime($event) {
      return $event[$event.length - 1].Departure.Time;
    },

    get2returntime($event) {
      return $event[$event.length - 1].Departure.Time;
    },
    getTime($event) {
      return moment($event[0].Departure.Time, "HH:mm:ss").format("HH:mm");
    },
    getArrivalTime($event) {
      return moment($event[$event.length - 1].Arrival.Time, "HH:mm:ss").format(
        "HH:mm"
      );
    },

    getArrivalCode($event) {
      return $event[$event.length - 1].Arrival.AirportCode;
    },
    getretairname($event) {
      return $event[$event.length - 1].Arrival.AirportName;
    },
    getreturnname($event) {
      return $event[0].MarketingCarrier.Name;
    },
    getreturnNumber($event) {
      return $event[0].MarketingCarrier.FlightNumber;
    },
    getreturnid($event) {
      return $event[0].MarketingCarrier.AirlineID;
    },

    getdeparDate($event) {
      return moment($event[0].Departure.Date, "YYYY-MM-DD").format(
        "ddd MMM DD"
      );
    },

    getdated($event) {
      return moment($event).format("dddd, MMM DD");
    },
    getdated1($event) {
      return moment($event).format("dddd, MMM DD");
    },
    getdated2($event) {
      return moment($event).format("dddd, MMM DD");
    },
    getdated3($event) {
      return moment($event).format("dddd, MMM DD");
    },
    getreturnAirportCode($event) {
      return $event[0].Departure.AirportCode;
    },
    getdeparAirportCode($event) {
      return $event[0].Departure.AirportCode;
    },

    getreturnTime($event) {
      return moment($event[0].Departure.Time, "HH:mm:ss").format("HH:mm");
    },
    getreturnArrivalTime($event) {
      return moment($event[$event.length - 1].Arrival.Time, "HH:mm:ss").format(
        "HH:mm"
      );
    },

    getreturnArrivalcode($event) {
      return $event[$event.length - 1].Arrival.AirportCode;
    },
    getdeparArrivalcode($event) {
      return $event[$event.length - 1].Arrival.AirportCode;
    },

    //     getlayover(data) {
    //       this.layoverTimes1 = [];

    // let $event=data
    // let layoverTime=[]
    // for (let i = 0; i <= $event.length-1; i++) {
    //   console.log($event[i],'data.length - 1')

    //   const currentIndex = i;
    //   const nextIndex = (i + 1) % data.length;

    //   const time1 = new Date(
    //     `${data[currentIndex].Arrival.Date}T${data[currentIndex].Arrival.Time}`
    //   );
    //   const time2 = new Date(
    //     `${data[nextIndex].Departure.Date}T${data[nextIndex].Departure.Time}`
    //   );

    //   const diffInMilliseconds = Math.abs(time2 - time1);
    //   const diffInMinutes = Math.floor(diffInMilliseconds / (1000 * 60));F

    //   const hours = Math.floor(diffInMinutes / 60);
    //   const minutes = diffInMinutes % 60;

    //     layoverTime .push(`${hours.toString().padStart(2, "0")} h:${minutes.toString().padStart(2, "0")} min`) ;

    // }

    // this.layoverTimes1=layoverTime
    // console.log(this.layoverTimes1,'0000000000000000000')
    //     },

    //     getlayoverdep(data) {
    //       console.log(data,'indexxxxx')
    //       this.layoverTimes= [];
    // let $event=data
    // let layoverTime=[]
    // for (let i = 0; i <= $event.length-1; i++) {
    //   console.log($event[i],'data.length - 1')

    //   const currentIndex = i;
    //   const nextIndex = (i + 1) % data.length;

    //   const time1 = new Date(
    //     `${data[currentIndex].Arrival.Date}T${data[currentIndex].Arrival.Time}`
    //   );
    //   const time2 = new Date(
    //     `${data[nextIndex].Departure.Date}T${data[nextIndex].Departure.Time}`
    //   );

    //   const diffInMilliseconds = Math.abs(time2 - time1);
    //   const diffInMinutes = Math.floor(diffInMilliseconds / (1000 * 60));

    //   const hours = Math.floor(diffInMinutes / 60);
    //   const minutes = diffInMinutes % 60;

    //     layoverTime .push(`${hours.toString().padStart(2, "0")} h:${minutes.toString().padStart(2, "0")} min`) ;

    // }

    // this.layoverTimes=layoverTime
    // console.log(this.layoverTimes,'0000000000000000000')
    // },

    closeRet(res, index) {
      this.rountresult.map((v, i) => {
        if (i == index) {
          v.Flightreturn = false;
        }
      });
    },

    // openModal(index) {
    //   this.showModal =  index;
    //   this.openmodal = true;
    //   document.documentElement.style.overflow = 'hidden';
    //   document.body.scroll = "no";
    //   // console.log(this.rountresult,'this.rountresultthis.rountresult.....1')
    // },
    closeModal() {
      this.rountresult[this.showModal].Flightdepature = false;
      // console.log(this.showModal,'lavsssssss')
      this.showModal = null;
      this.openmodal = false;
      document.documentElement.style.overflow = "scroll";
      // document.body.scroll = "yes";
    },
    closeModalOneWay() {
      this.onewayresulted[this.showModal].Flightdepature = false;
      // console.log(this.showModal,'lavsssssss')
      this.showModal = null;
      this.openmodal = false;
      document.documentElement.style.overflow = "scroll";
      // document.body.scroll = "yes";
    },
    closeModalMulti() {
      this.multicityData[this.showModal].Flightdepature = false;

      this.showModal = null;
      this.openmodal = false;
      document.documentElement.style.overflow = "scroll";
      // document.body.scroll = "yes";
    },
    flighted1(res, index) {
      this.rountresult.find((v, i) => {
        i == index
          ? (v.Flightdepature = !v.Flightdepature)
          : (v.Flightdepature = false);
      });

      this.showModal = index;
      this.openmodal = true;
      document.documentElement.style.overflow = "hidden";
      document.body.scroll = "no";
    },
    flighted2(res, index) {
      this.onewayresulted.find((v, i) =>
        i == index
          ? (v.Flightdepature = !v.Flightdepature)
          : (v.Flightdepature = false)
      );
      this.showModal = index;
      this.openmodal = true;
      document.documentElement.style.overflow = "hidden";
      document.body.scroll = "no";
    },

    flighted3(res, index) {
      console.log(index, res);
      this.multicityData.find((v, i) =>
        i == index
          ? (v.Flightdepature = !v.Flightdepature)
          : (v.Flightdepature = false)
      );
      this.showModal = index;
      this.openmodal = true;
      document.documentElement.style.overflow = "hidden";
      document.body.scroll = "no";
    },

    closeDep(res, index) {
      if (this.rountresult) {
        this.rountresult.map((v, i) => {
          // console.log(v, "nnnn");
          if (i == index) {
            v.Flightdepature = false;
          }
        });
      }
      if (this.onewayresulted) {
        this.onewayresulted.map((v, i) => {
          // console.log(v, "nnnn");
          if (i == index) {
            v.Flightdepature = false;
          }
        });
      }
      if (this.multicityData) {
        this.multicityData.map((v, i) => {
          // console.log(v, "nnnn");
          if (i == index) {
            v.Flightdepature = false;
          }
        });
      }
    },

    getflightdep(data) {
      return moment(data.Departure.Time, "HH:mm:ss").format("HH:mm");
    },
    getflightarr(data) {
      return moment(data.Arrival.Time, "HH:mm:ss").format("HH:mm");
    },
    getflightdep1(data) {
      return moment(data.Departure.Time, "HH:mm:ss").format("HH:mm");
    },
    getflightarrival(data) {
      return moment(data.Arrival.Time, "HH:mm:ss").format("HH:mm");
    },

    onewayselect(result, index) {
      localStorage.removeItem("itineryData");
      localStorage.removeItem("uniId");
      console.log(result, index);
      localStorage.setItem(
        `${result.currency}-${result.selectId}`,
        JSON.stringify(result)
      );
      this.$router.push({
        path: "/flightInfo",
        query: { pass_id1: `${result.currency}-${result.selectId}` },
      });
    },

    multidata() {
      multicityService.getAllUser().then((v) => {
        this.offerpay = v.data.OffersGroup.AirlineOffers[0].Offer;

        this.Journey = v.data.DataLists.FlightList.Flight;

        this.Journey1 = v.data.DataLists.FlightSegmentList.FlightSegment;
        this.Journey.forEach((a) => {
          this.flightsegmet.push(a.SegmentReferences.split(" "));
        });
        this.Journey1.forEach((t) => {
          this.flightsegmetkey.push(t);
        });

        this.Journey.forEach((q) => {
          this.airstime.push(q.Journey.Time);
          this.airstop.push(q.Journey.Stops);
        });
        for (let i = 0; i <= this.offerpay.length - 1; i++) {
          let item = [];
          item = this.offerpay[i].OfferItem;
          item.forEach((t) => {
            this.airprice.push(
              t.TotalPriceDetail.TotalAmount.BookingCurrencyPrice
            );
            if (
              t.PassengerType === "ADT" ||
              t.PassengerType == "ChD" ||
              t.PassengerType == "INF"
            ) {
              if (t.Refundable === "true") {
                this.airref.push("Refundable");
              } else {
                this.airref.push("NON Refundable");
              }
            }
          });
        }

        this.airprice.forEach((c) => {
          this.multicityData1.push({ airprice: c });
        });
      });
    },

    mydata() {
      try {
        this.resulted = JSON.parse(localStorage.getItem("myData"));
      } catch (error) {
        console.error("An error occurred:", error);
      }
    },

    deatailData() {
      if (this.detail.city == 2) {
        this.Oneway = true;
        (this.rount = false), (this.multicity = false);
        this.oneway1 = true;
      }
      if (this.detail.city == 1) {
        this.roundtrip = true;
        this.Oneway = false;
        (this.rount = true), (this.multicity = false);
      }
      if (this.detail.city == 3) {
        this.multitrip = true;
        this.Oneway = false;
        (this.rount = false), (this.multicity = true);
      }
    },

    // RoundTrip datePicker

    getDatepicker($data, index) {
      if (index > 0) {
        // console.log($data, index, '$data,index....1')

        return $data[index - 1].departDate;
      } else {
        // console.log($data, index, '$data,index....2')

        return `${new Date()}`;
      }
    },

    // fromDateChanged(newValue) {
    //   this.oneway.dedate = newValue;

    //   if (this.oneway.dedate) {
    //     this.clicked1 = true;
    //     console.log(this.oneway.dedate, "ddddddddd...1....eeeeeeeeeee");
    //     this.fromDateError = false;
    //     // this.$refs.dpRef1.openMenu();
    //   } else {
    //     this.clicked1 = false;
    //     this.fromDateError = true;
    //     this.$refs.dpRef1.openMenu();
    //     // console.log(this.oneway.dedate, "ddddddddd...2....eeeeeeeeeee");
    //   }
    //   if (!this.oneway.redate && this.rount) {
    //     this.$refs.dpRef2.openMenu();
    //   }
    // },
    fromDateChanged(newValue) {
      this.oneway.dedate = newValue;

      this.oneway.dedate = new Date(this.oneway.dedate)
      this.oneway.redate = new Date(this.oneway.redate)

      if (this.oneway.dedate) {
        if (this.oneway.redate && this.oneway.redate < this.oneway.dedate) {
          this.oneway.redate = null;
        }

        this.clicked1 = true;
        this.fromDateError = false;

        this.$refs.dpRef2.openMenu();
        // this.focusedElement = "calendar2";

      } else {
        this.clicked1 = false;
        this.fromDateError = true;

        this.$refs.dpRef1.openMenu();
      }
      if (this.rount && !this.oneway.redate) {
        this.$refs.dpRef2.openMenu();
      }
    },

    ClickOutDepat() {
      this.$refs.dpRef1.closeMenu();

      if (this.oneway.dedate) {
        this.clicked1 = true;
      } else {
        this.clicked1 = false;
      }
    },

    toDateChanged(newValue) {
      this.oneway.redate = newValue;
      // console.log(this.oneway.redate, "ttttttttttt");

      if (this.oneway.redate) {
        this.clicked2 = true;
        this.toDateError = false;
      } else {
        this.clicked2 = false;
        this.toDateError = true;
      }
    },

    triggerFunction() {
      alert("Hiii...");
      // this.triggerRadio = !this.triggerRadio

      // if (this.triggerRadio) {
      //   console.log(this.triggerRadio)
      //   this.$refs.radioSelect2.focus();
      // }
    },

    ClickOutReturn() {
      this.$refs.dpRef2.closeMenu();
      // console.log(this.oneway.redate, "kkkkkkkkk");

      if (this.oneway.redate) {
        this.clicked2 = true;
      } else {
        this.clicked2 = false;
      }
    },
    // RoundTrip datePicker End

    // Oneway datePicker
    onewayDateChanged(newValue) {
      this.oneway.dedate = newValue;

      if (this.oneway.dedate) {
        this.clicked1 = true;
      } else {
        this.clicked1 = false;
      }
    },

    onewayClickOutside() {
      this.$refs.dpRef3.closeMenu();
      if (this.oneway.dedate) {
        this.clicked1 = true;
      } else {
        this.clicked1 = false;
      }
    },

    multiDateChanged(newValue, index) {
      // console.log(index, newValue, "didididid...");

      this.multi_city.itinery.map((v, i) => {
        if (index == i) {
          if (v.departDate) {
            v.multiDateError = false;
            const inputElement = this.$refs.multiToAutocomplete[index + 1];
            if (inputElement) {
              inputElement.focus();
            }
          } else {
            const inputElement = this.$refs.dpRef4[index];
            if (inputElement) {
              inputElement.openMenu();
            }
          }
        }
      });

      this.multi_city.itinery.forEach((v, i) => {
        if (index < i) {
          if (newValue > new Date(v.departDate)) {
            v.departDate = null;
          }
        }
      });
    },

    multiClickOutside() {
      this.multi_city.itinery.clicked3 = false;
      this.datePickerIsOpen = false;
    },
    // Multicity datepicker End

    async fetchCity(event) {
      if (event.length >= 3) {
        try {
          const response = await axios.get(
            `${this.autocompleteApi}?airportString=${event}`,
            {
              headers: {
                "Content-Type": this.airlineIcaoType,
              },
            }
          );
          return response.data;
        } catch (error) {
          console.log(error, "erroroor.....");
        }
      } else {
        return [];
      }
    },

    async roundData() {
      // this.oneway.class = this.$route.query.class;

      // if (this.$route.query.from.name) {
      //   this.oneway.from = this.$route.query.from.name;
      //   this.tripData.from = this.$route.query.from.name;
      //   this.selectedFrom = true;
      // } else {
      //   this.oneway.from = this.$route.query.from;
      //   this.tripData.from = this.$route.query.from;
      //   this.selectedFrom = true;
      // }
      // if (this.$route.query.to.name) {
      //   this.oneway.to = this.$route.query.to.name;
      //   this.tripData.to = this.$route.query.to.name;
      //   this.selectedTo = true;
      // } else {
      //   this.oneway.to = this.$route.query.to;
      //   this.tripData.to = this.$route.query.to;
      //   this.selectedTo = true;
      // }
      // this.oneway.dedate = moment(this.$route.query.dedate).format(
      //   "DD MMM-YYYY"
      // );
      let itinerary = this.$route.query.itinerary;
      if (itinerary) {
        let getpaxtype = this.$route.query.paxType;
        let splitItin = itinerary.split("_");

        let getdepDetails = splitItin[0].split("-");
        let [from, to, dedate] = getdepDetails;
        console.log(getdepDetails, "dedateeee");
        let getfromlist = [];
        let getTolist = [];

        if (from) {
          getfromlist = await this.fetchCity(from);
          if (getfromlist.length > 0) {
            getfromlist.forEach((data, i) => {
              if (i == 0) {
                this.oneway.from = `${data.city} (${from})`;
                this.tripData.from = this.oneway.from;
                this.selectedFrom = true;
                console.log(this.oneway.from, "asdasdadadsas", i);
              }
            });
          }
        }

        if (to) {
          getTolist = await this.fetchCity(to);
          if (getTolist.length > 0) {
            getTolist.forEach((data, i) => {
              if (i == 0) {
                this.oneway.to = `${data.city} (${to})`;
                this.tripData.to = this.oneway.to;
                this.selectedTo = true;
                console.log(data.city, "asdasdadadsas", i);
              }
            });
          }
        }

        this.oneway.dedate = new Date(dedate);

        if (splitItin.length == 2) {
          let getredate = splitItin[1].split("-")[2];
          this.oneway.redate = new Date(getredate);
        }

        let getPaxDetails = getpaxtype.split("_");
        let [Adt, Chd, Inf] = getPaxDetails;

        let [getAdult, getChild, getInf] = [
          Adt.split("-")[1],
          Chd.split("-")[1],
          Inf.split("-")[1],
        ];

        this.oneway.adult = getAdult;
        this.oneway.child = getChild;
        this.oneway.infrant = getInf;

        console.log(
          this.oneway.adult,
          this.oneway.child,
          this.oneway.infrant,
          "adultChildDatassss"
        );
        this.oneway.city = this.$route.query.tripType;

        this.oneway.class = this.$route.query.cabin;
        let getrouteCabin = this.$route.query.cabin;
        if (getrouteCabin == "Economy") {
          this.oneway.class = "Economy";
        }
        if (getrouteCabin == "Business") {
          this.oneway.class = "Business";
        }
        if (getrouteCabin == "FirstClass") {
          this.oneway.class = "First Class";
        }
        if (getrouteCabin == "PremiumEconomy") {
          this.oneway.class = "Premium Economy";
        }
        this.classType = this.oneway.class;

        // this.oneway.dedate = new Date(this.$route.query.dedate)
        // this.tripData.dedate = new Date(this.$route.query.dedate)
        // this.oneway.redate = new Date(this.$route.query.redate)
        // this.tripData.redate = new Date(this.$route.query.redate)

        // this.oneway.class = this.$route.query.class;
        // this.tripData.class = this.$route.query.class;
        // this.classType = this.$route.query.class;

        if (getAdult) {
          for (let i = 0; i < getAdult; i++) {
            this.Adult.push({
              PassengerID: "T",
              PTC: "ADT",
            });
          }
        }

        if (getChild) {
          for (let i = 0; i < getChild; i++) {
            this.Child.push({
              PassengerID: "T",
              PTC: "CHD",
            });
          }
        }
        if (getInf) {
          for (let i = 0; i < getInf; i++) {
            this.Infrant.push({
              PassengerID: "T",
              PTC: "INF",
            });
          }
        }

        let totalpass = [];
        totalpass = [...this.Adult, ...this.Child, ...this.Infrant];

        totalpass.forEach((v, index) => {
          let obj = {
            PassengerID: `${v.PassengerID}${index + 1}`,
            PTC: v.PTC,
          };
          this.totalpassenger.push(obj);
        });
        this.Rountdata.request.AirShoppingRQ.DataLists.PassengerList.Passenger =
          this.totalpassenger;
        this.onewaydata.request.AirShoppingRQ.DataLists.PassengerList.Passenger =
          this.totalpassenger;

        this.aduls = parseFloat(getAdult);
        this.oneway.aduls = parseFloat(getAdult);

        this.aduls2 = parseFloat(getInf);
        this.oneway.aduls2 = parseFloat(getInf);

        this.aduls1 = parseFloat(getChild);
        this.oneway.aduls1 = parseFloat(getChild);
        this.totaladult = this.aduls + this.aduls1 + this.aduls2;
        this.select = this.$route.query.cabin;
        this.oneway.select = this.$route.query.cabin;
        if (
          this.oneway.from &&
          this.oneway.to &&
          this.oneway.dedate &&
          !this.oneway.redate
        ) {
          this.sendedone1();
        } else {
          this.sended1();
        }
      }
    },

    // multiDataConvert() {
    //   console.log(this.multi_city, "this.$route.querythis.$route.query.....3");
    //   this.red = this.multi_city.city;
    //   this.red1 = this.multi_city.city;
    //   if (this.multi_city.adult) {
    //     for (let i = 0; i < this.multi_city.adult; i++) {
    //       this.Adult.push({
    //         PassengerID: "T",
    //         PTC: "ADT",
    //       });
    //     }
    //   }

    //   if (this.multi_city.child) {
    //     for (let i = 0; i < this.multi_city.child; i++) {
    //       this.Child.push({
    //         PassengerID: "T",
    //         PTC: "CHD",
    //       });
    //     }
    //   }
    //   if (this.multi_city.infrant) {
    //     for (let i = 0; i < this.multi_city.infrant; i++) {
    //       this.Infrant.push({
    //         PassengerID: "T",
    //         PTC: "INF",
    //       });
    //     }
    //   }

    //   let totalpass = [];
    //   totalpass = [...this.Adult, ...this.Child, ...this.Infrant];
    //   totalpass.forEach((v, index) => {
    //     let obj = {
    //       PassengerID: `${v.PassengerID}${index + 1}`,
    //       PTC: v.PTC,
    //     };
    //     this.totalpassenger.push(obj);
    //   });
    //   this.multi_city.itinery.forEach((s) => {
    //     let $result = {
    //       Departure: {
    //         AirportCode: "",
    //         Date: "",
    //       },
    //       Arrival: {
    //         AirportCode: "",
    //       },
    //     };
    //     let tripData = {
    //       from: "",
    //       to: "",
    //     };
    //     if (s.from) {
    //       let res = "";
    //       res = s.from.split("(");
    //       console.log(res);
    //       tripData.from = res[0];
    //       $result.Departure.AirportCode = res[res.length - 1].replace(")", "");
    //       s.selectedFrom = true;
    //     } else {
    //       let res = "";
    //       res = s.from.name.split("(");
    //       tripData.from = res[0];
    //       $result.Departure.AirportCode = res[res.length - 1].replace(")", "");
    //       s.selectedFrom = true;
    //     }

    //     if (s.to) {
    //       let res = "";
    //       res = s.to.split("(");
    //       tripData.to = res[0];
    //       $result.Arrival.AirportCode = res[res.length - 1].replace(")", "");
    //       s.selectedTo = true;
    //     } else {
    //       let res = "";
    //       res = s.to.name.split("(");
    //       tripData.to = res[0];
    //       $result.Arrival.AirportCode = res[res.length - 1].replace(")", "");
    //       s.selectedTo = true;
    //     }
    //     if (s.departDate) {
    //       $result.Departure.Date = moment(s.departDate).format("YYYY-MM-DD");
    //     }
    //     // console.log($result, '$result$result')
    //     this.MulticityReq.request.AirShoppingRQ.CoreQuery.OriginDestinations.OriginDestination.push(
    //       $result
    //     );
    //     this.codeData.push(tripData);
    //   });
    //   //   this.aduls = parseFloat(this.$route.query.adult);
    //   // this.tripData.aduls = parseFloat(this.$route.query.adult);

    //   // this.aduls2 = parseFloat(this.$route.query.infrant);
    //   // this.tripData.aduls2 = parseFloat(this.$route.query.infrant);

    //   // this.aduls1 = parseFloat(this.$route.query.child);
    //   // this.tripData.aduls1 = parseFloat(this.$route.query.child);
    //   // this.totaladult = this.aduls + this.aduls1 + this.aduls2;

    //   console.log(this.codeData, "this.$route.querythis.$route.query.....2");

    //   console.log(this.totalpassenger, "aadjj");
    //   this.MulticityReq.request.AirShoppingRQ.DataLists.PassengerList.Passenger = this.totalpassenger;
    // },

    async multiDataConvert() {
      if (this.$route.query.itinerary) {
        let getItinerary = this.$route.query.itinerary;
        let getpaxtype = this.$route.query.paxType;

        let splitItin = getItinerary.split("_");
        let segments = splitItin.map((segment) => segment.split("-"));

        this.multi_city.itinery = [];
        for (let i = 0; i < segments.length; i++) {
          let [from, to, departDate] = segments[i];

          let $result = {
            Departure: {
              AirportCode: from,
              Date: moment(new Date(departDate)).format("YYYY-MM-DD"),
            },
            Arrival: {
              AirportCode: to,
            },
          };
          this.MulticityReq.request.AirShoppingRQ.CoreQuery.OriginDestinations.OriginDestination.push(
            $result
          );

          if (from && to) {
            const [fromCityList, toCityList] = await Promise.all([
              this.fetchCity(from),
              this.fetchCity(to),
            ]);

            console.log(fromCityList, "sdfdfdasdada", toCityList);

            if (fromCityList.length > 0 && toCityList.length > 0) {
              this.multi_city.itinery.push({
                from: `${fromCityList[0].city} (${from})`,
                to: `${toCityList[0].city} (${to})`,
                departDate: moment(new Date(departDate)).format("YYYY-MM-DD"),
              });

              this.codeData.push({
                from: `${fromCityList[0].city}`,
                to: `${toCityList[0].city}`,
              });
            }
          }

          if (from) {
            this.multi_city.itinery[i].selectedFrom = true;
          }

          if (to) {
            this.multi_city.itinery[i].selectedTo = true;
          }

          console.log(this.multi_city.itinery[i], `Segment ${i + 1}`);
        }

        let getPaxDetails = getpaxtype.split("_");
        let [Adt, Chd, Inf] = getPaxDetails;
        let [getAdult, getChild, getInf] = [
          Adt.split("-")[1],
          Chd.split("-")[1],
          Inf.split("-")[1],
        ];

        this.multi_city.adult = getAdult;
        this.multi_city.child = getChild;
        this.multi_city.infrant = getInf;

        // console.log(this.multi_city.adult,this.multi_city.child,this.multi_city.infrant,'adultChildDatasss')
        // this.classType = this.$route.query.cabin;

        let getrouteCabin = this.$route.query.cabin;
        if (getrouteCabin == "Economy") {
          this.classType = "Economy";
        }
        if (getrouteCabin == "Business") {
          this.classType = "Business";
        }
        if (getrouteCabin == "FirstClass") {
          this.classType = "First Class";
        }
        if (getrouteCabin == "PremiumEconomy") {
          this.classType = "Premium Economy";
        }
        if (getrouteCabin == "PremiumBusiness") {
          this.classType = "Premium Business";
        }
        if (getrouteCabin == "PremiumFirstClass") {
          this.classType = "Premium First";
        }

        console.log(this.classType, "asdasdasdasdaswqwqwqwdaszxsdfsfsd");

        this.multi_city.class = this.$route.query.tripType;

        if (getAdult) {
          for (let i = 0; i < getAdult; i++) {
            this.Adult.push({
              PassengerID: "T",
              PTC: "ADT",
            });
          }
        }

        if (getChild) {
          for (let i = 0; i < getChild; i++) {
            this.Child.push({
              PassengerID: "T",
              PTC: "CHD",
            });
          }
        }

        if (getInf) {
          for (let i = 0; i < getInf; i++) {
            this.Infrant.push({
              PassengerID: "T",
              PTC: "INF",
            });
          }
        }

        let totalpass = [...this.Adult, ...this.Child, ...this.Infrant];
        totalpass.forEach((v, index) => {
          let obj = {
            PassengerID: `${v.PassengerID}${index + 1}`,
            PTC: v.PTC,
          };
          this.totalpassenger.push(obj);
        });

        this.MulticityReq.request.AirShoppingRQ.DataLists.PassengerList.Passenger =
          this.totalpassenger;

        this.aduls = parseFloat(getAdult);
        this.tripData.adultValue = parseFloat(getAdult);

        this.aduls1 = parseFloat(getChild);
        this.tripData.childValue = parseFloat(getChild);

        this.aduls2 = parseFloat(getInf);
        this.tripData.infantValue = parseFloat(getInf);

        this.totaladult = this.adultValue + this.childValue + this.infantValue;
        this.multi_city.class = this.classType;
        this.tripData.select = this.$route.query.cabin;

        // let langLocale = `${this.pointofSale}-${this.$route.query.language}`;
        // this.$i18n.locale = langLocale;

        // this.changedLang = `${this.pointofSale}-${this.$route.query.language}`
        // this.$emit('localeChanged', this.changedLang);

        const tabId = sessionStorage.getItem("tabId") || Date.now().toString();
        sessionStorage.setItem("tabId", tabId);

        // if(this.multi_city.itinery.length > 0){
        //   await this.multicityresult();
        // }

        if (this.multi_city.itinery.length > 0) {
          // let result1 = [];
          // result1 = JSON.stringify(this.multi_city);
          // localStorage.setItem("searchedData", result1);

          const result1 = JSON.stringify(this.multi_city);
          localStorage.setItem(`searchedData-multicity-${tabId}`, result1);

          this.searchCase = this.multi_city;
          console.log(this.searchCase, "ssdasdseasaaSrchCase...3");

          if (this.searchCase) {
            this.searchloader = true;
          }
          console.log(this.multi_city, "MulticityRequestData...1");
          console.log(this.MulticityReq, "MulticityRequestData....2");
          await this.multicityresult();
        }
      }
    },

    multicityresult() {
      // this.multiDataConvert();

      this.multi_city.class = this.classType;
      let multiCabin_type = this.multi_city.class;

      console.log(
        multiCabin_type,
        "multiCabin_typemultiCabin_typemultiCabin_type"
      );

      if (multiCabin_type == "Economy") {
        multiCabin_type = "Y";
        this.MulticityReq.request.AirShoppingRQ.Preference.CabinType =
          multiCabin_type;
      } else if (multiCabin_type == "Business") {
        multiCabin_type = "C";
        this.MulticityReq.request.AirShoppingRQ.Preference.CabinType =
          multiCabin_type;
      } else if (multiCabin_type == "First Class") {
        multiCabin_type = "F";
        this.MulticityReq.request.AirShoppingRQ.Preference.CabinType =
          multiCabin_type;
      } else if (multiCabin_type == "Premium Economy") {
        multiCabin_type = "S";
        this.MulticityReq.request.AirShoppingRQ.Preference.CabinType =
          multiCabin_type;
      } else if (multiCabin_type == "Premium Business") {
        multiCabin_type = "J";
        this.MulticityReq.request.AirShoppingRQ.Preference.CabinType =
          multiCabin_type;
      } else if (multiCabin_type == "Premium First Class") {
        multiCabin_type = "P";
        this.MulticityReq.request.AirShoppingRQ.Preference.CabinType =
          multiCabin_type;
      }

      this.MulticityReq.request.AirShoppingRQ.Preference.TripType = "MULTICITY";
      this.MulticityReq.country = this.pointofSale;
      this.MulticityReq.request.AirShoppingRQ.Preference.Currency =
        this.preferCurrency;
      this.MulticityReq.request.AirShoppingRQ.MetaData.Currency =
        this.metaCurrency;
      this.MulticityReq.portal_domain = this.portal_domain;

      console.log(
        this.MulticityReq,
        "MulticityReqMulticityReqMulticityReqMulticityReq"
      );

      axios
        .post(this.makeSearchUrl, this.MulticityReq, {
          headers: {
            "Content-Type": "application/json",
          },
        })

        .then((response) => {
          console.log(response, "multi_citymulti_citymulti_city");
          if (response.data) {
            this.multicityTotalData.push(response.data);
            this.multicityconvert();
          }
        });
    },

    multicityconvert() {
      let baggageList = [];
      for (let i = 0; i < this.multicityTotalData.length; i++) {
        console.log(this.multicityTotalData[i], "power999999999");
        let shoppingId = this.multicityTotalData[i].ShoppingResponseId;
        let Journey = [];
        let flightsegment = [];
        Journey = this.multicityTotalData[i].DataLists.FlightList.Flight;
        baggageList =
          this.multicityTotalData[i].DataLists.BaggageAllowanceList
            .BaggageAllowance;
        console.log(Journey, "Journey6666666666666666");
        flightsegment =
          this.multicityTotalData[i].DataLists.FlightSegmentList.FlightSegment;
        // console.log(flightsegment, "flightsegment");
        let $data = [];
        $data = this.multicityTotalData[i].OffersGroup.AirlineOffers.Offer;

        let source1 = [];
        let source2 = [];
        let source3 = [];
        let source4 = [];
        let source5 = [];
        let Baggage1 = [];
        let Baggage2 = [];
        let Baggage3 = [];
        let Baggage4 = [];
        let Baggage5 = [];

        $data.forEach((v) => {
          v.OfferItem[0][0].FareComponent.forEach((s, index) => {
            if (index == 0) {
              Baggage1.push(
                v.OfferItem[0][0].BaggageAllowance[0].BaggageAllowanceRef
              );
              this.offerData1.push(s.SegmentRefs.split(" "));

              for (let i = 0; i <= Journey.length - 1; i++) {
                if (s.SegmentRefs == Journey[i].SegmentReferences) {
                  source1.push(Journey[i].Journey);
                }
              }
            }
            if (index == 1) {
              Baggage2.push(
                v.OfferItem[0][0].BaggageAllowance[0].BaggageAllowanceRef
              );
              this.offerData2.push(s.SegmentRefs.split(" "));
              for (let i = 0; i <= Journey.length - 1; i++) {
                if (s.SegmentRefs == Journey[i].SegmentReferences) {
                  source2.push(Journey[i].Journey);
                }
              }
            }
            if (index == 2) {
              Baggage3.push(
                v.OfferItem[0][0].BaggageAllowance[0].BaggageAllowanceRef
              );
              this.offerData3.push(s.SegmentRefs.split(" "));
              for (let i = 0; i <= Journey.length - 1; i++) {
                if (s.SegmentRefs == Journey[i].SegmentReferences) {
                  source3.push(Journey[i].Journey);
                }
              }
            }
            if (index == 3) {
              Baggage4.push(
                v.OfferItem[0][0].BaggageAllowance[0].BaggageAllowanceRef
              );
              this.offerData4.push(s.SegmentRefs.split(" "));
              for (let i = 0; i <= Journey.length - 1; i++) {
                if (s.SegmentRefs == Journey[i].SegmentReferences) {
                  source4.push(Journey[i].Journey);
                }
              }
            }
            if (index == 4) {
              Baggage5.push(
                v.OfferItem[0][0].BaggageAllowance[0].BaggageAllowanceRef
              );
              this.offerData5.push(s.SegmentRefs.split(" "));
              for (let i = 0; i <= Journey.length - 1; i++) {
                if (s.SegmentRefs == Journey[i].SegmentReferences) {
                  source5.push(Journey[i].Journey);
                }
              }
            }
          });
        });
        console.log(flightsegment, "this.flightsegment");
        let BaggageData1 = [];
        if (this.offerData1.length > 0) {
          Baggage1.forEach((v) => {
            baggageList.forEach((c) => {
              if (v == c.BaggageAllowanceID) {
                BaggageData1.push(c);
              }
            });
          });
          for (let i = 0; i < this.offerData1.length; i++) {
            let seg = [];
            this.offerData1[i].forEach((f, index) => {
              let items = [];
              items = flightsegment.filter((v) => v.SegmentKey == f);
              if (items.length > 0) {
                seg.push(items[0]);
              }
              if (index == this.offerData1[i].length - 1) {
                this.multicityData.push({ Flight1: seg });
              }
            });
          }
        }
        let BaggageData2 = [];
        if (this.offerData2.length > 0) {
          Baggage2.forEach((v) => {
            baggageList.forEach((c) => {
              if (v == c.BaggageAllowanceID) {
                BaggageData2.push(c);
              }
            });
          });
          for (let i = 0; i < this.offerData2.length; i++) {
            let seg = [];
            this.offerData2[i].forEach((s, index) => {
              let item = [];
              item = flightsegment.filter((v) => v.SegmentKey == s);
              if (item.length > 0) {
                seg.push(item[0]);
              }
              if (index == this.offerData2[i].length - 1) {
                this.Flight2.push(seg);
              }
            });
          }
        }
        let BaggageData3 = [];
        if (this.offerData3.length > 0) {
          Baggage3.forEach((v) => {
            baggageList.forEach((c) => {
              if (v == c.BaggageAllowanceID) {
                BaggageData3.push(c);
              }
            });
          });
          for (let i = 0; i < this.offerData3.length; i++) {
            let seg = [];
            this.offerData3[i].forEach((s, index) => {
              let item = [];
              item = flightsegment.filter((v) => v.SegmentKey == s);
              if (item.length > 0) {
                seg.push(item[0]);
              }
              if (index == this.offerData3[i].length - 1) {
                this.Flight3.push(seg);
              }
            });
          }
        }
        let BaggageData4 = [];
        if (this.offerData4.length > 0) {
          Baggage4.forEach((v) => {
            baggageList.forEach((c) => {
              if (v == c.BaggageAllowanceID) {
                BaggageData4.push(c);
              }
            });
          });

          for (let i = 0; i < this.offerData4.length; i++) {
            let seg = [];
            this.offerData4[i].forEach((s, index) => {
              let item = [];
              item = flightsegment.filter((v) => v.SegmentKey == s);
              if (item.length > 0) {
                seg.push(item[0]);
              }
              if (index == this.offerData4[i].length - 1) {
                this.Flight4.push(seg);
              }
            });
          }
        }
        let BaggageData5 = [];
        if (this.offerData5.length > 0) {
          Baggage5.forEach((v) => {
            baggageList.forEach((c) => {
              if (v == c.BaggageAllowanceID) {
                BaggageData5.push(c);
              }
            });
          });
          for (let i = 0; i < this.offerData5.length; i++) {
            let seg = [];
            this.offerData5[i].forEach((s, index) => {
              let item = [];
              item = flightsegment.filter((v) => v.SegmentKey == s);
              if (item.length > 0) {
                seg.push(item[0]);
              }
              if (index == this.offerData5[i].length - 1) {
                this.Flight5.push(seg);
              }
            });
          }
        }
        for (let i = 0; i < this.airlogodata.length - 1; i++) {
          for (let j = 0; j < flightsegment.length - 1; j++) {
            if (
              flightsegment[j].MarketingCarrier.AirlineID ==
              this.airlogodata[i].id
            ) {
              flightsegment[j].MarketingCarrier.logo = this.airlogodata[i].logo;
            }
          }
        }

        let currency = [];
        let Baseprice = [];
        let Tax = [];
        let perperson = [];
        let ownername = [];
        let ownerlogo = [];
        let offerId = [];
        let TotalPrice = [];
        let refund = [];

        $data.forEach((v) => {
          // console.log(v, "offeriddatataa");
          offerId.push(v.OfferID);
        });

        $data.forEach((s) => {
          if (s.ReqCurrency == "USD") {
            currency.push("US$");
          } else if (s.ReqCurrency == "CAD") {
            currency.push("CA$");
          } else {
            currency.push("US$");
          }

          ownername.push(s.OwnerName);
          for (let i = 0; i < this.airline_logoData.length - 1; i++) {
            if (s.Owner == this.airline_logoData[i].id) {
              ownerlogo.push(this.airline_logoData[i].logo);
            }
          }

          Baseprice.push(
            parseFloat(s.BasePrice.BookingCurrencyPrice).toFixed(2)
          );
          Tax.push(parseFloat(s.TaxPrice.BookingCurrencyPrice).toFixed(2));
          perperson.push(
            parseFloat(s.PerPerson.BookingCurrencyPrice).toFixed(2)
          );
          TotalPrice.push(
            parseFloat(s.TotalPrice.BookingCurrencyPrice).toFixed(2)
          );

          let adultPriceDetails = [];
          let childPriceDetails = [];
          let infPriceDetails = [];

          s.OfferItem[0].forEach((e) => {
            if (e.PassengerType === "ADT") {
              adultPriceDetails = this.getPriceDetails(
                e,
                "Adult",
                "adultcount"
              );
            }

            if (e.PassengerType === "CNN") {
              childPriceDetails = this.getPriceDetails(
                e,
                "Child",
                "childcount"
              );
            }

            if (e.PassengerType === "INF") {
              infPriceDetails = this.getPriceDetails(e, "Inf(lap)", "infcount");
            }
          });

          this.combinedPricedetail.push([
            adultPriceDetails,
            childPriceDetails,
            infPriceDetails,
          ]);

          s.OfferItem.forEach((v) => {
            console.log(
              v[0].FareDetail[0].Price.NonRefundable,
              "Price.RefundablePrice.Refundable"
            );

            if (v[0].FareDetail[0].Price.NonRefundable !== "true") {
              refund.push("Refundable");
            } else {
              refund.push("Non Refundable");
            }
          });
        });

        for (let i = 0; i < perperson.length; i++) {
          let nume1 = perperson[i];
          // console.log(nume1, "llldlldldldldld")
          this.find1.push(
            (Math.round(nume1 * 100) / 100).toFixed(2).toString().split(".")[0]
          );
          this.find2.push(
            (Math.round(nume1 * 100) / 100).toFixed(2).toString().split(".")[1]
          );
        }

        for (let i = 0; i < this.multicityData.length; i++) {
          this.multicityData[i].selectId = offerId[i];
          this.multicityData[i].currency = currency[i];
          this.multicityData[i].priceDetails = this.combinedPricedetail[i];
          this.multicityData[i].Baseprice = Baseprice[i];
          this.multicityData[i].TotalPrice = TotalPrice[i];
          this.multicityData[i].BaggageData1 = BaggageData1[i];
          this.multicityData[i].BaggageData2 = BaggageData2[i];
          this.multicityData[i].Taxprice = Tax[i];
          this.multicityData[i].perperson = perperson[i];
          this.multicityData[i].Flight2 = this.Flight2[i];
          this.multicityData[i].find1 = this.find1[i];
          this.multicityData[i].find2 = this.find2[i];
          this.multicityData[i].Flightdepature = false;
          this.multicityData[i].hoveredName1 = false;
          this.multicityData[i].ShoppingResponseId = shoppingId;
          this.multicityData[i].Adult = this.multi_city.adult;
          this.multicityData[i].Child = this.multi_city.child;
          this.multicityData[i].Inf = this.multi_city.infrant;
          // this.multicityData[i].Flight2 = this.Flight2[i];
          this.multicityData[i].ownerlogo = ownerlogo[i];
          this.multicityData[i].muticityData = this.codeData;
          this.multicityData[i].ownername = ownername[i];
          this.multicityData[i].Flight1JourneyTime = source1[i];
          this.multicityData[i].Flight2JourneyTime = source2[i];
          this.multicityData[i].Refund = refund[i];
          this.multicityData[i].cabinClass = this.multi_city.class;
          if (this.Flight3.length > 0) {
            this.multicityData[i].Flight3 = this.Flight3[i];
            this.multicityData[i].Flight3JourneyTime = source3[i];
            this.multicityData[i].BaggageData3 = BaggageData3[i];
          }

          if (this.Flight4.length > 0) {
            this.multicityData[i].BaggageData4 = BaggageData4[i];
            this.multicityData[i].Flight4 = this.Flight4[i];
            this.multicityData[i].Flight4JourneyTime = source4[i];
          }
          if (this.Flight5.length > 0) {
            this.multicityData[i].BaggageData5 = BaggageData5[i];
            this.multicityData[i].Flight5 = this.Flight5[i];
            this.multicityData[i].Flight5JourneyTime = source5[i];
          }
        }
        this.getmultitime1();

        console.log(
          this.multicityData,
          "multicityDatamulticityDatamulticityData55555555555...."
        );

        this.multiFilterData = this.multicityData;
        console.log(this.multiFilterData, "resultttt");
        this.priceRange();
        this.filterOutBoundMulti();
        this.getAiportFilterMulti();
        this.airlineFilter();
        this.applyFilters();

        this.multicityData = totalPricefilter.sortBy(this.multiFilterData, [
          "TotalAmount",
        ]);

        if (this.multicityData) {
          this.searchresult = true;
          this.searchloader = false;

          document.getElementById("navbar").style.display = "block";
          document.getElementById("food").style.display = "block";
        }
        // console.log(this.multicityData, "this.multicityDatathis.multicityData");
      }
      this.passengerData();
    },

    async airlineLogo_func() {
      let airlogoUrl = "";
      let airlogoType = "";

      airlogoUrl = this.airLogoApi.url;
      airlogoType = this.airLogoApi.type;

      await axios
        .get(airlogoUrl, {
          headers: {
            "Content-Type": airlogoType,
          },
        })
        .then((response) => {
          if (response.data) {
            this.airline_logoData = response.data;

            // console.log( this.airlogodata,'this.airlogodatathis.airlogodata')
          }
        })
        .catch((error) => {
          console.log(error, "ressloerrrororooool");
        });

      this.airlogodata = this.airline_logoData;
      //  console.log(this.airlogodata,'this.airlogodata')
    },

    roundedtrip() {
      for (let i = 0; i < this.localData.length; i++) {
        console.log(
          this.localData,
          "this.localData[i]this.localData[i]this.localData[i]"
        );

        if (this.localData[i]) {
          this.localData[i].forEach((v) => {
            // console.log(v.DataLists, "good");
            // let seats = [];
            let baggageList = [];
            this.Journey = v.DataLists.FlightList.Flight;
            let shoppingId = v.ShoppingResponseId;
            // console.log(shoppingId, "shoppingIdshoppingId");
            let Baseprice = [];
            let Tax = [];
            let pricedetail = [];
            let $data = v.OffersGroup.AirlineOffers.Offer;
            baggageList = v.DataLists.BaggageAllowanceList.BaggageAllowance;
            let offerId = [];
            // $data.forEach((v=>{
            //   offerId.push(v.OffersGroup.AirlineOffers.Offer.offerId)
            // }))
            // offerId.push(v.OffersGroup.AirlineOffers.Offer)

            let arrsplit = [];
            let depsplit = [];
            let resultdata = [];
            let resultdata1 = [];
            let refund = [];
            let currency = [];
            let ownerlogo = [];
            let ownername = [];
            // let depAirLogo = [];
            // let returnAirLogo = [];
            let perperson = [];
            let TotalPrice = [];

            $data.forEach((v) => {
              // console.log(v, "offeriddatataa");
              offerId.push(v.OfferID);
            });

            // let offers = $data;

            // offers.forEach((offer, index) => {
            //   console.log(`Offer ${index + 1}:`, offer);

            //   if (offer.OfferItem && offer.OfferItem.length > 0) {
            //     offer.OfferItem.forEach((item, itemIndex) => {
            //       console.log(`OfferItem ${itemIndex + 1} of Offer ${index + 1}:`, item);
            //       if(item[itemIndex].PassengerType == "ADT"){

            //       }
            //     });
            //   }
            // });

            $data.forEach((s) => {
              if (s.ReqCurrency == "USD") {
                currency.push("US$");
              } else if (s.ReqCurrency == "CAD") {
                currency.push("CA$");
              } else {
                currency.push("US$");
              }

              ownername.push(s.OwnerName);
              for (let i = 0; i < this.airline_logoData.length - 1; i++) {
                if (s.Owner == this.airline_logoData[i].id) {
                  ownerlogo.push(this.airline_logoData[i].logo);
                }
              }

              // console.log(s.ReqCurrency, "currency");
              Baseprice.push(
                parseFloat(s.BasePrice.BookingCurrencyPrice).toFixed(2)
              );
              Tax.push(parseFloat(s.TaxPrice.BookingCurrencyPrice).toFixed(2));
              perperson.push(
                parseFloat(s.PerPerson.BookingCurrencyPrice).toFixed(2)
              );
              TotalPrice.push(
                parseFloat(s.TotalPrice.BookingCurrencyPrice).toFixed(2)
              );
              pricedetail.push(s.OfferItem);

              let adultPriceDetails = [];
              let childPriceDetails = [];
              let infPriceDetails = [];

              s.OfferItem[0].forEach((e) => {
                // console.log(e, "local12233");
                if (e.PassengerType === "ADT") {
                  adultPriceDetails = this.getPriceDetails(
                    e,
                    "Adult",
                    "adultcount"
                  );
                }

                if (e.PassengerType === "CNN") {
                  childPriceDetails = this.getPriceDetails(
                    e,
                    "Child",
                    "childcount"
                  );
                }

                if (e.PassengerType === "INF") {
                  infPriceDetails = this.getPriceDetails(
                    e,
                    "Inf(lap)",
                    "infcount"
                  );
                }
              });

              this.combinedPricedetail.push([
                adultPriceDetails,
                childPriceDetails,
                infPriceDetails,
              ]);

              console.log(this.combinedPricedetail, "this.combinedPricedetail");
              s.OfferItem.forEach((v) => {
                // console.log(v, "kannannaaannnanannananannanan");
                resultdata1 = v[0].FareComponent[0].SegmentRefs;
                resultdata = v[0].FareComponent[1].SegmentRefs;

                // console.log(v[0].FareDetail[0].Price.NonRefundable, "kannannaaannnanannananannanan");

                if (v[0].FareDetail[0].Price.NonRefundable !== "true") {
                  refund.push("Refundable");
                } else {
                  refund.push("Non Refundable");
                }
              });
              arrsplit.push(resultdata.split(" "));
              depsplit.push(resultdata1.split(" "));
            });
            // console.log(arrsplit, "$data");

            // console.log(refund, 'ppppppppppppppppppppppppppppppppp')
            this.Journey1 = v.DataLists.FlightSegmentList.FlightSegment;
            // console.log( this.Journey1,'flight1')
            this.Journey.forEach((a) => {
              this.routtime.push(a.Journey);
              // console.log(a,'thesplitvalueaaaaaaaaaaaaaa')
              this.flightsegmet.push(a.SegmentReferences.toString().split(" "));
              // console.log(this.flightsegmet ,'thesplitvalue')
            });
            this.Journey1.forEach((t) => {
              this.flightsegmetkey.push(t);
              // console.log(this.flightsegmetkey, "vasanthjourney");
            });

            // seats = v.DataLists.PriceClassList.PriceClass;
            // console.log(seats, "seatsseatsseats");
            // seats.forEach((c) => {
            //   c.ClassOfService.forEach((v) => {
            //     for (let i = 0; i < this.flightsegmetkey.length - 1; i++) {
            //       if (v.SegementRef == this.flightsegmetkey[i].SegmentKey) {
            //         this.flightsegmetkey[i].Seat = v.Code.SeatsLeft;
            //       }
            //     }
            //   });
            // });

            for (let i = 0; i < this.airlogodata.length - 1; i++) {
              for (let j = 0; j < this.flightsegmetkey.length - 1; j++) {
                if (
                  this.flightsegmetkey[j].MarketingCarrier.AirlineID ==
                  this.airlogodata[i].id
                ) {
                  this.flightsegmetkey[j].MarketingCarrier.logo =
                    this.airlogodata[i].logo;
                }
              }
            }

            for (let i = 0; i <= depsplit.length - 1; i++) {
              // console.log(depsplit,'depsplit')
              let seg = [];
              depsplit[i].forEach((f, index) => {
                let items = [];
                items = this.flightsegmetkey.filter((v) => v.SegmentKey == f);
                if (items.length > 0) {
                  seg.push(items[0]);
                }
                if (index == depsplit[i].length - 1) {
                  this.rountresult.push({ Depature: seg });
                }
              });
            }

            for (let i = 0; i <= arrsplit.length - 1; i++) {
              // console.log(arrsplit,'arrsplit')
              let seg = [];
              arrsplit[i].forEach((f, index) => {
                this.items = this.flightsegmetkey.filter(
                  (v) => v.SegmentKey == f
                );
                if (this.items.length > 0) {
                  seg.push(this.items[0]);
                }
                if (index == arrsplit[i].length - 1) {
                  this.returnData.push(seg);
                }
              });
            }
            let Arrjourney = [];
            let depjourney = [];
            let depBaggage = [];
            let arrBaggage = [];
            for (let j = 0; j <= $data.length - 1; j++) {
              let result = $data[j].OfferItem[0][0].FareComponent[0];
              if (
                $data[j].OfferItem[0][0].BaggageAllowance[0] &&
                $data[j].OfferItem[0][0].BaggageAllowance[0].BaggageAllowanceRef
              ) {
                depBaggage.push(
                  $data[j].OfferItem[0][0].BaggageAllowance[0]
                    .BaggageAllowanceRef
                );
              }
              if (
                $data[j].OfferItem[0][0].BaggageAllowance[1] &&
                $data[j].OfferItem[0][0].BaggageAllowance[1].BaggageAllowanceRef
              ) {
                arrBaggage.push(
                  $data[j].OfferItem[0][0].BaggageAllowance[1]
                    .BaggageAllowanceRef
                );
              }
              let result1 = $data[j].OfferItem[0][0].FareComponent[1];
              let source = [];
              let source1 = [];
              for (let i = 0; i <= this.Journey.length - 1; i++) {
                if (result.SegmentRefs == this.Journey[i].SegmentReferences) {
                  source = this.Journey[i].Journey;
                } else if (
                  result1.SegmentRefs == this.Journey[i].SegmentReferences
                ) {
                  source1 = this.Journey[i].Journey;
                }
              }
              Arrjourney.push(source);
              depjourney.push(source1);
            }
            let BaggageData = [];
            let BaggageData1 = [];
            depBaggage.forEach((v) => {
              baggageList.forEach((c) => {
                if (v == c.BaggageAllowanceID) {
                  BaggageData.push(c);
                }
              });
            });
            arrBaggage.forEach((v) => {
              baggageList.forEach((c) => {
                if (v == c.BaggageAllowanceID) {
                  BaggageData1.push(c);
                }
              });
            });
            // for (let j = 0; j <= $data.length - 1; j++) {
            //   let result = $data[j].OfferItem[0][0].FareComponent[0];
            //   let result1 = $data[j].OfferItem[0][0].FareComponent[1];
            //   let source = [];
            //   let source1 = [];
            //   for (let i = 0; i <= this.Journey.length - 1; i++) {
            //     if (result.SegmentRefs == this.Journey[i].SegmentReferences) {
            //       source = this.Journey[i].Journey;
            //     } else if (
            //       result1.SegmentRefs == this.Journey[i].SegmentReferences
            //     ) {
            //       source1 = this.Journey[i].Journey;
            //     }
            //   }
            //   Arrjourney.push(source);
            //   depjourney.push(source1);
            // }
            for (let i = 0; i < perperson.length; i++) {
              let nume1 = perperson[i];
              // console.log(nume1, "llldlldldldldld")
              this.find1.push(
                (Math.round(nume1 * 100) / 100)
                  .toFixed(2)
                  .toString()
                  .split(".")[0]
              );
              this.find2.push(
                (Math.round(nume1 * 100) / 100)
                  .toFixed(2)
                  .toString()
                  .split(".")[1]
              );
            }
            // console.log(this.find1, "this.find1this.find1");
            for (let i = 0; i <= this.rountresult.length - 1; i++) {
              this.rountresult[i].Baseprice = Baseprice[i];
              this.rountresult[i].TotalPrice = TotalPrice[i];
              this.rountresult[i].Taxprice = Tax[i];
              this.rountresult[i].priceDetails = this.combinedPricedetail[i];
              this.rountresult[i].Return = this.returnData[i];
              this.rountresult[i].depBaggage = BaggageData[i];
              this.rountresult[i].arrBaggage = BaggageData1[i];
              this.rountresult[i].Flightreturn = false;
              this.rountresult[i].Flightdepature = false;
              this.rountresult[i].hoveredName1 = false;
              this.rountresult[i].hoveredName2 = false;
              this.rountresult[i].hoveredName3 = false;
              this.rountresult[i].hoveredName4 = false;
              this.rountresult[i].Refund = refund[i];
              this.rountresult[i].currency = currency[i];
              this.rountresult[i].ownerlogo = ownerlogo[i];
              this.rountresult[i].ownername = ownername[i];
              this.rountresult[i].Returnjourney = depjourney[i];
              this.rountresult[i].Depaturejourney = Arrjourney[i];
              this.rountresult[i].perperson = perperson[i];
              this.rountresult[i].cabinClass = this.oneway.class;
              this.rountresult[i].find1 = this.find1[i];
              this.rountresult[i].find2 = this.find2[i];
              this.rountresult[i].ShoppingResponseId = shoppingId;
              this.rountresult[i].selectId = offerId[i];
              this.rountresult[i].Adult = this.aduls;
              this.rountresult[i].Child = this.aduls1;
              this.rountresult[i].Inf = this.aduls2;
              this.rountresult[i].fromCityName = this.tripData.from;
              this.rountresult[i].toCityName = this.tripData.to;
            }

            console.log(this.rountresult, "resulppppppppppppppppppppppppt");
            this.gettimedep();
            this.gettimeret();

            this.filterData = this.rountresult;
            this.rounddatafil = true;

            this.priceRange();
            this.filterOutBound();
            this.getAiportFilter();
            this.airlineFilter();

            if (this.rountresult) {
              this.searchresult = true;
              this.searchloader = false;

              document.getElementById("navbar").style.display = "block";
              document.getElementById("food").style.display = "block";
            }
            // console.log(this.rountresult, "resulppppppppppppppppppppppppt");

            // this.filterShowing();

            // console.log(this.rountresult, 'result222222222222222222222222')

            this.rountresult = totalPricefilter.sortBy(this.filterData, [
              "TotalAmount",
            ]);
          });
        }
      }
      this.passengerData();
    },
    //     getper(res) {
    //         console.log(res.perperson, 'priceperson');
    //     let date = res.perperson.toString();
    //     let convertedDate = date.replace(".", "<sub>") + "</sub>";

    //     getPriceperson($event) {
    //   let nume1 = $event;
    //   console.log(nume1,"llldlldldldldld")
    //   this.find1 = (Math.round(nume1 * 100) / 100).toFixed(2).toString().split(".")[0];
    //   this.find2 = (Math.round(nume1 * 100) / 100).toFixed(2).toString().split(".")[1];
    // },

    // gettimedep() {
    //   for (let i = 0; i < this.rountresult.length; i++) {
    //     for (let j = 0; j < this.rountresult[i].Depature.length; j++) {
    //       const currentIndex = j;
    //       const nextIndex = (j + 1) % this.rountresult[i].Depature.length;

    //       const time1 = new Date(
    //         `${this.rountresult[i].Depature[currentIndex].Arrival.Date}T${this.rountresult[i].Depature[currentIndex].Arrival.Time}`
    //       );
    //       const time2 = new Date(
    //         `${this.rountresult[i].Depature[nextIndex].Departure.Date}T${this.rountresult[i].Depature[nextIndex].Departure.Time}`
    //       );

    //       const diffInMilliseconds = Math.abs(time2 - time1);
    //       const diffInMinutes = Math.floor(diffInMilliseconds / (1000 * 60));

    //       const hours = Math.floor(diffInMinutes / 60);
    //       const minutes = diffInMinutes % 60;

    //       this.rountresult[i].Depature[currentIndex].layoverTimes = `${hours
    //         .toString()
    //         .padStart(2, "0")} h:${minutes.toString().padStart(2, "0")} min`;
    //     }
    //   }
    // },
    getPriceDetails(e, type, quantityKey) {
      // console.log(e, "univer");
      if (e) {
        let basePrice =
          e.FareDetail[e.FareDetail.length - 1].Price.BaseAmount
            .BookingCurrencyPrice;
        let taxPrice =
          e.FareDetail[e.FareDetail.length - 1].Price.TaxAmount
            .BookingCurrencyPrice;
        let count = e.PassengerQuantity;

        return {
          baseprice: parseFloat(basePrice).toFixed(2),
          taxprice: parseFloat(taxPrice).toFixed(2),
          [quantityKey]: count,
          pax_type: type,
        };
      }
    },
    gettimedep() {
      let layoverTime = [];
      for (let i = 0; i < this.rountresult.length; i++) {
        let layoverTime1 = [];
        for (let j = 0; j < this.rountresult[i].Depature.length; j++) {
          const currentIndex = j;
          const nextIndex = (j + 1) % this.rountresult[i].Depature.length;

          const time1 = new Date(
            `${this.rountresult[i].Depature[currentIndex].Arrival.Date}T${this.rountresult[i].Depature[currentIndex].Arrival.Time}`
          );
          const time2 = new Date(
            `${this.rountresult[i].Depature[nextIndex].Departure.Date}T${this.rountresult[i].Depature[nextIndex].Departure.Time}`
          );

          let diffInMilliseconds = time2 - time1;
          let diffInMinutes = diffInMilliseconds / (1000 * 60);
          let diffInHours = Math.floor(diffInMinutes / 60);
          let remainingMinutes = diffInMinutes % 60;

          layoverTime1.push(
            `${diffInHours > 0 ? diffInHours + "h " : ""}${
              remainingMinutes > 0 ? remainingMinutes + "m" : ""
            }`
          );
        }
        layoverTime.push(layoverTime1);
        // console.log(layoverTime,'layoverTimelayoverTimee')
      }

      // console.log(layoverTime, "ppppppppp");
      this.rountresult.forEach((a, i) => {
        layoverTime.forEach((c, j) => {
          if (i == j) {
            a.layoverTimes1 = c;
          }
        });
      });
    },

    gettimeret() {
      let layoverTime = [];
      for (let i = 0; i < this.rountresult.length; i++) {
        let layoverTime1 = [];
        for (let j = 0; j < this.rountresult[i].Return.length; j++) {
          const currentIndex = j;
          const nextIndex = (j + 1) % this.rountresult[i].Return.length;

          const time1 = new Date(
            `${this.rountresult[i].Return[currentIndex].Arrival.Date}T${this.rountresult[i].Return[currentIndex].Arrival.Time}`
          );
          const time2 = new Date(
            `${this.rountresult[i].Return[nextIndex].Departure.Date}T${this.rountresult[i].Return[nextIndex].Departure.Time}`
          );

          let diffInMilliseconds = time2 - time1;
          let diffInMinutes = diffInMilliseconds / (1000 * 60);
          let diffInHours = Math.floor(diffInMinutes / 60);
          let remainingMinutes = diffInMinutes % 60;

          layoverTime1.push(
            `${diffInHours > 0 ? diffInHours + "h " : ""}${
              remainingMinutes > 0 ? remainingMinutes + "m" : ""
            }`
          );
        }
        layoverTime.push(layoverTime1);
      }

      // console.log(layoverTime, "ppppppppp");
      this.rountresult.forEach((a, i) => {
        layoverTime.forEach((c, j) => {
          if (i == j) {
            a.layoverTimes2 = c;
          }
        });
      });
    },

    gettimedep1() {
      let layoverTime = [];
      for (let i = 0; i < this.onewayresulted.length; i++) {
        let layoverTime1 = [];
        for (let j = 0; j < this.onewayresulted[i].Depature.length; j++) {
          const currentIndex = j;
          const nextIndex = (j + 1) % this.onewayresulted[i].Depature.length;

          const time1 = new Date(
            `${this.onewayresulted[i].Depature[currentIndex].Arrival.Date}T${this.onewayresulted[i].Depature[currentIndex].Arrival.Time}`
          );
          const time2 = new Date(
            `${this.onewayresulted[i].Depature[nextIndex].Departure.Date}T${this.onewayresulted[i].Depature[nextIndex].Departure.Time}`
          );

          let diffInMilliseconds = time2 - time1;
          let diffInMinutes = diffInMilliseconds / (1000 * 60);
          let diffInHours = Math.floor(diffInMinutes / 60);
          let remainingMinutes = diffInMinutes % 60;

          layoverTime1.push(
            `${diffInHours > 0 ? diffInHours + "h " : ""}${
              remainingMinutes > 0 ? remainingMinutes + "m" : ""
            }`
          );
        }
        layoverTime.push(layoverTime1);
      }

      // console.log(layoverTime, "ppppppppp");
      this.onewayresulted.forEach((a, i) => {
        layoverTime.forEach((c, j) => {
          if (i == j) {
            a.layoverTimes = c;
          }
        });
      });
    },

    getmultitime1() {
      let layoverTime1 = [];
      let layoverTime2 = [];
      let layoverTime3 = [];
      let layoverTime4 = [];

      for (let i = 0; i < this.multicityData.length; i++) {
        let layover1 = [];
        for (let j = 0; j < this.multicityData[i].Flight1.length; j++) {
          const currentIndex = j;
          const nextIndex = (j + 1) % this.multicityData[i].Flight1.length;

          const time1 = new Date(
            `${this.multicityData[i].Flight1[currentIndex].Arrival.Date}T${this.multicityData[i].Flight1[currentIndex].Arrival.Time}`
          );
          const time2 = new Date(
            `${this.multicityData[i].Flight1[nextIndex].Departure.Date}T${this.multicityData[i].Flight1[nextIndex].Departure.Time}`
          );

          let diffInMilliseconds = time2 - time1;
          let diffInMinutes = diffInMilliseconds / (1000 * 60);
          let diffInHours = Math.floor(diffInMinutes / 60);
          let remainingMinutes = diffInMinutes % 60;

          layover1.push(
            `${diffInHours > 0 ? diffInHours + "h " : ""}${
              remainingMinutes > 0 ? remainingMinutes + "m" : ""
            }`
          );
        }
        layoverTime1.push(layover1);

        this.multicityData.forEach((a, i) => {
          layoverTime1.forEach((c, j) => {
            if (i == j) {
              a.layoverTimes1 = c;
            }
          });
        });

        if (this.multicityData[i].Flight2) {
          let layover2 = [];
          for (let j = 0; j < this.multicityData[i].Flight2.length; j++) {
            const currentIndex = j;
            const nextIndex = (j + 1) % this.multicityData[i].Flight2.length;

            const time1 = new Date(
              `${this.multicityData[i].Flight2[currentIndex].Arrival.Date}T${this.multicityData[i].Flight2[currentIndex].Arrival.Time}`
            );
            const time2 = new Date(
              `${this.multicityData[i].Flight2[nextIndex].Departure.Date}T${this.multicityData[i].Flight2[nextIndex].Departure.Time}`
            );

            let diffInMilliseconds = time2 - time1;
            let diffInMinutes = diffInMilliseconds / (1000 * 60);
            let diffInHours = Math.floor(diffInMinutes / 60);
            let remainingMinutes = diffInMinutes % 60;

            layover2.push(
              `${diffInHours > 0 ? diffInHours + "h " : ""}${
                remainingMinutes > 0 ? remainingMinutes + "m" : ""
              }`
            );
          }
          layoverTime2.push(layover2);

          this.multicityData.forEach((a, i) => {
            layoverTime2.forEach((c, j) => {
              if (i == j) {
                a.layoverTimes2 = c;
              }
            });
          });
        }
        if (this.multicityData[i].Flight3) {
          let layover3 = [];
          for (let j = 0; j < this.multicityData[i].Flight3.length; j++) {
            const currentIndex = j;
            const nextIndex = (j + 1) % this.multicityData[i].Flight3.length;

            const time1 = new Date(
              `${this.multicityData[i].Flight3[currentIndex].Arrival.Date}T${this.multicityData[i].Flight3[currentIndex].Arrival.Time}`
            );
            const time2 = new Date(
              `${this.multicityData[i].Flight3[nextIndex].Departure.Date}T${this.multicityData[i].Flight3[nextIndex].Departure.Time}`
            );

            let diffInMilliseconds = time2 - time1;
            let diffInMinutes = diffInMilliseconds / (1000 * 60);
            let diffInHours = Math.floor(diffInMinutes / 60);
            let remainingMinutes = diffInMinutes % 60;

            layover3.push(
              `${diffInHours > 0 ? diffInHours + "h " : ""}${
                remainingMinutes > 0 ? remainingMinutes + "m" : ""
              }`
            );
          }
          layoverTime3.push(layover3);

          this.multicityData.forEach((a, i) => {
            layoverTime3.forEach((c, j) => {
              if (i == j) {
                a.layoverTimes3 = c;
              }
            });
          });
        }

        if (this.multicityData[i].Flight4) {
          let layover4 = [];
          for (let j = 0; j < this.multicityData[i].Flight4.length; j++) {
            const currentIndex = j;
            const nextIndex = (j + 1) % this.multicityData[i].Flight4.length;

            const time1 = new Date(
              `${this.multicityData[i].Flight4[currentIndex].Arrival.Date}T${this.multicityData[i].Flight4[currentIndex].Arrival.Time}`
            );
            const time2 = new Date(
              `${this.multicityData[i].Flight4[nextIndex].Departure.Date}T${this.multicityData[i].Flight4[nextIndex].Departure.Time}`
            );

            let diffInMilliseconds = time2 - time1;
            let diffInMinutes = diffInMilliseconds / (1000 * 60);
            let diffInHours = Math.floor(diffInMinutes / 60);
            let remainingMinutes = diffInMinutes % 60;

            layover4.push(
              `${diffInHours > 0 ? diffInHours + "h " : ""}${
                remainingMinutes > 0 ? remainingMinutes + "m" : ""
              }`
            );
          }
          layoverTime4.push(layover4);

          this.multicityData.forEach((a, i) => {
            layoverTime4.forEach((c, j) => {
              if (i == j) {
                a.layoverTimes4 = c;
              }
            });
          });
        }
      }
    },

    priceRange() {
      let min = Infinity;
      let max = -Infinity;

      for (let i = 0; i < this.filterData.length; i++) {
        const totalAmount = parseFloat(this.filterData[i].perperson);
        if (totalAmount < min) {
          min = totalAmount;
        }
        if (totalAmount > max) {
          max = totalAmount;
        }
      }

      for (let i = 0; i < this.onewayfilterData.length; i++) {
        const totalAmount = parseFloat(this.onewayfilterData[i].perperson);
        if (totalAmount < min) {
          min = totalAmount;
        }
        if (totalAmount > max) {
          max = totalAmount;
        }
      }

      for (let i = 0; i < this.multiFilterData.length; i++) {
        const totalAmount = parseFloat(this.multiFilterData[i].perperson);

        console.log(totalAmount, "tatatatat,,,.....");
        if (totalAmount < min) {
          min = totalAmount;
        }
        if (totalAmount > max) {
          max = totalAmount;
        }
      }

      this.minimum = min.toFixed(2);
      this.maximum = max.toFixed(2);
      this.steps = ((max - min) / 50).toFixed(2);
      this.changevalue[0] = min.toFixed(2);
      this.changevalue[1] = max.toFixed(2);
      console.log(this.changevalue, "totalAmount");

      this.initialChangeValue = this.changevalue;

      this.currencyCode = this.preferCurrency;
    },
    filterOutBound() {
      // console.log(data,'outBoundTime')
      let outBoundTime1 = [];
      let inBoundTime1 = [];
      if (this.rountresult) {
        this.rountresult.forEach((v) => {
          if (v.Depature) {
            let result = v.Depaturejourney.Time.split(" ");
            let hours = parseInt(result[0]);
            let minutes = parseInt(result[2]);

            let roundedHours = Math.round(hours + minutes / 60);
            outBoundTime1.push(roundedHours + "Hour");
          }
          if (v.Return) {
            this.isReturn = true;
            let result1 = v.Returnjourney.Time.split(" ");
            let hours = parseInt(result1[0]);
            let minutes = parseInt(result1[2]);

            let roundedHours = Math.round(hours + minutes / 60);
            inBoundTime1.push(roundedHours + "Hour");
          }
        });
      }
      if (this.onewayresulted) {
        this.onewayresulted.forEach((v) => {
          // console.log(this.onewayresulted, "onewayyyy");
          if (v.Depature) {
            let result = v.Depaturejourney.Time.split("H")[0];
            outBoundTime1.push(result + "Hour");
          }
        });
      }

      let hours = [...new Set(outBoundTime1)];
      // console.log(hours,'hours')
      let numbers = hours.map((str) => parseInt(str));

      let minHour = Math.min(...numbers);
      let maxHour = Math.max(...numbers);
      this.defaultOutBoundTime = `${maxHour} hour`;
      this.outBound = `${maxHour} hour`;
      for (let i = minHour; i <= maxHour; i++) {
        this.outBoundTime.push(`${i} hour`);
      }
      // console.log(this.defaultOutBoundTime,'this.outBoundTimethis.outBoundTime..1')

      let hours1 = [...new Set(inBoundTime1)];
      let numbers1 = hours1.map((str) => parseInt(str));

      let minHour1 = Math.min(...numbers1);
      let maxHour1 = Math.max(...numbers1);
      this.defaultInBoundTime = `${maxHour1} hour`;
      this.inBound = `${maxHour1} hour`;
      for (let i = minHour1; i <= maxHour1; i++) {
        this.inBoundTime.push(`${i} hour`);
      }
      // console.log(this.defaultInBoundTime,'this.outBoundTimethis.outBoundTime..2')
    },
    filterOutBoundMulti() {
      let outBoundTime1 = [];
      this.multicityData.forEach((v) => {
        for (let i = 1; i <= 4; i++) {
          if (v[`Flight${i}`]) {
            let result = v[`Flight${i}JourneyTime`].Time.split("H");
            let hours = parseInt(result[0]);
            let minutes = parseInt(result[1]);
            let roundedHours = Math.round(hours + minutes / 60);
            outBoundTime1.push(roundedHours + "Hour");
          }
        }
      });
      let hours = [...new Set(outBoundTime1)];
      // console.log(hours,'hours')
      let numbers = hours.map((str) => parseInt(str));

      let minHour = Math.min(...numbers);
      let maxHour = Math.max(...numbers);
      this.defaultOutBoundMulti = `${maxHour} hour`;
      this.outBoundMulti = `${maxHour} hour`;
      for (let i = minHour; i <= maxHour; i++) {
        this.outBoundTimeMulti.push(`${i} hour`);
      }
      console.log(this.outBoundTimeMulti, "outBoundoutBound");
    },

    getAiportFilter() {
      if (this.rountresult.length > 0) {
        console.log("yessss1");
        let fromAirportsSet = new Set();
        let toAirportsSet = new Set();

        this.filterData.forEach((data) => {
          fromAirportsSet.add(data.Depature[0].Departure.AirportName);
          toAirportsSet.add(
            data.Depature[data.Depature.length - 1].Arrival.AirportName
          );
        });

        this.fromAirportName = Array.from(fromAirportsSet);
        this.toAirportName = Array.from(toAirportsSet);
      }
      console.log(this.onewayresulted, "onewayyyyyyyy");
      if (this.onewayresulted.length > 0) {
        console.log("yessss");
        let fromAirportsSet = new Set();

        this.onewayfilterData.forEach((data) => {
          fromAirportsSet.add(data.Depature[0].Departure.AirportName);
        });

        this.fromAirportName = Array.from(fromAirportsSet);
      }
    },

    getAiportFilterMulti() {
      let flight1From = new Set();
      let flight1To = new Set();
      let flight2From = new Set();
      let flight2To = new Set();
      let flight3From = new Set();
      let flight3To = new Set();
      let flight4From = new Set();
      let flight4To = new Set();

      this.multiFilterData.forEach((data) => {
        flight1From.add(data.Flight1[0].Departure.AirportName);
        flight1To.add(
          data.Flight1[data.Flight1.length - 1].Arrival.AirportName
        );
        flight2From.add(data.Flight2[0].Departure.AirportName);
        flight2To.add(
          data.Flight2[data.Flight2.length - 1].Arrival.AirportName
        );

        if (data.Flight3) {
          flight3From.add(data.Flight3[0].Departure.AirportName);
          flight3To.add(
            data.Flight3[data.Flight3.length - 1].Arrival.AirportName
          );
        }

        if (data.Flight4) {
          flight4From.add(data.Flight4[0].Departure.AirportName);
          flight4To.add(
            data.Flight4[data.Flight4.length - 1].Arrival.AirportName
          );
        }
      });

      this.multiAirport = [
        {
          from: Array.from(flight1From) || [],
          to: Array.from(flight1To) || [],
        },
        {
          from: Array.from(flight2From) || [],
          to: Array.from(flight2To) || [],
        },
        {
          from: Array.from(flight3From) || [],
          to: Array.from(flight3To) || [],
        },
        {
          from: Array.from(flight4From) || [],
          to: Array.from(flight4To) || [],
        },
      ];
      console.log(this.multiAirport, "multiAirportmultiAirport");
    },

    // Filter initailly Working ------->

    filterShowing() {
      let filterStore = [];
      // filterStore = this.multiFilterData
      filterStore = this.filterData;

      console.log(filterStore, "fsfsfsfsfs....1...1...1");

      filterStore.forEach((v) => {
        if (v.Depaturejourney.Stops !== 0 && v.Returnjourney.Stops !== 0) {
          this.showNonStop = false;
        } else {
          this.showNonStop = true;
        }
      });

      filterStore.forEach((v) => {
        if (v.Depaturejourney.Stops == 1 && v.Returnjourney.Stops == 1) {
          this.showOneStop = true;
        } else {
          this.showOneStop = false;
        }
      });

      filterStore.forEach((v) => {
        if (v.Depaturejourney.Stops > 1 && v.Returnjourney.Stops > 1) {
          this.showOneplusStop = true;
        } else {
          this.showOneplusStop = false;
        }
      });
    },

    onewaytrip() {
      let onewayresult = [];
      onewayresult = JSON.parse(localStorage.getItem("onewaytrip"));
      console.log(onewayresult, "onewayresultonewayresult");
      let baggageList = [];
      if (onewayresult) {
        for (let i = 0; i < onewayresult.length; i++) {
          if (onewayresult[i]) {
            this.Journey = onewayresult[i].DataLists.FlightList.Flight;
            baggageList =
              onewayresult[i].DataLists.BaggageAllowanceList.BaggageAllowance;
            let shoppingId = onewayresult[i].ShoppingResponseId;
            let refund = [];
            let Baseprice = [];
            let Tax = [];
            let pricedetail = [];
            let $data = onewayresult[i].OffersGroup.AirlineOffers.Offer;

            let $data_onewayId =
              onewayresult[i].OffersGroup.AirlineOffers.Offer;

            // console.log($data_onewayId,'onewaaayyyyyyy....1...')

            let offerId = [];
            let depsplit = [];
            let resultdata1 = [];
            let currency = [];
            let ownerlogo = [];
            let ownername = [];
            let perperson = [];
            let TotalPrice = [];
            $data_onewayId.forEach((v) => {
              offerId.push(v.OfferID);
            });

            $data.forEach((s) => {
              if (s.ReqCurrency == "USD") {
                currency.push("US$");
              } else if (s.ReqCurrency == "CAD") {
                currency.push("CA$");
              } else {
                currency.push("US$");
              }
              ownername.push(s.OwnerName);
              for (let i = 0; i < this.airline_logoData.length - 1; i++) {
                if (s.Owner == this.airline_logoData[i].id) {
                  ownerlogo.push(this.airline_logoData[i].logo);
                }
              }

              Baseprice.push(
                parseFloat(s.BasePrice.BookingCurrencyPrice).toFixed(2)
              );
              Tax.push(parseFloat(s.TaxPrice.BookingCurrencyPrice).toFixed(2));
              perperson.push(
                parseFloat(s.PerPerson.BookingCurrencyPrice).toFixed(2)
              );
              TotalPrice.push(
                parseFloat(s.TotalPrice.BookingCurrencyPrice).toFixed(2)
              );

              pricedetail.push(s.OfferItem);
              let adultPriceDetails = [];
              let childPriceDetails = [];
              let infPriceDetails = [];

              s.OfferItem[0].forEach((e) => {
                console.log(e, "passtype");
                if (e.PassengerType === "ADT") {
                  adultPriceDetails = this.getPriceDetails(
                    e,
                    "Adult",
                    "adultcount"
                  );
                }

                if (e.PassengerType === "CNN") {
                  childPriceDetails = this.getPriceDetails(
                    e,
                    "Child",
                    "childcount"
                  );
                }

                if (e.PassengerType === "INF") {
                  infPriceDetails = this.getPriceDetails(
                    e,
                    "Inf(lap)",
                    "infcount"
                  );
                }
              });

              this.combinedPricedetail.push([
                adultPriceDetails,
                childPriceDetails,
                infPriceDetails,
              ]);
              console.log(this.combinedPricedetail, "this.combinedPricedetail");
              s.OfferItem.forEach((v) => {
                if (v[0].FareDetail[0].Price.NonRefundable !== "true") {
                  refund.push("Refundable");
                } else {
                  refund.push("Non Refundable");
                }

                if (v[0].FareComponent[0].SegmentRefs) {
                  resultdata1 = v[0].FareComponent[0].SegmentRefs;
                }
              });

              depsplit.push(resultdata1.split(" "));
            });

            // console.log(depsplit, '$data')
            this.Journey1 =
              onewayresult[i].DataLists.FlightSegmentList.FlightSegment;
            // console.log( this.Journey1,'flight1')Seatsseats
            this.Journey.forEach((a) => {
              this.routtime.push(a.Journey);
              this.flightsegmet.push(a.SegmentReferences.split(" "));
            });
            this.Journey1.forEach((t) => {
              this.flightsegmetkey.push(t);
            });
            // let seats = [];
            // seats = onewayresult[i].DataLists.PriceClassList.PriceClass;
            // seats.forEach((c) => {
            //   c.ClassOfService.forEach((v) => {
            //     for (let i = 0; i < this.flightsegmetkey.length - 1; i++) {
            //       if (v.SegementRef == this.flightsegmetkey[i].SegmentKey) {
            //         this.flightsegmetkey[i].Seat = v.Code.SeatsLeft;
            //       }
            //     }
            //   });
            // });

            for (let i = 0; i < this.airlogodata.length - 1; i++) {
              for (let j = 0; j < this.flightsegmetkey.length - 1; j++) {
                if (
                  this.flightsegmetkey[j].MarketingCarrier.AirlineID ==
                  this.airlogodata[i].id
                ) {
                  this.flightsegmetkey[j].MarketingCarrier.logo =
                    this.airlogodata[i].logo;
                }
              }
            }

            for (let i = 0; i <= depsplit.length - 1; i++) {
              let seg = [];
              depsplit[i].forEach((f, index) => {
                let items = [];
                items = this.flightsegmetkey.filter((v) => v.SegmentKey == f);
                if (items.length > 0) {
                  seg.push(items[0]);
                }
                if (index == depsplit[i].length - 1) {
                  this.onewayresulted.push({ Depature: seg });
                }
              });
            }

            let depjourney = [];
            let Baggage = [];
            for (let j = 0; j <= $data.length - 1; j++) {
              let result = $data[j].OfferItem[0][0].FareComponent[0];
              // console.log($data[j].OfferItem[0][0].BaggageAllowance[0],'baggagebaggage')
              if (
                $data[j].OfferItem[0][0].BaggageAllowance[0] &&
                $data[j].OfferItem[0][0].BaggageAllowance[0].BaggageAllowanceRef
              ) {
                Baggage.push(
                  $data[j].OfferItem[0][0].BaggageAllowance[0]
                    .BaggageAllowanceRef
                );
              }
              let source = [];

              for (let i = 0; i <= this.Journey.length - 1; i++) {
                if (result.SegmentRefs == this.Journey[i].SegmentReferences) {
                  source = this.Journey[i].Journey;
                }
              }

              depjourney.push(source);
            }
            let BaggageData = [];

            Baggage.forEach((v) => {
              baggageList.forEach((c) => {
                if (v == c.BaggageAllowanceID) {
                  BaggageData.push(c);
                }
              });
            });

            for (let i = 0; i < perperson.length; i++) {
              let nume1 = perperson[i];
              // console.log(nume1, "llldlldldldldld")
              this.find1.push(
                (Math.round(nume1 * 100) / 100)
                  .toFixed(2)
                  .toString()
                  .split(".")[0]
              );
              this.find2.push(
                (Math.round(nume1 * 100) / 100)
                  .toFixed(2)
                  .toString()
                  .split(".")[1]
              );
            }

            for (let i = 0; i < this.onewayresulted.length; i++) {
              this.onewayresulted[i].Baseprice = Baseprice[i];
              this.onewayresulted[i].TotalPrice = TotalPrice[i];
              this.onewayresulted[i].priceDetails = this.combinedPricedetail[i];
              this.onewayresulted[i].Taxprice = Tax[i];
              this.onewayresulted[i].pricedetail = pricedetail[i];
              this.onewayresulted[i].Flightreturn = false;
              this.onewayresulted[i].Flightdepature = false;
              this.onewayresulted[i].hoveredName1 = false;
              this.onewayresulted[i].hoveredName2 = false;
              this.onewayresulted[i].hoveredName3 = false;
              this.onewayresulted[i].hoveredName4 = false;
              this.onewayresulted[i].BaggageData = BaggageData[i];
              this.onewayresulted[i].Refund = refund[i];
              this.onewayresulted[i].currency = currency[i];
              this.onewayresulted[i].ownerlogo = ownerlogo[i];
              this.onewayresulted[i].ownername = ownername[i];
              this.onewayresulted[i].Depaturejourney = depjourney[i];
              this.onewayresulted[i].perperson = perperson[i];
              this.onewayresulted[i].cabinClass = this.oneway.class;
              this.onewayresulted[i].find1 = this.find1[i];
              this.onewayresulted[i].find2 = this.find2[i];
              this.onewayresulted[i].ShoppingResponseId = shoppingId;
              this.onewayresulted[i].selectId = offerId[i];
              this.onewayresulted[i].Adult = this.aduls;
              this.onewayresulted[i].Child = this.aduls1;
              this.onewayresulted[i].Inf = this.aduls2;
              this.onewayresulted[i].fromCity = this.oneway.from;
              this.onewayresulted[i].toCity = this.oneway.to;
            }

            console.log(this.onewayresulted, "oneway.....w...w..w..w");
            //  this.onewayfilterData=this.onewayresulted
            this.gettimedep1();
            this.onewayfilterData = this.onewayresulted;
            this.onedatafil = true;
            this.priceRange();
            this.filterOutBound();
            this.getAiportFilter();
            this.airlineFilter();
            if (this.onewayfilterData) {
              this.searchresult = true;
              this.searchloader = false;

              document.getElementById("navbar").style.display = "block";
              document.getElementById("food").style.display = "block";
            }

            this.onewayresulted = totalPricefilter.sortBy(
              this.onewayfilterData,
              ["TotalAmount"]
            );

            // console.log(this.onewayresulted, "onewayresulted");
          }
        }
      }
      this.passengerData();
    },
    handleMultiSearch(newMultiData) {
      let storedRecentFrom =
        JSON.parse(localStorage.getItem("recentFrom")) || [];

      storedRecentFrom = storedRecentFrom.filter((existingItem) => {
        const existingItinery = existingItem.itinery || [];

        const isDuplicate = newMultiData.itinery.every((newItin) =>
          existingItinery.some(
            (existingItin) =>
              newItin.from === existingItin.from &&
              newItin.to === existingItin.to &&
              newItin.departDate === existingItin.departDate
          )
        );

        const isAdditionalPropsMatch =
          existingItem.adult === newMultiData.adult &&
          existingItem.child === newMultiData.child &&
          existingItem.infrant === newMultiData.infrant &&
          existingItem.class === newMultiData.class &&
          existingItem.city === newMultiData.city;

        return !(isDuplicate && isAdditionalPropsMatch);
      });

      storedRecentFrom.unshift(newMultiData);

      // localStorage.setItem("recentFrom", JSON.stringify(storedRecentFrom));
      return storedRecentFrom;
    },

    handleRecentSearch(data) {
      let recentSearchObj;

      let isOneway = this.oneway.city == "2" ? true : false;

      if (data) {
        recentSearchObj = {
          from: data.from || "",
          to: data.to || "",
          fromDate: data.fromDate || "",
          toDate: isOneway ? null : data.toDate || null,
          adult: data.adult || 0,
          child: data.child || 0,
          inf: data.inf || 0,
          city: data.city || "",
          class: data.class || "",
        };
        console.log("Using provided data:", recentSearchObj);
      } else {
        recentSearchObj = {
          from: this.oneway.from || "",
          to: this.oneway.to || "",
          fromDate: this.oneway.dedate || "",
          toDate: isOneway ? null : this.oneway.redate || null,
          adult: this.aduls || 0,
          child: this.aduls1 || 0,
          inf: this.aduls2 || 0,
          city: this.red1 || "",
          class: this.classType || "",
        };
        console.log("Using current state values:", recentSearchObj);
      }

      let storedRecentFrom =
        JSON.parse(localStorage.getItem("recentFrom")) || [];
      console.log("Stored recent searches:", storedRecentFrom);

      storedRecentFrom = storedRecentFrom.filter((search) => {
        const searchFromDate = new Date(search.fromDate).toDateString();
        const searchToDate = new Date(search.toDate).toDateString();
        const recentFromDate = new Date(
          recentSearchObj.fromDate
        ).toDateString();
        const recentToDate = new Date(recentSearchObj.toDate).toDateString();

        return !(
          search.from === recentSearchObj.from &&
          search.to === recentSearchObj.to &&
          searchFromDate === recentFromDate &&
          searchToDate === recentToDate &&
          search.adult === recentSearchObj.adult &&
          search.child === recentSearchObj.child &&
          search.inf === recentSearchObj.inf &&
          search.city === recentSearchObj.city &&
          search.class === recentSearchObj.class
        );
      });

      storedRecentFrom.unshift(recentSearchObj);
      console.log("Updated search list:", storedRecentFrom);

      // localStorage.setItem("recentFrom", JSON.stringify(storedRecentFrom));
      return storedRecentFrom;
    },

    sended() {
      // console.log(this.oneway, "this.onewaythis.onewaythis.oneway");
      this.onewayfilterData = [];
      this.rountresult = [];
      localStorage.removeItem("rounttrip");
      localStorage.removeItem("onewaytrip");

      if (
        this.oneway.from &&
        this.oneway.to &&
        this.oneway.dedate &&
        this.oneway.redate &&
        !isNaN(new Date(this.oneway.redate).getTime())
      ) {
        this.oneway.city = this.red1;
        this.oneway.adult = this.aduls;
        this.oneway.infrant = this.aduls2;
        this.oneway.child = this.aduls1;
        this.oneway.class = this.classType;
        let result1 = [];

        result1 = JSON.stringify(this.oneway);
        localStorage.setItem("rountData", result1);

        if (this.oneway.from.name) {
          this.roundfrom = this.oneway.from.name;
        } else if (this.oneway.from) {
          this.roundfrom = this.oneway.from;
        }

        if (this.oneway.to.name) {
          this.roundto = this.oneway.to.name;
        } else if (this.oneway.to) {
          this.roundto = this.oneway.to;
        }

        this.urldedate = moment(this.oneway.dedate).format("YYYY/MM/DD");
        this.urlredate = moment(this.oneway.redate).format("YYYY/MM/DD");
        let rountdetail = {
          from: this.roundfrom,
          to: this.roundto,
          dedate: this.urldedate,
          redate: this.urlredate,
          class: this.oneway.class,
          child: this.oneway.child,
          adult: this.oneway.adult,
          infrant: this.oneway.infrant,
          city: this.oneway.city,
        };

        const tabId = sessionStorage.getItem("tabId") || Date.now().toString();
        sessionStorage.setItem("tabId", tabId);

        localStorage.setItem(
          `searchedData-round-${tabId}`,
          JSON.stringify(rountdetail)
        );

        let fromCode = rountdetail.from.match(/\(([^)]+)\)/)[1];
        let toCode = rountdetail.to.match(/\(([^)]+)\)/)[1];

        let itinerary = `${fromCode}-${toCode}-${rountdetail.dedate}_${toCode}-${fromCode}-${rountdetail.redate}`;

        let tripType = "";
        if (rountdetail.city == 1) {
          tripType = "RT";
        }

        let paxType = `A-${rountdetail.adult}_C-${rountdetail.child}_I-${rountdetail.infrant}`;

        let cabin = rountdetail.class;
        if (this.oneway.class == "Economy") {
          cabin = "Economy";
        }
        if (this.oneway.class == "Business") {
          cabin = "Business";
        }
        if (this.oneway.class == "First Class") {
          cabin = "FirstClass";
        }
        if (this.oneway.class == "Premium Economy") {
          cabin = "PremiumEconomy";
        }

        const resultData = this.handleRecentSearch();
        localStorage.setItem("recentFrom", JSON.stringify(resultData));

        setTimeout(() => {
          location.reload();
        }, 500);

        this.$router.push({
          path: "/search",
          query: { itinerary, tripType, paxType, cabin },
        });

        // this.sended1()
      } else {
        // alert("please enter your detail");

        this.validateOnewayForm();
      }
    },

    sendedone() {
      localStorage.removeItem("rounttrip");
      localStorage.removeItem("onewaytrip");

      this.onewayfilterData = [];
      this.rountresult = [];

      if (this.oneway.from && this.oneway.to && this.oneway.dedate) {
        this.oneway.city = this.red1;
        this.oneway.adult = this.aduls;
        this.oneway.infrant = this.aduls2;
        this.oneway.child = this.aduls1;
        let result1 = [];
        result1 = JSON.stringify(this.oneway);
        localStorage.setItem("rountData", result1);

        if (this.oneway.from.name) {
          this.roundfrom = this.oneway.from.name;
        } else if (this.oneway.from) {
          this.roundfrom = this.oneway.from;
        }

        if (this.oneway.to.name) {
          this.roundto = this.oneway.to.name;
        } else if (this.oneway.to) {
          this.roundto = this.oneway.to;
        }

        this.urldedate = moment(this.oneway.dedate).format("YYYY/MM/DD");

        let rountdetail = {
          from: this.roundfrom,
          to: this.roundto,
          dedate: this.urldedate,
          class: this.oneway.class,
          child: this.oneway.child,
          adult: this.oneway.adult,
          infrant: this.oneway.infrant,
          city: this.oneway.city,
        };

        const tabId = sessionStorage.getItem("tabId") || Date.now().toString();
        sessionStorage.setItem("tabId", tabId);

        localStorage.setItem(
          `searchedData-oneway-${tabId}`,
          JSON.stringify(rountdetail)
        );

        let fromCode = rountdetail.from.match(/\(([^)]+)\)/)[1];
        let toCode = rountdetail.to.match(/\(([^)]+)\)/)[1];

        let itinerary = `${fromCode}-${toCode}-${rountdetail.dedate}`;

        let tripType = "";
        if (rountdetail.city == 2) {
          tripType = "OW";
        }

        let paxType = `A-${rountdetail.adult}_C-${rountdetail.child}_I-${rountdetail.infrant}`;

        let cabin = rountdetail.class;

        const resultData = this.handleRecentSearch();
        localStorage.setItem("recentFrom", JSON.stringify(resultData));

        setTimeout(() => {
          location.reload();
        }, 500);

        this.$router.push({
          path: "/search",
          query: { itinerary, tripType, paxType, cabin },
        });

        // this.sended1()
      } else {
        // alert("please enter your detail");

        this.validateOnewayForm();
      }
    },

    async sended1() {
      // console.log("kkkkkkkkkkkkkkkkkk");
      localStorage.removeItem("onewaytrip");
      localStorage.removeItem("rounttrip");
      this.onewayresulted = [];

      // if (this.concatenatedArray) {
      //   this.concatenatedArray.forEach((v) => {
      //     console.log(v, "vvvvvvvvvvvvvvvvvvvvvvvvvv");
      //     resul.push(v);
      //   });
      // } else {
      //   console.log(this.concatenatedArray);
      // }

      this.oneway.city = this.red1;
      this.oneway.adult = this.aduls;
      this.oneway.infrant = this.aduls2;
      this.oneway.child = this.aduls1;
      // this.oneway.class = this.select;
      let rountdetail = {
        from: null,
        to: null,
        dedate: this.oneway.dedate,
        class: this.oneway.class,
        child: this.oneway.child,
        adult: this.oneway.adult,
        infrant: this.oneway.infrant,
        city: this.oneway.city,
      };
      console.log(this.oneway, "rountdetaillsss");
      let result1 = [];
      result1 = JSON.stringify(this.oneway);
      localStorage.setItem("rountData", result1);

      if (
        this.oneway.from &&
        this.oneway.to &&
        this.oneway.dedate &&
        this.oneway.redate
      ) {
        this.loadSearch = true;
        this.disButton = true;
        let res = "";
        if (this.oneway.from.name) {
          rountdetail.from = this.oneway.from.name;
          res = this.oneway.from.name.split(" ");
          this.roundfrom = res[res.length - 1]
            .replace("(", "")
            .replace(")", "");
        } else {
          rountdetail.from = this.oneway.from;
          res = this.oneway.from.split(" ");
          this.roundfrom = res[res.length - 1]
            .replace("(", "")
            .replace(")", "");
        }

        let res1 = "";
        if (this.oneway.to.name) {
          rountdetail.to = this.oneway.to.name;
          res1 = this.oneway.to.name.split(" ");
          this.roundto = res1[res1.length - 1]
            .replace("(", "")
            .replace(")", "");
        } else {
          rountdetail.to = this.oneway.to;
          res1 = this.oneway.to.split(" ");
          this.roundto = res1[res1.length - 1]
            .replace("(", "")
            .replace(")", "");
        }

        let result =
          this.Rountdata.request.AirShoppingRQ.CoreQuery.OriginDestinations
            .OriginDestination;
        for (let i = 0; i <= result.length - 1; i++) {
          result[0].Departure.AirportCode = this.roundfrom;

          result[0].Departure.Date = moment(
            this.oneway.dedate,
            "DD MMM-YYYY"
          ).format("YYYY-MM-DD");
          result[0].Arrival.AirportCode = this.roundto;
          result[1].Departure.AirportCode = this.roundto;
          result[1].Departure.Date = moment(
            this.oneway.redate,
            "DD MMM-YYYY"
          ).format("YYYY-MM-DD");
          result[1].Arrival.AirportCode = this.roundfrom;
        }

        let cabin_type = this.oneway.class;
        if (cabin_type == "Economy") {
          cabin_type = "Y";
          this.Rountdata.request.AirShoppingRQ.Preference.CabinType =
            cabin_type;
        } else if (cabin_type == "Business") {
          cabin_type = "C";
          this.Rountdata.request.AirShoppingRQ.Preference.CabinType =
            cabin_type;
        } else if (cabin_type == "First Class") {
          cabin_type = "F";
          this.Rountdata.request.AirShoppingRQ.Preference.CabinType =
            cabin_type;
        } else if (cabin_type == "Premium Economy") {
          cabin_type = "S";
          this.Rountdata.request.AirShoppingRQ.Preference.CabinType =
            cabin_type;
        } else if (cabin_type == "Premium Business") {
          cabin_type = "J";
          this.Rountdata.request.AirShoppingRQ.Preference.CabinType =
            cabin_type;
        } else if (cabin_type == "Premium First Class") {
          cabin_type = "P";
          this.Rountdata.request.AirShoppingRQ.Preference.CabinType =
            cabin_type;
        }
        this.Rountdata.request.AirShoppingRQ.Preference.TripType = "ROUND";
        this.Rountdata.country = this.pointofSale;
        this.Rountdata.request.AirShoppingRQ.Preference.Currency =
          this.preferCurrency;
        this.Rountdata.request.AirShoppingRQ.MetaData.Currency =
          this.metaCurrency;
        this.Rountdata.portal_domain = this.portal_domain;

        // console.log(
        //   this.Rountdata,
        //   "this.Rountdatathis.Rountdatathis.Rountdatathis.Rountdatathis.Rountdata"
        // );
        console.log(this.Rountdata, "roundtdaaaaaaa");
        await axios
          .post(this.makeSearchUrl, this.Rountdata, {
            headers: {
              "Content-Type": "application/json",
            },
          })

          .then((response) => {
            // console.log(response," this.responseData this.responseData this.responseData");

            this.responseData.push(response.data);

            console.log(
              this.responseData,
              "this.responseData this.responseData this.responseData"
            );

            let rountData = [];
            rountData = JSON.stringify(this.responseData);
            localStorage.setItem("rounttrip", rountData);

            let result = "";
            result = localStorage.getItem("rounttrip");
            this.localData.push(JSON.parse(result));
            // console.log(this.localData, " this.localData this.localData");
            if (this.localData) {
              this.roundedtrip();
            }

            // if (response.data.Errors =='Errors') {
            //   this.searchresult = false;
            //   this.searchloader = true;
            //   setTimeout(() => {
            //     this.pageError = true;
            //     this.searchresult = false;
            //   this.searchloader = false;
            //   }, 7000);
            // }

            // else {

            //   this.responseData.push(response.data.AirShoppingRS);
            //   console.log(this.responseData,'this.responseDatathis.responseData')
            //   console.log(
            //     this.responseData,
            //     " this.responseData this.responseData this.responseData"
            //   );
            //   let rountData = [];
            //   rountData = JSON.stringify(this.responseData);
            //   localStorage.setItem("rounttrip", rountData);
            //   let result = "";
            //   result = localStorage.getItem("rounttrip");
            //   this.localData.push(JSON.parse(result));
            //   console.log(this.localData, " this.localData this.localData");
            //   if (this.localData) {
            //     this.roundedtrip();
            //   }
            // }
          })

          .catch((error) => {
            console.log(error, "errorsadasdasda");
            this.$router.push("/pageError");
          });
      } else {
        // alert("please enter your detail");
        this.validateOnewayForm();
      }

      // console.log(this.responseData, "0077777777777");
    },

    sendedone1() {
      localStorage.removeItem("rounttrip");
      localStorage.removeItem("onewaytrip");

      let resul =
        this.onewaydata.request.AirShoppingRQ.DataLists.PassengerList.Passenger;

      // console.log(resul, "5555555555555555555555");
      if (this.concatenatedArray) {
        this.concatenatedArray.forEach((v) => {
          // console.log(v, "vvvvvvvvvvvvvvvvvvvvvvvvvv");
          resul.push(v);
        });
      } else {
        // console.log(this.concatenatedArray);
      }

      if (this.oneway.from && this.oneway.to && this.oneway.dedate) {
        this.loadSearch = true;
        this.disButton = true;

        this.oneway.city = this.red1;
        this.oneway.adult = this.aduls;
        this.oneway.infrant = this.aduls2;
        this.oneway.child = this.aduls1;
        // this.oneway.class = this.select;
        // console.log(this.oneway, "this.onewaythis.onewaythis.oneway");
        let result1 = [];
        result1 = JSON.stringify(this.oneway);
        localStorage.setItem("rountData", result1);

        let res = "";

        if (this.oneway.from.name) {
          res = this.oneway.from.name.split(" ");
          this.roundfrom = res[res.length - 1]
            .replace("(", "")
            .replace(")", "");
        } else {
          res = this.oneway.from.split(" ");
          this.roundfrom = res[res.length - 1]
            .replace("(", "")
            .replace(")", "");
        }

        let res1 = "";
        if (this.oneway.to.name) {
          res1 = this.oneway.to.name.split(" ");
          this.roundto = res1[res1.length - 1]
            .replace("(", "")
            .replace(")", "");
        } else {
          res1 = this.oneway.to.split(" ");
          this.roundto = res1[res1.length - 1]
            .replace("(", "")
            .replace(")", "");
        }

        this.urldedate = moment(this.oneway.dedate, "DD MMM-YYYY").format(
          "YYYY-MM-DD"
        );
        // let rountdetail = {
        //   from: this.roundfrom,
        //   to: this.roundto,
        //   dedate: this.urldedate,
        //   redate: this.urlredate,
        //   class: this.oneway.class,
        //   child: this.oneway.child,
        //   adult: this.oneway.adult,
        //   infrant: this.oneway.infrant,
        //   city: this.oneway.city,
        // };

        let result =
          this.onewaydata.request.AirShoppingRQ.CoreQuery.OriginDestinations
            .OriginDestination;
        for (let i = 0; i <= result.length - 1; i++) {
          result[0].Departure.AirportCode = this.roundfrom;
          result[0].Departure.Date = moment(
            this.oneway.dedate,
            "DD MMM-YYYY"
          ).format("YYYY-MM-DD");
          result[0].Arrival.AirportCode = this.roundto;
          // result[1].Departure.AirportCode = this.roundto
          // result[1].Departure.Date = moment(this.oneway.redate).format('YYYY-MM-DD')
          // result[1].Arrival.AirportCode = this.roundfrom
        }

        let cabin_type = this.oneway.class;
        if (cabin_type == "Economy") {
          cabin_type = "Y";
          this.onewaydata.request.AirShoppingRQ.Preference.CabinType =
            cabin_type;
        } else if (cabin_type == "Business") {
          cabin_type = "C";
          this.onewaydata.request.AirShoppingRQ.Preference.CabinType =
            cabin_type;
        } else if (cabin_type == "First Class") {
          cabin_type = "F";
          this.onewaydata.request.AirShoppingRQ.Preference.CabinType =
            cabin_type;
        } else if (cabin_type == "Premium Economy") {
          cabin_type = "S";
          this.onewaydata.request.AirShoppingRQ.Preference.CabinType =
            cabin_type;
        } else if (cabin_type == "Premium Business") {
          cabin_type = "J";
          this.onewaydata.request.AirShoppingRQ.Preference.CabinType =
            cabin_type;
        } else if (cabin_type == "Premium First Class") {
          cabin_type = "P";
          this.onewaydata.request.AirShoppingRQ.Preference.CabinType =
            cabin_type;
        }

        this.onewaydata.request.AirShoppingRQ.Preference.TripType = "ONEWAY";
        this.onewaydata.country = this.pointofSale;
        this.onewaydata.request.AirShoppingRQ.Preference.Currency =
          this.preferCurrency;
        this.onewaydata.request.AirShoppingRQ.MetaData.Currency =
          this.metaCurrency;
        this.onewaydata.portal_domain = this.portal_domain;

        console.log(this.onewaydata, "this.onewaydatathis.onewaydata");

        axios
          .post(this.makeSearchUrl, this.onewaydata, {
            headers: {
              "Content-Type": "application/json",
            },
          })
          .then((response) => {
            console.log(response, "responseresponseresponse");
            this.responseData.push(response.data);
            // console.log(
            //   this.responseData,
            //   "this.responseDatathis.responseData"
            // );
            let rountData = [];
            rountData = JSON.stringify(this.responseData);

            localStorage.setItem("onewaytrip", rountData);

            if (rountData) {
              this.onewaytrip();
            }

            //             if (response.data.AirShoppingRS.Errors) {
            //               this.searchresult = false;
            //               this.searchloader = true;
            //               setTimeout(() => {
            //                 this.pageError = true;
            //                 this.searchresult = false;
            //               this.searchloader = false;
            //               }, 7000);
            //             }

            // else{
            //   this.responseData.push(response.data.AirShoppingRS);
            // console.og(this.responseData,'this.responseDatathis.responseData')
            // let rountData = [];
            // rountData = JSON.stringify(this.responseData);

            // localStorage.setItem("onewaytrip", rountData);
            // this.$router.push({ path: "/search", query: rountdetail });

            // if (rountData) {
            //   this.onewaytrip();
            // }
            // }
          })
          .catch((error) => {
            console.log(error);
            this.$router.push("/pageError");
          });
      } else {
        // alert("please enter your detail");

        this.validateOnewayForm();
      }
    },

    sendedMulti() {
      if (
        this.multi_city.itinery[0].from &&
        this.multi_city.itinery[0].to &&
        this.multi_city.itinery[0].departDate &&
        this.multi_city.itinery[1].from &&
        this.multi_city.itinery[1].to &&
        this.multi_city.itinery[1].departDate &&
        (!this.multi_city.itinery[2] ||
          (this.multi_city.itinery[2].from &&
            this.multi_city.itinery[2].to &&
            this.multi_city.itinery[2].departDate)) &&
        (!this.multi_city.itinery[3] ||
          (this.multi_city.itinery[3].from &&
            this.multi_city.itinery[3].to &&
            this.multi_city.itinery[3].departDate))
      ) {
        this.multi_city.adult = this.aduls;
        this.multi_city.child = this.aduls1;
        this.multi_city.infrant = this.aduls2;
        this.multi_city.class = this.classType;

        const tabId = sessionStorage.getItem("tabId") || Date.now().toString();
        sessionStorage.setItem("tabId", tabId);

        const result1 = JSON.stringify(this.multi_city);
        localStorage.setItem(`searchedData-multicity-${tabId}`, result1);

        console.log(this.multi_city, "datasdatasdatas");

        // let multicityData = {
        //   itinery: [],
        //   class: "",
        //   child: "",
        //   adult: "",
        //   infrant: "",
        //   city: "3",
        // };

        // multicityData.class = this.multi_city.class;
        // multicityData.adult = this.multi_city.adult;
        // multicityData.child = this.multi_city.child;
        // multicityData.infrant = this.multi_city.infrant;
        // multicityData.city = this.multi_city.city;
        // this.multi_city.itinery.forEach((v) => {
        //   console.log(v, "multy");
        //   let itineraryObject = {
        //     from: v.from.name ? v.from.name : v.from,
        //     to: v.to.name ? v.to.name : v.to,
        //     departDate: moment(v.departDate).format("YYYY-MM-DD"),
        //   };
        //   multicityData.itinery.push(itineraryObject);
        // });

        let itinerary = this.multi_city.itinery
          .map((v) => {
            const from = v.from.match(/\(([^)]+)\)/)
              ? v.from.match(/\(([^)]+)\)/)[1]
              : v.from;
            const to = v.to.match(/\(([^)]+)\)/)
              ? v.to.match(/\(([^)]+)\)/)[1]
              : v.to;
            const departDate = moment(v.departDate).format("YYYY/MM/DD");
            return `${from}-${to}-${departDate}`;
          })
          .join("_");

        let tripType = "MC";
        let paxType = `A-${this.aduls}_C-${this.aduls1}_I-${this.aduls2}`;
        let cabin = this.multi_city.class;
        if (this.classType == "Economy") {
          cabin = "Economy";
        }
        if (this.classType == "Business") {
          cabin = "Business";
        }
        if (this.classType == "First Class") {
          cabin = "FirstClass";
        }
        if (this.classType == "Premium Economy") {
          cabin = "PremiumEconomy";
        }

        const resultData = this.handleMultiSearch(this.multi_city);
        localStorage.setItem("recentFrom", JSON.stringify(resultData));

        // console.log(multicityData, "this.multicityData5555555555555");

        setTimeout(() => {
          location.reload();
        }, 500);

        // let muti_city = JSON.stringify(multicityData);
        this.$router.push({
          path: "/search",
          query: { itinerary, tripType, paxType, cabin },
        });
      } else {
        //  alert("please enter your detail");

        this.validateMultiForm();
      }
    },

    sendedMulticity() {
      for (let i = 0; i < this.multi_city.itinery.length; i++) {
        if (this.multi_city.itinery[i].from) {
          this.multi_city.itinery[i].multiFromError = false;

          // console.log(this.multi_city.itinery[i].from, "mememememe.....");
        } else {
          this.multi_city.itinery[i].multiFromError = true;
        }

        if (this.multi_city.itinery[i].to) {
          this.multi_city.itinery[i].multiToError = false;
        } else {
          this.multi_city.itinery[i].multiToError = true;
        }

        if (this.multi_city.itinery[i].departDate) {
          this.multi_city.itinery[i].multiDateError = false;
        } else {
          this.multi_city.itinery[i].multiDateError = true;
        }
      }
    },

    validateMultiForm() {
      // let dateError = true;
      console.log("validateMultiformError");
      this.multi_city.itinery.forEach((data, index) => {
        if (!data.departDate) {
          this.multi_city.itinery[index].multiDateError = true;
          // dateError = false;
        } else {
          this.multi_city.itinery[index].multiDateError = false;
        }
        if (!data.from) {
          this.multi_city.itinery[index].multiFromError = true;
        } else {
          this.multi_city.itinery[index].multiFromError = false;
        }
        if (!data.to) {
          this.multi_city.itinery[index].multiToError = true;
        } else {
          this.multi_city.itinery[index].multiToError = false;
        }
      });
    },

    selected(result) {
      this.searchloader = true;
      this.priceChangeValue = result;

      console.log(result, "ppppp");
      if (result.Flight1) {
        let obj = {
          adult: this.multi_city.adult,
          child: this.multi_city.child,
          inf: this.multi_city.infrant,
          class: this.multi_city.class,
        };
        console.log(obj, "ppp");
        localStorage.setItem(
          `${"pass"}-${result.selectId}`,
          JSON.stringify(obj)
        );
        localStorage.setItem(
          `${"multi"}-${result.selectId}`,
          JSON.stringify(result)
        );
        this.priceConfirm(this.priceChangeValue);
      } else {
        // localStorage.setItem(
        //   `${result.currency}-${result.selectId}`,
        //   JSON.stringify(result)
        // );
        // this.$router.push({
        //   path: "/flightInfo",
        //   query: { pass_id: `${result.currency}-${result.selectId}` },
        // });

        this.priceConfirm(this.priceChangeValue);
      }
    },

    priceConfirm(result) {
      // console.log(result, this.priceChangeValue, "Price confirmation");
      if (result == "1") {
        this.routerChange();
        console.log("pppppp");
      } else {
        let $pracingReq = {
          service_type: "PricingBeforeBooking",
          api_ver: "1",
          portal_id: this.portal_id,
          payload: {
            shopping_response_id: this.priceChangeValue.ShoppingResponseId,
            offer_id: this.priceChangeValue.selectId,
          },
        };
        console.log($pracingReq, this.pricingApi, "0p0p0p0p0p");
        axios
          .post(this.pricingApi, $pracingReq, {
            headers: {
              "Content-Type": "application/json",
            },
          })
          .then((response) => {
            let $priceres = response.data;
            this.priceRes = response.data;

            console.log(
              $priceres.payload.itin_total_fare,
              this.priceChangeValue.TotalPrice,
              $priceres,
              "responsefareRulesApi"
            );
            // this.routerChange()
            this.searchloader = false;
            if ($priceres.response_status == "success") {
              console.log("ssssss");
              if (
                $priceres.payload.itin_total_fare ==
                this.priceChangeValue.TotalPrice
              ) {
                console.log("ssssss88");
                this.routerChange();
              } else if (
                $priceres.payload.itin_total_fare >
                this.priceChangeValue.TotalPrice
              ) {
                this.priceText =
                  "Oops! The fare has been updated by the Airlines";
                this.pricedialog = true;
              } else if (
                $priceres.payload.itin_total_fare <
                this.priceChangeValue.TotalPrice
              ) {
                this.priceText =
                  "Oops! The fare has been updated by the Airlines";
                this.pricedialog = true;
              } else {
                // this.routerChange();
              }
            }
          })
          .catch((error) => {
            console.log(error, "erroroor.....");
            this.searchloader = false;
            this.priceError = true;
          });
      }
    },

    routerChange() {
      let data = "";
      let event = "";

      if (this.onewayresulted.length > 0) {
        data = "pass_uid1";
        event = this.priceChangeValue.currency;
      } else if (this.rountresult.length > 0) {
        data = "pass_uid";
        event = this.priceChangeValue.currency;
        // console.log(
        //   this.priceChangeValue,
        //   event,
        //   data,
        //   `${data}:${event}-${this.priceChangeValue.selectId}`,
        //   "this.priceChangeValue"
        // );
      } else {
        data = "pass_uid";
        event = "multi";
      }

      setTimeout(() => {
        this.dataLoader = false;
        localStorage.setItem(
          `${event}-${this.priceChangeValue.selectId}`,
          JSON.stringify(this.priceChangeValue)
        );

        this.$router.push({
          path: "/flightinfo",
          query: {
            [data]: `${event}-${this.priceChangeValue.selectId}`,
          },
        });
        // // this.flightpage = true;
        // // this.flightInfoData = {
        // //   [data]: `${event}-${this.priceChangeValue.selectId}`,
        // };
        console.log(this.flightInfoData, "this.flightInfoData");
      }, 3000);
    },

    fromClick($event) {
      // console.log($event, "p.....1");
      if ($event.from) {
        $event.from = "";
        this.fromErrorMessage = true;

        // console.log(this.fromErrorMessage, "p.....2");
      }
    },

    toClick($event) {
      // console.log($event, "p.....");
      if ($event.to) {
        $event.to = "";
        this.toErrorMessage = true;

        // console.log(this.toErrorMessage, "p.....3");
      }
    },

    multiFromClick(index) {
      this.multi_city.itinery.map((v, i) => {
        if (i == index) {
          if (v.from) {
            v.from = null;
            v.multiFromError = true;

            // console.log("vvvvv......4444");
            if (!v.from) {
              v.multiFromError = true;

              // console.log("vvvvv......555");
            } else {
              v.multiFromError = false;

              // console.log("vvvvv......666");
            }
          }
        }
      });
    },

    multiToClick(index) {
      this.multi_city.itinery.map((v, i) => {
        if (i == index) {
          if (v.to) {
            v.to = null;
            v.multiToError = true;

            // console.log("vvvvv......111");
            if (!v.to) {
              // console.log("vvvvv......222");
              v.multiToError = true;
            } else {
              v.multiToError = false;

              // console.log("vvvvv......333");
            }
          }
        }
      });
    },

    onChangeFrom() {
      setTimeout(() => {
        if (this.oneway.from) {
          console.log("errorMessage...1");
          this.fromErrorMessage = false;
          this.changeTextFrom = true;
        }
      }, 50);

      setTimeout(() => {
        if (!this.oneway.from) {
          console.log("errorMessage...2");
          this.fromErrorMessage = true;
        }
      }, 100);
    },

    onChangeTo() {
      setTimeout(() => {
        if (this.oneway.to) {
          this.toErrorMessage = false;
          this.changeTextTo = true;
        }
      }, 50);

      setTimeout(() => {
        if (!this.oneway.to) {
          this.toErrorMessage = true;
        }
      }, 100);
    },

    onChangeMultiFrom(index) {
      this.multiData.itinery.map((v, i) => {
        if (i == index) {
          if (v.from) {
            v.multiFromError = false;
            v.maximumletter = false;
          } else {
            v.multiFromError = true;
          }
        }
      });
    },

    onChangeMultiTo(index) {
      this.multiData.itinery.map((v, i) => {
        if (i == index) {
          if (v.to) {
            v.multiToError = false;
            v.maximumletter1 = false;
          } else {
            v.multiToError = true;
            // v.maximumletter1 = true
          }
        }
      });

      // for (let i = 1; i < this.multiData.itinery.length; i++) {
      //   this.multiData.itinery[i].from = this.multiData.itinery[i - 1].to;
      // }
    },

    validateOnewayForm() {
      console.log(this.oneway.from, "validateeeee");
      if (this.oneway.from) {
        this.fromErrorMessage = false;
      } else {
        this.fromErrorMessage = true;
      }

      if (this.oneway.to) {
        this.toErrorMessage = false;
      } else {
        this.toErrorMessage = true;
      }

      if (this.oneway.dedate) {
        this.fromDateError = false;
      } else {
        this.fromDateError = true;
      }

      if (
        this.oneway.redate &&
        !isNaN(new Date(this.oneway.redate).getTime())
      ) {
        this.toDateError = false;
      } else {
        this.toDateError = true;
      }
      // this.fromErrorMessage = !this.oneway.from;
      // this.toErrorMessage = !this.oneway.to;
      // this.fromDateError = !this.oneway.dedate;
      // this.toDateError = !this.oneway.redate;
    },

    // autocompletJson() {

    //   const getConfigData = getConfigDataFromLocalStorage();

    //   this.airlineIcaoUrl =
    //     getConfigData.payload.portal_configuration.content_data.airports_icao.url;
    //   this.airlineIcaoType =
    //     getConfigData.payload.portal_configuration.content_data.airports_icao.type;

    //   axios
    //     .get(this.airlineIcaoUrl, {
    //       headers: {
    //         "Content-Type": this.airlineIcaoType,
    //       },
    //     })
    //     .then((response) => {
    //       let airlineIcao = response.data;
    //       if (airlineIcao) {

    //         Object.keys(airlineIcao).forEach((icao) => {
    //           const airport = airlineIcao[icao];

    //           if (airport.iata !== "") {
    //             this.fromAirports.push({
    //               iata: airport.iata,
    //               icao: airport.icao,
    //               places: ` ${airport.iata}, ${airport.city}, ${airport.state}, ${airport.country}, ${airport.name}`,
    //             });
    //           }
    //         });
    //       }
    //     })

    //     .catch((error) => {
    //       console.log(error, "erroroor.....");
    //     });
    // },

    // fetchItems() {
    //   Object.keys(userData).forEach((icao) => {
    //     const airport = userData[icao];
    //     if (airport.iata !== "") {
    //       this.fromAirports.push({
    //         iata: airport.iata,
    //         icao: airport.icao,
    //         places: `${airport.iata}, ${airport.city}, ${airport.state}, ${airport.country}, ${airport.name}`,
    //       });
    //     }
    //   });
    // },

    async search(event) {
      console.log(event, "searchsearchsearchsearch");

      if (event.query.length >= 1) {
        this.formres = false;
        if (event.query.length >= 1) {
          this.formres1 = false;
        }
      }

      this.fromAirports = [];

      if (event.query.length >= 3) {
        await axios
          .get(`${this.autocompleteApi}?airportString=${event.query}`, {
            headers: {
              "Content-Type": this.airlineIcaoType,
            },
          })
          .then((response) => {
            console.log(response, "response");

            let airlineIcao = response.data;
            if (airlineIcao) {
              Object.keys(airlineIcao).forEach((icao) => {
                const airport = airlineIcao[icao];

                if (airport.iata !== "") {
                  this.fromAirports.push({
                    iata: airport.iata,
                    icao: airport.icao,
                    places: `${airport.iata}, ${airport.city}, ${airport.state}, ${airport.country}, ${airport.name}`,
                  });
                }
              });
            }
          })

          .catch((error) => {
            console.log(error, "erroroor.....");
          });

        this.maximumletter = false;
        this.maximumletter1 = false;
        this.loading = true;
        const searchString = event.query.toUpperCase();
        // console.log(searchString,'searchStringsearchString')
        setTimeout(() => {
          const iataMatch = [];
          const otherMatches = [];
          this.fromAirports.forEach((e) => {
            const iata = e.iata ? e.iata.toUpperCase() : "";
            const placesLowerCase = e.places.toLowerCase();
            if (iata === searchString) {
              iataMatch.push(e);
              // console.log(iataMatch,'iataMatchiataMatch')
            } else if (placesLowerCase.includes(searchString.toLowerCase())) {
              otherMatches.push(e);
              //  console.log(otherMatches,'otherMatches')
            }
          });

          this.airitems = iataMatch.concat(otherMatches);
          this.loading = false;
        }, 1000);
      } else {
        if (this.oneway.from || this.oneway.to) {
          if (this.oneway.from.length < 3) {
            console.log("maximmmm...1");
            this.maximumletter = true;
          } else {
            this.maximumletter = false;
          }
          if (this.oneway.to.length < 3) {
            console.log("maximmmm...2");
            this.maximumletter1 = true;
          } else {
            this.maximumletter1 = false;
          }
        }
        if (this.multi_city.itinery) {
          this.multi_city.itinery.map((v) => {
            if (v.from.length < 3) {
              this.maximumletter = true;
            } else {
              this.maximumletter = false;
            }
            if (v.to.length < 3) {
              this.maximumletter1 = true;
            } else {
              this.maximumletter1 = false;
            }
          });
        }
      }
    },
    focus1() {
      setTimeout(() => {
        this.$refs.secondAutoComplete.$el.querySelector("input").focus();
      }, 100);
    },

    focus2() {
      this.$refs.dpRef1.openMenu();
    },

    focus3() {
      setTimeout(() => {
        this.$refs.onewayTo.$el.querySelector("input").focus();
      }, 10);
    },

    focus4() {
      this.$refs.dpRef3.openMenu();
    },

    focus5(index) {
      setTimeout(() => {
        if (this.$refs.multiTo.length > 0) {
          const inputElement =
            this.$refs.multiTo[index].$el.querySelector("input");
          if (inputElement) {
            inputElement.focus();
          }
        }
      }, 10);
    },

    focus6(index) {
      setTimeout(() => {
        if (this.$refs.dpRef4.length > 0) {
          const inputElement = this.$refs.dpRef4[index];
          if (inputElement) {
            inputElement.openMenu();
          }
        }

        for (let i = 0; i < 1; i++) {
          // console.log(this.multi_city.itinery[0].to, "bfbfbfbf.....");
          this.multi_city.itinery[1].from = this.multi_city.itinery[0].to;
        }
      }, 10);
    },

    Multifocus1(index) {
      setTimeout(() => {
        if (this.$refs.multiTo.length > 0) {
          const inputElement =
            this.$refs.multiTo[index].$el.querySelector("input");
          if (inputElement) {
            inputElement.focus();
          }
        }
      }, 10);
    },

    Multifocus2(data, index) {
      setTimeout(() => {
        if (this.$refs.dpRef4.length > 0) {
          const inputElement = this.$refs.dpRef4[index];
          if (inputElement) {
            inputElement.openMenu();
          }
        }

        console.log(data, "bfbfbfbf.....");
        // return data.to.name

        // for (let i = 0; i < 1; i++) {
        //   //console.log(this.multiData[0].to, 'bfbfbfbf.....')
        //   this.input[1].from = this.input[0].to;
        // }

        for (let i = 0; i < 1; i++) {
          this.multi_city.itinery[1].from = this.multi_city.itinery[0].to;
        }
      }, 10);
    },

    handleResize() {
      if (window.innerWidth < 992) {
        this.isIcon = false;
      } else {
        this.isIcon = true;
      }
    },
    handleCalenderResize() {
      if (window.innerWidth < 400) {
        this.multiCalender = false;
      } else {
        this.multiCalender = true;
      }
    },

    // Form Header section

    getMultifrom(data) {
      // console.log(data, 'datatatetee')
      if (data.itinery[0].from === null) {
        return null;
      } else {
        const $data = data.itinery[0]?.from?.name;
        return $data || data.itinery[0].from;
      }

      // if (data.itinery[0].from.name) {
      //   return data.itinery[0].from.name
      // }
      // else {
      //   return data.itinery[0].from
      // }
    },
    getMultiDedate1(data) {
      if (data.itinery[0].departDate) {
        // return data.itinery[0].departDate
        return moment(data.itinery[0].departDate, "DD-MMM-YYYY").format(
          "DD-MMM-YYYY"
        );
      } else {
        return "";
      }
    },

    getMultiTo(data) {
      // console.log(data, "Testing...1...1...1.");

      if (data.itinery[data.itinery.length - 1].to === null) {
        return null;
      } else {
        const $data = data.itinery[data.itinery.length - 1]?.to?.name;
        return $data || data.itinery[data.itinery.length - 1].to;
      }
    },

    getMultiDedate2(data) {
      if (data.itinery[data.itinery.length - 1].departDate) {
        // return data.itinery[data.itinery.length - 1].departDate
        return moment(
          data.itinery[data.itinery.length - 1].departDate,
          "DD-MMM-YYYY"
        ).format("DD-MMM-YYYY");
      } else {
        return "";
      }
    },

    getMultiClass(data) {
      console.log(data, "mdatatiCadata");
      if (data.class) {
        return data.class;
      } else {
        return "";
      }
    },

    //     getMultifrom(data) {
    //       if (data.length>0) {

    //   console.log(data,'wrthofjsdosjkdjk')

    //    if (data.itinery[0].from) {
    //           return data.itinery[0].from
    //       }
    // }

    //        else {
    //        return data
    //       }

    //     },
    //     getMultiDedate1(data) {

    //          if (data.length>0) {
    //    if (data.itinery[0].departDate) {
    //           return data.itinery[0].departDate
    //       }
    // }

    //        else {
    //        return data
    //       }

    //     },

    //     getMultiTo(data) {

    //         if (data.length>0) {
    //    if (data.itinery[data.itinery.length - 1].to) {
    //           return data.itinery[data.itinery.length - 1].to
    //       }
    // }

    //        else {
    //        return data
    //       }

    //     },
    //     getMultiDedate2(data) {

    //          if (data.length>0) {
    //    if (data.itinery[data.itinery.length - 1].departDate) {
    //           return data.itinery[data.itinery.length - 1].departDate
    //       }
    // }

    //        else {
    //        return data
    //       }
    //     },

    //     getMultiClass(data) {
    //       if (data.classes) {
    //         return data.classes
    //       }
    //       else {
    //         return ''
    //       }

    //     },

    filterByTimeRange(flight, range) {
      let [start, end] = range;
      const depTimeParts = flight.Depature[0].Departure.Time.split(":");
      let hours = parseInt(depTimeParts[0]);
      let minutes = parseInt(depTimeParts[1]);
      let totalHours = Math.round(hours + minutes / 60);
      let value = totalHours >= start && totalHours < end;
      return value;
    },
    filterByTimeRange1(flight, range) {
      let [start, end] = range;
      const depTimeParts =
        flight.Depature[flight.Depature.length - 1].Arrival.Time.split(":");
      let hours = parseInt(depTimeParts[0]);
      let minutes = parseInt(depTimeParts[1]);
      let totalHours = Math.round(hours + minutes / 60);
      let value = totalHours >= start && totalHours < end;
      return value;
    },
    filterByTimeRangeRet(flight, range) {
      let [start, end] = range;
      const retTimeParts = flight.Return[0].Departure.Time.split(":");
      let hours = parseInt(retTimeParts[0]);
      let minutes = parseInt(retTimeParts[1]);
      let totalHours = Math.round(hours + minutes / 60);
      let value = totalHours >= start && totalHours < end;
      console.log(hours, value, "valuevalue");
      return value;
    },
    filterByTimeRangeRet1(flight, range) {
      let [start, end] = range;
      const retTimeParts =
        flight.Return[flight.Return.length - 1].Arrival.Time.split(":");
      let hours = parseInt(retTimeParts[0]);
      let minutes = parseInt(retTimeParts[1]);
      let totalHours = Math.round(hours + minutes / 60);
      let value = totalHours >= start && totalHours < end;
      console.log(hours, value, "valuevalue");
      return value;
    },

    // Methods to toggle time filters and apply them

    TimeFilter1() {
      this.timeFilterData1 = !this.timeFilterData1;
      this.applyFilters();
    },

    TimeFilter2() {
      this.timeFilterData2 = !this.timeFilterData2;
      this.applyFilters();
    },

    TimeFilter3() {
      this.timeFilterData3 = !this.timeFilterData3;
      this.applyFilters();
    },
    TimeFilter4() {
      this.timeFilterData4 = !this.timeFilterData4;
      this.applyFilters();
    },

    TimeFilterArr1() {
      this.timeFilterArrData1 = !this.timeFilterArrData1;
      this.applyFilters();
    },

    TimeFilterArr2() {
      this.timeFilterArrData2 = !this.timeFilterArrData2;
      this.applyFilters();
    },

    TimeFilterArr3() {
      this.timeFilterArrData3 = !this.timeFilterArrData3;
      this.applyFilters();
    },
    TimeFilterArr4() {
      this.timeFilterArrData4 = !this.timeFilterArrData4;
      this.applyFilters();
    },
    TimeretFilter1() {
      this.timearrFilterData1 = !this.timearrFilterData1;
      this.applyFilters();
    },
    TimeretFilter2() {
      this.timearrFilterData2 = !this.timearrFilterData2;
      this.applyFilters();
    },
    TimeretFilter3() {
      this.timearrFilterData3 = !this.timearrFilterData3;
      this.applyFilters();
    },
    TimeretFilter4() {
      this.timearrFilterData4 = !this.timearrFilterData4;
      this.applyFilters();
    },
    TimeretFilterarr1() {
      this.timearrFilterArrData1 = !this.timearrFilterArrData1;
      this.applyFilters();
    },
    TimeretFilterarr2() {
      this.timearrFilterArrData2 = !this.timearrFilterArrData2;
      this.applyFilters();
    },
    TimeretFilterarr3() {
      this.timearrFilterArrData3 = !this.timearrFilterArrData3;
      this.applyFilters();
    },
    TimeretFilterarr4() {
      this.timearrFilterArrData4 = !this.timearrFilterArrData4;
      this.applyFilters();
    },

    //multicity
    TimeMulti1() {
      this.timeMulti1 = !this.timeMulti1;
      this.applyFilters();
    },
    TimeMulti2() {
      this.timeMulti2 = !this.timeMulti2;
      this.applyFilters();
    },
    TimeMulti3() {
      this.timeMulti3 = !this.timeMulti3;
      this.applyFilters();
    },
    TimeMulti4() {
      this.timeMulti4 = !this.timeMulti4;
      this.applyFilters();
    },
    TimeMultiArr1() {
      this.timeMultiArr1 = !this.timeMultiArr1;
      this.applyFilters();
    },
    TimeMultiArr2() {
      this.timeMultiArr2 = !this.timeMultiArr2;
      this.applyFilters();
    },
    TimeMultiArr3() {
      this.timeMultiArr3 = !this.timeMultiArr3;
      this.applyFilters();
    },
    TimeMultiArr4() {
      this.timeMultiArr4 = !this.timeMultiArr4;
      this.applyFilters();
    },

    applyFilters() {
      if (this.rountresult.length > 0) {
        this.filteredData = this.filterData;
        // console.log(this.filteredData, "resultttt....3");
      } else if (this.onewayresulted.length > 0) {
        this.filteredData = this.onewayfilterData;
        // console.log(this.filteredData, "resultttt....4");
      } else if (this.multicityData.length > 0) {
        this.filteredData = this.multiFilterData;
        this.multifil = true;

        // console.log(this.filteredData, "resultttt...5");
      }
      let result = this.filteredData;

      if (this.RefundableData && this.nonRefundableData) {
        result = result.filter(
          (v) => v.Refund === "Refundable" || v.Refund === "Non Refundable"
        );
      } else {
        // Refundable filter
        if (this.RefundableData) {
          result = result.filter((v) => v.Refund === "Refundable");
        }

        // Non-refundable filter
        if (this.nonRefundableData) {
          result = result.filter((v) => v.Refund === "Non Refundable");
        }
      }

      // Non-Stop filter OneStopFil
      if (this.rountresult.length > 0) {
        if (this.NonStopFil && this.OneStopFil && this.OnePlusFil) {
          result = result.filter(
            (v) =>
              v.Depaturejourney.Stops === 0 ||
              v.Returnjourney.Stops === 0 ||
              v.Depaturejourney.Stops === 1 ||
              v.Returnjourney.Stops === 1 ||
              v.Depaturejourney.Stops > 1 ||
              v.Returnjourney.Stops > 1
          );
        } else {
          // Non Stop and One Stop filter
          if (this.NonStopFil && this.OneStopFil) {
            result = result.filter(
              (v) =>
                v.Depaturejourney.Stops === 0 ||
                v.Returnjourney.Stops === 0 ||
                v.Depaturejourney.Stops === 1 ||
                v.Returnjourney.Stops === 1
            );
          } else {
            // Non Stop and One Plus filter
            if (this.NonStopFil && this.OnePlusFil) {
              result = result.filter(
                (v) =>
                  v.Depaturejourney.Stops === 0 ||
                  v.Returnjourney.Stops === 0 ||
                  v.Depaturejourney.Stops > 1 ||
                  v.Returnjourney.Stops > 1
              );
            } else {
              // One Stop and One Plus filter
              if (this.OneStopFil && this.OnePlusFil) {
                result = result.filter(
                  (v) =>
                    v.Depaturejourney.Stops === 1 ||
                    v.Returnjourney.Stops === 1 ||
                    v.Depaturejourney.Stops > 1 ||
                    v.Returnjourney.Stops > 1
                );
              } else {
                if (this.NonStopFil) {
                  result = result.filter(
                    (v) =>
                      v.Depaturejourney.Stops === 0 ||
                      v.Returnjourney.Stops === 0
                  );
                }
                if (this.OneStopFil) {
                  result = result.filter(
                    (v) =>
                      v.Depaturejourney.Stops === 1 ||
                      v.Returnjourney.Stops === 1
                  );
                }
                if (this.OnePlusFil) {
                  result = result.filter(
                    (v) =>
                      v.Depaturejourney.Stops > 1 || v.Returnjourney.Stops > 1
                  );
                }
              }
            }
          }
        }
      } else if (this.onewayfilterData.length > 0) {
        if (this.NonStopFil && this.OneStopFil && this.OnePlusFil) {
          result = result.filter(
            (v) =>
              v.Depaturejourney.Stops === 0 ||
              v.Depaturejourney.Stops === 1 ||
              v.Depaturejourney.Stops > 1
          );
        } else {
          // Non Stop and One Stop filter
          if (this.NonStopFil && this.OneStopFil) {
            result = result.filter(
              (v) =>
                v.Depaturejourney.Stops === 0 || v.Depaturejourney.Stops === 1
            );
          } else {
            // Non Stop and One Plus filter
            if (this.NonStopFil && this.OnePlusFil) {
              result = result.filter(
                (v) =>
                  v.Depaturejourney.Stops === 0 || v.Depaturejourney.Stops > 1
              );
            } else {
              // One Stop and One Plus filter
              if (this.OneStopFil && this.OnePlusFil) {
                result = result.filter(
                  (v) =>
                    v.Depaturejourney.Stops === 1 || v.Depaturejourney.Stops > 1
                );
              } else {
                if (this.NonStopFil) {
                  result = result.filter((v) => v.Depaturejourney.Stops === 0);
                }
                if (this.OneStopFil) {
                  result = result.filter((v) => v.Depaturejourney.Stops === 1);
                }
                if (this.OnePlusFil) {
                  result = result.filter((v) => v.Depaturejourney.Stops > 1);
                }
              }
            }
          }
        }
      } else if (this.multiFilterData.length > 0) {
        if (this.NonStopFil && this.OneStopFil && this.OnePlusFil) {
          result = result.filter(
            (v) =>
              v.Flight1JourneyTime.Stops === 0 ||
              v.Flight2JourneyTime.Stops === 0 ||
              v.Flight3JourneyTime.Stops === 0 ||
              v.Flight4JourneyTime.Stops === 0 ||
              v.Flight1JourneyTime.Stops === 1 ||
              v.Flight2JourneyTime.Stops === 1 ||
              v.Flight3JourneyTime.Stops === 1 ||
              v.Flight4JourneyTime.Stops === 1 ||
              v.Flight1JourneyTime.Stops > 1 ||
              v.Flight2JourneyTime.Stops > 1 ||
              v.Flight3JourneyTime.Stops > 1 ||
              v.Flight4JourneyTime.Stops > 1
          );
        } else {
          // Non Stop and One Stop filter
          if (this.NonStopFil && this.OneStopFil) {
            result = result.filter(
              (v) =>
                v.Flight1JourneyTime.Stops === 0 ||
                v.Flight2JourneyTime.Stops === 0 ||
                v.Flight3JourneyTime.Stops === 0 ||
                v.Flight4JourneyTime.Stops === 0 ||
                v.Flight1JourneyTime.Stops === 1 ||
                v.Flight2JourneyTime.Stops === 1 ||
                v.Flight3JourneyTime.Stops === 1 ||
                v.Flight4JourneyTime.Stops === 1
            );
          } else {
            // Non Stop and One Plus filter
            if (this.NonStopFil && this.OnePlusFil) {
              result = result.filter(
                (v) =>
                  v.Flight1JourneyTime.Stops === 0 ||
                  v.Flight2JourneyTime.Stops === 0 ||
                  v.Flight3JourneyTime.Stops === 0 ||
                  v.Flight4JourneyTime.Stops === 0 ||
                  v.Flight1JourneyTime.Stops > 1 ||
                  v.Flight2JourneyTime.Stops > 1 ||
                  v.Flight3JourneyTime.Stops > 1 ||
                  v.Flight4JourneyTime.Stops > 1
              );
            } else {
              // One Stop and One Plus filter
              if (this.OneStopFil && this.OnePlusFil) {
                result = result.filter(
                  (v) =>
                    v.Flight1JourneyTime.Stops === 1 ||
                    v.Flight2JourneyTime.Stops === 1 ||
                    v.Flight3JourneyTime.Stops === 1 ||
                    v.Flight4JourneyTime.Stops === 1 ||
                    v.Flight1JourneyTime.Stops > 1 ||
                    v.Flight2JourneyTime.Stops > 1 ||
                    v.Flight3JourneyTime.Stops > 1 ||
                    v.Flight4JourneyTime.Stops > 1
                );
              } else {
                if (this.NonStopFil) {
                  result = result.filter((v) => {
                    if (v.Flight4JourneyTime) {
                      return (
                        v.Flight1JourneyTime.Stops === 0 ||
                        v.Flight2JourneyTime.Stops === 0 ||
                        v.Flight3JourneyTime.Stops === 0 ||
                        v.Flight4JourneyTime.Stops === 0
                      );
                    } else if (
                      v.Flight1JourneyTime &&
                      v.Flight2JourneyTime &&
                      v.Flight3JourneyTime
                    ) {
                      return (
                        v.Flight1JourneyTime.Stops === 0 ||
                        v.Flight2JourneyTime.Stops === 0 ||
                        v.Flight3JourneyTime.Stops === 0
                      );
                    } else if (v.Flight1JourneyTime && v.Flight2JourneyTime) {
                      return (
                        v.Flight1JourneyTime.Stops === 0 ||
                        v.Flight2JourneyTime.Stops === 0
                      );
                    }
                  });
                }
                if (this.OneStopFil) {
                  result = result.filter((v) => {
                    if (v.Flight4JourneyTime) {
                      return (
                        v.Flight1JourneyTime.Stops === 1 ||
                        v.Flight2JourneyTime.Stops === 1 ||
                        v.Flight3JourneyTime.Stops === 1 ||
                        v.Flight4JourneyTime.Stops === 1
                      );
                    } else if (
                      v.Flight1JourneyTime &&
                      v.Flight2JourneyTime &&
                      v.Flight3JourneyTime
                    ) {
                      return (
                        v.Flight1JourneyTime.Stops === 1 ||
                        v.Flight2JourneyTime.Stops === 1 ||
                        v.Flight3JourneyTime.Stops === 1
                      );
                    } else if (v.Flight1JourneyTime && v.Flight2JourneyTime) {
                      return (
                        v.Flight1JourneyTime.Stops === 1 ||
                        v.Flight2JourneyTime.Stops === 1
                      );
                    }
                  });
                }
                if (this.OnePlusFil) {
                  result = result.filter((v) => {
                    if (v.Flight4JourneyTime) {
                      return (
                        v.Flight1JourneyTime.Stops > 1 ||
                        v.Flight2JourneyTime.Stops > 1 ||
                        v.Flight3JourneyTime.Stops > 1 ||
                        v.Flight4JourneyTime.Stops > 1
                      );
                    } else if (
                      v.Flight1JourneyTime &&
                      v.Flight2JourneyTime &&
                      v.Flight3JourneyTime
                    ) {
                      return (
                        v.Flight1JourneyTime.Stops > 1 ||
                        v.Flight2JourneyTime.Stops > 1 ||
                        v.Flight3JourneyTime.Stops > 1
                      );
                    } else if (v.Flight1JourneyTime && v.Flight2JourneyTime) {
                      return (
                        v.Flight1JourneyTime.Stops > 1 ||
                        v.Flight2JourneyTime.Stops > 1
                      );
                    }
                  });
                }
              }
            }
          }
        }
      }

      if (this.changevalue && this.changevalue.length === 2) {
        const [minValue, maxValue] = this.changevalue;
        result = result.filter((item) => {
          const price = parseFloat(item.perperson);
          return price >= parseFloat(minValue) && price <= parseFloat(maxValue);
        });
      }

      if (this.selectedFromAirports) {
        result = result.filter((flight) =>
          this.selectedFromAirports.includes(
            flight.Depature[0].Departure.AirportName
          )
        );
        // console.log(result, "resultttt...1");
      }
      if (this.selectedToAirports) {
        result = result.filter((flight) =>
          this.selectedToAirports.includes(
            flight.Depature[flight.Depature.length - 1].Arrival.AirportName
          )
        );
        // console.log(result, "resultttt...2");
      }

      if (this.multicityData.length > 0) {
        if (this.multiFromAirports) {
          result = result.filter(
            (flight) =>
              this.multiFromAirports.includes(
                flight.Flight1[0].Departure.AirportName
              ) ||
              this.multiFromAirports.includes(
                flight.Flight2[0].Departure.AirportName
              ) ||
              this.multiFromAirports.includes(
                flight.Flight3[0].Departure.AirportName
              ) ||
              this.multiFromAirports.includes(
                flight.Flight4[0].Departure.AirportName
              )
          );
        }
        if (this.multiToAirports) {
          result = result.filter(
            (flight) =>
              this.multiToAirports.includes(
                flight.Flight1[flight.Flight1.length - 1].Arrival.AirportName
              ) ||
              this.multiToAirports.includes(
                flight.Flight2[flight.Flight2.length - 1].Arrival.AirportName
              ) ||
              this.multiToAirports.includes(
                flight.Flight3[flight.Flight3.length - 1].Arrival.AirportName
              ) ||
              this.multiToAirports.includes(
                flight.Flight4[flight.Flight4.length - 1].Arrival.AirportName
              )
          );
        }
      }

      if (this.rountresult || this.onewayfilterData) {
        if (this.defaultOutBoundTime) {
          let compare = parseInt(this.defaultOutBoundTime.split(" ")[0]);
          result = result.filter((v) => {
            if (v.Depaturejourney.Time) {
              let timeParts = v.Depaturejourney.Time.split(" ");
              let hours = parseInt(timeParts[0]);
              let minutes = parseInt(timeParts[2]);
              let roundedHours = Math.round(hours + minutes / 60);
              return roundedHours <= compare;
            }
            return false;
          });
          //  console.log(result,"resulttt.....Hours1")
        }
      }
      if (this.rountresult.length) {
        if (this.defaultInBoundTime) {
          // console.log(this.defaultInBoundTime,'defaultttt')
          let compare = parseInt(this.defaultInBoundTime.split(" ")[0]);
          result = result.filter((v) => {
            if (v.Returnjourney.Time) {
              let timeParts = v.Returnjourney.Time.split(" ");
              let hours = parseInt(timeParts[0]);
              let minutes = parseInt(timeParts[2]);

              let roundedHours = Math.round(hours + minutes / 60);
              return roundedHours <= compare;
            }
            return false;
          });
          // console.log(result,"resuttt.....Hours2")
        }
      }
      if (this.multiFilterData.length > 0) {
        if (this.defaultOutBoundMulti) {
          let compare = parseInt(this.defaultOutBoundMulti.split(" ")[0]);

          result = result.filter((v) => {
            const checkTime = (flight) => {
              if (flight && flight.Time) {
                let timeParts = flight.Time.split(" ");
                let hours = parseInt(timeParts[0]);
                let minutes = parseInt(timeParts[2]);
                let roundedHours = Math.round(hours + minutes / 60);
                return roundedHours <= compare;
              }
              return false;
            };

            return (
              checkTime(v.Flight1JourneyTime) ||
              checkTime(v.Flight2JourneyTime) ||
              checkTime(v.Flight3JourneyTime) ||
              checkTime(v.Flight4JourneyTime)
            );
          });
        }
      }

      if (this.rountresult || this.onewayresulted) {
        if (
          this.timeFilterData1 &&
          this.timeFilterData2 &&
          this.timeFilterData3 &&
          this.timeFilterData4
        ) {
          result = result.filter(
            (flight) =>
              this.filterByTimeRange(flight, this.morningRange) ||
              this.filterByTimeRange(flight, this.afternoonRange) ||
              this.filterByTimeRange(flight, this.eveningRange) ||
              this.filterByTimeRange(flight, this.beforeMorningRange)
          );
        } else if (
          (this.timeFilterData1 && this.timeFilterData2) ||
          (this.timeFilterData1 && this.timeFilterData4) ||
          (this.timeFilterData1 && this.timeFilterData3) ||
          (this.timeFilterData2 && this.timeFilterData4) ||
          (this.timeFilterData2 && this.timeFilterData3) ||
          (this.timeFilterData3 && this.timeFilterData4)
        ) {
          result = result.filter((flight) => {
            const timeFilters = [];
            if (this.timeFilterData1) timeFilters.push(this.morningRange);
            if (this.timeFilterData2) timeFilters.push(this.afternoonRange);
            if (this.timeFilterData3) timeFilters.push(this.eveningRange);
            if (this.timeFilterData4) timeFilters.push(this.beforeMorningRange);
            return timeFilters.some((range) =>
              this.filterByTimeRange(flight, range)
            );
          });
        } else {
          if (this.timeFilterData1) {
            result = result.filter((flight) =>
              this.filterByTimeRange(flight, this.morningRange)
            );
          } else if (this.timeFilterData2) {
            result = result.filter((flight) =>
              this.filterByTimeRange(flight, this.afternoonRange)
            );
          } else if (this.timeFilterData3) {
            result = result.filter((flight) =>
              this.filterByTimeRange(flight, this.eveningRange)
            );
          } else if (this.timeFilterData4) {
            result = result.filter((flight) =>
              this.filterByTimeRange(flight, this.beforeMorningRange)
            );
          }
        }

        // arrival time filters
        if (
          this.timeFilterArrData1 &&
          this.timeFilterArrData2 &&
          this.timeFilterArrData3 &&
          this.timeFilterArrData4
        ) {
          result = result.filter(
            (flight) =>
              this.filterByTimeRange1(flight, this.morningRange) ||
              this.filterByTimeRange1(flight, this.afternoonRange) ||
              this.filterByTimeRange1(flight, this.eveningRange) ||
              this.filterByTimeRange1(flight, this.beforeMorningRange)
          );
        } else if (
          (this.timeFilterArrData1 && this.timeFilterArrData2) ||
          (this.timeFilterArrData1 && this.timeFilterArrData4) ||
          (this.timeFilterArrData1 && this.timeFilterArrData3) ||
          (this.timeFilterArrData2 && this.timeFilterArrData4) ||
          (this.timeFilterArrData2 && this.timeFilterArrData3) ||
          (this.timeFilterArrData3 && this.timeFilterArrData4)
        ) {
          result = result.filter((flight) => {
            const timeFilters = [];
            if (this.timeFilterArrData1) timeFilters.push(this.morningRange);
            if (this.timeFilterArrData2) timeFilters.push(this.afternoonRange);
            if (this.timeFilterArrData3) timeFilters.push(this.eveningRange);
            if (this.timeFilterArrData4)
              timeFilters.push(this.beforeMorningRange);

            return timeFilters.some((range) =>
              this.filterByTimeRange1(flight, range)
            );
          });
        } else {
          if (this.timeFilterArrData1) {
            result = result.filter((flight) =>
              this.filterByTimeRange1(flight, this.morningRange)
            );
          } else if (this.timeFilterArrData2) {
            result = result.filter((flight) =>
              this.filterByTimeRange1(flight, this.afternoonRange)
            );
          } else if (this.timeFilterArrData3) {
            result = result.filter((flight) =>
              this.filterByTimeRange1(flight, this.eveningRange)
            );
          } else if (this.timeFilterArrData4) {
            result = result.filter((flight) =>
              this.filterByTimeRange1(flight, this.beforeMorningRange)
            );
          }
        }

        //Return
        if (
          this.timearrFilterData1 &&
          this.timearrFilterData2 &&
          this.timearrFilterData3 &&
          this.timearrFilterData4
        ) {
          result = result.filter(
            (flight) =>
              this.filterByTimeRangeRet(flight, this.morningRange) ||
              this.filterByTimeRangeRet(flight, this.afternoonRange) ||
              this.filterByTimeRangeRet(flight, this.eveningRange) ||
              this.filterByTimeRangeRet(flight, this.beforeMorningRange)
          );
        } else if (
          (this.timearrFilterData1 && this.timearrFilterData2) ||
          (this.timearrFilterData1 && this.timearrFilterData4) ||
          (this.timearrFilterData1 && this.timearrFilterData3) ||
          (this.timearrFilterData2 && this.timearrFilterData4) ||
          (this.timearrFilterData2 && this.timearrFilterData3) ||
          (this.timearrFilterData3 && this.timearrFilterData4)
        ) {
          result = result.filter((flight) => {
            const timeFilters = [];
            if (this.timearrFilterData1) timeFilters.push(this.morningRange);
            if (this.timearrFilterData2) timeFilters.push(this.afternoonRange);
            if (this.timearrFilterData3) timeFilters.push(this.eveningRange);
            if (this.timearrFilterData4)
              timeFilters.push(this.beforeMorningRange);

            return timeFilters.some((range) =>
              this.filterByTimeRangeRet(flight, range)
            );
          });
        } else {
          if (this.timearrFilterData1) {
            result = result.filter((flight) =>
              this.filterByTimeRangeRet(flight, this.morningRange)
            );
          }
          if (this.timearrFilterData2) {
            result = result.filter((flight) =>
              this.filterByTimeRangeRet(flight, this.afternoonRange)
            );
          }
          if (this.timearrFilterData3) {
            result = result.filter((flight) =>
              this.filterByTimeRangeRet(flight, this.eveningRange)
            );
          }
          if (this.timearrFilterData4) {
            result = result.filter((flight) =>
              this.filterByTimeRangeRet(flight, this.beforeMorningRange)
            );
          }
        }

        if (
          this.timearrFilterArrData1 &&
          this.timearrFilterArrData2 &&
          this.timearrFilterArrData3 &&
          this.timearrFilterArrData4
        ) {
          result = result.filter(
            (flight) =>
              this.filterByTimeRangeRet1(flight, this.morningRange) ||
              this.filterByTimeRangeRet1(flight, this.afternoonRange) ||
              this.filterByTimeRangeRet1(flight, this.eveningRange) ||
              this.filterByTimeRangeRet1(flight, this.beforeMorningRange)
          );
        } else if (
          (this.timearrFilterArrData1 && this.timearrFilterArrData2) ||
          (this.timearrFilterArrData1 && this.timearrFilterArrData4) ||
          (this.timearrFilterArrData1 && this.timearrFilterArrData3) ||
          (this.timearrFilterArrData2 && this.timearrFilterArrData4) ||
          (this.timearrFilterArrData2 && this.timearrFilterArrData3) ||
          (this.timearrFilterArrData3 && this.timearrFilterArrData4)
        ) {
          result = result.filter((flight) => {
            const timeFilters = [];
            if (this.timearrFilterArrData1) timeFilters.push(this.morningRange);
            if (this.timearrFilterArrData2)
              timeFilters.push(this.afternoonRange);
            if (this.timearrFilterArrData3) timeFilters.push(this.eveningRange);
            if (this.timearrFilterArrData4)
              timeFilters.push(this.beforeMorningRange);

            return timeFilters.some((range) =>
              this.filterByTimeRangeRet1(flight, range)
            );
          });
        } else {
          if (this.timearrFilterArrData1) {
            result = result.filter((flight) =>
              this.filterByTimeRangeRet1(flight, this.morningRange)
            );
          }
          if (this.timearrFilterArrData2) {
            result = result.filter((flight) =>
              this.filterByTimeRangeRet1(flight, this.afternoonRange)
            );
          }
          if (this.timearrFilterArrData3) {
            result = result.filter((flight) =>
              this.filterByTimeRangeRet1(flight, this.eveningRange)
            );
          }
          if (this.timearrFilterArrData4) {
            result = result.filter((flight) =>
              this.filterByTimeRangeRet1(flight, this.beforeMorningRange)
            );
          }
        }
      }
      if (this.multicityData) {
        console.log("dataaaa...1");
        if (
          this.timeMulti1 &&
          this.timeMulti2 &&
          this.timeMulti3 &&
          this.timeMulti4
        ) {
          result = result.filter(
            (flight) =>
              this.filterTimeRangeMulti(flight, this.morningRange) ||
              this.filterTimeRangeMulti(flight, this.afternoonRange) ||
              this.filterTimeRangeMulti(flight, this.eveningRange) ||
              this.filterTimeRangeMulti(flight, this.beforeMorningRange)
          );
        } else if (
          (this.timeMulti1 && this.timeMulti2) ||
          (this.timeMulti1 && this.timeMulti4) ||
          (this.timeMulti1 && this.timeMulti3) ||
          (this.timeMulti2 && this.timeMulti4) ||
          (this.timeMulti2 && this.timeMulti3) ||
          (this.timeMulti3 && this.timeMulti4)
        ) {
          console.log("dataaaa...2");
          result = result.filter((flight) => {
            const timeFilters = [];
            if (this.timeMulti1) timeFilters.push(this.morningRange);
            if (this.timeMulti2) timeFilters.push(this.afternoonRange);
            if (this.timeMulti3) timeFilters.push(this.eveningRange);
            if (this.timeMulti4) timeFilters.push(this.beforeMorningRange);
            return timeFilters.some((range) =>
              this.filterTimeRangeMulti(flight, range)
            );
          });
        } else {
          if (this.timeMulti4) {
            result = result.filter((flight) =>
              this.filterTimeRangeMulti(flight, this.beforeMorningRange)
            );
          }
          if (this.timeMulti1) {
            result = result.filter((flight) =>
              this.filterTimeRangeMulti(flight, this.morningRange)
            );
          }
          if (this.timeMulti2) {
            result = result.filter((flight) =>
              this.filterTimeRangeMulti(flight, this.afternoonRange)
            );
          }
          if (this.timeMulti3) {
            result = result.filter((flight) =>
              this.filterTimeRangeMulti(flight, this.eveningRange)
            );
          }
        }
        // console.log(result, "resulttMultii");
        if (
          this.timeMultiArr1 &&
          this.timeMultiArr2 &&
          this.timeMultiArr3 &&
          this.timeMultiArr4
        ) {
          result = result.filter(
            (flight) =>
              this.filterTimeMultiArr(flight, this.morningRange) ||
              this.filterTimeMultiArr(flight, this.afternoonRange) ||
              this.filterTimeMultiArr(flight, this.eveningRange) ||
              this.filterTimeMultiArr(flight, this.beforeMorningRange)
          );
        } else if (
          (this.timeMultiArr1 && this.timeMultiArr2) ||
          (this.timeMultiArr1 && this.timeMultiArr3) ||
          (this.timeMultiArr2 && this.timeMultiArr3) ||
          (this.timeMultiArr1 && this.timeMultiArr4) ||
          (this.timeMultiArr2 && this.timeMultiArr4) ||
          (this.timeMultiArr3 && this.timeMultiArr4)
        ) {
          result = result.filter((flight) => {
            const timeFilters = [];
            if (this.timeMultiArr1) timeFilters.push(this.morningRange);
            if (this.timeMultiArr2) timeFilters.push(this.afternoonRange);
            if (this.timeMultiArr3) timeFilters.push(this.eveningRange);
            if (this.timeMultiArr3) timeFilters.push(this.beforeMorningRange);

            return timeFilters.some((range) =>
              this.filterTimeMultiArr(flight, range)
            );
          });
        } else {
          if (this.timeMultiArr1) {
            result = result.filter((flight) =>
              this.filterTimeMultiArr(flight, this.morningRange)
            );
          }
          if (this.timeMultiArr2) {
            result = result.filter((flight) =>
              this.filterTimeMultiArr(flight, this.afternoonRange)
            );
          }
          if (this.timeMultiArr3) {
            result = result.filter((flight) =>
              this.filterTimeMultiArr(flight, this.eveningRange)
            );
          }
          if (this.timeMultiArr4) {
            result = result.filter((flight) =>
              this.filterTimeMultiArr(flight, this.beforeMorningRange)
            );
          }
        }
      }

      if (this.airlineList) {
        const activeAirlines = this.airlineList
          .filter((airline) => airline.active)
          .map((airline) => airline.airline);

        // result = result.filter(flight => activeAirlines.includes(flight.ownername));
        if (this.rountresult.length > 0) {
          result = result.filter((flight) => {
            const ownernameMatch = activeAirlines.includes(flight.ownername);

            const marketingCarrierMatch = flight.Depature.some((dep) =>
              activeAirlines.includes(dep.MarketingCarrier.Name)
            );
            const marketingCarrierMatch1 = flight.Return.some((dep) =>
              activeAirlines.includes(dep.MarketingCarrier.Name)
            );

            return (
              ownernameMatch || marketingCarrierMatch || marketingCarrierMatch1
            );
          });
        } else if (this.onewayresulted.length > 0) {
          result = result.filter((flight) => {
            const ownernameMatch = activeAirlines.includes(flight.ownername);

            const marketingCarrierMatch = flight.Depature.some((dep) =>
              activeAirlines.includes(dep.MarketingCarrier.Name)
            );

            return ownernameMatch || marketingCarrierMatch;
          });
        } else if (this.multicityData.length > 0) {
          result = result.filter((flight) => {
            const ownernameMatch = activeAirlines.includes(flight.ownername);
            if (
              flight.Flight1 &&
              flight.Flight2 &&
              flight.Flight3 &&
              flight.Flight4
            ) {
              const marketingFlight1 = flight.Flight1.some((dep) =>
                activeAirlines.includes(dep.MarketingCarrier.Name)
              );
              const marketingFlight2 = flight.Flight2.some((dep) =>
                activeAirlines.includes(dep.MarketingCarrier.Name)
              );
              const marketingFlight3 = flight.Flight3.some((dep) =>
                activeAirlines.includes(dep.MarketingCarrier.Name)
              );
              const marketingFlight4 = flight.Flight4.some((dep) =>
                activeAirlines.includes(dep.MarketingCarrier.Name)
              );

              return (
                ownernameMatch ||
                marketingFlight1 ||
                marketingFlight2 ||
                marketingFlight3 ||
                marketingFlight4
              );
            } else if (flight.Flight1 && flight.Flight2 && flight.Flight3) {
              const marketingFlight1 = flight.Flight1.some((dep) =>
                activeAirlines.includes(dep.MarketingCarrier.Name)
              );
              const marketingFlight2 = flight.Flight2.some((dep) =>
                activeAirlines.includes(dep.MarketingCarrier.Name)
              );
              const marketingFlight3 = flight.Flight3.some((dep) =>
                activeAirlines.includes(dep.MarketingCarrier.Name)
              );

              return (
                ownernameMatch ||
                marketingFlight1 ||
                marketingFlight2 ||
                marketingFlight3
              );
            } else {
              const marketingCarrierMatch = flight.Flight1.some((dep) =>
                activeAirlines.includes(dep.MarketingCarrier.Name)
              );
              const marketingCarrierMatch1 = flight.Flight2.some((dep) =>
                activeAirlines.includes(dep.MarketingCarrier.Name)
              );

              return (
                ownernameMatch ||
                marketingCarrierMatch ||
                marketingCarrierMatch1
              );
            }
          });
        }

        // console.log(result, "resulttttMultti");
      }
      if (this.rountresult.length > 0) {
        this.hasNonStop = result.some(
          (flight) =>
            flight.Depaturejourney.Stops === 0 ||
            flight.Returnjourney.Stops === 0
        );
        this.hasOneStop = result.some(
          (flight) =>
            flight.Depaturejourney.Stops === 1 ||
            flight.Returnjourney.Stops === 1
        );
        this.hasOnePlusStop = result.some(
          (flight) =>
            flight.Depaturejourney.Stops > 1 || flight.Returnjourney.Stops > 1
        );
      } else if (this.onewayresulted.length > 0) {
        this.hasNonStop = result.some(
          (flight) => flight.Depaturejourney.Stops === 0
        );
        this.hasOneStop = result.some(
          (flight) => flight.Depaturejourney.Stops === 1
        );
        this.hasOnePlusStop = result.some(
          (flight) => flight.Depaturejourney.Stops > 1
        );
      } else if (this.multiFilterData.length > 0) {
        this.multiFilterData.forEach((v) => {
          if (v.Flight4) {
            this.hasNonStop = result.some(
              (flight) =>
                flight.Flight1JourneyTime.Stops === 0 ||
                flight.Flight2JourneyTime.Stops === 0 ||
                flight.Flight3JourneyTime.Stops === 0 ||
                flight.Flight4JourneyTime.Stops === 0
            );
            this.hasOneStop = result.some(
              (flight) =>
                flight.Flight1JourneyTime.Stops === 1 ||
                flight.Flight2JourneyTime.Stops === 1 ||
                flight.Flight3JourneyTime.Stops === 1 ||
                flight.Flight4JourneyTime.Stops === 1
            );
            this.hasOnePlusStop = result.some(
              (flight) =>
                flight.Flight1JourneyTime.Stops > 1 ||
                flight.Flight2JourneyTime.Stops > 1 ||
                flight.Flight3JourneyTime.Stops > 1 ||
                flight.Flight4JourneyTime.Stops > 1
            );
          } else if (v.Flight1 && v.Flight2 && v.Flight3) {
            this.hasNonStop = result.some(
              (flight) =>
                flight.Flight1JourneyTime.Stops === 0 ||
                flight.Flight2JourneyTime.Stops === 0 ||
                flight.Flight3JourneyTime.Stops === 0
            );
            this.hasOneStop = result.some(
              (flight) =>
                flight.Flight1JourneyTime.Stops === 1 ||
                flight.Flight2JourneyTime.Stops === 1 ||
                flight.Flight3JourneyTime.Stops === 1
            );
            this.hasOnePlusStop = result.some(
              (flight) =>
                flight.Flight1JourneyTime.Stops > 1 ||
                flight.Flight2JourneyTime.Stops > 1 ||
                flight.Flight3JourneyTime.Stops > 1
            );
          } else {
            this.hasNonStop = result.some(
              (flight) =>
                flight.Flight1JourneyTime.Stops === 0 ||
                flight.Flight2JourneyTime.Stops === 0
            );
            this.hasOneStop = result.some(
              (flight) =>
                flight.Flight1JourneyTime.Stops === 1 ||
                flight.Flight2JourneyTime.Stops === 1
            );
            this.hasOnePlusStop = result.some(
              (flight) =>
                flight.Flight1JourneyTime.Stops > 1 ||
                flight.Flight2JourneyTime.Stops > 1
            );
          }
        });
      }
      this.hasRefundable = result.some(
        (flight) => flight.Refund === "Refundable"
      );
      this.hasNonRefundable = result.some(
        (flight) => flight.Refund === "Non Refundable"
      );

      // console.log(result, "resulttttMultti..1");
      this.hasMorningFlights =
        !this.timeFilterData1 ||
        result.some((flight) =>
          this.filterByTimeRange(flight, this.morningRange)
        );
      this.hasAfternoonFlights =
        !this.timeFilterData2 ||
        result.some((flight) =>
          this.filterByTimeRange(flight, this.afternoonRange)
        );
      this.hasEveningFlights =
        !this.timeFilterData3 ||
        result.some((flight) =>
          this.filterByTimeRange(flight, this.eveningRange)
        );

      this.hasMorningFlights1 =
        !this.timeFilterArrData1 ||
        result.some((flight) =>
          this.filterByTimeRange1(flight, this.morningRange)
        );
      this.hasAfternoonFlights1 =
        !this.timeFilterArrData2 ||
        result.some((flight) =>
          this.filterByTimeRange1(flight, this.afternoonRange)
        );
      this.hasEveningFlights1 =
        !this.timeFilterArrData3 ||
        result.some((flight) =>
          this.filterByTimeRange1(flight, this.eveningRange)
        );

      this.hasMorningReturn =
        !this.timearrFilterData1 ||
        result.some((flight) =>
          this.filterByTimeRangeRet(flight, this.morningRange)
        );
      this.hasAfternoonReturn =
        !this.timearrFilterData2 ||
        result.some((flight) =>
          this.filterByTimeRangeRet(flight, this.afternoonRange)
        );
      this.hasEveningReturn =
        !this.timearrFilterData3 ||
        result.some((flight) =>
          this.filterByTimeRangeRet(flight, this.eveningRange)
        );

      this.hasMorningReturn1 =
        !this.timearrFilterArrData1 ||
        result.some((flight) =>
          this.filterByTimeRangeRet1(flight, this.morningRange)
        );
      this.hasAfternoonReturn1 =
        !this.timearrFilterArrData2 ||
        result.some((flight) =>
          this.filterByTimeRangeRet1(flight, this.afternoonRange)
        );
      this.hasEveningReturn1 =
        !this.timearrFilterArrData3 ||
        result.some((flight) =>
          this.filterByTimeRangeRet1(flight, this.eveningRange)
        );

      // this.disableData = !this.hasMorningFlights || !this.hasAfternoonFlights || !this.hasEveningFlights ||!this.hasMorningFlights1 || !this.hasAfternoonFlights1 || !this.hasEveningFlights1 || !this.hasMorningReturn || !this.hasAfternoonReturn || !this.hasEveningReturn || !this.hasMorningReturn1 || !this.hasAfternoonReturn1 || !this.hasEveningReturn1 || result.length == 0

      // console.log(this.hasMorningFlights, "filterFlights1");
      // console.log(this.hasAfternoonFlights, "filterFlights2");
      // console.log(this.hasEveningFlights, "filterFlights3");

      if (this.filterData.length > 0) {
        this.rountresult = result;

        this.disableData = this.rountresult.length == 0;
        if (this.disableData) {
          this.hasRefundable = this.filterData.some(
            (flight) => flight.Refund === "Refundable"
          );
          this.hasNonRefundable = this.filterData.some(
            (flight) => flight.Refund === "Non Refundable"
          );
          this.hasNonStop = this.filterData.some(
            (flight) =>
              flight.Depaturejourney.Stops === 0 ||
              flight.Returnjourney.Stops === 0
          );
          this.hasOneStop = this.filterData.some(
            (flight) =>
              flight.Depaturejourney.Stops === 1 ||
              flight.Returnjourney.Stops === 1
          );
          this.hasOnePlusStop = this.filterData.some(
            (flight) =>
              flight.Depaturejourney.Stops > 1 || flight.Returnjourney.Stops > 1
          );
        }
      }
      if (this.onewayfilterData.length > 0) {
        this.onewayresulted = result;
        this.disableData = this.onewayresulted.length == 0;
        if (
          (this.hasMorningFlights ||
            this.hasAfternoonFlights ||
            this.hasEveningFlights ||
            this.hasMorningFlights1 ||
            this.hasAfternoonFlights1 ||
            this.hasEveningFlights1) &&
          this.disableData
        ) {
          this.hasRefundable = this.onewayfilterData.some(
            (flight) => flight.Refund === "Refundable"
          );
          this.hasNonRefundable = this.onewayfilterData.some(
            (flight) => flight.Refund === "Non Refundable"
          );
          this.hasNonStop = this.onewayfilterData.some(
            (flight) => flight.Depaturejourney.Stops === 0
          );
          this.hasOneStop = this.onewayfilterData.some(
            (flight) => flight.Depaturejourney.Stops === 1
          );
          this.hasOnePlusStop = this.onewayfilterData.some(
            (flight) => flight.Depaturejourney.Stops > 1
          );
        }
      }
      if (this.multiFilterData.length > 0) {
        this.multicityData = result;
        // console.log(result, "resulttttMult");
        this.disableData = this.multicityData.length == 0;
        if (this.disableData) {
          this.hasRefundable = this.multiFilterData.some(
            (flight) => flight.Refund === "Refundable"
          );
          this.hasNonRefundable = this.multiFilterData.some(
            (flight) => flight.Refund === "Non Refundable"
          );
          this.multiFilterData.forEach((v) => {
            if (v.Flight4) {
              this.hasNonStop = this.multiFilterData.some(
                (flight) =>
                  flight.Flight1JourneyTime.Stops === 0 ||
                  flight.Flight2JourneyTime.Stops === 0 ||
                  flight.Flight3JourneyTime.Stops === 0 ||
                  flight.Flight4JourneyTime.Stops === 0
              );
              this.hasOneStop = this.multiFilterData.some(
                (flight) =>
                  flight.Flight1JourneyTime.Stops === 1 ||
                  flight.Flight2JourneyTime.Stops === 1 ||
                  flight.Flight3JourneyTime.Stops === 1 ||
                  flight.Flight4JourneyTime.Stops === 1
              );
              this.hasOnePlusStop = this.multiFilterData.some(
                (flight) =>
                  flight.Flight1JourneyTime.Stops > 1 ||
                  flight.Flight2JourneyTime.Stops > 1 ||
                  flight.Flight3JourneyTime.Stops > 1 ||
                  flight.Flight4JourneyTime.Stops > 1
              );
            } else if (v.Flight1 && v.Flight2 && v.Flight3) {
              this.hasNonStop = this.multiFilterData.some(
                (flight) =>
                  flight.Flight1JourneyTime.Stops === 0 ||
                  flight.Flight2JourneyTime.Stops === 0 ||
                  flight.Flight3JourneyTime.Stops === 0
              );
              this.hasOneStop = this.multiFilterData.some(
                (flight) =>
                  flight.Flight1JourneyTime.Stops === 1 ||
                  flight.Flight2JourneyTime.Stops === 1 ||
                  flight.Flight3JourneyTime.Stops === 1
              );
              this.hasOnePlusStop = this.multiFilterData.some(
                (flight) =>
                  flight.Flight1JourneyTime.Stops > 1 ||
                  flight.Flight2JourneyTime.Stops > 1 ||
                  flight.Flight3JourneyTime.Stops > 1
              );
            } else {
              this.hasNonStop = this.multiFilterData.some(
                (flight) =>
                  flight.Flight1JourneyTime.Stops === 0 ||
                  flight.Flight2JourneyTime.Stops === 0
              );
              this.hasOneStop = this.multiFilterData.some(
                (flight) =>
                  flight.Flight1JourneyTime.Stops === 1 ||
                  flight.Flight2JourneyTime.Stops === 1
              );
              this.hasOnePlusStop = this.multiFilterData.some(
                (flight) =>
                  flight.Flight1JourneyTime.Stops > 1 ||
                  flight.Flight2JourneyTime.Stops > 1
              );
            }
          });
        }
        // console.log(this.multicityData, "multiiiiii");
      }
      // this.rountresult = result;
    },

    getMultiAdult(data) {
      // console.log(data, 'lkllklklklk...22..2.2.2')
      if (data.adult > 0) {
        return data.adult;
      } else {
        return "";
      }
    },
    getMultiChild(data) {
      if (data.child > 0) {
        return data.child;
      } else {
        return "";
      }
    },
    getMultiInfrant(data) {
      if (data.infrant > 0) {
        return data.infrant;
      } else {
        return "";
      }
    },

    async typeMultiCity(event, index, $num) {
      console.log(event, index, $num, "searchsearchsearchsearch");
      // let $event = [];
      console.log(event, index, "searchsearchsearchsearch");

      if (event.length >= 3) {
        if ($num == 1 && event.length > 2) {
          this.multi_city.itinery.map((v, i) => {
            v.autocomTo = false;
            v.fromloader = true;
            if (i == index && event.length > 2) {
              v.autocomfrom = true;
            } else {
              v.autocomfrom = false;
              v.fromloader = false;
              this.multidrop = [];
            }
          });
        } else if ($num == 2 && event.length > 0) {
          this.multi_city.itinery.map((v, i) => {
            v.autocomfrom = false;
            if (i == index && event.length > 2) {
              v.autocomTo = true;
              v.toloader = true;
            } else {
              v.autocomTo = false;
              v.toloader = false;
              this.multidrop1 = [];
            }
          });

          // for (let i = 1; i < this.multiData.itinery.length; i++) {
          //   this.multiData.itinery[i].from = this.multiData.itinery[i - 1].to;
          // }
        }

        await axios
          .get(`${this.autocompleteApi}?airportString=${event}`, {
            headers: {
              "Content-Type": this.airlineIcaoType,
            },
          })
          .then((response) => {
            console.log(response.data, "responsedata");

            if ($num == 1) {
              this.multi_city.itinery.map((v) => {
                v.fromloader = false;
              });

              this.multidrop = response.data;
              console.log(this.multidrop, "this.multidrop");
            } else if ($num == 2) {
              this.multi_city.itinery.map((v) => {
                v.toloader = false;
              });

              this.multidrop1 = response.data;
            }
          })

          .catch((error) => {
            console.log(error, "erroroor.....");
          });

        this.maximumletter = false;
        this.maximumletter1 = false;
        this.loading = true;
      }
    },
    datachange(data) {
      console.log(data, "pppp");
      this.oneway.from = `${data.city} (${data.iata})`;
      this.inputCity = this.oneway.from;
      this.typedrop = false;
      const regex = /[-()]/;
      if (regex.test(this.oneway.from)) {
        this.selectedFrom = true;
      }
      if (!this.oneway.to) {
        this.$refs.toAutoComplete.focus();
      } else {
        this.$refs.fromAutoComplete.focus();
      }
    },
    datachangeTo(data) {
      this.oneway.to = `${data.city} (${data.iata})`;
      this.inputCityto = this.oneway.to;
      this.typedrop1 = false;
      const regex = /[-()]/;
      if (regex.test(this.oneway.to)) {
        this.selectedTo = true;
      }
      if (!this.oneway.dedate) {
        this.$refs.dpRef1.openMenu();
      } else if (!this.oneway.to) {
        this.$refs.toAutoComplete.focus();
      }
    },
    datachangeMuti(data, index) {
      console.log(data, index, "data,index1");
      this.multi_city.itinery.map((v, i) => {
        if (i == index) {
          v.from = `${data.city} (${data.iata})`;
          v.autocomfrom = false;
          v.multiFromError = false;
          v.selectedFrom = true;
          if (!v.from) {
            this.$refs.multiFromAutocomplete[index].focus();
          }
          if (!v.to) {
            const inputElement = this.$refs.multiToAutocomplete[index];
            if (inputElement) {
              inputElement.focus();
            }
          } else {
            const inputElement = this.$refs.multiFromAutocomplete[index];
            // console.log(this.$refs.fromAutoComplete);
            if (inputElement) {
              inputElement.blur();
            }
          }
        }
      });
      console.log(this.multi_city, "this.multiDatathis.multiData");
    },
    datachangeMutiTo(data, index) {
      console.log(data, index, "data,index1");
      this.multi_city.itinery.map((v, i) => {
        if (i == index) {
          v.to = `${data.city} (${data.iata})`;
          v.autocomTo = false;
          v.multiToError = false;
          v.selectedTo = true;
          if (!v.to) {
            this.$refs.multiToAutocomplete[index].focus();
          }
          if (!v.departDate) {
            const inputElement = this.$refs.dpRef4[index];
            if (inputElement) {
              inputElement.openMenu();
            } else {
              const inputElement = this.$refs.multiFromAutocomplete[index];
              if (inputElement) {
                inputElement.blur();
              }
            }
          }
          if (this.multi_city.itinery.length - 1 != index) {
            this.multi_city.itinery[i + 1].from = this.multi_city.itinery[i].to;
            this.multi_city.itinery[i + 1].multiFromError = false;
            this.multi_city.itinery[i + 1].selectedFrom = true;
          }
        }
      });

      // for (let i = 1; i < this.multiData.itinery.length; i++) {
      //   this.multiData.itinery[i].from = this.multiData.itinery[i - 1].to;
      // }
      console.log(this.multiData, "this.multiDatathis.multiData");
    },

    async typeCity(event, $data) {
      if (event.length < 3 && $data == 1) {
        this.maximumletter = true;
      } else {
        this.maximumletter = false;
      }
      if (event.length < 3 && $data == 2) {
        this.maximumletter1 = true;
      } else {
        this.maximumletter1 = false;
      }
      if (event.length >= 1) {
        this.formres = false;
        if (event.length >= 1) {
          this.formres1 = false;
        }
      }
      this.city = [];
      this.city1 = [];
      // let $event = [];
      console.log(event, $data, "searchsearchsearchsearch");
      if ($data == 1 && event.length > 0) {
        this.fromloader = true;
      } else {
        this.fromloader = false;
      }

      if ($data == 2 && event.length > 0) {
        this.toloader = true;
      } else {
        this.toloader = false;
      }

      if (event.length >= 3) {
        await axios
          .get(`${this.autocompleteApi}?airportString=${event}`, {
            headers: {
              "Content-Type": this.airlineIcaoType,
            },
          })
          .then((response) => {
            console.log(response, response.data, "responseyyyyyyy");

            if ($data == 1) {
              this.fromloader = false;
              this.city = response.data;
              this.typedrop = true;
            } else if ($data == 2) {
              this.toloader = false;
              this.city1 = response.data;
              this.typedrop1 = true;
            }
          })

          .catch((error) => {
            console.log(error, "erroroor.....");
          });

        this.maximumletter = false;
        this.maximumletter1 = false;
        this.loading = true;
      }
    },
    handleOutsideClick(event) {
      // console.log(event,'handleOutsideClick')
      if (!event.target.closest(".input-styles")) {
        this.typedrop = false;
        this.typedrop1 = false;
        this.formres = false;
        this.formres1 = false;
        this.focusedElement = null;
        this.focusEvent1 = false;
        this.focusEvent2 = false;
        this.focusEvent3 = false;

        this.multi_city.itinery.map((v) => {
          // console.log(v, i, "focusMultiEvent3focusMultiEvent3")
          v.focusMultiEvent1 = false;
          v.focusMultiEvent2 = false;
          v.focusMultiEvent3 = false;
        });
      }

      if (!event.target.closest(".subscribe-inputFields")) {
        this.focusedElement = null;
        this.roundedShadow = false;
      }
    },

    // Form Header section End

    getConfig() {
      // console.log(this.$store.state.data,'homehoemehojoke...')

      // let getConfigData = this.$store.state.data
      // let portalLocal = localStorage.getItem("portalData")

      // let getConfigData1 = JSON.parse(portalLocal)

      const getConfigData1 = getConfigDataFromLocalStorage();

      if (getConfigData1) {
        this.makeSearchUrl =
          getConfigData1.payload.portal_configuration.API_endpoints.search;

        this.airLogoApi =
          getConfigData1.payload.portal_configuration.content_data.airlines_logo;
        if (this.airLogoApi) {
          this.airlineLogo_func();
        }

        this.preferCurrency =
          getConfigData1.payload.portal_configuration.currency.iso_code;
        this.metaCurrency =
          getConfigData1.payload.portal_configuration.currency.iso_code;
        this.autocompleteApi =
          getConfigData1.payload.portal_configuration.API_endpoints.autocomplete;
        this.pointofSale =
          getConfigData1.payload.portal_configuration.pointofsale;

        this.pricingApi =
          getConfigData1.payload.portal_configuration.API_endpoints.pricing ||
          getConfigData1.payload.portal_configuration.API_endpoints
            .pricingconfirmation;

        this.portal_id = getConfigData1.payload.portal_configuration.portal_id;

        this.portal_domain =
          getConfigData1.payload.portal_configuration.portal_url.replace(
            "https://",
            ""
          );

        // console.log(this.makeSearchUrl, 'head...sea..1..')

        // this.getConfigFunc();
      }
    },
    getClassType() {
      return [
        {
          text: this.$t("formsContents.options.economy"),
          value: "Economy",
          department: "Economy Department",
        },
        {
          text: this.$t("formsContents.options.business"),
          value: "Business",
          department: "Business Department",
        },
        {
          text: this.$t("formsContents.options.firstClass"),
          value: "First Class",
          department: "First Class Department",
        },
      ];
    },
    translateRefund(key) {
      // console.log(key,'keykeykeykeykeykeykey')
      if (key == "Refundable") {
        return this.$t("searchPageContent.itinContent.refund");
      } else {
        return this.$t("searchPageContent.itinContent.nonRefund");
      }
    },
    translateClass(classKey) {
      // console.log(classKey,'classKeyclassKeyclassKey')
      if (classKey == "Economy") {
        return this.$t("formsContents.options.economy");
      } else if (classKey == "Business") {
        return this.$t("formsContents.options.business");
      } else if (classKey == "First Class") {
        return this.$t("formsContents.options.firstClass");
      } else if (classKey == "Premium Economy") {
        return this.$t("formsContents.options.premiumEconomy");
      } else {
        return this.$t("formsContents.options.economy");
      }
    },
  },

  // AutoComplete Functions End

  watch: {
    "$i18n.locale": {
      handler: function () {
        this.classTypeitems = this.getClassType();
        this.translateRefund();
        this.translateClass();
      },
      deep: true,
    },
    RefundableData() {
      this.applyFilters();
    },
    nonRefundableData() {
      this.applyFilters();
    },
    NonStopFil() {
      this.applyFilters();
    },
    OneStopFil() {
      this.applyFilters();
    },
    OnePlusFil() {
      this.applyFilters();
    },
    defaultOutBoundTime() {
      this.applyFilters();
    },
    defaultInBoundTime() {
      this.applyFilters();
    },
    selectedFromAirports() {
      this.applyFilters();
    },
    selectedToAirports() {
      this.applyFilters();
    },
    changevalue() {
      this.applyFilters();
    },
    timeFilterData1() {
      this.applyFilters();
    },
    timeFilterData2() {
      this.applyFilters();
    },

    classType(newSelectValue) {
      console.log("select property changed to:", newSelectValue);

      if (newSelectValue) {
        setTimeout(() => {
          const focusDrop = this.$refs.countrySelect;
          if (focusDrop) {
            this.$refs.countrySelect.focus();
          }

          this.classSelect = false;
          console.log(this.classSelect);
        }, 100);
      }
    },
  },

  computed: {
    filteredCountries() {
      return this.airitems.map((item) => {
        const parts = item.places.split(", ");
        if (parts.length >= 2) {
          const name = `${parts[1]} (${parts[0]})`;
          // const name = `${parts[0]} - ${parts[1]}`;
          const originalName = item.places;
          return {
            name,
            originalName,
          };
        }
      });
    },
  },

  created() {
    this.getConfig();

    localStorage.removeItem("rounttrip");
    localStorage.removeItem("onewaytrip");

    // this.red1 = this.$route.query.city;
    this.red1 = this.$route.query.city;
    this.red = this.$route.query.city;

    console.log(this.red, "nandupillai", this.red1, "nandupillai");

    // this.autocompletJson();
    let tripType = this.$route.query.tripType;
    this.red1 = tripType === "RT" ? 1 : tripType === "OW" ? 2 : 3;

    if (this.red1 == 1 || this.red1 == 2) {
      this.roundData();
      this.mydata();
      this.airlineLogo_func();
      // this.filterShowing();
    } else {
      this.airlineLogo_func();
      this.roundModify = false;
      this.onewayModify = false;
      this.multiModify = true;
      this.red1 = 3;
      console.log(this.red1, "reeddd111....");
      // this.paxhide = false
      localStorage.removeItem("rountData");
      // this.multi_city = JSON.parse(this.$route.query.muti_city);
      // console.log(this.multi_city.itinery, " this.multi_city this.multi_city");
      // this.tripData.from = this.multi_city.itinery[0].from;
      // this.tripData.to =
      //   this.multi_city.itinery[this.multi_city.itinery.length - 1].to;
      // this.tripData.dedate = moment(this.multi_city.itinery[0].departDate).format(
      //   "DD MMM-YYYY"
      // );
      // this.tripData.redate=this.multi_city.itinery[this.multi_city.itinery.length-1].depdate
      console.log(
        this.tripData.dedate,
        this.multi_city.itinery[0].departDate,
        "this.tripData.dedate"
      );
      this.tripData.class = this.multi_city.class;
      this.aduls = this.multi_city.adult;
      this.aduls2 = this.multi_city.infrant;
      this.aduls1 = this.multi_city.child;
      this.classType = this.multi_city.class;

      console.log(
        this.multi_city,
        "this.showMulti_datauerthis.showMulti_datay"
      );

      // this.showMulti_data = this.multi_city
      // this.formMultiData = JSON.parse(this.$route.query.muti_city);
      this.multiDataConvert();

      // this.filterShowing();

      console.log(this.multi_city, "this.$route.querythis.$route.query....1");

      if (this.multi_city.itinery.length == 4) {
        console.log(
          this.multi_city,
          this.multi_city.itinery.length,
          "lengthlen.....gthlength"
        );
        this.addmore = false;
        this.clearall = true;
      }
    }

    // console.log(this.red1, "this.red1 this.red1 this.red1 this.red1 ");
    // if (this.red1 == 1) {
    //   this.sended1();
    // }
    // if (this.red1 == 2) {
    //   this.sendedone1();
    // }
    this.generateBookingRefId();
    this.actActive();

    this.deatailData();
    this.clicked1 = true;
    this.clicked2 = true;
    window.addEventListener("resize", this.handleResize);
    window.addEventListener("resize", this.handleCalenderResize);
  },
  beforeUnmount() {
    document.body.removeEventListener("click", this.handleOutsideClick);
  },

  mounted() {
    document.getElementById("navbar").style.display = "none";
    document.getElementById("food").style.display = "none";
    document.body.addEventListener("click", this.handleOutsideClick);
    // this.autocompletJson();
    // this.fetchItems();
    this.handleResize();
    this.handleCalenderResize();
  },
};
</script>

<style scoped>
@import "@/assets/style.css";
.custom-card {
  padding: 10px 10px;
  overflow: unset;
  transition: 2s;
  color: white;
}

.showSearch {
  width: auto;
  border: 1px solid lightgray;
  border-radius: 2px;
  padding: 6px;
  /* margin: 0px 8px; */
  box-shadow: 0px 1px 2px 0px gray;
}

/* Filter By Section*/

>>> .filter_icons .v-selection-control--density-default {
  --v-selection-control-size: 30px !important;
}

>>> .filter_icons .v-selection-control .v-label {
  font-size: 14px;
  font-weight: 500 !important;
  color: black;
}
>>> .v-selection-control .v-label {
  color: black;
}

>>> .filter_icons .v-selection-control__input .v-icon {
  font-size: 20px !important;
}

>>> .filter_icons .price_slider .v-slider-track {
  --v-slider-track-size: 3px !important;
}

>>> .filter_icons .price_slider .v-slider-thumb {
  --v-slider-thumb-size: 16px !important;
}

/* Filter By Section End*/

.bg-image {
  /* height: auto;
  background-image: url("../assets/tourist_vector.jpg");
  background-size: 100% 100%;
  background-position: left; */

  /* margin-top:70px; */
}

.dp__pointer {
  cursor: pointer;
  border: none;
  border-bottom: 1px solid grey;
  border-radius: 0px;
  width: 100%;
}

>>> .dp__input_icon {
  cursor: pointer;
  position: absolute;
  top: 64%;
  left: 82%;
  color: black !important;
  transform: translateY(-50%);
  color: var(--dp-icon-color);
}

/* .mdi-menu-down::before {
  content: "\F035D222";
  display: none !important;
} */

.v-input__append > .v-icon {
  position: relative;
  right: 43px;
  top: -4px;
}

.dp__input_wrap {
  position: relative;
  width: 100%;
  box-sizing: unset;
  top: -10px;
}

.labeling {
  position: relative;
  bottom: 9px;
}

.input-styles .v-input--density-default {
  --v-input-control-height: 38px;
  position: relative;
  top: -22px;
}

.v-slider.v-input--horizontal {
  position: relative;
  top: 0px;
}

.cart-adult {
  padding: 15px;
  width: 500px;
  cursor: pointer;
  position: relative;
  top: -65px;
}

>>> .dp__clear_icon {
  display: black;
  position: absolute;
  top: 50%;
  /* right: 0; */
  transform: translateY(-50%);
  cursor: pointer;
}

>>> .dp__overlay_col[aria-disabled="true"] {
  display: none;
}

.adult {
  border: 1px solid lightgrey;
  border-radius: 5px;
  background-color: #f7f7f7;
}

.adult-plus {
  text-align: center;
  font-size: 15px;
  font-weight: 500;
  color: #05386d;
}

.adult-title {
  font-size: 12px;
  font-weight: 600;
  color: #05386d;
}

.price-head .price-currency {
  font-size: 18px;
  font-weight: 600;
  color: #000000;
  padding-top: 17px;
  padding-right: 6px;
}

.price-head .price-Amount {
  font-size: 36px;
  font-weight: 600;
  color: #000000;
}

.price-head .price-Decimal {
  color: #000000;
}

>>> .selected_btn .v-btn__content {
  font-size: 17px;
  font-weight: 600;
  text-transform: uppercase !important;
}

.radio {
  background-color: #3c0ec2 !important;
  color: white !important;
  box-shadow: 0 4px 16px rgba(17, 17, 26, 0.05),
    0 8px 32px rgba(17, 17, 26, 0.05) !important;
}

/* >>>.normal-radio .v-btn__content{
  color:black;
} */

.normal-radio {
  font-size: 12px !important;
  font-weight: 500;
  color: black;
  height: 28px;
  width: 90px;
  padding-right: 10px;
  padding-right: 9px;
  border: 2px solid white;
  /* border: 1px solid #0000003d; */
  box-shadow: none;
  border-radius: 5px;
  text-transform: capitalize;
}

/* .normal-radio span {

 } */

/* .normal-radio {
  font-size: 12px !important;
  font-weight: 600;
  height: 30px;
  width: auto;
  color:black;
} */

.air-title {
  background-color: #ff5400;
  color: white;
  padding: 5px;
}

.cart1 {
  box-shadow: none;
  border: 1px solid lightgray;
  font-size: 15px;
  font-weight: 500;
  max-width: 208px;
  border-radius: 5px;
}

.cart1 p {
  text-align: center;
}

hr {
  margin: 0rem 0 !important;
}

.f-size-16 {
  font-size: 16px;
}

.f-size-15 {
  font-size: 15px;
}

.f-size-14 {
  font-size: 14px;
}

.f-size-13 {
  font-size: 13px;
}

.f-size-12 {
  font-size: 12px;
}

.f-size-11 {
  font-size: 11px;
}

.f-size-10 {
  font-size: 10px;
}

.f-size-9 {
  font-size: 9px;
}

.fw-500 {
  font-weight: 500;
}

.rec-num {
  font-size: 14px;
  font-weight: 600;
  color: navy;
}

.bord {
  border-right: 2px solid gray;
  position: relative;
  top: -2px;
  height: 63px;
}

.depar {
  background-color: lightgray;
  padding: 3px;
}

.res-name {
  /* font-size: 15px; */
  font-weight: 500;
  margin: 0;
  /* margin-top: 5px; */
  /* padding: 0px 0px 0px 8px; */
}

.res-stop {
  font-size: 12px;
  font-weight: 600;
  color: #6a2e4d;
}

/* .res-stop {
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 0px !important;
  position: relative;
  top: 2px;
  right: 12px;
  color: grey;
} */

.res-time {
  font-size: 11px;
  font-weight: 500;
}

.dot-1 {
  width: 8px;
  height: 8px;
  border: 1px solid black;
  background-color: black;
  border-radius: 50%;
  position: relative;
  top: 42%;
  left: 30%;
  z-index: 1;
}

.dot-2 {
  width: 8px;
  height: 8px;
  border: 1px solid black;
  background-color: black;
  border-radius: 50%;
  position: relative;
  top: 18%;
  left: 50%;
  z-index: 1;
}

.res-icon {
  border: 1px solid #05386d;
  position: relative;
  top: 14px;
  width: 92%;
}

.res-icon1 {
  float: right;
  color: #05386d;
  font-size: 18px;
}

>>> .dp__menu_inner {
  padding: var(--dp-menu-padding);
  box-shadow: 0px 0px 4px 0px black;
  background-color: #f5f5f521;
}

>>> .v-input__details {
  display: none;
}

.v-input--density-default {
  --v-input-control-height: 38px;
}

.stop {
  font-size: 16px;
  font-weight: 500;
  padding: 7px;
}

.v-slider.v-input--horizontal .v-slider-thumb {
  top: 50%;
  transform: translateY(-50%);
  color: white;
  border: 3px solid #3c3cde;
  border-radius: 50px;
}

.v-slider.v-input--horizontal .v-slider-track__fill {
  height: 3px;
  background-color: #7474e0;
}

/*  */
.v-slider-thumb__surface {
  height: 12px !important;
  width: 12px !important;
}

.v-checkbox .v-selection-control {
  min-height: var(--v-input-control-height);
  position: relative;
  top: 12px;
}

>>> .v-timeline-divider__dot--size-large {
  height: 22px;
  width: 56px;
}

>>> .v-timeline-divider__inner-dot {
  border-radius: 15px;
}

>>> .v-timeline-divider__inner-dot span {
  font-size: 11px;
  font-weight: 600;
}

.mdi-airplane-takeoff {
  content: "\F05D5";
  margin-top: 0px;
  margin-right: 10px;
  color: gray;
}

.mdi-airplane-landing {
  content: "\F05D4";
  margin-top: 0px;
  margin-right: 10px;
  color: gray;
}

>>> .dp__input_icon {
  display: none;
  top: 64%;
  left: -5% !important;
}

>>> .p-autocomplete-loader {
  position: absolute;
  top: 50%;
  right: 5px;
  margin-top: -0.5rem;
  left: 13 5px !important;
  display: block !important;
}

>>> .p-icon {
  display: none;
}

.p-error {
  font-size: 12px;
  color: red;
  position: absolute;
}

.dropdown-box {
  padding: 8px 10px;
  color: white;
  background-color: lightslategray;
  border-bottom: 1px solid white;
}

.dropdown-box:hover {
  background-color: black;
  color: white;
  border-radius: 10px;
  /* background-color: lightgray;
  color: black; */
}

>>> .p-autocomplete-dd .p-autocomplete-input,
.p-autocomplete-dd .p-autocomplete-multiple-container {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  outline: none !important;
}

.p-float-label {
  width: 100%;
  font-size: 16px;
  color: black;
  font-weight: 500;
}

.p-float-label label {
  position: relative;
  pointer-events: none;
  top: -60px !important;
  left: -30px;
  margin-top: -0.5rem;
  transition-property: all;
  transition-timing-function: ease;
  line-height: 1;
  color: black;
  font-weight: 600;
  /* font-size: 16px;
  color: #242424;
  font-weight:600; */
}

.p-component * {
  box-sizing: border-box;
  /* padding: 2px;
  padding-top: 4px;
  padding-bottom: 4px; */
}

>>> .p-input-filled {
  box-shadow: 1px 1px 6px 0px black !important;
}

.dp__icon {
  display: block;
}

.v-icon {
  --v-icon-size-multiplier: 1;
  align-items: center;
  justify-content: flex-start;
}

>>> .dp__input {
  border: none;
  /* border-bottom: 1px solid lightgrey !important; */
  border-radius: 0px !important;
  padding-left: 2px;
  padding-right: 2px;
  height: 26px;
  font-family: "Poppins", sans-serif !important;
}

.mdi-calendar {
  margin-right: 10px;
  width: auto;
  color: grey;
}

.calendar-container {
  display: flex;
  position: relative;
}

.label-top {
  position: absolute;
  top: -10px !important;
  left: -48px !important;
  transform: translateY(-100%);
  background-color: transparent;
  padding: 0 8px;
  font-size: 14px;
  color: white;
  /* color: #242424; */
  font-weight: 600;
  /* transition: all 0.3s ease-out; */
  pointer-events: none;
}

.datepicker-label {
  position: absolute;
  /* top: 28px !important; */
  left: 44% !important;
  transform: translateY(-110%);
  background-color: transparent;
  padding: 0 12px;
  font-size: 14px;
  color: #242424;
  font-weight: 600;
  /* transition: all 0.3s ease-out; */
  pointer-events: none;
  z-index: 1;
}
.datepicker-label3 {
  position: absolute;
  left: 46% !important;
  transform: translateY(-110%);
  background-color: transparent;
  padding: 0 12px;
  font-size: 14px;
  color: #242424;
  font-weight: 600;
  pointer-events: none;
  z-index: 1;
}

.label-top1 {
  position: absolute;
  top: 0 !important;
  left: -8px !important;
  transform: translateY(-100%);
  background-color: transparent;
  padding: 0 8px;
  font-size: 14px;
  color: #242424;
  font-weight: 600;
  /* transition: all 0.3s ease-out; */
  pointer-events: none;
}

.datepicker-label2 {
  position: absolute;
  /* top: 28px !important; */
  left: 61% !important;
  transform: translateY(-110%);
  background-color: transparent;
  padding: 0 12px;
  font-size: 1rem;
  font-weight: 600;
  color: #242424;
  font-size: 14px;
  /* transition: all 0.3s ease-out; */
  pointer-events: none;
  z-index: 1;
}

.multiLabel-top {
  position: absolute;
  left: -8px !important;
  bottom: 0px !important;
  transform: translateY(-100%);
  background-color: transparent;
  padding: 0 8px;
  font-size: 12px;
  color: #242424;
  /* transition: all 0.3s ease-out; */
  pointer-events: none;
}

.multiDatepicker-label {
  position: absolute !important;
  top: 28px !important;
  left: -9px !important;
  transform: translateY(-100%);
  background-color: transparent !important;
  padding: 0 8px;
  font-size: 1rem;
  color: #999;
  /* transition: all 0.3s ease-out; */
  pointer-events: none;
  z-index: 1;
}

.delete-icon {
  display: flex;
  justify-content: end;
}

.hoverData1 {
  position: absolute;
  left: 50%;
  top: -33px;
  width: 200px;
  border-radius: 5px;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.8);
  color: white !important;
  padding: 5px;
  text-align: center;
  opacity: 0;
  transition: opacity 0.3s;
}

.hoverDataStop1 {
  position: absolute;
  left: 38.4%;
  top: -34px;
  width: 200px;
  border-radius: 5px;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.8);
  color: white !important;
  padding: 5px;
  text-align: center;
  opacity: 0;
  transition: opacity 0.3s;
  height: 50px;
}

.hoverDataStop2 {
  position: absolute;
  left: 62%;
  top: -34px;
  width: 200px;
  border-radius: 5px;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.8);
  color: white !important;
  padding: 5px;
  text-align: center;
  opacity: 0;
  transition: opacity 0.3s;
  height: 50px;
}

.content {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  /* cursor: pointer; */
}

/* .content:hover{
  width:100%;
  font-size:13px;
} */
.hoverData2 {
  position: absolute;
  left: 63%;
  bottom: 63px;
  border-radius: 5px;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.8);
  color: white !important;
  padding: 5px;
  text-align: center;
  opacity: 0;
  transition: opacity 0.3s;
}

.hoverData1.visible {
  visibility: visible;
  opacity: 1;
}

.hoverDataStop1.visible {
  visibility: visible;
  opacity: 1;
}

.hoverDataStop2.visible {
  visibility: visible;
  opacity: 1;
}

.hoverData2.visible {
  visibility: visible;
  opacity: 1;
}

.hoverData1::before {
  content: "";
  position: absolute;
  bottom: -5px;
  left: 50%;
  transform: translateX(-50%) rotate(45deg);
  width: 10px;
  height: 10px;
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
}

.hoverDataStop1::before {
  content: "";
  position: absolute;
  bottom: -5px;
  left: 50%;
  transform: translateX(-50%) rotate(45deg);
  width: 10px;
  height: 10px;
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
}

.hoverDataStop2::before {
  content: "";
  position: absolute;
  bottom: -5px;
  left: 50%;
  transform: translateX(-50%) rotate(45deg);
  width: 10px;
  height: 10px;
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
}

.hoverData2::before {
  content: "";
  position: absolute;
  bottom: -5px;
  left: 50%;
  transform: translateX(-50%) rotate(45deg);
  width: 10px;
  height: 10px;
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
}

.refunt {
  color: blue;
}

>>> .v-timeline--vertical.v-timeline {
  grid-row-gap: 1px;
}

>>> .v-timeline--vertical.v-timeline {
  /* grid-row-gap: 24px; */
  height: auto !important;
}

.filter-toggle {
  display: none;
}

.v-card {
  border-radius: 10px !important;
  z-index: revert-layer;
  overflow: unset;
}

.hoveredCard {
  border: 1px solid #ccc;
  border-radius: 1px !important;
}

.hoveredCard:hover {
  cursor: pointer;
  box-shadow: 0px 0px 3px 3px #1700ff29;
  /* box-shadow: 0px 0px 4px 0px black; */
  /* box-shadow: none; */
  /* border: 2px solid lightgray; */
  /* border-radius: 8px !important; */
}

/* .card-in {
  box-shadow: none;
} */

/* .card-in:hover {
  box-shadow: 0px 0px 1px 0px black;
} */

.card-in {
  border-top: 2px solid lightgray;
  border-bottom: 2px solid lightgray;
  border-radius: 0 !important;
  box-shadow: none !important;
  /* box-shadow: 0px 0px 1px 0px black; */
}

.time-section .v-icon {
  font-size: 12px !important;
  color: #6a2e4d;
}

.time-section span {
  font-size: 9px;
  margin-left: 5px;
  font-weight: 500;
  color: #6a2e4d;
}

.clear_style {
  border-radius: 5px;
  padding: 4px 6px;
  height: 30px;
  color: white;
  background: red;
}

/* .clear_style:hover {
  background-color: #ffe9e9;
  color:black !important;
} */

.add_style {
  border-radius: 5px;
  padding: 4px;
  height: 30px;
  cursor: pointer;
  color: white;
  background: #26225e;
}
.passengers-data .input-styles {
  border-radius: 2rem;
}
>>> .passengers-data .v-select__menu-icon {
  color: black;
}
/* .add_style:hover {
  background-color: #e9ffe9;
} */

@media only screen and (max-width: 991px) {
  .filter-toggle {
    display: block;
  }

  .magnify-btn .v-btn {
    width: 140px !important;
  }
  .magnify-btn1 .v-btn {
    width: 140px !important;
  }
  .Timing-section {
    padding: 0px 20px !important;
  }
}

@media only screen and (max-width: 768px) {
  .filter-toggle {
    display: block;
  }

  .airline-logo {
    width: auto;
  }

  .airline-logo .v-img {
    width: 65%;
  }

  .res-name {
    /* font-size: 15px; */
    font-weight: 500;
    margin: 0;
    /* margin-top: 5px; */
    /* padding: 0px 0px 0px 12px; */
  }

  /* .res-stop {
    font-size: 12px;
    font-weight: 600;
    margin-bottom: 0px !important;
    position: relative;
    top: 2px;
    right: 10px;
    color: grey;
  } */

  .time-section .v-icon {
    font-size: 12px !important;
    color: grey;
  }

  .time-section span {
    font-size: 9px !important;
    font-weight: 500 !important;
    color: grey;
  }

  .airline-logo {
    padding: 5px;
  }

  .airline-logo .v-img {
    width: 20px !important;
  }

  .airline-profile {
    padding: 0;
  }

  .airline-name {
    font-size: 8px;
  }

  .airline-id {
    font-size: 6px;
  }

  .aircode-name {
    font-size: 11px;
  }

  .airline-date {
    font-size: 8px;
  }

  .airline-time {
    font-size: 10px;
  }

  .class-type .v-icon {
    font-size: 11px !important;
  }

  .class-type span {
    font-size: 9px;
  }
}

@media only screen and (min-width: 479px) {
  .aircode-hide {
    display: block;
  }

  .aircode-show {
    display: none;
  }

  .airlineLogo-hide {
    display: flex !important;
  }

  .airlineLogo-show {
    display: none !important;
  }

  .select-section-hide {
    display: flex !important;
  }

  .select-section-hide1 {
    display: flex !important;
  }

  .date-section-hide {
    display: block;
  }

  .date-section-show {
    display: none;
  }

  .fare-detail-mobile {
    display: none;
  }
}

@media only screen and (max-width: 480px) {
  .datepicker-label {
    left: 7% !important;
  }
  .datepicker-label2 {
    left: 7% !important;
  }
  .datepicker-label3 {
    left: -17% !important;
  }
  .rountdata,
  .multicityData {
    border-right: none !important;
  }
  .onewayData {
    border-left: none !important;
  }
  .flight-detail-wrap .flight-date {
    width: 100% !important;
  }
  .hoverDataStop1 {
    left: 38%;
  }

  .hoverDataStop2 {
    left: 59%;
  }

  .airlineLogo-hide {
    display: none !important;
  }

  .airlineLogo-show {
    display: block !important;
  }

  /* .pax-fonts span[data-v-92fd54d6] {
    font-size: 12px;
    color: #242424;
    font-weight: 700;
  } */
  .pax-fonts span {
    font-size: 14px !important;
  }
  >>> .modify-data .v-select__selection-text {
    font-size: 14px !important;
  }
  >>> .passengers-data .v-select__menu-icon {
    margin-inline-start: 0px !important;
  }
  >>> .passengers-data .v-field__input {
    padding-inline-start: 0px;
    padding-inline-end: 0px;
  }
  >>> .passengers-data .v-field {
    grid-template-columns: 0% 86%;
  }
  >>> .passengers-data .modify-data .v-field {
    grid-template-columns: 0% 77%;
  }
  >>> .passengers-data .v-select .v-select__selection {
    margin-top: 0px;
  }

  .aircode-hide {
    display: none;
  }

  .aircode-show {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .select-section-hide {
    display: none !important;
  }

  .select-section-hide1 {
    display: none !important;
  }
  .price-head .price-currency {
    padding-top: 10px;
  }

  .res-name {
    font-size: 10px;
    font-weight: 500;
    margin: 0;
  }

  .airlineLogo-show .mbl-price-section {
    margin-bottom: 0px !important;
  }

  .airlineLogo-show .mbl-price-section .price-currency {
    margin-bottom: 0px !important;
    font-size: 14px !important;
    font-weight: 600;
    color: black;
    padding-top: 10px;
    padding-right: 4px;
  }

  .airlineLogo-show .mbl-price-section .price-Amount {
    margin-bottom: 0px !important;
    font-size: 26px !important;
  }

  .airlineLogo-show .mbl-price-section .content {
    margin-bottom: 0px !important;
  }

  .airlineLogo-show .v-btn {
    height: 30px !important;
  }

  >>> .airlineLogo-show .v-btn .v-btn__content {
    font-size: 15px !important;
    /* padding-left: 7px; */
  }

  .date-section-hide {
    display: none;
  }

  .date-section-show {
    display: block;
  }

  .Timing-section {
    padding: 0px 20px !important;
  }

  .fare-detail-mobile {
    display: block;
  }

  .fare-detail-mobile .v-card-text {
    color: rgb(51, 102, 204);
    font-weight: 500;
    padding-left: 0 !important;
    text-align: left;
    width: auto !important;
  }

  .fare-detail-mobile .v-icon {
    font-size: 24px !important;
    height: 18px !important;
    width: 24px !important;
    position: relative !important;
    right: 60px !important;
    top: 2px !important;
  }

  .itin_Borders {
    box-shadow: inset 0px 0px 3px 0px black;
    padding-top: 16px;
    border: 1px solid black;
    margin-bottom: 8px;
    border-radius: 4px;
  }
}

>>> .v-field__outline {
  display: none;
}

.input-styles {
  box-shadow: 0px 1px 2px 0px gray;
  padding: 5px;
  background: white;
  /* background: #ffffff82; */
  border-radius: 7px;
  height: 38px;
}

.input-styles-1 {
  box-shadow: 0px 1px 2px 0px gray;
  padding: 5px;
  background: #ffffff82;
  border-radius: 7px;
  height: 30px;
  width: 100%;
}

.form-card {
  width: 90%;
  padding: 20px;
  overflow: unset;
  z-index: 1;
  cursor: pointer;
  background-color: rgba(255, 255, 255, 0.25);
  box-shadow: none !important;
}

.back-bg {
  margin: 20px;
  width: 500px;
  height: 500px;
  border-radius: 30px;
}

.fromTop {
  font-size: 14px;
  font-weight: 600;
  position: absolute;
  left: 20px;
  top: 60px;
  color: gray;
}

.toTop {
  font-size: 14px;
  font-weight: 600;
  position: absolute;
  left: 52%;
  top: 60px;
  color: gray;
}

>>> .normal-radio .v-label {
  font-weight: 600 !important;
  opacity: 1 !important;
}

.round-mode,
.oneway-mode {
  padding: 10px 10px;
  overflow: unset;
  box-shadow: none;
  border: 2px solid lightgray;
  border-radius: 5px !important;
}

/* Traveller Pax Dropdown section */

>>> .input-styles .select-1 {
  --v-field-padding-top--plain-underlined: 12px;
}

>>> .input-styles .select-1 .v-field__input {
  padding-top: 22px;
}

>>> .input-styles .select-1 .v-field__append-inner {
  padding-top: 23px;
}

>>> .select-2 .v-input {
  --v-input-chips-margin-bottom: 0 !important;
  --v-input-chips-margin-top: -3px !important;
}

>>> .select-2 .v-input--density-default {
  top: 0;
}

>>> .select-2 .v-field__input {
  padding-top: 0px;
}

>>> .select-2 .v-field__append-inner {
  padding-top: 0px;
}

/* Traveller Pax Dropdown section End */

.p-float-label .p-inputwrapper-focus ~ label {
  /* display:none !important; */
  font-size: 14px !important;
  /* top: -1.2rem !important;
  left: -38px; */
  /* color: #242424; */
  font-weight: 600;
}

.p-float-label .p-inputwrapper-filled ~ label {
  /* display:none !important; */
  font-size: 14px !important;
  /* top: -1.2rem !important;
  left: -38px; */
  /* color: white; */
  /* color: #242424; */
  font-weight: 600;
}

.p-inputtext {
  font-size: 16px !important;
  font-weight: 600px !important;
}

.pax-fonts span {
  font-size: 16px;
  color: #242424;
  font-weight: 600;
}

.dialog-bottom-transition-enter-active,
.dialog-bottom-transition-leave-active {
  transition: transform 0.2s ease-in-out;
}

>>> .dp__month_year_select {
  pointer-events: none;
}

>>> .dp__today {
  border: 1px solid #162849 !important;
}

>>> .dp__date_hover:hover {
  background: #162849;
  color: white;
}

>>> .dp__menu.dp__menu_index.dp__theme_light.dp__menu_transitioned {
  top: -10px !important;
  left: -50px !important;
  transform: none !important;
}

#navbar {
  display: block;
}

#food {
  display: block;
}

/* >>>.v-overlay__content{
  left:950px !important;
} */

.toggle {
  width: 120px;
  background: white;
  color: tomato;
  border: 0;
  padding: 5px;
  cursor: pointer;
}

.close {
  background: white;
  border: 0;
  cursor: pointer;
}

.right-drawer {
  position: absolute;
  top: 0;
  right: 0;
  width: 0; /* initially */
  overflow: hidden;
  height: 100vh;
  padding-left: 0; /* initially */
  border-left: 1px solid whitesmoke;
  background: white;
  z-index: 200;
  transition: all 0.2s;
}

.drawer-mask {
  position: absolute;
  left: 0;
  top: 0;
  width: 0; /* initially */
  height: 100vh;
  background: transparent;
  opacity: 0.3;
  z-index: 199;
  transition: opacity 0.2s;
}
>>> .modify-data .select-2 .v-field__input {
  padding-top: 23px !important;
}
>>> .modify-data .select-2 .v-field__append-inner {
  padding-top: 23px !important;
}
>>> .modify-data .v-select__selection-text {
  font-size: 16px;
  color: #242424;
  font-weight: 600;
  padding-left: 5px;
  margin-top: 2px;
}

/* modal */
.modal {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
}

.modal-content {
  background-color: white;
  padding: 1rem 1.5rem;
  width: 80%;
  margin: auto;
  /* top: 8%; */
  border-radius: 0.5rem;
  /* position: absolute; */
}

.close-button {
  position: absolute;
  top: 0.5rem;
  right: 2.5rem;
  cursor: pointer;
  font-size: 1.5rem;
  color: gray;
}

.close-button:hover {
  color: darkgray;
}

.trigger {
  /* padding: 0.5rem 1rem; */
  /* background-color: #007bff; */
  color: white;
  border: none;
  border-radius: 0.25rem;
  cursor: pointer;
}
.modify-btn {
  display: none !important;
}
.modify-btn-web {
  display: flex !important;
}

.magnify-btn2 {
  display: none !important;
}
.magnify-btn1 {
  display: block !important;
}
.passengers-data .input-styles {
  height: 28px;
}
>>> .passengers-data .input-styles .v-input--density-default {
  top: -27px;
}
@media screen and (max-width: 480px) {
  /* .modify-form .col-12 {
    width: 95% !important;
  } */
  .modal-content {
    width: 100%;
  }
  .modify-btn {
    display: block !important;
  }

  /* >>>.modify-btn .v-btn .v-btn--elevated .v-theme--light .v-btn--density-default .v-btn--size-default .v-btn--variant-elevated{
    height: 66px !important;
    margin-top: 8px !important;
  } */
  .modify-btn-web {
    display: none !important;
  }
  .f-size-24 {
    font-size: 16px !important;
  }
  .magnify-btn2 {
    display: flex !important;
  }
  .magnify-btn1 {
    display: none !important;
  }
  .magnify-btn {
    display: none !important;
  }
}
.f-size-24 {
  font-size: 24px;
}
.price-col {
  display: none;
}

@media screen and (min-width: 481px) and (max-width: 992px) {
  .price-col {
    display: block;
  }
  .airportName {
    display: none;
  }
}

>>> .outbound .v-input {
  border: 1px solid lightgray;
  border-radius: 8px;
}
>>> .inbound .v-input {
  border: 1px solid lightgray;
  border-radius: 8px;
}
/* >>>.outbound .v-select__selection .v-select__selection-text{
  --v-input-padding-top: 6px !important;
} */

>>> .outbound .v-field {
  height: 35px;
}
>>> .outbound .v-field .v-field__field {
  height: 35px;
  top: -10px;
}
>>> .outbound .v-field__input .v-input--density-default {
  --v-input-padding-top: 6px !important;
}
>>> .inbound .v-field {
  height: 35px;
}
>>> .inbound .v-field .v-field__field {
  height: 35px;
  top: -10px;
}
>>> .inbound .v-field__input .v-input--density-default {
  --v-input-padding-top: 6px !important;
}
.rountdata,
.multicityData {
  border-right: 1px dashed #d2d2d2;
}
.onewayData {
  border-left: 1px dashed #d2d2d2;
}
.disabled {
  pointer-events: none;
  opacity: 0.5;
}
/* .trigger:hover {
    background-color: #0056b3;
} */

>>> .p-autocomplete:has(.p-autocomplete-dropdown) .p-autocomplete-loader {
  right: 0;
}
.inter-input {
  width: 100%;
  background: transparent;
  border: none;
  font-size: 16px;
  font-weight: 600;
  /* color: #6a2e4d; */
  color: black;
  /* padding: 20px 20px; */
  height: 25px;
  font-family: "Barlow", sans-serif;
}
.vas-autocomplete input::placeholder {
  font-size: 16px !important;
  font-weight: 600 !important;
  color: black !important;
  font-family: "Barlow", sans-serif !important;
}

.multicity .p-float-label .dp__main .dp__input_wrap input::placeholder {
  font-size: 16px !important;
  font-weight: 600 !important;
  color: black !important;
  font-family: "Barlow", sans-serif !important;
}
>>> .dp__main .dp__input_wrap input::placeholder {
  font-size: 16px !important;
  font-weight: 600 !important;
  color: black !important;
  font-family: "Barlow", sans-serif !important;
}
>>> .dp__main .dp__input_wrap input {
  font-size: 16px !important;
  font-weight: 600 !important;
  color: black !important;
  font-family: "Barlow", sans-serif !important;
}
.autocompete-menual-dropdown {
  border: 1px solid lightgray;
  background-color: white;
  cursor: pointer;
  max-height: 250px;
  overflow-y: auto;
  position: absolute;
  width: 300px;
  /* max-width: 100%; */
  padding: 4px;
  border-radius: 2px;
  margin-top: 18px;
  /* margin-left: -44px; */
  z-index: 99999;
  font-size: 14px;
  font-weight: 500;
}
.flight-info p {
  color: #6a2e4d !important;
}
.itineryDatas span {
  display: block;
}
.pricePer {
  font-size: 13px;
  color: #6a2e4d;
  line-height: 1.4;
  margin-bottom: 5px;
}
.layover-section {
  position: relative; /* Ensure relative positioning for the section */
  margin-top: 8px; /* Space above the section */
}

.dashed-line {
  border-bottom: 1px dashed gray;
  width: 100%;
}

.layover-time {
  font-size: 11px;
  color: #6a2e4d;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: -8px;
  background-color: #ebebee;
  padding: 1px 12px;
  border-radius: 8px;
  font-weight: 500;
}
p {
  font-family: "Barlow", sans-serif !important;
}
span,
li {
  font-family: "Barlow", sans-serif !important;
}
.layover-container {
  display: inline-block; /* Ensures the layover time and icon stay on the same line */
  position: relative;
}

.overnight-info {
  display: inline-block;
  cursor: pointer;
  font-size: 12px;
  color: #6a2e4d;
  margin-left: 2px;
  position: relative;
  top: -1px;
}

.icon-info {
  display: inline-block;
  background-color: #6a2e4d; /* Maroon background */
  color: #ffffff; /* White color for the "i" */
  border-radius: 50%; /* Makes it a circle */
  width: 18px; /* Adjust the width and height for the size of the icon */
  height: 18px;
  text-align: center; /* Centers the "i" inside the circle */
  line-height: 18px; /* Vertically aligns the text */
  font-size: 12px;
  font-weight: bold;
}

.tooltip-text {
  visibility: hidden;
  background-color: #333;
  color: #fff;
  text-align: center;
  border-radius: 5px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 100%; /* Position the tooltip above the icon */
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap;
  font-size: 13px;
}

.overnight-info:hover .tooltip-text {
  visibility: visible;
}
</style>
