<template>
  <div class="bg-img">
    <div style="margin-left:100px; margin-top:100px;" class="pb-5">
      <p class="err-text">{{ $t("pageNotFound.text1")}}</p>
      <p class="err-content">{{ $t("pageNotFound.text2") }}</p>
      <v-btn width="200px" height="50px" color="#26225e" @click="$router.push('/')">
        {{ $t("pageNotFound.backHomeBtn") }}
      </v-btn>
    </div>

    
  </div>
</template>
<script>

export default {

  data() {
    return {

    };
  },


}

</script>
<style>
.bg-img {
  background-image: url('../assets/img/images/pagenot.jpg');
    background-repeat: no-repeat;
    background-position: bottom right;
    width: 100%;
    height: 100%;
    background-size: 62% 113%;

}
.err-text{
  font-size: 4rem;
    font-weight: 400;
    margin: 0;
}
.err-content{
  font-size: 22px;
  font-weight: 400;
  width:33%;
}


@media only screen and (max-width: 480px) {

  .err-text{
  font-size: 3rem;
    font-weight: 400;
    margin: 0;
}
.err-content{
  font-size: 12px;
    font-weight: 400;

}

}



</style>